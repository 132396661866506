/* eslint-disable jam3/no-sanitizer-with-danger */

import React from 'react'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import { withStyles } from '@material-ui/core'
import ButtonLink from 'components/button_link/View'
import NxtVirtualCurrency from 'components/nxt_virtual_currency'
import styles from './View.Style'
import { timelineConceptMap } from './View.Utils'

const BalanceCard = props => {
  const {
    classes,
    concept,
    amountChanged,
    totalBalance,
    isBalanceRising,
    ideaName,
    ideaId,
    navigateToIdea,
  } = props

  const renderConcept = () => {
    switch (concept) {
      case 'IDEA_APROVED':
      case 'IDEA_PROTOTYPED':
      case 'IDEA_SUPPORTED':
        return (
          <>
            <ButtonLink
              classes={{ linkButton: classes.linkButton }}
              onLinkClick={() => navigateToIdea(ideaId)}
            >
              {ideaName}
            </ButtonLink>
            <span dangerouslySetInnerHTML={{ __html: timelineConceptMap[concept] }} />
          </>
        )
      case 'COMMENT_DELETE':
      case 'NEW_COMMENT':
      case 'INVESTMENT':
      case 'CONCEPT_INVEST_REWARD':
        return (
          <>
            <span dangerouslySetInnerHTML={{ __html: timelineConceptMap[concept] }} />
            <ButtonLink
              classes={{ linkButton: classes.linkButton }}
              onLinkClick={() => navigateToIdea(ideaId)}
            >
              {ideaName}
            </ButtonLink>
          </>
        )
      default:
        return <span dangerouslySetInnerHTML={{ __html: timelineConceptMap[concept] }} />
    }
  }
  return (
    <>
      <div className={classes.title}>
        <span className="icon-funds" />
        {isBalanceRising ? (
          <span>{i18n.t('TIMELINE.BALANCE_UP')}</span>
        ) : (
          <span>{i18n.t('TIMELINE.BALANCE_DOWN')}</span>
        )}
        <NxtVirtualCurrency classes={{ root: classes.currency }}>
          {amountChanged}
        </NxtVirtualCurrency>
      </div>
      <div className={classes.body}>
        <div>{renderConcept()}</div>
        <div>
          <span>{i18n.t('TIMELINE.ACTUAL_BALANCE')}</span>
          <span className={classes.moneyBody}>
            <NxtVirtualCurrency classes={{ root: classes.currency }}>
              {totalBalance}
            </NxtVirtualCurrency>
          </span>
        </div>
      </div>
    </>
  )
}

BalanceCard.defaultProps = {
  ideaName: '',
  ideaId: '',
}

BalanceCard.propTypes = {
  concept: PropTypes.string.isRequired,
  amountChanged: PropTypes.string.isRequired,
  totalBalance: PropTypes.string.isRequired,
  isBalanceRising: PropTypes.bool.isRequired,
  ideaName: PropTypes.string,
  ideaId: PropTypes.string,
  navigateToIdea: PropTypes.func.isRequired,
}

export default withStyles(styles)(BalanceCard)
