
/* eslint-disable prefer-const */
/* eslint-disable  object-shorthand */

import React from 'react'
import { connect } from 'react-redux'
import debounce from 'debounce'
import View from './View'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import {
  showPopupDialog,
  showPopupErrorSnackbar,
  showPopupSuccessSnackbar,
} from 'support/popup_dialogs'
import i18n from 'support/i18n'
import ImageCropper from 'components/image_uploader/components/image_cropper/View'

import ConfirmSubmitContainer from './components/dialogs/ConfirmSubmitContainer'

import { getActiveOrganizations } from 'services/organizations'
// import { deleteChallenge,postChallengeImage, putDraftChallenges } from 'services/challenge_steps'
import { deleteChallenge } from 'services/challenge_steps'
import { getServiceLineStatus } from 'services/create_idea'
import Navigation from 'support/navigation'
import ErrorSubmitModal from './components/dialogs/ErrorSubmitContainer'
import { deleteInfoImageSaveDraft, deleteRewardImage, getChallenge, getChallengeDraft, getGroups, getProposerSuggestions, getRoiInfo, getSponsorSuggestions, postChallengeMainImageSaveDraft, postInfoChallengeImageSaveDraft, postRewardChallengeImage, putChallengeDates, putChallengeDatesNew, putChallengeInfo, putChallengeInfoNew, putPeopleInfo, putPeopleInfoNew, putRewardInfo, putRewardInfoNew, putRoiInfo, putRoiInfoNew, saveDraftChallenges } from '../../services/challenge_steps'

import { setAdvancedAmountToBeSupported, setAdvancedApprovedIdeas, setAdvancedImplementedIdeas, setAdvancedSupportIdeas, setChallengeDescription, setChallengeRewardDescription, setChallengeRewardOther, setChallengeRewardRewarded, setEasyModeSetting, setExternalVideo, setGamingTabValue, setOrganization, setPreviewFromDate, setPreviewToDate, setProposerSuggestion, setSponsorSuggestion, setTitleDescription, setWhyImportantDescription, uploadImageFailure } from '../../actions/sync/challenge_steps'
import { advancedValuesBySetting } from './View.Utils'
import { SET_ADVANCED_AMOUNT_TO_BE_SUPPORTED, SET_ADVANCED_APPROVED_IDEAS, SET_ADVANCED_IMPLEMENTED_IDEAS, SET_ADVANCED_SUPPORT_IDEAS } from '../../actions/sync/challenge_steps/Constants'


const challenges = [
  {
    name: 'harry challenge',
    image: "https://www.industrialempathy.com/img/remote/ZiClJf-1920w.jpg",
    description: "this is the description",
    author: "harry",
    expiry: 1643281670000,
    no_tagged: 5
  },
  {
    name: 'harry challenge',
    image: "https://www.industrialempathy.com/img/remote/ZiClJf-1920w.jpg",
    description: "this is the description",
    author: "harry",
    expiry: 1643281670000,
    no_tagged: 5
  },
  {
    name: 'harry challenge',
    image: "https://www.industrialempathy.com/img/remote/ZiClJf-1920w.jpg",
    description: "this is the description",
    author: "harry",
    expiry: 1643281670000,
    no_tagged: 5
  }
]

const mapStateToProps = state => ({
  isLoading:state.data.ChallengeDraft.isLoading,
  draft_challenges: state.data.ChallengeDraft,
  images: [],
  categories: ['Blockchain', 'Future Working', 'Other'],
  challenges: challenges,
  csrf:state.auth.csrf,
  imagesLength:state.data.ChallengeDraft.challengeDescription.imagesLength,
  challengeDescription : state.data.ChallengeDraft.challengeDescription,
  externalVideoURL:state.data.ChallengeDraft.challengeDescription.externalVideo,
  sponsorSuggestions: state.data.ChallengeDraft.sponsorSuggestions,
  sponsorStarted: state.data.ChallengeDraft.sponsorStarted,
  proposerSuggestions: state.data.ChallengeDraft.proposerSuggestions,
  proposerStarted: state.data.ChallengeDraft.proposerStarted,
  selectedSponsor: state.data.ChallengeDraft.selectedSponsor,
  selectedProposer: state.data.ChallengeDraft.selectedProposer,

  selectedGamingSetting: state.data.ChallengeDraft.gaming.easyMode.settings,

  approveMult: state.data.ChallengeDraft.gaming.advancedMode.approveMult,
  implementMult: state.data.ChallengeDraft.gaming.advancedMode.implementMult,
  supportMult: state.data.ChallengeDraft.gaming.advancedMode.supportMult,
  currentGoal: state.data.ChallengeDraft.gaming.advancedMode.currentGoal,
  defaultValues: state.data.ChallengeDraft.gaming.advancedMode.defaultValues,
  activeTab: state.data.ChallengeDraft.gaming.activeTab,

  reward: state.data.ChallengeDraft.reward,
  rewardImg: state.data.ChallengeDraft.reward.image,

  previewChallenge: state.data.ChallengeDraft.preview,
  organizationList: state.data.domain.folderOrganizations,
  isOrganizationsActive: state.data.domain.domainConfiguration.isOrganizationsActive,
  isActive: state.data.ChallengeDraft.isActive,
  isDraft:state.data.ChallengeDraft.status,

})



const mapDispatchToProps = (dispatch,ownProps) => {
  // console.log("ownprops",ownProps)
  const challengeId = ownProps.match.params.id
  return {
    
    onTitleChange :  title => dispatch(setTitleDescription(title)),
    onWhyImportantChange : debounce(whyImportant => dispatch(setWhyImportantDescription(whyImportant)) , 300),
    onDescriptionChange: debounce( description => dispatch(setChallengeDescription(description)), 300) ,
    initializeChallenge: isProposing => {
      // console.log("isPro =>", isProposing)
      const onSuccess = () => {
        if (!isProposing) {
          dispatch(getRoiInfo())
        }
      }
      dispatch(getChallengeDraft(onSuccess))

      if (!isProposing) {
        dispatch(getGroups())
      }
    },
    completeChallengeData: () => {
      dispatch(getRoiInfo())
      dispatch(getGroups())
    },
    onResetChallenge: () => {
      const onSuccess = () => {
        dispatch(getRoiInfo())
      }
      dispatch(deleteChallenge(onSuccess))
    },
    getChallenge: () => {
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('CHALLENGES.GET_CHALLENGE_DETAIL_KO'), dispatch)
      }
      dispatch(getChallenge(challengeId, onFailure))
    },

    postLaunchChallengeMethod: () => showPopupDialog(<ConfirmSubmitContainer chData />, dispatch),


    submitDraftSupportLaunchChallenge: () => {
      // console.log('formdata==>', formData)
      const onSuccess = data => {
        // console.log("success submit =>",data)
        // let message = i18n.t('DRAFTCHALLENGE')
        // showPopupSuccessSnackbar(message, dispatch)
        // dispatch(Navigation.navigateToChallengeDetail(data.id))
      }
      const onFailure = ({ data }) => {
        const messageTag =
          data && data.error && data.error.error
            ? `BACKEND_ERRORS.${data.error.error}`
            : 'BACKEND_ERRORS.UNEXPECTED'
        showPopupDialog(<ErrorSubmitModal description={i18n.t(messageTag)} />, dispatch)
      }
      dispatch(saveDraftChallenges(onSuccess, onFailure))
    },


    submitDraft: () => {
      // console.log('formdata==>', formData)
      const onSuccess = data => {
        // console.log("success submit draft =>",data)
        let message = i18n.t('DRAFTCHALLENGE')
        showPopupSuccessSnackbar(message, dispatch)
        dispatch(Navigation.navigateToChallengeDetail(data.id))
      }
      const onFailure = ({ data }) => {
        const messageTag =
          data && data.error && data.error.error
            ? `BACKEND_ERRORS.${data.error.error}`
            : 'BACKEND_ERRORS.UNEXPECTED'
        showPopupDialog(<ErrorSubmitModal description={i18n.t(messageTag)} />, dispatch)
      }
      dispatch(saveDraftChallenges(onSuccess, onFailure))
    },

    onSelectImage: image => {
      const onSuccess = () => dispatch(hideDialog())
      const onFailure = error => {
        if (error.status === 413) {
          dispatch(uploadImageFailure(i18n.t('IDEA.CHOOSE_VALID_IMAGE')))
        } else {
          showPopupErrorSnackbar(i18n.t('BACKEND_ERRORS.UNEXPECTED'), dispatch)
        }
        dispatch(hideDialog())
      }
      const component = (
        <ImageCropper
          imageFile={image}
          onSelectCrop={(_, croppedImage) => {
            dispatch(postInfoChallengeImageSaveDraft(croppedImage, onSuccess, onFailure))
          }}
          onClose={() => dispatch(hideDialog())}
        />
      )
      showPopupDialog(component, dispatch)
    },
    onSelectMainImage: imageName => {
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('BACKEND_ERRORS.UNEXPECTED'), dispatch)
      }
      dispatch(postChallengeMainImageSaveDraft(imageName, onFailure))
    },
    deleteImage: (_, imageName) => {
      const onFailure = errorResponse => {
        const { data } = errorResponse
        if (data && data.error && data.error.status === 400 && data.error.code === 2003) {
          showPopupErrorSnackbar(i18n.t('CHALLENGES.DELETE_IMAGE_ERROR'), dispatch)
        } else {
          showPopupErrorSnackbar(i18n.t('BACKEND_ERRORS.UNEXPECTED'), dispatch)
        }
      }
      dispatch(deleteInfoImageSaveDraft(imageName, onFailure))
    },

    // people

    getSponsorSuggestions: username => {
      dispatch(getSponsorSuggestions(username))
    },
    getProposerSuggestions: username => {
      dispatch(getProposerSuggestions(username))
    },
    onSponsorSelected: suggestion => {
      dispatch(setSponsorSuggestion(suggestion))
    },
    onRemoveSponsor: () => {
      dispatch(setSponsorSuggestion({}))
    },
    onProposerSelected: suggestion => {
      dispatch(setProposerSuggestion(suggestion))
    },
    onRemoveProposer: () => {
      dispatch(setProposerSuggestion({}))
    },

    //gamimg

    setActiveTab: (event, activeTab) => {
      dispatch(setGamingTabValue(activeTab))
    },
    onSettingChange: (setting, defaultValues) => {
      dispatch(
        setEasyModeSetting({
          setting,
          advancedModeMult: advancedValuesBySetting(setting, defaultValues),
        }),
      )
    },
    onSliderChangeCommitted: (type, amount) => {
      switch (type) {
        case SET_ADVANCED_AMOUNT_TO_BE_SUPPORTED:
          dispatch(setAdvancedAmountToBeSupported(amount))
          break
        case SET_ADVANCED_SUPPORT_IDEAS:
          dispatch(setAdvancedSupportIdeas(amount))
          break
        case SET_ADVANCED_APPROVED_IDEAS:
          dispatch(setAdvancedApprovedIdeas(amount))
          break
        case SET_ADVANCED_IMPLEMENTED_IDEAS:
          dispatch(setAdvancedImplementedIdeas(amount))
          break
        default:
          break
      }
    },


    // reward

    onRewardDescriptionChange: debounce(
      value => dispatch(setChallengeRewardDescription(value)),
      300,
    ),
    onRewardRewardedChange: value => dispatch(setChallengeRewardRewarded(value)),
    onRewardOtherChange: debounce(value => dispatch(setChallengeRewardOther(value)), 300),
    onSelectRewardImage: (image) => {
      // console.log('onSelectRewardImage',image)
      const onSuccess = () => dispatch(hideDialog())
      const onFailure = error => {
        if (error.status === 413) {
          dispatch(uploadImageFailure(i18n.t('IDEA.CHOOSE_VALID_IMAGE')))
        } else {
          showPopupErrorSnackbar(i18n.t('BACKEND_ERRORS.UNEXPECTED'), dispatch)
        }
        dispatch(hideDialog())
      }
      const component = (
        <ImageCropper
          imageFile={image}
          onSelectCrop={(_, croppedImage) => {
            dispatch(postRewardChallengeImage(croppedImage, onSuccess, onFailure))
          }}
          onClose={() => dispatch(hideDialog())}
        />
      )
      showPopupDialog(component, dispatch)
    },
    deleteRewardImage: (_, imageName) => {
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('BACKEND_ERRORS.UNEXPECTED'), dispatch)
      }
      dispatch(deleteRewardImage(imageName, onFailure))
    },

    // preview

    onSelectPreviewFromDate: fromDate => {
      dispatch(setPreviewFromDate(fromDate))
    },
    onSelectPreviewToDate: toDate => {
      dispatch(setPreviewToDate(toDate))
    },
    onOrganizationSelect: value => dispatch(setOrganization(value)),
    getOrganizations: () => dispatch(getActiveOrganizations()),

    navigateToChallenges: () => {
      dispatch(Navigation.navigateToChallenges())
    },
    getServiceLineStatus : () =>{
      dispatch(getServiceLineStatus())
    },
    editDates: () => {
      const onSuccess = () => {
        Navigation.navigateToChallengeDetail(challengeId)
      }
      dispatch(putChallengeDatesNew(onSuccess))
    },
    saveRewardInfo: () => {
      
      dispatch(putRewardInfoNew())
    },
    saveRoiInfo: () => {
      
      dispatch(putRoiInfoNew())
    },
    savePeopleInfo: () => {
      
      dispatch(putPeopleInfoNew())
    },
    saveDescriptionInfo: () => {
      dispatch(putChallengeInfoNew())
    },
    launchChallenge: isNewChallenge => {
      const component = <ConfirmSubmitContainer shouldDeleteDraft={isNewChallenge} />
      // console.log("isNewCh",isNewChallenge,component)
      showPopupDialog(component, dispatch)
    },
    onUploadExternalVideo: src => dispatch(setExternalVideo(src)),
    onRemoveExternalVideo: () => dispatch(setExternalVideo('')),


  }
}

export default connect(mapStateToProps, mapDispatchToProps)(View)