
/* eslint-disable prefer-const,jsx-a11y/no-noninteractive-element-interactions */

import React from "react"
import { Modal, Box, Typography } from '@material-ui/core';
import ExpandingDetails from '../expanding_details/View'
import TableHead from "./TableHead";
import TableBody from "./Tablebody";
import { flattenObject, useSortTable } from "./useSortTable";
import Table from "./Table";

import withStyles from '@material-ui/core/styles/withStyles'
import style from './View.Style'


const InvestorsModal = props => {
    const {
        showInvestors,
        classes,
        setShowInvestors,
        remove_icon,
        totalInvestors,
        investmentDetailsText,
        investment,
    } = props


    const investorDetailsMap = (investorList, stage) => {
        return (investorList.map(investor => {
            let singleObj = { investor, 'stage': stage }
            let singleflatObj = flattenObject(singleObj)
            // console.log("single",singleObj,singleflatObj)
            return (singleflatObj)
        }))
    }


    //old rendar obj data

    // const investorDetailsMap  = (investorList, stage) => {
    //     return(investorList.map(investor => {
    //         return( 
    //             <div className={classes.row_wrapper}>
    //                 <div className={'grid_item'}><img src={investor.photoSrc}/></div>
    //                 <div className={'grid_item'}>{investor.name}</div>
    //                 <div className={'grid_item'}>{investor.email}</div>
    //                 <div className={'grid_item'}>{stage}</div>
    //                 <div className={'grid_item'}>{investor.amount}</div>
    //             </div>
    //         )
    //     }))
    // }

    const columns1 = [
        { label: "Name", accessor: "name", sortable: true },
        { label: "Email", accessor: "email", sortable: false },
        { label: "Date", accessor: "created", sortable: true },
        { label: "Stage", accessor: "stage", sortable: true },
        { label: "Amount", accessor: "amount", sortable: true, sortbyOrder: "desc" },
    ];

    const invarr = [
        investorDetailsMap(investment.seedInvestors, 'Seed Round'),
        investorDetailsMap(investment.firstRoundInvestors, '1st Round'),
        investorDetailsMap(investment.secondRoundInvestors, '2nd Round'),
        investorDetailsMap(investment.thirdRoundInvestors, '3rd Round')
    ]

    const investorTableDataArr = invarr.flat(2)
    // console.log('====', invarr, investorTableDataArr)



    return (
        <Modal
            open={showInvestors}
            onClose={() => setShowInvestors(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={classes.modal_style}>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography className={classes.modal_header}>
                        Investor details
                    </Typography>
                    <img
                        alt="remove"
                        src={remove_icon}
                        style={{
                            height: '45px',
                            cursor: 'pointer'
                        }}
                        onClick={() => setShowInvestors(false)}
                    />
                </div>
                <Typography className={classes.modal_subtext}>
                    This idea has received {totalInvestors} investments so far.
                </Typography>
                <Typography className={classes.modal_subtext}>
                    Just as when we invest in a real company, the earlier you
                    invest the higher your returns will be if the idea turns out to be successful.
                    <br />
                    We define the Venture Rounds as the following:
                </Typography>
                <ExpandingDetails text={investmentDetailsText} />
                {/* <Typography className={classes.investors_grid}>
                    <div className={classes.investorGridHeader}>Name</div>
                    <div className={classes.investorGridHeader}>Email</div>
                    <div className={classes.investorGridHeader}>Date</div>
                    <div className={classes.investorGridHeader}>Stage</div>
                    <div className={classes.investorGridHeader}>Amount</div> */}

                {/* {investorDetailsMap(investment.seedInvestors, 'Seed Round')}
      {investorDetailsMap(investment.firstRoundInvestors, '1st Round')}
      {investorDetailsMap(investment.secondRoundInvestors, '2nd Round')}
      {investorDetailsMap(investment.thirdRoundInvestors, '3rd Round')} */}
                {/* </Typography> */}

                <div className={classes.investortable}>
                    <Table
                        data={investorTableDataArr}
                        columns={columns1}
                    />
                </div>


            </Box>
        </Modal>
    )
}


export default withStyles(style)(InvestorsModal)