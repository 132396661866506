import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import defaultPhoto from 'img/default-photo.png'
import clsx from 'clsx'
import styles from './View.Style'
import Image from '../image/View'

const NxtUserCard = props => {
  const {
    classes,
    isInverted,
    imageSrc,
    label,
    usernameElement,
    textColor,
    imageSize,
    isTextInverted,
  } = props
  return (
    <>
      {!isInverted && (
        <div className={classes.imageName}>
          <Image
            defaultSrc={defaultPhoto}
            alt="proposer photo"
            src={imageSrc}
            style={{ height: imageSize, width: imageSize }}
          />
          <div
            className={clsx(classes.textContainer, isTextInverted && classes.flexInverted)}
            style={{ color: textColor }}
          >
            {label.length > 0 && <span>{label}</span>}
            {usernameElement}
          </div>
        </div>
      )}
      {isInverted && (
        <div className={classes.nameImage}>
          <div
            className={clsx(classes.textContainer, isTextInverted && classes.flexInverted)}
            style={{ color: textColor }}
          >
            {label.length > 0 && <span>{label}</span>}
            {usernameElement}
          </div>
          <Image
            defaultSrc={defaultPhoto}
            alt="proposer photo"
            src={imageSrc}
            style={{ height: imageSize, width: imageSize }}
          />
        </div>
      )}
    </>
  )
}

NxtUserCard.defaultProps = {
  isInverted: false,
  isTextInverted: false,
  label: '',
  textColor: 'white',
  imageSize: '3.375rem',
  imageSrc: '',
}

NxtUserCard.propTypes = {
  imageSrc: PropTypes.string,
  usernameElement: PropTypes.element.isRequired,
  imageSize: PropTypes.string,
  isInverted: PropTypes.bool,
  isTextInverted: PropTypes.bool,
  label: PropTypes.string,
  textColor: PropTypes.string,
}

export default withStyles(styles)(NxtUserCard)
