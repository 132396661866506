import React, {  useState, useEffect } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'
import IdeaPanel from '../../components_newui/idea_panel/View'
import Breadcrumbs from 'components_newui/pageBreadcrumbsMenu/View'
import noIdeasImg from 'img/no_ideas.png'
import statusConstants from 'common_constants/idea_states_constants'
import NxtCircularLoader from 'components/nxt_circular_loader/View'
import DraftIdeaCard from './draft_idea_card/View'
import { Container } from '@material-ui/core'
import navigation from '../../support/navigation'

const MyIdeas = props => {

    const {
        classes,
        userIdeas,
        getMyIdeas,
        navigateToIdea,
        onDeleteClick,isIdeasLoading
    } = props

    const [showDrafts, setShowDrafts] = useState(false)
    const [ideas, setIdeas] = useState(userIdeas
        .filter(idea => idea.status !== statusConstants.DRAFT))

    const [draftideas, setdraftIdeas] = useState(userIdeas
        .filter(idea => idea.status === statusConstants.DRAFT))

    // Maybe have page hierarchy come from state????? at least get navigate functions from state
    const pageHierarchy = [
        {string: "Home", navigate: ()=>navigation.navigateToExplore()}, 
        { string: "My ideas", navigate: () => {} }
    ]

    useEffect(() => {
        getMyIdeas()
    }, [])

    useEffect(() => {
        // showDrafts ?
        //     setdraftIdeas(userIdeas
        //         .filter(idea => idea.status === statusConstants.DRAFT))
        //     :
        //     setIdeas(userIdeas
        //         .filter(idea => idea.status !== statusConstants.DRAFT))

        //         setdraftIdeas(userIdeas
        //             .filter(idea => idea.status === statusConstants.DRAFT))

        if (showDrafts) {
            setdraftIdeas(userIdeas.filter(idea => idea.status === statusConstants.DRAFT));
          } else {
            setIdeas(userIdeas.filter(idea => idea.status !== statusConstants.DRAFT));
          }
          

    }, [showDrafts, userIdeas])



    return (
        <>
            <Breadcrumbs crumbs={pageHierarchy} />
            <Container>

                <NxtCircularLoader isLoading={isIdeasLoading}>  
                <main className={classes.background}>
                    <div className={classes.top_section_text}>
                        <div className={classes.title}>
                            {showDrafts ?   `Draft ideas (${draftideas.length})`  :   `My ideas (${ideas.length})`   }
                        </div>
                        <button type='button'
                            className={classes.investedIdeasNumber}
                            onClick={() => setShowDrafts(prev => !prev)}
                        >
                            {`${showDrafts ?  `My ideas (${ideas.length})`   :  `Draft idea (${draftideas.length}) `  }`}
                        </button>
                    </div>
                    


                    {
                        (showDrafts && draftideas.length < 1 && !draftideas.length) ?

                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <img className={classes.noIdeaImg} src={noIdeasImg} alt='no idea found' />
                                <p className={classes.noIdeas}>No existing <span className={classes.purpleNo}>ideas</span></p>
                            </div>
                            :
                            showDrafts &&
                            <div className={classes.IdeaGridDraft}>
                                {draftideas
                                    .map(idea =>
                                        <DraftIdeaCard idea={idea} key={idea.id} handleDelete={() => onDeleteClick(idea.id)} handleContinue={() => navigateToIdea(idea.id)} />
                                    )}
                            </div>
                    }
                    {
                        (!showDrafts && ideas.length < 1 && !ideas.length) ?

                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <img className={classes.noIdeaImg} src={noIdeasImg} alt='no idea found' />
                                <p className={classes.noIdeas}>No existing <span className={classes.purpleNo}>ideas</span></p>
                            </div>
                            :
                            !showDrafts &&
                            <div className={classes.IdeaGrid}>
                                {ideas
                                    .map(idea =>
                                        <IdeaPanel key={idea.id} idea={idea} urlId={idea.id} />
                                    )}
                            </div>
                    }
                </main>
                </NxtCircularLoader>
            </Container>
        </>
    )
}

export default withStyles(styles)(MyIdeas)