/* eslint-disable  object-shorthand */
/* eslint-disable react/destructuring-assignment */

import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import * as PropTypes from 'prop-types'
 
import CommentsPieChart from '../pie_chart/View'
//import CommentsPieChart from '../pie_chart/View'
//import { FIRST_LIMIT_CONSTANT, LIMIT_CONSTANT } from 'common_constants/Evaluation'

import EvaluatorCommentsList from '../evaluator/components/evaluator_comments/View'
//react-google-charts also needs react-load-script
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'

class EvaluationPending extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadComments: false,
      stage: 0,
    }
    this.loadPrevComments = this.loadPrevComments.bind(this)
    this.cancelCommentListUi = this.cancelCommentListUi.bind(this)
  }

  // componentDidMount() {
  //   const { title, getEvaluationDraft } = this.props
  //   //if (title) getEvaluationDraft(title)
  // }

  componentDidUpdate(prevProps) {
    const { commentListGraph, title, getEvaluationDraft } = this.props
    if (prevProps.commentListGraph !== commentListGraph) {
      this.setState({ stage: 0 })
    }
    if (prevProps.title !== this.props.title) {
      if (title) getEvaluationDraft(title)
    }
  }

  loadPrevComments = stage => {
    this.setState({ loadComments: true, stage: stage })
  }
  
  cancelCommentListUi = () => {
    this.setState({ loadComments: false })
  }

  render() {
    const { commentListGraph } = this.props

    const { loadComments, stage } = this.state

    return (
      <div style={{ flexGrow: 1 }}>
        {/* <Grid container direction="column" justify="center" alignItems="center"> */}
        {loadComments && (
          <EvaluatorCommentsList
            stage={stage}
            commentsList={commentListGraph.Qcomments}
            open={loadComments}
            isAdmin={false}
            handleDialog={this.cancelCommentListUi}
          />
        )}

        <Grid container direction="row" justify="center">
          {/*Left grid*/}

          <Grid item xs={12} sm={6} style={{ backgroundColor: 'white' }}>
            <Grid
              container
              style={{
                height: '100%',
                width: '100%',
                backgroundColor: 'white',
              }}
              alignContent="flex-start"
            >
              <Grid item xs={6} alignItems="center">
                {commentListGraph.Q1measurement && !loadComments && (
                  <CommentsPieChart
                    title="How innovative is this idea?"
                    list={commentListGraph.Q1measurement}
                    loadPrevComments={this.loadPrevComments}
                    loadPrevCommentsNo={1}
                  />
                )}
              </Grid>
              <Grid item xs={6} alignItems="center">
                {commentListGraph.Q2measurement && !loadComments && (
                  <CommentsPieChart
                    title="Is the value of this idea clear?"
                    list={commentListGraph.Q2measurement}
                    loadPrevComments={this.loadPrevComments}
                    loadPrevCommentsNo={2}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {commentListGraph.Q3measurement && !loadComments && (
                  <CommentsPieChart
                    title="Is this idea mature/ detailed enough to go to the next phase?"
                    list={commentListGraph.Q3measurement}
                    loadPrevComments={this.loadPrevComments}
                    loadPrevCommentsNo={3}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {commentListGraph.type && (
                  <CommentsPieChart
                    title="How do you feel about this idea?"
                    list={commentListGraph.type}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          {/*Left grid*/}
        </Grid>
      </div>
    )
  }
}

EvaluationPending.propTypes = {
  commentListGraph: PropTypes.arrayOf(
    PropTypes.shape({
      Q1Comment: PropTypes.string,
      Q1measurement: PropTypes.number,
      Q2Comment: PropTypes.string,
      Q2measurement: PropTypes.number,
      Q3Comment: PropTypes.string,
      Q3measurement: PropTypes.number,
      loggedInUser: PropTypes.string,
      type: PropTypes.bool,
    }),
  ),
}
EvaluationPending.defaultProps = {
  commentListGraph: PropTypes.arrayOf(
    PropTypes.shape({
      Q1Comment: 'hi1',
      Q1measurement: 0,
      Q2Comment: 'hi2',
      Q2measurement: 0,
      Q3Comment: 'hi3',
      Q3measurement: 0,
      loggedInUser: '',
      type: false,
    }),
  ).isRequired,
}

export default withStyles(styles)(EvaluationPending)
