/* eslint-disable react/destructuring-assignment,  no-unneeded-ternary, react/no-unused-state */
/* eslint-disable class-methods-use-this,react/no-unused-class-component-methods */
/* eslint-disable react/sort-comp,react/no-unused-class-component-methods */


import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { ShowFilter } from 'components'
import withStyles from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { FIRST_LIMIT_CONSTANT, LIMIT_CONSTANT } from 'common_constants/Evaluation'
import EvaluationDashboard from './components/evaluator/View'
import AdminEvaluationDashboard from './components/admin/View'
import ArchivedEvaliuationBoard from './components/archived/View'
import NxtCircularLoader from 'components/nxt_circular_loader/View'
import ShareIdea from './components/share_idea/View'
import i18n from 'support/i18n'
import styles from './View.Style'

class EvaluationBoard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSlider: false,
      offset: 0,
      showDetailsFromEmailLink: props.showIdea || false,
      showArchivedIdeas: false,
      showAllIdeaIdeas: true,
      showPendingIdeas: true,
      evaluatorClicked: true,
      dashboardClicked: false,
      archivedClicked: false,
      pendingClicked: true,
      showArchivedButton: props.isAdmin ? true : false,
      showPendingButton: props.isAdmin ? true : false,
    }
    this.id = props.match.params.id
    this.handleFilter = this.handleFilter.bind(this)
    this.handleOnLoadMore = this.handleOnLoadMore.bind(this)
    this.ideaClickHandle = this.ideaClickHandle.bind(this)
    this.postQuestionValues = this.postQuestionValues.bind(this)
    this.handleDashboard = this.handleDashboard.bind(this)
    this.handleArchived = this.handleArchived.bind(this)
    this.handlePending = this.handlePending.bind(this)
    this.handleEvaluation = this.handleEvaluation.bind(this)
    this.getEvaluationIdeaWithFilter = this.getEvaluationIdeaWithFilter.bind(this)
  }

  componentDidMount() {
    const { getEvaluationIdeaById, getIdea, isAdmin, localAdmin } = this.props
    this.getEvaluationIdeaWithFilter()

    if ((isAdmin || localAdmin) && this.props.match.params.title) {
      // below line for email link for Admin role
      getEvaluationIdeaById(this.props.match.params.title)
    }
    if (!isAdmin && this.props.match.params.title) {
      // below line for email link for evaluator role
      getIdea(this.props.match.params.title)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // const { getEvaluationIdea } = this.props
    // console.log(`inside componentDidUpdate`)
    // // if (prevState.pendingClicked !== this.state.pendingClicked && this.state.pendingClicked) {
    // //   getEvaluationIdea()
    // // }
  }

  getEvaluationIdeaWithFilter(offset = 0) {
    const { getEvaluationIdea } = this.props
    getEvaluationIdea(offset ? LIMIT_CONSTANT : FIRST_LIMIT_CONSTANT, offset)
  }

  handleEvaluation = () => {
    window.location.reload()
  }

  handleArchived = () => {
    const { archivedClicked } = this.state
    if (archivedClicked) {
      this.setState({
        archivedClicked: true,
        showArchivedIdeas: true,
        showDetailsFromEmailLink: false,
        showAllIdeaIdeas: false,
        pendingClicked: false,
      })
    }
    this.setState({
      archivedClicked: true,
      showArchivedIdeas: true,
      showDetailsFromEmailLink: false,
      showAllIdeaIdeas: false,
      pendingClicked: false,
    })
  }

  handlePending = () => {
    const {
      isAdmin,
      localAdmin,
      isEvaluator,
      localEvaluator,
      getEvaluationPendingIdea,
    } = this.props
    if (isAdmin || localAdmin) window.location.reload()
    if (isEvaluator || localEvaluator) {
      getEvaluationPendingIdea()
      this.setState({
        pendingClicked: true,
        dashboardClicked: true,
        archivedClicked: false,
        evaluatorClicked: false,
        showAllIdeaIdeas: true,
        showDetailsFromEmailLink: false,
        showArchivedIdeas: false,
      })
    }
  }

  ideaClickHandle(id) {
    const { getIdea, isAdmin, localAdmin, getEvaluationIdeaById } = this.props
    const { showArchivedIdeas, showDetailsFromEmailLink } = this.state

    if ((isAdmin || localAdmin) && !showArchivedIdeas) {
      this.setState({
        showDetailsFromEmailLink: !showDetailsFromEmailLink,
        id,
        showAllIdeaIdeas: false,
      })
      // getIdea(id)
      getEvaluationIdeaById(id)
    }
    if ((!isAdmin || localAdmin) && !showArchivedIdeas) {
      this.setState({
        showDetailsFromEmailLink: true,
        id,
        showAllIdeaIdeas: false,
      })
      getIdea(id)
      getEvaluationIdeaById(id)
    }
  }

  postQuestionValues(queNo, comment, mesurment) {
    const { getEvaluationIdeaPost } = this.props
    getEvaluationIdeaPost(comment, mesurment, '', 0, '', 0, '', 0, '', 0)
  }

  handleFilter(value) {
    this.setState({ showSlider: value })
  }

  handleOnLoadMore() {
    const { offset } = this.state
    const newLimit = offset ? LIMIT_CONSTANT : FIRST_LIMIT_CONSTANT
    const newOffset = newLimit + offset
    this.getEvaluationIdeaWithFilter(newOffset)
    this.setState({ offset: newOffset })
  }

  handleDashboard() {
    const {
      isEvaluator,
      localEvaluator,
      isAdmin,
      localAdmin,
      getEvaluationPendingIdea,
    } = this.props

    if (isEvaluator || localEvaluator) {
      this.setState({
        dashboardClicked: true,
        evaluatorClicked: false,
        archivedClicked: false,
        pendingClicked: true,
        showDetailsFromEmailLink: false,
        showAllIdeaIdeas: true,
        showArchivedIdeas: false,
        showPendingButton: true,
        showArchivedButton: true,
      })
      getEvaluationPendingIdea()
    }
    if (isAdmin || localAdmin) {
      window.location.reload()
    }
  }

  render() {
    const { classes } = this.props
    const { showSlider, id } = this.state
    const {
      ideas,
      images,
      title,
      description,
      totalEvaluationIdea,
      hasMoreIdeasToLoad,
      hasMoreArchivedIdeasToLoad,
      ideaStatusStepper,
      navigateToUserProfile,
      getEvaluationIdea,
      getEvaluationArchivedIdea,
      // getEvaluationPendingIdea,
      getEvaluationArchivedIdeaById,
      restoreEvaluationArchivedIdeaById,
      restoreEvaluationArchivedIdeaByIdUpdated,
      getEvaluationIdeaPost,
      getEvaluationDraft,
      getEvaluationIdeaStatusPost,
      getEvaluationIdeaById,
      activeStep,
      goBack,
      goNext,
      evaluationIdea,
      commentsList,
      
      isAdmin,
      localAdmin,
      isEvaluator,
      localEvaluator,
      saveEvaluationIdeaById,
      errorMessage,
      evaluationLoading,
      adminEvaluationLoading,
      commentListGraph,
      ideaTitle,
      ideaText,
      boardReviewDate,
      boardStatus,
      feedbackForIdeaOwner,
      boardComment,
      ideaPitchDate,
      pitchStatus,
      roundClosed,
      adminImages,
      archivedIdeaTitle,
      archivedIdeaText,
      archivedBoardReviewDate,
      archivedBoardStatus,
      archivedFeedbackForIdeaOwner,
      archivedBoardComments,
      archivedIdeaPitchDate,
      archivedPitchStatus,
      archivedRoundClosed,
      archivedAdminImages,

      ideasArchived,
      totalArchived,
      onShareIdea,
      getExpertCommentsMail,
      expertCommentMail,
    } = this.props
    const {
      showArchivedIdeas,
      showDetailsFromEmailLink,
      showAllIdeaIdeas,
      evaluatorClicked,
      dashboardClicked,
      archivedClicked,
      pendingClicked,
    } = this.state

    // alert(`${adminEvaluationLoading} || ${evaluationLoading}`)

    return (
      <>
        <div className={classes.top}>
          <h1>
            <i className="icon-idea" /> {i18n.t('SCREEN.EVALUATION_BOARD')}
          </h1>
        </div>


        <div className={classes.middles}>

          <div className={classes.center}>

            {/* Background image */}
            <div className={classes.outerSpace}>
              {/* Top buttons */}
              <div className={classes.topButtons}>
                {(isEvaluator || localEvaluator) && (
                  <Button
                    variant="contained"
                    className={clsx(
                      evaluatorClicked ? classes.selectedMainButton : classes.mainbutton,
                      classes.evaluationButton,
                    )}
                    onClick={this.handleEvaluation}
                  >
                    <Typography noWrap variant="h5">
                      Evaluation
                    </Typography>
                  </Button>
                )}

                <Button
                  variant="contained"
                  // className={clsx(classes.mainbutton, classes.dashboardButton)}
                  className={clsx(
                    dashboardClicked || isAdmin || localAdmin
                      ? classes.selectedMainButton
                      : classes.mainbutton,
                    isAdmin || localAdmin ? classes.evaluationButton : classes.dashboardButton,
                  )}
                  onClick={this.handleDashboard}
                >
                  <Typography noWrap variant="h5">
                    Dashboard
                  </Typography>
                </Button>
              </div>

              {/* Right buttons */}
              <div className={clsx(classes.novarotate, classes.divRightButtons)}>
                {(dashboardClicked || isAdmin || localAdmin) && (
                  <Button
                    variant="contained"
                    id="mainbutton"
                    className={clsx(
                      archivedClicked ? classes.selectedMainButton : classes.mainbutton,
                      classes.archivedButton,
                    )}
                    onClick={this.handleArchived}
                  >
                    <Typography noWrap variant="h5">
                      Archived
                    </Typography>
                  </Button>
                )}
                {(dashboardClicked || isAdmin || localAdmin) && (
                  <Button
                    variant="contained"
                    className={clsx(
                      pendingClicked ? classes.selectedMainButton : classes.mainbutton,
                      classes.pendingButton,
                    )}
                    onClick={this.handlePending}
                  >
                    <Typography noWrap variant="h5">
                      Pending
                    </Typography>
                  </Button>
                )}
              </div>

              {/* Red outer container */}
              <Grid container className={classes.redOuterContainer} spacing={3}>
                {/* Grey inner container */}
                <Grid item xs={12} className={classes.greyInnerContainer}>
                  {showAllIdeaIdeas && (
                    <NxtCircularLoader isLoading={adminEvaluationLoading || evaluationLoading} >
                      <ShowFilter
                        cards={ideas}
                        totalIdeas={totalEvaluationIdea}
                        onClick={this.ideaClickHandle}
                        showSlider={showSlider}
                        onSubmitterClick={navigateToUserProfile}
                        onCardTypeChange={this.handleFilter}
                        onLoadMore={this.handleOnLoadMore}
                        showLoadMoreButton={hasMoreIdeasToLoad}
                        ideaStatusStepper={ideaStatusStepper}
                        showSmallCardCircle

                      // showSlider={showSlider}
                      // onCardTypeChange={this.handleCardTypeChange}

                      // onLoadMore={this.handleOnLoadMore}
                      // offset={offset}
                      // showLoadMoreButton={hasMoreIdeasToLoad}
                      // ideaStatusStepper={ideaStatusStepper}
                      // showSmallCardCircle
                      />
                    </NxtCircularLoader>
                  )}

                  {showDetailsFromEmailLink && (isEvaluator || localEvaluator) && (
                    <EvaluationDashboard
                      images={images}
                      title={title}
                      id={id}
                      description={description}
                      activeStep={activeStep}
                      getEvaluationIdea={getEvaluationIdea}
                      getEvaluationDraft={getEvaluationDraft}
                      postEvaluationIdea={getEvaluationIdeaPost}
                      evaluationIdea={evaluationIdea}
                      postEvaluationIdeaStatus={getEvaluationIdeaStatusPost}
                      evaluationLoading={evaluationLoading}
                      commentsList={commentsList}
                      commentListGraph={commentListGraph}
                      isPendingClicked={pendingClicked}
                      isDashboardClicked={dashboardClicked}
                      goNext={goNext}
                      goBack={goBack}
                      getExpertCommentsMail={getExpertCommentsMail}
                      expertCommentMail={expertCommentMail}
                    />
                  )}

                  {showDetailsFromEmailLink && (isAdmin || localAdmin) && (
                    <AdminEvaluationDashboard
                      // images={images}
                      isAdmin={isAdmin}
                      localAdmin={localAdmin}
                      id={id}
                      ideaTitle={ideaTitle}
                      ideaText={ideaText}
                      boardReviewDate={boardReviewDate}
                      boardStatus={boardStatus}
                      feedbackForIdeaOwner={feedbackForIdeaOwner}
                      boardComment={boardComment}
                      ideaPitchDate={ideaPitchDate}
                      pitchStatus={pitchStatus}
                      roundClosed={roundClosed}
                      images={adminImages}
                      adminEvaluationLoading={adminEvaluationLoading}
                      commentListGraph={commentListGraph}
                      saveEvaluationIdeaById={saveEvaluationIdeaById}
                      // shareEvaluationIdeaById={shareEvaluationIdeaById}
                      errorMessage={errorMessage}
                      onShareIdea={onShareIdea}
                      getExpertCommentsMail={getExpertCommentsMail}
                      expertCommentMail={expertCommentMail}
                    />
                  )}

                  {showArchivedIdeas && (
                    // <div>hi</div>
                    <ArchivedEvaliuationBoard
                      // Admin Data
                      isAdmin={isAdmin}
                      localAdmin={localAdmin}
                      id={id}
                      ideaTitle={ideaTitle}
                      ideaText={ideaText}
                      boardReviewDate={boardReviewDate}
                      boardStatus={boardStatus}
                      feedbackForIdeaOwner={feedbackForIdeaOwner}
                      ideaPitchDate={ideaPitchDate}
                      pitchStatus={pitchStatus}
                      roundClosed={roundClosed}
                      images={adminImages}
                      imagesN={images}
                      adminEvaluationLoading={adminEvaluationLoading || evaluationLoading}
                      commentListGraph={commentListGraph}
                      saveEvaluationIdeaById={saveEvaluationIdeaById}
                      // shareEvaluationIdeaById={shareEvaluationIdeaById}
                      errorMessage={errorMessage}
                      // //Functions
                      getEvaluationArchivedIdea={getEvaluationArchivedIdea}
                      getEvaluationIdeaById={getEvaluationIdeaById}
                      getEvaluationArchivedIdeaById={getEvaluationArchivedIdeaById}
                      restoreEvaluationArchivedIdeaById={restoreEvaluationArchivedIdeaById}
                      restoreEvaluationArchivedIdeaByIdUpdated={
                        restoreEvaluationArchivedIdeaByIdUpdated
                      }
                      // //For graphs

                      ideas={ideasArchived}
                      showSlider={showSlider}
                      onClick={this.ideaClickHandle}
                      onCardTypeChange={this.handleFilter}
                      onLoadMore={this.handleOnLoadMore}
                      totalEvaluationIdea={totalArchived}
                      hasMoreIdeasToLoad={hasMoreArchivedIdeasToLoad}
                      ideaStatusStepper={ideaStatusStepper}
                      onSubmitterClick={navigateToUserProfile}
                      showSmallCardCircle
                      archivedIdeaTitle={archivedIdeaTitle}
                      archivedIdeaText={archivedIdeaText}
                      archivedBoardReviewDate={archivedBoardReviewDate}
                      archivedBoardStatus={archivedBoardStatus}
                      archivedFeedbackForIdeaOwner={archivedFeedbackForIdeaOwner}
                      archivedBoardComments={archivedBoardComments}
                      archivedIdeaPitchDate={archivedIdeaPitchDate}
                      archivedPitchStatus={archivedPitchStatus}
                      archivedRoundClosed={archivedRoundClosed}
                      archivedAdminImages={archivedAdminImages}
                      archivedClicked={archivedClicked}
                    />
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </>
    )
  }
}

EvaluationBoard.propTypes = {
  getPendingIdeas: PropTypes.func.isRequired,
  navigateToIdea: PropTypes.func.isRequired,
  ideas: PropTypes.arrayOf(
    PropTypes.shape({
      challenged: PropTypes.bool,
      textWithoutTags: PropTypes.string,
      id: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          bucket: PropTypes.string,
          key: PropTypes.string,
          name: PropTypes.string,
          size: PropTypes.number,
          url: PropTypes.string,
        }),
      ),
      investors: PropTypes.string,
      goal: PropTypes.string,
      pledged: PropTypes.string,
      profit: PropTypes.string,
      sponsored: PropTypes.bool,
      ownerName: PropTypes.string,
      title: PropTypes.string,
      created: PropTypes.number,
      countdownEnds: PropTypes.number,
      status: PropTypes.string,
    }),
  ).isRequired,
}

export default withStyles(styles)(EvaluationBoard)
