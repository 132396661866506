/* eslint-disable no-console */
/* eslint-disable react/function-component-definition */

import React, {  useCallback, useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import {useIsAuthenticated,useMsal} from '@azure/msal-react'
import AuthProvider from 'pagesNewUi/azure_sso/AuthProvider'
import withStyles from '@material-ui/core/styles/withStyles'
import ExternalLogin from './components/external_login/View'
import styles from './View.Style'
import LandingPageBlock from './components/landing_page_about_block/View'
import aboutIcon from './img/What is NOVA@3x.svg'
import whyIcon from './img/Why are we doing it@3x.svg'
import getInvolvedIcon from './img/How can you get involved@3x.svg'
import ideaIcon from './img/Idea submission & approval@3x.svg'
import prototypeIcon from './img/Prototyping@3x.svg'
import reviewIcon from './img/Review@3x.svg'
import demandIcon from './img/Demand and delivery@3x.png'
import LandingPageSteps from './components/landing_page_steps/View'
import NxtCircularLoader from 'components/nxt_circular_loader/View'
import  Header  from './components/landing_page_header/View'
// import {loginRequest} from '../../authProvider'
import upIcon from './img/Top.png'


function NewLandingPage(props) {
    //  const  isAuthenticated = useIsAuthenticated();
  const [showExternalLogin, setShowExternalLogin] = useState(false)
    // const { instance } = useMsal();



  const {
    classes,
    isLogging,
    onSignIn,
    onExternalSubmit,
    onForgotPasswordClick,
    isEmailNotVerifiedYet,
    messageText,
    messageType,
    resetAuthMessageBox,
    onSendMeActivationEmailClick,
    // ssoLoginStarted,
    account, onSSOSubmit, ssoLoginError, error,
  } = props


useEffect(()=>{
  // console.log("use effect account======>>>>",isLogging,account)

    if (account) {
      const jsonData = JSON.stringify(account)
      const obj = JSON.parse(jsonData)
      // console.log("user effect object=====>>>",isLogging,obj)
      onSSOSubmit(
        obj.idToken.given_name,
        obj.idToken.family_name,
        obj.idToken.email,
        obj.idToken.ctry,
        true,
      )
    }
  
  if (error) {
    console.log(error)
    ssoLoginError(`Error: ${error}`)
  }
},[account])

  

  // console.log("account======>>>>>>>>>>>>>>>>",isLogging,account)

  // function scrollToTop() {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth',
  //   })
  // }

  const scrollToTop = useCallback(() => {
    // handle click event
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, []);


//   const handleLoginPopup = () => {
//     instance.loginPopup({
//         ...loginRequest,
//         redirectUri: '/redirect'
//     }).catch((error) => console.log(error));
// };

//     let account1 = instance.getActiveAccount();
    
//     console.log("acc========>",account1)

//     let activeAccount;

//     if (instance) {
//         activeAccount = instance.getActiveAccount();
//     }
//     console.log("C-----",activeAccount)

  return (
    
      <NxtCircularLoader isLoading={isLogging}> 
      <Header showExternalLogin={showExternalLogin} setShowExternalLogin={setShowExternalLogin} onSignIn={onSignIn}/>
      <div className={classes.about}>
        <p className={classes.header}>About Nova</p>
        <p className={classes.infoText}>
          Nova is an innovation management ecosystem that enables and empowers employees to innovate
          and challenge, find funding and see their ideas brought into reality.
        </p>
      </div>
      <div className={classes.aboutBlocks}>
        <LandingPageBlock
          title="What is Nova?"
          description="Nova is an innovation management ecosystem that enables and empowers employees to innovate and challenge, find funding and see their ideas brought into reality."
          icon={aboutIcon}
        />
        <LandingPageBlock
          title="Why are we doing it?"
          description="The Vodafone Way is what makes us stand out from the rest. We want to embody being “Innovation Hungry” by creating and delivering new products, services and ways of working."
          icon={whyIcon}
        />
        <LandingPageBlock
          title="How can you get involved?"
          description="You can share your ideas on the platform throughout the year or join annual events such as the Ideathon and Hackathon. You can also attend special workshops and through gamification support ideas of your peers."
          icon={getInvolvedIcon}
        />
      </div>
      <div className={classes.howItWorks}>
        <p className={classes.header}>How it works</p>
        <p className={classes.infoText}>
          Using the concept of gamification, ideas that are submitted to NOVA collect virtual
          ‘funding’. With enough funding, your idea can be brought to life through our idea
          lifecycle.
        </p>
        <div className={classes.steps}>
          <span className={classes.floatLeft}>
            <LandingPageSteps
              side="left"
              stepNumber="01"
              title="Idea submission & Approval"
              description="Do you have an idea that adds business value to the company? Submit it in the NOVA idea platform, and if your idea receives enough support from the community through funding, it will be sent to the NOVA board for approval."
              icon={ideaIcon}
            />
          </span>
          <span className={classes.floatRight}>
            <LandingPageSteps
              side="right"
              stepNumber="02"
              title="Prototyping"
              description="Time to put your idea to the test using internal labs, crowdsourcing and co-creation with our external partners!"
              icon={prototypeIcon}
            />
          </span>
          <span className={classes.floatLeft}>
            <LandingPageSteps
              side="left"
              stepNumber="03"
              title="Review"
              description="Your prototype will be reviewed by the NOVA senior leader team. Don’t worry we support you through this!"
              icon={reviewIcon}
            />
          </span>
          <span className={classes.floatRight}>
            <LandingPageSteps
              side="right"
              stepNumber="04"
              title="Demand and delivery"
              description="The last step is to find the stakeholders and progress your idea further."
              icon={demandIcon}
            />
          </span>
        </div>
        <Button
          className={classes.up}
          onClick={scrollToTop}
          style={{ backgroundColor: 'transparent' }}
        >
          <img className={classes.upIcon} src={upIcon} alt="img not found" />
        </Button>
      </div>
      {showExternalLogin && (
          <ExternalLogin
            openDialog={showExternalLogin}
            isLogging={isLogging}
            closeDialog={() => setShowExternalLogin(false)}
            forgotPassword={onForgotPasswordClick}
            onExternalSubmit={onExternalSubmit}
            isEmailNotVerifiedYet={isEmailNotVerifiedYet}
            messageText={messageText}
            messageType={messageType}
            onSendMeActivationEmailClick={onSendMeActivationEmailClick}
            resetAuthMessageBox={resetAuthMessageBox}
          />
        )}
        </NxtCircularLoader>
    
  )
}

// export default withStyles(styles)(NewLandingPage)
export default AuthProvider(withStyles(styles)(NewLandingPage))
