const styles = theme => ({
  root: {
    margin: theme.spacing(2, 0, 4, 0),
  },
  mainTitle: {
    margin: theme.spacing(5 / 4, 0),
    '@media(max-width:767px)':{
      fontSize:'16px'
    }
  },
  rewardsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  line: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: theme.spacing(4),
    '@media(max-width:767px)':{
      display:'block'
    }
    
  },
  inputLabel: {
    flex: 1,
    textAlign: 'right',
    paddingTop: theme.spacing(2),
    '@media(max-width:767px)':{
      textAlign:'left',
      fontSize:'14px'
    }
  },
  imageLabel: {
    flex: 1,
    textAlign: 'right',
    paddingTop: theme.spacing(1),
    '@media(max-width:767px)':{
      textAlign:'left'
    }
  },
  inputContainer: {
    flex: 3,
    marginLeft: theme.spacing(4),
    '@media(max-width:767px)':{
      marginLeft:'0px'
    }
  },
  // error: {
  //   color: theme.palette.error.main,
  // },

  radioImages: {
    '& > div:first-child': {
      width: '500px',
    },
  },
  imageContainer: {
    position: 'relative',
    width: '101px',
  },
  img: {
    display: 'block',
    overflow: 'hidden',
    width: '100%',
  },
  input: {
    display: 'none',
  },
  deleteButton: {
    position: 'absolute',
    right: 0,
  },
  button: {
    width: '100%',
  },
  error: {
    color: theme.palette.error.main,
  },
  stepperRoot: {
    float: 'right',
    width: '484px',
  },
  radioButtonMain: {
    paddingLeft: theme.spacing(1.5),
  },
  add_image: {
    width: '280px',
    height: '120px',
    border: '1px dashed #666666',
    borderRadius: '10px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width:767px)':{
      width:'100%'
    },
  },
  upload_icon: {
    width: '62px',
    height: '62px',
    padding: '2px',
  },
  upload_media_help_text_1: {
    fontFamily: 'VodafoneRg-Bold',
    color: '#6551FF',
    fontSize: '14px',
    padding: '2px',
  },
  dragndrop_text:{
    fontFamily: 'VodafoneRg-Regular',
    color: '#333333',
    fontSize: '16px',
    paddingTop: '6px',
  },
  format_support_text: {
    fontFamily: 'VodafoneRg-Regular',
    color: '#999999',
    fontSize: '14px',
    paddingTop: '6px',
    paddingBottom: '15px',
  },
  image_grid: {
    display: 'grid', 
    gridTemplateColumns: '1fr 1fr 1fr', 
    columnGap: '25px',
    rowGap: '25px',
  },
  image_name: {
    fontSize: '16px',
    fontFamily: 'VodafoneRg-Regular',
    color: '#333333',
    paddingTop: '7px',
  },

  thumb_img: {
    display: 'block',
    overflow: 'hidden',
    width: '97px',
    height: '97px',
    borderRadius: '10px',
  },
  // deleteButton: {
  //   position: 'absolute',
  //   right: -5,
  //   top: -5,
  //   height: '20px',
  //   width: '20px',
  //   cursor: 'pointer',
  // },

  // image_name:{
  //   fontSize: '16px',
  //   fontFamily: 'VodafoneRg-Regular',
  //   color: '#333333',
  //   paddingTop: '7px',
  // }

})

export default styles
