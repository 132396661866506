import React, {  useState, useEffect } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { Container } from '@material-ui/core';
import NxtCircularLoader from 'components/nxt_circular_loader/View'
import Breadcrumbs from 'components_newui/pageBreadcrumbsMenu/View'
import IdeaPanel from '../../components_newui/idea_panel/View'
import FilterComponent from '../../components/filter_component/View'

import { FIRST_LIMIT_CONSTANT, LIMIT_CONSTANT } from 'common_constants/Ideas'
import noDraftIdeasImg from 'img/no_draft_idea.png'
import navigation from 'support/navigation';

import * as pages from 'common_constants/Pages'
import NavigationService from 'support/navigation/NavigationService'

import styles from './View.Style'
import { Link } from 'react-router-dom';



const InvestedIdeas = props => {
    const ROUTES = NavigationService.getRoutes()
    // console.log("🚀 ~ InvestedIdeas ~ ROUTES:", ROUTES)
    const AllIdeasPath =  ROUTES[pages.ALL_IDEAS].path
    


    const [investedIdeaData, setInvestedIdeaData ] = useState([])
    const [activeFilters, setActiveFilters] = useState({
        ideaStatusFilter: [],
        types: [],
        organizationsFilter:[]
    })
    const [searchQuery, setSearchQuery] = useState("")

    const {
        classes,
        ideasInvested,
        userEmail,
        getInvestments,
        navigateToIdea,
        navigateToAllIdeas,
        ideaStatusStepper,
        typesStaus,
        applyFilter,
        serviceLineOrgs,
        domainLoaded,
        isIdeasLoading,
        getActiveOrganizations
    } = props

    useEffect(() => {
        setInvestedIdeaData(ideasInvested)
    }, [ideasInvested])

    useEffect(() => {
        getInvestments(userEmail)
    }, [userEmail])

    useEffect(() => {
        const {getTypes} = props
        if (domainLoaded) {
            getTypes()
            getActiveOrganizations()
        }
    }, [domainLoaded])


    const handleActiveFilters = async (newFilters) => {
        await setActiveFilters(newFilters)
    }

    const getInvestmentIdea = (offset = 0) =>{
        const {ideaStatusFilter,types,organizationsFilter} = activeFilters
        const limitOffset = offset ? LIMIT_CONSTANT : FIRST_LIMIT_CONSTANT
        
        const serviceLineOrgsNameList = serviceLineOrgs.filter(item => organizationsFilter.includes(item.id)).map(itemName => itemName.name)
        applyFilter(userEmail,
            serviceLineOrgsNameList,
            ideaStatusFilter,
            types,
            limitOffset,
            offset
        )
    }

    useEffect(()=>{getInvestmentIdea()},[activeFilters,userEmail])

    const numberOfInvestedIdeas = investedIdeaData.length

    // Maybe have page hierarchy come from state????? at least get navigate functions from state
    const pageHierarchy = [
        {string: "Home", navigate: ()=>navigation.navigateToExplore()}, 
        { string: "My invested ideas", navigate: () => {} }
    ]


    return (
        <>
            <Breadcrumbs crumbs={pageHierarchy} />
            <Container>

                <main className={classes.background}>
                    <div className={classes.top_section_text}>
                        <div className={classes.title}>My invested ideas ({numberOfInvestedIdeas})</div>
                        <Link to={`${AllIdeasPath}`} className={classes.investedIdeasNumber} > All ideas </Link>
                        {/* <button type='button' className={classes.investedIdeasNumber} onClick={navigateToAllIdeas}>All ideas</button> */}
                    </div>
    

                        <FilterComponent
                            activeFilters={activeFilters}
                            serviceLineOrgs={[]}
                            types={[]}
                            ideaStatusFilter={false}
                            handleActiveFilters={handleActiveFilters}
                            search={searchQuery}
                            setSearch={setSearchQuery}
                            applySearch={() => setInvestedIdeaData(ideasInvested.filter(item => (
                                item.title.toLowerCase().includes(searchQuery.toLowerCase())
                            )))}
                            resetSearch={() => { setSearchQuery(''); setInvestedIdeaData(ideasInvested) }}
                        />
                                        <NxtCircularLoader isLoading={isIdeasLoading}>
                                        

                    {
                        investedIdeaData && investedIdeaData.length > 0 ?
                            <div className={classes.IdeaGrid}>
                                { investedIdeaData.map(idea => <IdeaPanel key={idea.id} urlId={idea.id} idea={idea} />) }
                            </div>
                            :
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <img src={noDraftIdeasImg} className={classes.no_idea_img} alt='no draft data'/>
                                <div className={classes.no_idea_title}>
                                    No ideas found
                                </div>
                            </div>
                    }
                            </NxtCircularLoader>
                </main>
            </Container>
        </>
    )
}

export default withStyles(styles)(InvestedIdeas)