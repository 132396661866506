import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'
import searchIcon from 'img/search-icon.png'
import closeIcon from 'img/cancel-or-no-ideas.png'

const SearchPopup = props => {
  const { classes, search, popular, close } = props
  const [query, setQuery] = useState('')

  function handleKeyDown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
        // Handle the event, e.g., trigger the click handler
        search(query)
    }
}
  function handleKeyDownClose(event) {
    if (event.key === 'Enter' || event.key === ' ') {
        // Handle the event, e.g., trigger the click handler
        close()
    }
}

  return (
    <div className={classes.popup}>
      <div
        role="button"   
        onClick={close} 
        onKeyDown={handleKeyDownClose} 
        tabIndex="0"
      >
        <img 
          alt='close icon' 
          src={closeIcon} 
          className={classes.close_icon} 
          
          
        />
      </div>
      <div className={classes.txtsize40} style={{fontSize: '40px'}}>Search</div>
      <div style={{position: 'relative'}}>
        <input 
          className={classes.bar} 
          value={query} 
          onChange={e => setQuery(e.target.value)}
          onKeyDown={handleKeyDown} 
          role="button" 
          tabIndex="0"
          // onKeyDown={e => {
          //   e.key === 'Enter' && search(query)
          // }}
          />
        <div 
          className={classes.bar_submit}
          onClick={()=>search(query)}
          onKeyDown={handleKeyDown} 
          role="button" 
          tabIndex="0"
        >
          <img src={searchIcon} className={classes.search_ico} alt='search icon' />
        </div>
      </div>

      <div className={classes.txtsize32} style={{fontSize: '32px'}}>Popular searches</div>
      <div>
        {popular.map(term => (
          <span 
            key={term}
            onClick={() => setQuery(term)}
            onKeyDown={(event) => event.key === 'Enter' || event.key === ' ' ? setQuery(term) : null} 
            role="button" 
            tabIndex="0"
            className={classes.crumb}>
              {term}
          </span>
        ))}
      </div>
    </div>
  )
}



export default withStyles(styles)(SearchPopup)
