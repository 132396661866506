/* eslint-disable react/function-component-definition */

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Switch from '@material-ui/core/Switch'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined'
import SaveIcon from '@material-ui/icons/Save'
import PrintIcon from '@material-ui/icons/Print'
import ShareIcon from '@material-ui/icons/Share'
import FavoriteIcon from '@material-ui/icons/RemoveRedEye'

const useStyles = makeStyles(theme => ({
  root: {},
  exampleWrapper: {
    // position: 'absolute',

    float: 'right',
  },

  speedDial: {
    // position: 'absolute',
    // '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    //   bottom: theme.spacing(2),
    //   right: theme.spacing(2),
    // },
    // '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    //   top: theme.spacing(2),
    //   left: theme.spacing(2),
    // },
  },
}))

const actions = [
  { icon: <FavoriteIcon />, name: 'View Comments', operation: 'view' },
  { icon: <ShareIcon />, name: 'Share', operation: 'share' },
]

export default function SpeedDials(props) {
  const classes = useStyles()
  const { id, handleShareIdea, getExpertCommentsMail, handleViewExpertComments } = props
  // const [direction, setDirection] = React.useState('down')
  const [open, setOpen] = React.useState(false)
  const [hidden, setHidden] = React.useState(false)

  const handleClose = e => {
    // console.log(e.target.key)
    setOpen(false)
  }

  const handleOpen = (e, opration) => {
    e.preventDefault()

    setOpen(!open) // to close the speed dial, remove this line if not needed.

    setOpen(true)
    // getExpertCommentsMail(id)
  }

  const handleClick = (e, opration) => {
    e.preventDefault()
    if (opration === 'share') {
      handleShareIdea()
    } else if (opration === 'view') {
      getExpertCommentsMail(id)
      handleViewExpertComments()
    }
    setOpen(!open) // to close the speed dial, remove this line if not needed.

    // setOpen(true)
  }

  return (
    <div className={classes.exampleWrapper}>
      <SpeedDial
        ariaLabel="SpeedDial example"
        className={classes.speedDial}
        hidden={hidden}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="down"
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            // onClick={handleClose}
            onClick={e => {
              handleClick(e, action.operation)
            }}
          />
        ))}
      </SpeedDial>
    </div>
  )
}
