/* eslint-disable react/function-component-definition */

import React from 'react'
import * as PropTypes from 'prop-types'
import styles from './View.Style'
import withStyles from '@material-ui/core/styles/withStyles'


function LandingPageSteps(props) {
  const {side, stepNumber, title, description, icon, classes } = props

  if(side === "left"){
    return (
      <div className={classes.block}>
        <p className={classes.number}>{stepNumber}</p>
        <div className={[classes.textBlock,classes.mobFlexOrder].join(' ')}>
          <p className={classes.title}>{title}</p>
          <p className={classes.description}>{description}</p>
        </div>
        <img className={classes.icon} src={icon} alt="img not found"/>
      </div>
    )
  }
  // else{
    return (
      <div className={classes.block}>
        <img className={[classes.icon,classes.iconFlexOrder].join(' ')} src={icon} alt="img not found"/>
        <div className={[classes.textBlock,classes.mobFlexOrder].join(' ')}>
          <p className={classes.title}>{title}</p>
          <p className={classes.description}>{description}</p>
        </div>
        <p className={classes.number}>{stepNumber}</p>
      </div>
    )
  // }
}

LandingPageSteps.defaultProps = {
  title: '',
  src: '',
  description: '',
}



export default withStyles(styles)(LandingPageSteps)