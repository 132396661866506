import { connect } from 'react-redux'
import { onLogin } from 'actions/sync/auth'
import { postMicrosoftResult } from 'services/auth'
import Navigation from 'support/navigation'
import View from 'pagesNewUi/microsoft_login/View'

const mapDispatchToProps = (dispatch, ownProps) => ({
  checkMicrosoftLogin: () => {
    const { email, code, domain } = ownProps.match.params
    const onSuccess = () => onLogin(dispatch, domain)
    const onFailure = () => Navigation.navigateToCustomLogin(domain)
    dispatch(postMicrosoftResult(email, code, onSuccess, onFailure, true))
  },
})

export default connect(
  null,
  mapDispatchToProps,
)(View)
