import React, { useCallback } from 'react'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'
import withStyles from '@material-ui/core/styles/withStyles'
import MobileStepper from '@material-ui/core/MobileStepper'
import Button from '@material-ui/core/Button'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import SwipeableViews from 'react-swipeable-views'
import styles from './View.Style'

const AutoPlaySwipeableViews = SwipeableViews

const Slider = props => {
  const {
    activeStep,
    classes,
    className,
    children,
    maxSteps,
    setActiveStep,
    onNextClick,
    onPreviousClick,
  } = props

  // function handleNext() {
  //   setActiveStep(activeStep + 1)
  //   onNextClick()
  // }

  // function handleBack() {
  //   setActiveStep(activeStep - 1)
  //   onPreviousClick()
  // }

  // function handleStepChange(step) {
  //   setActiveStep(step)
  // }

  const handleNext = useCallback(() => {
   
    setActiveStep(activeStep + 1)
    onNextClick()
  }, []);
  const handleBack = useCallback(() => {

    setActiveStep(activeStep - 1)
    onPreviousClick()
  }, []);
  const handleStepChange = useCallback((step) => {
  
    setActiveStep(step)
  }, [activeStep]); 

  return (
    <>
      <AutoPlaySwipeableViews
        axis={classes.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {children}
      </AutoPlaySwipeableViews>

      <MobileStepper
        classes={{ root: clsx(classes.stepperRoot, className.stepperRoot) }}
        steps={maxSteps}
        position="static"
        variant="dots"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            {classes.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {classes.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          </Button>
        }
      />
    </>
  )
}

Slider.defaultProps = {
  className: {
    stepperRoot: '',
  },
  onNextClick: () => {},
  onPreviousClick: () => {},
}

Slider.propTypes = {
  classes: PropTypes.shape({
    amount: PropTypes.string,
    days: PropTypes.string,
    descriptionCard: PropTypes.string,
    dialGraph: PropTypes.string,
    dialGraphText: PropTypes.string,
    graphBlock: PropTypes.string,
    sliderContent: PropTypes.string,
    stepperRoot: PropTypes.string,
  }).isRequired,
  className: PropTypes.shape({
    stepperRoot: PropTypes.string,
  }),
  activeStep: PropTypes.number.isRequired,
  maxSteps: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  onNextClick: PropTypes.func,
  onPreviousClick: PropTypes.func,
}

export default withStyles(styles)(Slider)
