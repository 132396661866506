import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, TextField, CircularProgress, InputAdornment } from '@material-ui/core'
import SuggestionList from './components/suggestion_list/View'
import styles from './View.Style'

class AutoSuggestion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listWidth: '',
      innerSuggestions: [],
      userName: '',
    }
    this.inputReference = React.createRef()
    this.handleUsernameChange = this.handleUsernameChange.bind(this)
  }

  componentDidMount() {
    if (this.inputReference.current) {
      this.setState({
        listWidth: `${this.inputReference.current.offsetWidth}px`,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { suggestions } = this.props
    if (prevProps.suggestions !== suggestions) {
      this.setInnerSuggestions(suggestions)
    }
  }

  handleUsernameChange(event) {
    const { getSuggestions, minLengthToSearch } = this.props
    const userName = event.target.value
    this.setState({ userName })
    if (userName.length >= minLengthToSearch) {
      getSuggestions(userName)
    } else {
      this.setInnerSuggestions([])
    }
  }

  setInnerSuggestions(innerSuggestions) {
    this.setState({
      innerSuggestions,
    })
  }

  render() {
    const {
      classes,
      suggestions,
      inputLabel,
      isLoading,
      onSuggestionSelected,
      hasError,
      minLengthToSearch,
    } = this.props
    const { listWidth, innerSuggestions, userName } = this.state
    return (
      <div className={classes.root}>
        <div className={classes.inputWrapper}>
          <TextField
            ref={this.inputReference}
            label={inputLabel}
            margin="dense"
            fullWidth
            error={hasError}
            onChange={this.handleUsernameChange}
            InputProps={{
              endAdornment: isLoading ? (
                <InputAdornment position="start">
                  <CircularProgress size={20} />
                </InputAdornment>
              ) : null,
            }}
          />
        </div>
        <div>
          {innerSuggestions.length > 0 && userName.length >= minLengthToSearch && (
            <SuggestionList
              suggestions={innerSuggestions}
              listWidth={listWidth}
              lastSuggestionIndex={suggestions.length - 1}
              onSuggestionSelect={onSuggestionSelected}
            />
          )}
        </div>
      </div>
    )
  }
}
AutoSuggestion.defaultProps = {
  inputLabel: '',
  minLengthToSearch: 3,
  isLoading: false,
  hasError: false,
}

AutoSuggestion.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getSuggestions: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  inputLabel: PropTypes.string,
  minLengthToSearch: PropTypes.number,
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
}
export default withStyles(styles)(AutoSuggestion)
