import React from 'react'
import { withStyles, withTheme } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'
import { ideaStatusTypeMapper } from 'pagesNewUi/new_idea_detail/Constants'
import styles from './View.style'

const IdeaStatusStep = props => {
  const {
    classes,
    icon,
    mainCircleValue,
    secondaryCircleValue,
    text,
    statusType,
  } = props

  return (
    <div
      className={clsx(
        classes.statusItems,
        statusType === ideaStatusTypeMapper.next && classes.disabledStatus,
      )}
    >
      <div
        className={clsx(
          classes.iconContainer,
          statusType === ideaStatusTypeMapper.next && classes.iconContainerNext,
        )}
      >
        <span className={`${classes.iconsStyles} ${icon}`} />
        <div className={classes.primaryProgressContainer}>
          <CircularProgress
            thickness={4}
            variant="static"
            size={40}
            value={mainCircleValue}
            style={{
              color: `${statusType === ideaStatusTypeMapper.next ? '#333333' : '#7834FF'}`,
            }}
          />
        </div>
        <div className={classes.secondaryProgressContainer}>
          <CircularProgress
            variant="static"
            size={30}
            value={secondaryCircleValue}
            style={{
              color: `${
                statusType === ideaStatusTypeMapper.next ? '#333333' : '#7834FF'
              }`,
            }}
          />
        </div>
      </div>
      <div
        className={clsx(
          classes.textContainer,
          statusType === ideaStatusTypeMapper.next && classes.textContainerNext,
          statusType === ideaStatusTypeMapper.previous && classes.textContainerPrevious,
        )}
      >
        <span>{text}</span>
      </div>
    </div>
  )
}

IdeaStatusStep.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  mainCircleValue: PropTypes.number.isRequired,
  secondaryCircleValue: PropTypes.number,
  statusType: PropTypes.oneOf([
    ideaStatusTypeMapper.previous,
    ideaStatusTypeMapper.current,
    ideaStatusTypeMapper.next,
  ]).isRequired,
}

IdeaStatusStep.defaultProps = {
  secondaryCircleValue: 0,
}

export default withTheme(withStyles(styles)(IdeaStatusStep))
