import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import NxtCircularLoader from 'components/nxt_circular_loader/View'
import clsx from 'clsx'
import styles from './View.Style'
/* eslint-disable react/no-array-index-key, jsx-a11y/control-has-associated-label  */

const ComparisonTable = props => {
  const { classes, header, comparisonRow, dataRows, isLoading } = props

  const getComparisonClass = type => {
    if (type === 'positive') return classes.positiveComparison
    if (type === 'negative') return classes.negativeComparison
    return classes.equalComparison
  }
  return (
    <div className={classes.root}>
      <NxtCircularLoader isLoading={isLoading}>
        <table className={classes.table}>
          <thead>
            <tr>
              <td />
              {header.map((head, index) => (
                <th key={index} className={clsx(classes.headerColumn, index)}>
                  <div style={{ borderBottomColor: head.borderColor }}>
                    {head.icon && <span className={`icon-${head.icon}`} />}
                    <span className={classes.headerTitle}>{head.text}</span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataRows.map((row, index) => (
              <tr key={index}>
                <td>
                  <div className={classes.firstColumn}>
                    {row.firstColumn.map((column, colIndex) => (
                      <div key={colIndex} className={classes.twoRowsText}>
                        <span className={classes.amount}>{column.mainText}</span>
                        <span className={classes.subText}>{column.subText}</span>
                      </div>
                    ))}
                  </div>
                </td>
                {row.data.map((amount, dataIndex) => (
                  <td key={dataIndex}>
                    <span className={classes.amount}>{amount !== undefined ? amount : '-'}</span>
                  </td>
                ))}
              </tr>
            ))}
            {/* { */}
              <tr>
                <td>
                  {comparisonRow.firstColumn && !!comparisonRow.firstColumn.length && (
                    <div className={classes.firstColumn}>
                      {comparisonRow.firstColumn.map((column, colIndex) => (
                        <div
                          key={colIndex}
                          className={clsx(classes.twoRowsText, getComparisonClass(column.type))}
                        >
                          <span className={classes.amount}>{column.mainText}</span>
                          <span className={classes.subText}>{column.subText}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </td>
                {comparisonRow.data &&
                  comparisonRow.data.map((amount, dataIndex) => (
                    <td key={dataIndex} className={clsx(getComparisonClass(amount.type))}>
                      <div className={classes.twoRowsText}>
                        <span className={classes.amount}>{amount.value}</span>
                        {!!amount.percentage && (
                          <span className={classes.subText}>{amount.percentage}</span>
                        )}
                      </div>
                    </td>
                  ))}
              </tr>
            {/*  } */}
          </tbody>
        </table>
      </NxtCircularLoader>
    </div>
  )
}

ComparisonTable.defaultProps = {
  isLoading: false,
}

ComparisonTable.propTypes = {
  header: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.string,
    }),
  ).isRequired,
  dataRows: PropTypes.arrayOf(
    PropTypes.shape({
      firstColumn: PropTypes.arrayOf(
        PropTypes.shape({
          mainText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          subText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
      data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    }),
  ).isRequired,
  comparisonRow: PropTypes.shape({
    firstColumn: PropTypes.arrayOf(
      PropTypes.shape({
        mainText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        subText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
    data: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        type: PropTypes.string,
      }),
    ),
  }).isRequired,
  isLoading: PropTypes.bool,
}

export default withStyles(styles)(ComparisonTable)
