/* eslint-disable react/jsx-boolean-value */

import React, { Component,  useState } from 'react'
import i18n from 'support/i18n'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '../../../../components_newui/button/View'
import { ButtonLink } from 'components'
import TextEditor from 'components/text_editor/View'
import styles from './View.Style'
import IdeaAvatar from '../idea_avatar/View'
import InviteeForm from './components/invitee_form/View'
import PendingInvitations from './components/pendings_invitations/View'
import { ClickAwayListener } from '@material-ui/core'

import AutoSuggestion from 'components/autosuggestion/View'
import SuggestionSelected from 'components/suggestion_selected/View'


const SendJoinSection = (props) =>{
  const {
    classes,
    team,
    onSearchSuggestions,
    suggestions,
    inputLabel,
    pendingInvitationsUsers,
    onSendInvitationClick,
    onPendingUserClick,
    onTeamUserClick,
    onPendingUserRemoveClick,
    canAddMembers,
    sectionTitle,
    inputHelpText,
    highlightedUserEmail,
    isSearchingForUser,
    canJoinTeam,
    isAlreadyInvited,
    isAnonymous,
  } = props

const [requestAccessToTeam,setRequestAccessToTeam] = useState('')
const [showJoinTeamDialog,setShowJoinTeamDialog] = useState(false)
const [InviteeFormPopup,setInviteeFormPopup] = useState(false)
const [selectedSuggestion,setSelectedSuggestion] = useState({})

const handleSendRequest =(request) =>{
  const { ideaId, onRequestJoinIdeaTeam } = props
  onRequestJoinIdeaTeam(ideaId, request)
}

const togglepopup = ()=>{
  setShowJoinTeamDialog(!showJoinTeamDialog)
}
 const toggleInvitePopup = ()=>{
  setInviteeFormPopup(!InviteeFormPopup)
 }

 const InviteeFormPopupCancel = ()=>{
    setInviteeFormPopup(false)
    setSelectedSuggestion({})
 }

 const handleSendInvitation =(email)=>{
  // const { pendingInvitationsUsers, onSendInvitationClick } = props
  const alreadyPending = pendingInvitationsUsers.find(user => user.email === email)
  if (!alreadyPending) {
    setSelectedSuggestion({})
    onSendInvitationClick(email)
  }
}

  return (
    <>
    <div className={classes.root}>
        <div className={classes.rows}>
          <h2 className={classes.titleSection}>
            {/* <span className="icon-team" /> */}
            {sectionTitle}
          </h2>

          {!isAlreadyInvited && canJoinTeam && (
            <Button className={classes.joinButton} styled={true} color="primary" onClick={togglepopup} text={i18n.t('IDEA.JOIN_THIS_TEAM')}>
            </Button>
          )}

          {canAddMembers && (
              <Button
                id="add-member-button"
                className={classes.joinButton}
                onClick={ toggleInvitePopup}
                text={i18n.t('IDEA.ADD_MEMBER')}
              >
              </Button>
          )}
        </div>
      <div>
        {!isAnonymous &&
          team.map(userTeam => (
            <ButtonLink
              onLinkClick={() => onTeamUserClick(userTeam.email)}
              key={userTeam.email}
              className
            >
              <IdeaAvatar
                name={userTeam.fullName}
                photoSrc={userTeam.photoSrc}
                isAdmin={highlightedUserEmail === userTeam.email}
                hasDeleteButton={canAddMembers && highlightedUserEmail !== userTeam.email}
                onDeleteButtonClick={() => onPendingUserRemoveClick(userTeam.email)}
              />
            </ButtonLink>
          ))}
        {canAddMembers && (

            InviteeFormPopup && (
                <div className={classes.faded_out} >
                  <div className={classes.idea_preview}>
                    <ClickAwayListener onClickAway={() => setInviteeFormPopup(false)}>
                      <div className={classes.containerWrp}>
                      
                          {selectedSuggestion.fullName && (
                            <SuggestionSelected
                              suggestion={selectedSuggestion}
                              onRemoveSuggestion={() => setSelectedSuggestion({})}
                            />
                          )}
                          {!selectedSuggestion.fullName && (
                            <>
                              {inputHelpText && (
                                <div id="input-help-text" className={classes.helpText}>
                                  {inputHelpText}
                                </div>
                              )}
                              <AutoSuggestion
                                classes={{ root: classes.autosuggestionContainer }}
                                isLoading={isSearchingForUser}
                                inputLabel={inputLabel}
                                onSuggestionSelected={suggestion => setSelectedSuggestion(suggestion)}
                                getSuggestions={onSearchSuggestions}
                                suggestions={suggestions}
                              />
                            </>
                          )}
                          <div className={classes.editorButtons}>
                          <Button
                              id="cancel-button"
                              text= {i18n.t('IDEA.CANCEL')}
                              onClick={InviteeFormPopupCancel }
                            >
                             
                            </Button>
                            <Button
                              id="send-invitation-button"
                              text= {i18n.t('IDEA.SEND_INVITATION')}
                              disabled={!selectedSuggestion.fullName}
                              onClick={() => handleSendInvitation(selectedSuggestion.email)}
                            >
                             
                            </Button>
                           
                          </div>

                      </div>
                    </ClickAwayListener>
                  </div>
                </div>
              
            )


          // <InviteeForm
          //   onSearchSuggestions={onSearchSuggestions}
          //   onSendInvitationClick={onSendInvitationClick}
          //   suggestions={suggestions}
          //   inputLabel={inputLabel}
          //   inputHelpText={inputHelpText}
          //   pendingInvitationsUsers={pendingInvitationsUsers}
          //   isSearchingForUser={isSearchingForUser}
          // />
        )}
        <PendingInvitations
          pendingInvitationsUsers={pendingInvitationsUsers}
          onPendingUserClick={onPendingUserClick}
          onPendingUserRemoveClick={onPendingUserRemoveClick}
          showDeleteUserIcon={canAddMembers}
        />
      </div>
      <div className={classes.joinTheTeamContainer}>

      </div>
    </div>



      {
        showJoinTeamDialog &&
        <div>
        {!isAlreadyInvited && (
          <div className={classes.faded_out} >
            <div className={classes.idea_preview}>
              <ClickAwayListener onClickAway={() => setShowJoinTeamDialog(false)}>

              
          <div className={classes.containerWrp}>
            <div className={classes.title}>
                Join the idea team
            </div>
            <div className={classes.subtitle}>
                Explain why you want to be part of the idea team.
            </div>

            <TextEditor
              onTextChange={text => setRequestAccessToTeam(text)}
              value={requestAccessToTeam}
              showDescription={false}
            />
            <div className={classes.editorButtons}>
              
              <Button
                id="cancel-button"
                
                onClick={() =>  setShowJoinTeamDialog(false)}
                text={i18n.t('IDEA.CANCEL')}
              >
                
              </Button>
              <Button
                id="send-invitation-button"
                
                onClick={() => handleSendRequest(requestAccessToTeam)}
                text={i18n.t('IDEA.SEND_REQUEST')}
              >
              </Button>
            </div>
          </div>
        


              </ClickAwayListener>
            </div>
          </div>
          )}
        </div>
      }
  </>
  )
} 


SendJoinSection.defaultProps = {
  inputHelpText: '',
}

SendJoinSection.propTypes = {
  isSearchingForUser: PropTypes.bool.isRequired,
  inputHelpText: PropTypes.string,
  sectionTitle: PropTypes.string.isRequired,
  inputLabel: PropTypes.string.isRequired,
  highlightedUserEmail: PropTypes.string.isRequired,
  team: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      isAdmin: PropTypes.bool.isRequired,
      photoSrc: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
  ).isRequired,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      photoSrc: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
  ).isRequired,
  pendingInvitationsUsers: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      photoSrc: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSearchSuggestions: PropTypes.func.isRequired,
  onSendInvitationClick: PropTypes.func.isRequired,
  onPendingUserClick: PropTypes.func.isRequired,
  onTeamUserClick: PropTypes.func.isRequired,
  onPendingUserRemoveClick: PropTypes.func.isRequired,
  canAddMembers: PropTypes.bool.isRequired,
  canJoinTeam: PropTypes.bool.isRequired,
}

export default withStyles(styles)(SendJoinSection)
