/* eslint-disable react/sort-comp,react/no-unused-state */

import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { Box, Container } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Rating from '@material-ui/lab/Rating'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import i18n from 'support/i18n'
import EvaluatorIdeaDescription from '../idea_description/View'

class EvaluationExpertCommentPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      textValue: '',
      imageUrl: props.images,
      videoUrl: [],
      id: props.match.params.id,
    }
    this.handleHomepage = this.handleHomepage.bind(this)
    this.handleTextChange = this.handleTextChange.bind(this)
    this.handleRating = this.handleRating.bind(this)
    this.postExpertComments = this.postExpertComments.bind(this)
  }

  componentDidMount() {
    const { getIdea } = this.props
    const { id } = this.state
    getIdea(id)
  }

  componentDidUpdate(prevProps) {
    const { videoSrc, images } = this.props
    if (prevProps.images !== images) {
      this.setState({ imageUrl: images, videoUrl: videoSrc })
    }
  }
  
  handleTextChange(event) {
    const { value } = event.target

    this.setState({ textValue: value })
  }

  handleRating(e, v) {
    this.setState({ rating: v })
  }

  postExpertComments() {
    const { postExpertComments } = this.props
    const { id, rating, textValue } = this.state
    postExpertComments(id, rating, textValue)
  }

  handleHomepage() {
    const { navigateToEvaluationBoard } = this.props
    navigateToEvaluationBoard()
  }

  render() {
    const { classes, images, imageUrl, title, description } = this.props
    const { videoUrl, textValue } = this.state
    // console.log(`123${images}`)
    // console.log(`1234${imageUrl}`)

    return (
      <>
        <div className={classes.top}>
          <h1>
            <i className="icon-idea" /> {i18n.t('SCREEN.EXPERT_COMMENT')}
          </h1>
        </div>
        <div className={classes.middles}>
          <div className={classes.center}>
            {/* Background image */}
            <div className={classes.outerSpace}>
              {/* Top buttons */}
              <div className={classes.topButtons}>
                <Button
                  variant="contained"
                  className={clsx(classes.selectedMainButton, classes.evaluationButton)}
                  onClick={this.handleHomepage}
                >
                  <Typography noWrap variant="h5">
                    Idea Homepage
                  </Typography>
                </Button>
              </div>

              {/* Red outer container */}
              <Grid container className={classes.redOuterContainer} spacing={3}>
                {/* Grey inner container */}
                <Grid item xs={12} className={classes.greyInnerContainer}>
                  {/* {showAllIdeaIdeas && (
                  
                )} */}
                  <div style={{ flexGrow: 1 }}>
                    {/* <Grid container direction="column" justify="center" alignItems="center"> */}

                    <EvaluatorIdeaDescription
                      images={images}
                      videoUrl={videoUrl}
                      title={title}
                      description={description}
                      showFab={false}
                    />

                    {/* Right grid */}
                      <Box item xs={12} width="100%" maxHeight="420px">
                        <Container
                          style={{
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'white',
                            padding: '30px',
                          }}
                        >
                          <Box py={1} display="flex" justifyContent="center">
                            <Typography variant="h5" component="h1">
                              Rate this idea out of 5:
                            </Typography>
                          </Box>

                          <Box py={1} display="flex" justifyContent="center">
                            <Rating
                              name="customized-empty"
                              defaultValue={0}
                              precision={0.5}
                              emptyIcon={<StarBorderIcon fontSize="inherit" />}
                              onChange={this.handleRating}
                            />
                          </Box>
                          <Box pt={3} display="flex" justifyContent="center">
                            <TextField
                              id="outlined-multiline-static"
                              label="Comments"
                              value={textValue}
                              onChange={this.handleTextChange}
                              multiline
                              rowsMax={3}
                              variant="outlined"
                              style={{ width: '50vw' }}
                            />
                          </Box>

                          <Box pt={1} display="flex" justifyContent="center">
                            <Box px={1}>
                              <Button
                                // disabled={evaluationLoading}
                                variant="contained"
                                px={3}
                                id="redBackground"
                                height="100%"
                                color="primary"
                                onClick={this.postExpertComments}
                              >
                                Submit
                              </Button>
                            </Box>
                          </Box>
                        </Container>
                      </Box>
                    {/* </Grid> */}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withStyles(styles)(EvaluationExpertCommentPage)
