/* eslint-disable no-dupe-keys */ 
import React from 'react'
import { connect } from 'react-redux'
import debounce from 'debounce'
import {
  getIdea,
  getIdeaContributions,
  saveContribution,
  deleteContribution,
  getIdeaInvestors,
  postInvestIdea,
  getIsUserInvitedToInnovationIdeaTeam,
  getIsUserInvitedToIdeaTeam,
  postAcceptJoinIdeaTeam,
  postRejectJoinIdeaTeam,
  postAcceptJoinInnovationTeam,
  postRejectJoinInnovationTeam,
  postSendInvitations,
  getIdeaTeam,
  reportIdea,
  deleteUserFromTeam,
  postIdeaDocument,
  deleteIdeaDocument,
  postComment,
  getIdeaComments,
  deleteComment,
  voteComment,
  putComment,
  postValidateIdea,
  deleteIdea,
  postActivatePilotProject,
  postImplementPilotProject,
  postTransferPromoter,
  postPublishDraft,
  getIsTeamRequestActive,
  getKpis,
  updateKpiSuccess,
  newKpi,
  updateKpi,
} from 'services/create_idea'
import {
  setContributionTime,
  setContributionBudget,
  setContributionOther,
  resetIdeaState,
} from 'actions/sync/create_idea_form'
import { resetSponsorSuggestions } from 'actions/sync/challenge_steps'
import Navigation from 'support/navigation'
import {
  showPopupDialog,
  showPopupSuccessSnackbar,
  showPopupErrorSnackbar,
} from 'support/popup_dialogs'
import i18n from 'support/i18n'
import { getSponsorSuggestions } from 'services/challenge_steps'
import { getApprovedReasons, getRejectedReasons } from 'services/domain'
import { requestJoinIdeaTeam } from 'services/ideas'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import { getBase64Split } from 'support/utils/file'
import { MAX_SIZE_DOCUMENT } from 'common_constants/Ideas'
import ConfirmSubmitContainer from './components/report_idea_section/components/dialogs/ConfirmSubmitContainer'
import AskInvestmentDialog from './components/summary_section/components/ask_investments_section/dialogs/AskInvestmentDialogContainer'
import SuccessSubmitModal from './components/report_idea_section/components/dialogs/SuccessSubmitContainer'
import ConfirmDeleteKpiModal from './components/kpi_section/dialogs/ConfirmDeleteContainer'
import ConfirmDeleteIdeaModal from './components/dialogs/ConfirmDeleteContainer'
import DiscardView from './components/dialogs/components/discard_view'
import ApproveView from './components/dialogs/components/approve_view'
import FeedbackView from './components/dialogs/components/feedback_view'
import SendInvitationsContainer from './components/comments_section/SendInvitationsContainer'
import View from './View'

const mapStateToProps = state => ({
  idea: state.data.createIdea,
  hasDomainLoaded: state.data.domain.dataLoaded,
  canSendPilotProjectTeamInvitations : state.data.createIdea.canSendPilotProjectTeamInvitations,
  canSendIdeaTeamInvitations: state.data.createIdea.canSendIdeaTeamInvitations,
  isAnonymous: state.data.createIdea.isAnonymous,
  pilotTeam: state.data.createIdea.pilotTeam,
  pendingInvitationsUsers: state.data.createIdea.pendingInvitationsUsers,
  pendingInvitationsPilotTeamUsers: state.data.createIdea.pendingInvitationsPilotTeamUsers,
  promoterEmail: state.data.createIdea.promoter.email,
  // isSearchingForUser: state.data.challengeSteps.sponsorStarted,
  isAlreadyInvited: state.data.createIdea.isAlreadyInvited,
  ownerEmail: state.data.createIdea.owner,
  
  isOrganizationsActive: state.data.domain.domainConfiguration.isOrganizationsActive,
  organizationTitle: state.data.domain.domainConfiguration.organizationsName,

  contributors: state.data.createIdea.contributors,

  ownContribution: state.data.createIdea.ownContribution,
  isBudgetActive: state.data.domain.domainConfiguration.isBudgetActive,
  isTimeActive: state.data.domain.domainConfiguration.isTimeActive,
  isOtherActive: state.data.domain.domainConfiguration.isOtherActive,
  isContributionActive: state.data.domain.domainConfiguration.isContributionActive,
  userEmail: state.data.user.userEmail,
  ideaStatusStepper: state.data.domain.domainConfiguration.ideaStatusStepper,
    isLoading:state.data.createIdea.isLoading,
  contributeCurrencySymbol: state.data.domain.domainConfiguration.contributeCurrencySymbol,
  currencyIcon: state.data.domain.domainConfiguration.currencyIcon,
  
  userBalance: parseInt(state.data.user.balance || 0, 10),
  isDraft: state.data.createIdea.isDraft,
  suggestions: state.data.ChallengeDraft.sponsorSuggestions,
  
  isAdmin: state.data.user.admin,
  localAdmin: state.data.user.localAdmin,
  localInnovator: state.data.user.localInnovation,
  isInnovator: state.data.user.isInnovator,
  isOwner: state.data.createIdea.owner === state.data.user.userEmail,
  isPromoter: state.data.createIdea.promoter.email === state.data.user.userEmail,
  userData: state.data.user,
  sameOrigin: state.data.user.countryId === state.data.createIdea.origin,
  isSearchingForUser: state.data.ChallengeDraft.sponsorStarted,
  
  rejectedReasons: state.data.domain.rejectedReasons,
  approvedReasons: state.data.domain.approvedReasons,

  currentStatus: state.data.createIdea.status,
  statusMessageText: state.data.createIdea.statusMessageText,
  statusMessageTitle: state.data.createIdea.statusMessageTitle,
  feedbackFromNova: state.data.createIdea.feedbackFromNova,
  boardDecision: state.data.createIdea.boardDecision,
  pitchDecision: state.data.createIdea.pitchDecision,
  investmentIdea: state.data.createIdea.investment,

  documentIdeaKey:state.data.createIdea.documentIdeaKey,
  isDocumentLoading: state.data.createIdea.isDocumentLoading,
  csrfToken: state.auth.csrf,
  // localAdmin: state.data.user.localAdmin,
  // localInnovator: state.data.user.localInnovation,
  // currencyIcon: state.data.domain.domainConfiguration.currencyIcon,
  
  investmentDetailsText: '<p><b>1st round: 0% - 33%</b></p>'
    +'<p>Taking the minimum investment threshold as 100%, the first 33% of investments made will be classed as the first round of investments.</p>'
    +'<p><b>2nd round: 34% - 66%</b></p>'
    +'<p>Again taking the minimum investment threshold as 100%, from 34% - 66% of investments made will be classed as the second round of investments.</p>'
    +'<p><b>3rd round: 67% - 100%</b></p>'
    +'<p>Again taking the minimum investment threshold as 100%, from 67% - 100% of investments made will be classed as the third round of investments.</p><br>'
  })

const mapDispatchToProps = dispatch => {
  const onFailureKpis = () => {
    showPopupErrorSnackbar(i18n.t('ERROR'), dispatch)
  }

  return {
    onComponentUnmount: () => dispatch(resetIdeaState()),
    navigateToExplore: () => Navigation.navigateToExplore(),
    navigateToAllIdeas: () => Navigation.navigateToAllIdeas(),
    onValidatePendingIdeaClick: ideaId => {
      const onSuccess = () => {
        dispatch(getIdea(ideaId))
      }
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('IDEA_INNOVATION_TEAM.TEAM_JOIN_KO'), dispatch)
      }
      dispatch(postValidateIdea(ideaId, onSuccess, onFailure))
    },
    onRejectPendingIdeaClick: ideaId => {
      const onMainButtonClick = () => {
        const onSuccess = () => {
          showPopupSuccessSnackbar(i18n.t('EXPLORE_IDEA.IDEA_DELETED'), dispatch)
          Navigation.navigateToExplore()
        }
        const onFailure = () => {
          showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.ERROR_DELETING'), dispatch)
        }
        dispatch(deleteIdea(ideaId, onSuccess, onFailure))
        dispatch(hideDialog())
      }
      showPopupDialog(
        <ConfirmSubmitContainer
          onMainButtonClick={onMainButtonClick}
          title={i18n.t('CONFIRMATION_MESSAGE')}
          description={i18n.t('EXPLORE_IDEA.DELETE_IDEA')}
        />,
        dispatch,
      )
    },
    showAcceptInvitationSnackbar: () => {
      showPopupSuccessSnackbar(i18n.t('IDEA.ACTION.INVITE_TEAM_ACCEPT'), dispatch)
    },
    showRejectInvitationSnackbar: () => {
      showPopupErrorSnackbar(i18n.t('IDEA.ACTION.INVITE_TEAM_REJECT'), dispatch)
    },
    postAcceptJoinInnovationTeam: ideaId => {
      const onSuccess = () => {
        showPopupSuccessSnackbar(i18n.t('IDEA_INNOVATION_TEAM.TEAM_JOIN_OK'), dispatch)
      }
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('IDEA_INNOVATION_TEAM.TEAM_JOIN_KO'), dispatch)
      }
      dispatch(postAcceptJoinInnovationTeam(ideaId, onSuccess, onFailure))
    },
    postRejectJoinInnovationTeam: ideaId => {
      const onSuccess = () => {
        showPopupSuccessSnackbar(i18n.t('TEAM_REJECT_OK'), dispatch)
      }
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('IDEA_INNOVATION_TEAM.TEAM_JOIN_KO'), dispatch)
      }
      dispatch(postRejectJoinInnovationTeam(ideaId, onSuccess, onFailure))
    },
    postAcceptJoinIdeaTeam: ideaId => {
      const onSuccess = () => {
        showPopupSuccessSnackbar(i18n.t('IDEA_TEAM.TEAM_JOIN_OK'), dispatch)
      }
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('IDEA_TEAM.TEAM_JOIN_KO'), dispatch)
      }
      dispatch(postAcceptJoinIdeaTeam(ideaId, onSuccess, onFailure))
    },
    postRejectJoinIdeaTeam: ideaId => {
      const onSuccess = () => {
        showPopupSuccessSnackbar(i18n.t('TEAM_REJECT_OK'), dispatch)
      }
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('IDEA_TEAM.TEAM_JOIN_KO'), dispatch)
      }
      dispatch(postRejectJoinIdeaTeam(ideaId, onSuccess, onFailure))
    },
    getIsUserInvitedToInnovationIdeaTeam: ideaId => {
      dispatch(getIsUserInvitedToInnovationIdeaTeam(ideaId))
    },
    getIsUserInvitedToIdeaTeam: ideaId => {
      dispatch(getIsUserInvitedToIdeaTeam(ideaId))
    },
    getIdeaTeam: ideaId => {
      dispatch(getIdeaTeam(ideaId))
    },
    onSendInvitationClick: (email, ideaId) => {
      const onSuccess = () => {
        dispatch(getIdeaTeam(ideaId))
        showPopupSuccessSnackbar(i18n.t('IDEA.INVITE_SUCCESS_SEND_EMAIL'), dispatch)
      }
      const onFailure = () =>
        showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.ERROR_INVESTING'), dispatch)

      dispatch(postSendInvitations(email, ideaId, onSuccess, onFailure))
    },
    onSearchSuggestions: username => {
      dispatch(getSponsorSuggestions(username))
    },
    //onAskInvestmentClick: id => {
      //showPopupDialog(<AskInvestmentDialog ideaId={id} />, dispatch)
    //},
    investInIdea: (id, investmentAmount) => {
      const onSuccess = () => {
        dispatch(getIdeaInvestors(id))
        showPopupSuccessSnackbar(i18n.t('EXPLORE_IDEA.INVEST_RECEIVED'), dispatch)
      }
      const onFailure = () =>
        showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.ERROR_INVESTING'), dispatch)
      dispatch(postInvestIdea(id, investmentAmount, onSuccess, onFailure))
    },
    publishIdea: (id, investmentAmount) => {
      const onSuccess = () => {
        dispatch(getIdea(id))
      }
      const onFailure = () => showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.ERROR_PUBLISH'), dispatch)
      dispatch(postPublishDraft(id, investmentAmount, onSuccess, onFailure))
    },
    showNoImageDraftSnackbar: () => {
      showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.IMAGE_MANDATORY'), dispatch)
    },
    getIdea: id => {
      dispatch(getIdea(id))
    },
    navigateToChallengeDetail: challengeId => {
      Navigation.navigateToChallengeDetail(challengeId)
    },
    navigateToUserProfile: userEmail => {
      Navigation.navigateToUserProfile(userEmail)
    },
    onReportClick: id => {
      const onMainButtonClick = () => {
        const onSuccess = () => {
          showPopupDialog(<SuccessSubmitModal />, dispatch)
        }
        dispatch(reportIdea(id, onSuccess))
        dispatch(hideDialog())
      }
      showPopupDialog(
        <ConfirmSubmitContainer
          onMainButtonClick={onMainButtonClick}
          title={i18n.t('EXPLORE_IDEA.REPORT_IDEA')}
          description={i18n.t('EXPLORE_IDEA.REPORT_IDEA_TEXT')}
        />,
        dispatch,
      )
    },
    getContributions: id => dispatch(getIdeaContributions(id)),
    setContributionTime: debounce(value => dispatch(setContributionTime(value)), 300),
    setContributionBudget: debounce(value => dispatch(setContributionBudget(value)), 300),
    setContributionOther: debounce(value => dispatch(setContributionOther(value)), 100),
    onSaveContribution: (ideaId, contribId) => dispatch(saveContribution(ideaId, contribId)),
    onDeleteContribution: ideaId => dispatch(deleteContribution(ideaId)),
    getInvestors: id => dispatch(getIdeaInvestors(id)),
    onSummaryStepperHelpClick: () => Navigation.navigateToExternal('https://help.nextinit.com'),
    onPendingUserRemoveClick: (ideaId, email, isInitiative) => {
      const onMainButtonClick = () => {
        const onSuccess = () => {
          dispatch(getIdeaTeam(ideaId))
          showPopupSuccessSnackbar(i18n.t('IDEA.TEAM_DELETE_OK'), dispatch)
        }
        const onFailure = () => {
          showPopupErrorSnackbar(i18n.t('IDEA.TEAM_DELETE_FAIL'), dispatch)
        }
        dispatch(deleteUserFromTeam(ideaId, email, isInitiative, onSuccess, onFailure))
        dispatch(hideDialog())
      }
      showPopupDialog(
        <ConfirmSubmitContainer
          onMainButtonClick={onMainButtonClick}
          title={i18n.t('CONFIRMATION_MESSAGE')}
          description={i18n.t('IDEA.TEAM_DELETE_CONFIRM')}
        />,
        dispatch,
      )
    },
    onDocumentUpload: (id, file) => {
      const onSuccess = () => {
        showPopupSuccessSnackbar(i18n.t('EXPLORE_IDEA.UPLOAD_DOC_COMPLETE'), dispatch)
        dispatch(hideDialog())
      }
      const onFailure = error => {
        if (error.status === 413) {
          showPopupErrorSnackbar(
            i18n.t('EXPLORE_IDEA.UPLOAD_MAX_SIZE_DOC_ERROR', { max_size: MAX_SIZE_DOCUMENT }),
            dispatch,
          )
        } else {
          showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.UPLOAD_DOC_ERROR'), dispatch)
        }
        dispatch(hideDialog())
      }
      getBase64Split(
        file,
        (fileName, base64File) => {
          dispatch(postIdeaDocument(id, fileName, base64File, onSuccess, onFailure))
        },
        true,
      )
    },
    onDocumentDelete: (id, documentId) => {
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.DELETE_KO'), dispatch)
      }
      dispatch(deleteIdeaDocument(id, documentId, onFailure))
    },
    onPostComment: (value, parentId, ideaId) => {
      const onSuccess = () => {
        dispatch(getIdeaComments(ideaId))
      }
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.ERROR_COMMENT'), dispatch)
      }
      dispatch(postComment(value, parentId, ideaId, onSuccess, onFailure))
    },
    getComments: ideaId => dispatch(getIdeaComments(ideaId)),
    onDeleteComment: (commentId, ideaId) => {
      const onMainButtonClick = () => {
        const onSuccess = () => {
          showPopupSuccessSnackbar(i18n.t('COMMENT.DELETE_MSG_SUCCESS'), dispatch)
          dispatch(getIdeaComments(ideaId))
        }
        const onFailure = () => {
          showPopupErrorSnackbar(i18n.t('COMMENT.DELETE_MSG_ERROR'), dispatch)
        }
        dispatch(deleteComment(commentId, ideaId, onSuccess, onFailure))
        dispatch(hideDialog())
      }
      showPopupDialog(
        <ConfirmSubmitContainer
          onMainButtonClick={onMainButtonClick}
          title={i18n.t('CONFIRMATION_MESSAGE')}
          description={i18n.t('COMMENT.DELETE_MSG')}
        />,
        dispatch,
      )
    },
    onVoteComment: (commentId, ideaId) => {
      const onSuccess = () => {
        dispatch(getIdeaComments(ideaId))
      }
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('BACKEND_ERRORS.UNEXPECTED'), dispatch)
      }
      dispatch(voteComment(commentId, ideaId, onSuccess, onFailure))
    },
    onEditComment: (comment, commentId, ideaId) => {
      const onSuccess = () => {
        dispatch(getIdeaComments(ideaId))
      }
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('BACKEND_ERRORS.UNEXPECTED'), dispatch)
      }
      dispatch(putComment(comment, commentId, ideaId, onSuccess, onFailure))
    },
    onDiscardClick: (id, rejectedReasons) => {
      showPopupDialog(<DiscardView id={id} rejectedReasons={rejectedReasons} />, dispatch)
    },
    onFeedbackClick: (id, feedbackMessage) => {
      showPopupDialog(<FeedbackView id={id} feedbackMessage={feedbackMessage} />, dispatch)
    },
    onApproveClick: (id, approvedReasons) => {
      showPopupDialog(<ApproveView id={id} approvedReasons={approvedReasons} />, dispatch)
    },
    onDeleteClick: id => {
      showPopupDialog(<ConfirmDeleteIdeaModal id={id} />, dispatch)
    },
    onEditClick: id => {
      dispatch(Navigation.navigateToEditIdeaNew(id))
    },
    getRejectedReasons: () => {
      dispatch(getRejectedReasons())
    },
    getApprovedReasons: () => {
      dispatch(getApprovedReasons())
    },
    onShareIdea: id => {
      showPopupDialog(<SendInvitationsContainer ideaId={id} />, dispatch)
    },
    onRequestJoinIdeaTeam: (id, request) => {
      dispatch(requestJoinIdeaTeam(id, request))
    },
    onActivatePilot: ideaId => {
      const onMainButtonClick = () => {
        const onSuccess = () => {
          showPopupSuccessSnackbar(i18n.t('EXPLORE_IDEA.ACTIVATE_SUCCESS'), dispatch)
          dispatch(getIdea(ideaId))
        }
        const onFailure = () => {
          showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.ACTIVATE_ERROR'), dispatch)
        }
        dispatch(postActivatePilotProject(ideaId, onSuccess, onFailure))
        dispatch(hideDialog())
      }
      showPopupDialog(
        <ConfirmSubmitContainer
          onMainButtonClick={onMainButtonClick}
          title={i18n.t('CONFIRMATION_MESSAGE')}
          description={i18n.t('EXPLORE_IDEA.ACTIVATE_MESSAGE')}
        />,
        dispatch,
      )
    },
    onImplementPilot: ideaId => {
      const onMainButtonClick = () => {
        const onSuccess = () => {
          showPopupSuccessSnackbar(i18n.t('EXPLORE_IDEA.IMPLEMENT_SUCCESS'), dispatch)
          dispatch(getIdea(ideaId))
        }
        const onFailure = () => {
          showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.IMPLEMENT_ERROR'), dispatch)
        }
        dispatch(postImplementPilotProject(ideaId, onSuccess, onFailure))
        dispatch(hideDialog())
      }
      showPopupDialog(
        <ConfirmSubmitContainer
          onMainButtonClick={onMainButtonClick}
          title={i18n.t('CONFIRMATION_MESSAGE')}
          description={i18n.t('EXPLORE_IDEA.LOADING_MESSAGE')}
        />,
        dispatch,
      )
    },
    onTransferLeader: (ideaId, email) => {
      const onSuccess = () => {
        dispatch(getIdea(ideaId))
      }
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.TRANSFER_ERROR'), dispatch)
      }
      dispatch(postTransferPromoter(ideaId, email, onSuccess, onFailure))
    },
    onResetSuggestionList: () => dispatch(resetSponsorSuggestions()),
    getIsTeamRequestActive: id => dispatch(getIsTeamRequestActive(id)),
    onUpdateKpiAction: ({ id, type, unit, range, expected, current, name, success, ideaId }) => {
      const onSuccess = () => dispatch(getKpis(ideaId))
      dispatch(
        updateKpi(
          { id, type, unit, range, expected, current, name, success, ideaId },
          onSuccess,
          onFailureKpis,
        ),
      )
    },
    onUpdateKpiSuccess: ({ id, success, ideaId }) => {
      const onSuccess = () => dispatch(getKpis(ideaId))
      dispatch(updateKpiSuccess({ id, success, ideaId }, onSuccess, onFailureKpis))
    },
    onDeleteKpi: ({ id, ideaId }) => {
      showPopupDialog(<ConfirmDeleteKpiModal id={id} ideaId={ideaId} />, dispatch)
    },
    onSubmitKpiForm: ({ type, unit, range, expected, current, name, ideaId }) => {
      const onSuccess = () => dispatch(getKpis(ideaId))
      dispatch(
        newKpi({ type, unit, range, expected, current, name, ideaId }, onSuccess, onFailureKpis),
      )
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
