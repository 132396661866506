const styles = theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1, 1),
    '&:after': {
      content: '""',
      display: 'block',
      height: '10px',
      width: '10px',
      borderRadius: '100%',
      backgroundColor: props =>
        props.isRead ? theme.palette.common.white : theme.palette.primary.main,
      border: '1px solid',
      borderColor: theme.palette.primary.main,
    },
  },
  image: {
    width: '40px',
    height: '40px',
    objectFit: 'cover',
    borderRadius: '100%',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    borderRadius: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingLeft: theme.spacing(1),
  },
  timeAgo: {
    fontSize: '10px',
    color: '#bbb',
    textTransform: 'capitalize',
    paddingTop: theme.spacing(1),
  },
  description: {
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'VodafoneRg-Regular',
    fontSize: '0.825rem',
    // fontSize: '14px',
    fontWeight: props => (props.isRead ? 'normal' : 'bold'),
    '& > span > a': {
      color: theme.palette.primary.main,
    },
  },
})
export default styles
