const styles = theme => ({
  videoFormContainer: {
    //display: 'flex',
    //flexDirection: 'column',
    flex:1,
    width:'100%'
  },
  mainButtons: {
    display: 'flex',
    width: 700,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    paddingTop: theme.spacing(2),
    '& > button': {
      width: '30%',
      height: 36,
      marginRight: theme.spacing(2),
    },
  },
  buttonsWrapper: {
    marginTop: theme.spacing(2),
    '& > button': {
      marginRight: theme.spacing(2),
    },
  },
  video: {
    margin: theme.spacing(3, 0),
    maxWidth: 560,
    maxHeight: 400,
  },
  spinner: {
    marginRight: theme.spacing(1.5),
  },
  add_image: {
    width: '280px',
    height: '120px',
    border: '1px dashed #666666',
    borderRadius: '10px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '@media(max-width:767px)':{
      width:'100%',
      marginTop:'16px'
    }

  },
  upload_icon: {
    width: '62px',
    height: '62px',
    padding: '2px',
  },
  upload_media_help_text_1: {
    fontFamily: 'VodafoneRg-Bold',
    color: '#6551FF',
    fontSize: '14px',
    padding: '2px',
  },
  dragndrop_text:{
    fontFamily: 'VodafoneRg-Regular',
    color: '#333333',
    fontSize: '16px',
    paddingTop: '6px',
  },
  format_support_text: {
    fontFamily: 'VodafoneRg-Regular',
    color: '#999999',
    fontSize: '14px',
    paddingTop: '6px',
    paddingBottom: '15px',
  },
  or: {
    margin: '20px 0 20px 0',
    fontFamily: 'VodafoneRg-Regular',
    color: '#333333',
    fontSize: '18px',
    textAlign:'center',
  },
  external_video_upload_img:{
    // position: 'absolute',
    right: -42,
    top: 10,
    height: '34px',
    width: '34px',
    cursor: 'pointer',
    marginLeft:'8px'
  },
  remove_ext_video: {
    position: 'absolute',
    top: -10,
    right: -10,
    height: '30px',
    width: '30px',
    cursor: 'pointer',
  },
  videobox:{
    position:'relative',
    alignItems:'center',
    '& .MuiInputLabel-outlined':{
      fontSize:'11px'
    }
  }
})

export default styles
