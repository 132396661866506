import React, { useState } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import * as PropTypes from 'prop-types'
// import { Footer, Header } from 'components'
import FooterNew from 'components_newui/footerNewUi/View'
import { Navbar } from 'components_newui'
import * as pages from 'common_constants/Pages'
// import UIToggle from './components/ui_toggle/View'
import { ClickAwayListener } from '@material-ui/core';
import EvaluationExpertCommentPage from 'pagesNewUi/evaluation_board/components/expert_comments'

import {
  AllIdeas,
  MyIdeas,
  PostIdea,
  // PostIdeaold,
  ChallengeDetails,
  InvestedIdeas,
  PostChallenge,
  ProposeChallenge,
  NewExplore,
  NewIdeaDetails,
  NewAllChallenges,
  NewMyChallenges,
  Newdraftchallenges,
  PendingValidation,
  // Profile,
  Investments,
  Preferences,
  Balance,
  // Ranking,
  SearchPage,
  ContactUs,
  CaseStudy,
  CaseStudyDetail,

  Error,
  HowItWorks,
  LegalAcceptance,
  PrivacyCookies,
  Profile,
  Ranking,
  Dashboard,
  AboutUs,
  EvaluationBoard,
  NovaResearch,

} from 'pagesNewUi'

// import IdeaForm from 'pages/idea/form'
// import IdeaDetail from 'pages/idea/detail'
import SearchPopup from './components/search/View'
import NavigationService from '../../NavigationService'

const LoggedInNavigator = props => {
  // console.log("login--->",props.isLoading)
  const {
    isLoading,
    whiteListDomainService,
    commonUserData,
    // appVersion,
    classes,
    copyrightYear,
    newIdeas,
    loggedIn,
    logoUrl,
    navigateToExplore,
    navigateToMyIdeas,
    navigateToMyChallenge,
    navigateToInvestments,
    navigateToProposeChallenge,
    navigateToChallenges,
    navigateToAboutus,
    navigateToNovaResearch,
    navigateToNovaContact,
    navigateToCaseStudy,
    notifications,
    showAdmin,
    submitYourIdea,
    userBalance,
    userName,
    userPhoto,
    userLoginEmail,
    totalInvestments,
    customFooterText,
    markNotificationsRead,
    onLogoClick,
    onCopyrightClick,
    currencyIcon,
    domains,
    domain,
    navigateToDomain,
    isPrivateFlowActive,
    navigateToNewChallenge,
    navigateToAllIdeas,
    navigateToSearchPage,
    applySearch,
    // changeUI,
    // new_ui,
    navigateToMyInvestedIdeas,
    isAdmin,isOwner,localAdmin,
    // localInnovator
  } = props

  const ROUTES = NavigationService.getRoutes()
  let explorePath = ROUTES[pages.EXPLORE_PAGE].path;

  if(window.location.href.includes('utm_source')){
    explorePath += `?${  window.location.href.split('?').pop()}`;
    // explorePath += '?' + window.location.href.split('?').pop();
  }
  const [showSearch, setShowSearch] = useState(false)
  
  return (
    <div className={classes.base}>
      {
        (showSearch) && 
        <>
        <div style={{
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            position: 'fixed',
            opacity: '60%',
            backgroundColor: '#333333',
            zIndex: 35,
        }}/> 
        <ClickAwayListener onClickAway={()=>{
          setShowSearch(false);
          }}>
            <div>
              <SearchPopup 
                search ={query=>{
                  applySearch(query)
                  navigateToSearchPage()
                  setShowSearch(false)
                }}
                close={()=>setShowSearch(false)} 
                popular={['Blockchain', 'AR', 'MEC', 'Machine Learning', 'NLP']}
              />
            </div>
        </ClickAwayListener>
        
        </>
      }
       <Navbar
       logoUrl={logoUrl}
        onLogoClick={onLogoClick}
        navigateToExplore={navigateToExplore}
        navigateToAllIdeas={navigateToAllIdeas}
        navigateToMyIdeas={navigateToMyIdeas}
        navigateToMyChallenge={navigateToMyChallenge}
        navigateToInvestments={navigateToInvestments}
        navigateToAboutus={navigateToAboutus}
        navigateToNovaResearch={navigateToNovaResearch}
        navigateToNovaContact={navigateToNovaContact}
        navigateToChallenges={navigateToChallenges}
        navigateToNewChallenge={navigateToNewChallenge}
        navigateToProposeChallenge={navigateToProposeChallenge}
        notifications={notifications}
        showAdmin={showAdmin}
        userBalance={userBalance}
        userName={userName}
        userLoginEmail={userLoginEmail}
        userPhoto={userPhoto}
        currencyIcon={currencyIcon}
        domains={domains}
        currentDomain={domain}
        navigateToDomain={navigateToDomain}
        markNotificationsRead={markNotificationsRead}
        submitYourIdea={submitYourIdea}
        onSearchClick={()=>setShowSearch(true)}
        commonUserData={commonUserData}
        navigateToMyInvestedIdeas={navigateToMyInvestedIdeas}
        navigateToCaseStudy={navigateToCaseStudy}
        whiteListDomainService={whiteListDomainService}
        whiteListDomainLoading = {isLoading}
      />
      
      <div className={classes.main}>
        <Switch>
          {
          // <Route exact path="/" render={() => <Redirect to={ROUTES[pages.EXPLORE_PAGE].path} />} />
          }
          <Route exact path="/" render={() => <Redirect to={explorePath} />} />
          <Route
            exact
            path={ROUTES[pages.ROOT_LOGGED_PAGE].path}
            render={() => <Redirect to={ROUTES[pages.EXPLORE_PAGE].path} />}
          />
          <Route
            exact
            path={ROUTES[pages.DASHBOARD].path}
            render={componentProps => <Dashboard {...componentProps} />}
          />
          <Route
            exact
            path={`${ROUTES[pages.DETAIL_CHALLENGE].path}`}
            render={componentProps => (   <ChallengeDetails {...componentProps} />  )}
          />
          <Route
            exact
            path={ROUTES[pages.PROPOSE_CHALLENGE].path}
            render={componentProps => (
            <ProposeChallenge {...componentProps} /> )}
          />
          <Route
            exact
            path={ROUTES[pages.NEW_CHALLENGE].path}
            render={componentProps => (
              <PostChallenge {...componentProps} />)}
          />
          <Route
            exact
            path={ROUTES[pages.EDIT_CHALLENGE].path}
            render={componentProps => (
              <PostChallenge {...componentProps} />)}
            // render={componentProps => <ChallengeForm {...componentProps} />}
          />
          <Route
            exact
            path={ROUTES[pages.MY_CHALLENGES_PAGE].path}
            render={componentProps => (
            <NewMyChallenges  {...componentProps} /> )}
          />
          <Route
            exact
            path={ROUTES[pages.CHALLENGES_PAGE].path}
            render={componentProps => (
            <NewAllChallenges {...componentProps} /> )}
          />
          <Route
            exact
            path={ROUTES[pages.DRAFT_CHALLENGES].path}
            render = {    componentProps => ( <Newdraftchallenges {...componentProps} /> )   }
          />
          <Route
            exact
            path={ROUTES[pages.EXPLORE_PAGE].path}
            render={componentProps => (
             <NewExplore {...componentProps} /> 
          )}
          />
          <Route
            exact
            path={ROUTES[pages.ALL_IDEAS].path}
            render={componentProps => (   <AllIdeas {...componentProps} />   ) }
            
          />
          <Route
            exact
            path={ROUTES[pages.HOW_IT_WORKS].path}
            render={componentProps => <HowItWorks {...componentProps} />}
          />
          {/* <Route // commented before code for new idea
            exact
            path={ROUTES[pages.NEW_IDEA].path}
            render={componentProps => <IdeaForm key="new" {...componentProps} />}
          /> */}
           <Route
            exact
            path={ROUTES[pages.NEW_IDEA].path}
            render={componentProps => (    <PostIdea key="new" {...componentProps} />   )}
          />
          {/* {isPrivateFlowActive && (
            <Route
              exact
              path={ROUTES[pages.NEW_PRIVATE_IDEA].path}
              render={componentProps => <IdeaForm isPrivate key="new" {...componentProps} />}
            />
          )} */}
          <Route
            exact
            path={ROUTES[pages.NEW_IDEA_CHALLENGE_SELECTED].path}
            render={componentProps => <PostIdea key="newChallenge" {...componentProps} />}
          />
          <Route
            exact
            path={ROUTES[pages.EDIT_IDEA].path}
            // render={componentProps => <IdeaForm key="edit" {...componentProps} />}
            render={componentProps => (   <PostIdea key="edit" {...componentProps} />     )}
          />
          <Route
            exact
            path={ `${ROUTES[pages.DETAIL_IDEA].path}` }
            render={componentProps => (  <NewIdeaDetails {...componentProps} />  )}
          />
          <Route
            exact
            path={ROUTES[pages.INVESTMENTS_PAGE].path}
            render={componentProps => (  <Investments {...componentProps} /> )   }
          />
           <Route
            exact
            path={ROUTES[pages.MY_INVESTED_IDEAS].path}
            render={componentProps => (   <InvestedIdeas {...componentProps} /> ) }
          />
          <Route 
            exact
            path={ROUTES[pages.INVESTMENT_DETAILS_PAGE].path}
            render={componentProps => <Investments {...componentProps} />}
          />
          <Route 
            exact
            path={ROUTES[pages.BALANCE_DETAILS_PAGE].path}
            render={componentProps => <Balance {...componentProps} />}
          />
          <Route 
            exact
            path={ROUTES[pages.SEARCH_PAGE].path}
            render={componentProps => <SearchPage {...componentProps} />}
          />
          <Route
            exact
            path={ROUTES[pages.LEGAL_ACCEPTANCE_PAGE].path}
            render={componentProps => <LegalAcceptance {...componentProps} />}
          />
          <Route
            exact
            path={ROUTES[pages.MY_IDEAS_PAGE].path}
            render={componentProps => (  <MyIdeas {...componentProps} />  )}
          />
          <Route
            exact
            path={ROUTES[pages.PENDING_VALIDATION_PAGE].path}
            render={componentProps => <PendingValidation {...componentProps} />}
          />
          <Route
            exact
            path={ROUTES[pages.EVALUATION_BOARD_PAGE].path}
            render={componentProps => <EvaluationBoard {...componentProps} />}
          />
          <Route
            exact
            path={ROUTES[pages.EVALUATION_IDEA_DETAIL].path}
            render={componentProps => <EvaluationBoard showIdea {...componentProps} />}
          />
          <Route
            exact
            path={ROUTES[pages.EVALUATION_EXPERT_COMMENTS].path}
            render={componentProps => <EvaluationExpertCommentPage {...componentProps} />}
          />
          <Route
            exact
            path={ROUTES[pages.PRIVACY_COOKIES_PAGE].path}
            render={componentProps => <PrivacyCookies {...componentProps} />}
          />
          {/* <Route
            exact
            path={ROUTES[pages.PRIVATE_IDEAS_PAGE].path}
            render={componentProps => <PrivateIdeas {...componentProps} />}
          /> */}
          <Route
            exact
            path={ROUTES[pages.PROFILE_PAGE].path}
            render={componentProps => (  <Profile {...componentProps} />  )}
          />
          <Route
            exact
            path={ROUTES[pages.PROFILE_PAGE_USER].path}
            render={componentProps => (   <Profile {...componentProps} />  )}  
          />
          <Route
            exact
            path={ROUTES[pages.RANKING_PAGE].path}
            render={componentProps => (
              <Ranking {...componentProps} />)}
          />
          <Route
            exact
            path={ROUTES[pages.USER_PREFERENCES_PAGE].path}
            render={componentProps => (
            <Preferences {...componentProps} /> )}
          />
          <Route 
            exact
            path={ROUTES[pages.CONTACTUS].path}
            render={componentProps =>(  <ContactUs {...componentProps} />  )}
          /> 
          <Route 
            exact
            path={ROUTES[pages.CASESTUDY].path}
            render={componentProps =>(  <CaseStudy {...componentProps} /> )}
          />     
           <Route 
            exact
            path={`${ROUTES[pages.DETAIL_CASESTUDY].path}/:title`}
            render={componentProps =>(  <CaseStudyDetail {...componentProps} /> )}
          />          
          <Route
            exact
            path={ROUTES[pages.LOGIN_PAGE].path}
            render={() => <Redirect to={ROUTES[pages.EXPLORE_PAGE].path} />}
          />
          <Route
            exact
            path={ROUTES[pages.AZURE_LOGIN].path}
            render={() => <Redirect to={ROUTES[pages.EXPLORE_PAGE].path} />}
          />
          <Route
            exact
            path={ROUTES[pages.ADFS_LOGIN].path}
            render={() => <Redirect to={ROUTES[pages.EXPLORE_PAGE].path} />}
          />
          <Route
            exact
            path={ROUTES[pages.IDENTITY_CHOOSE_PAGE].path}
            render={() => <Redirect to={ROUTES[pages.EXPLORE_PAGE].path} />}
          />
          <Route
            exact
            path={ROUTES[pages.CUSTOM_LOGIN_PAGE].path}
            render={() => <Redirect to={ROUTES[pages.EXPLORE_PAGE].path} />}
          />
          <Route
            exact
            path={ROUTES[pages.ABOUT_US].path}
            render={componentProps => <AboutUs {...componentProps} />}
          />
          <Route
            exact
            path={ROUTES[pages.NOVA_RESEARCH].path}
            render={componentProps => <NovaResearch {...componentProps} />}
          />
          <Route
            render={() => (loggedIn ? <Error /> : <Redirect to={ROUTES[pages.LOGIN_PAGE].path} />)}
          />
        </Switch>
      </div>
      {/* <Footer
        appVersion={appVersion}
        copyrightYear={copyrightYear}
        logoUrl={logoUrl}
        newIdeas={newIdeas}
        showAdmin={showAdmin}
        // submitYourIdea={submitYourIdea}
        totalInvestments={totalInvestments}
        customFooterText={customFooterText}
        onLogoClick={onLogoClick}
        onCopyrightClick={onCopyrightClick}
        isPrivate={isPrivateFlowActive}
      /> */}
      <FooterNew
      copyrightYear={copyrightYear}
        newIdeas={newIdeas}
        customFooterText={customFooterText}
        onCopyrightClick={onCopyrightClick}
        totalInvestments={totalInvestments}
        navigateToAllIdeas={navigateToAllIdeas}
        navigateToMyIdeas={navigateToMyIdeas}
        navigateToInvestments={navigateToInvestments}
        navigateToNewChallenge={navigateToNewChallenge}
        navigateToChallenges={navigateToChallenges}
        navigateToAboutus={navigateToAboutus}
        navigateToNovaResearch={navigateToNovaResearch}
        navigateToNovaContact={navigateToNovaContact} 
        navigateToMyInvestedIdeas={navigateToMyInvestedIdeas}
        navigateToProposeChallenge={navigateToProposeChallenge}
        isAdmin={isAdmin}
        localAdmin={localAdmin}
        isOwner={isOwner}
        logoUrl={logoUrl}
        />
        
    </div>
  )
}

LoggedInNavigator.defaultProps = {
  customFooterText: '',
  logoUrl: '',
  userPhoto: '',
}

LoggedInNavigator.propTypes = {
  classes: PropTypes.shape({
    base: PropTypes.string.isRequired,
  }).isRequired,
  logoUrl: PropTypes.string,
  notifications: PropTypes.shape({
    notifications: PropTypes.arrayOf(PropTypes.shape({})),
    activities: PropTypes.arrayOf(PropTypes.shape({})),
    totalUnread: PropTypes.number,
    unreadActivities: PropTypes.number,
    unreadNotifications: PropTypes.number,
  }).isRequired,
  userBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  userName: PropTypes.string.isRequired,
  userPhoto: PropTypes.string,
  customFooterText: PropTypes.string,
  domains: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  navigateToDomain: PropTypes.func.isRequired,
  markNotificationsRead: PropTypes.func.isRequired,
  onCopyrightClick: PropTypes.func.isRequired,
  onLogoClick: PropTypes.func.isRequired,
}

export default LoggedInNavigator
