
import React from "react";
import TableBody from "./Tablebody";
import TableHead from "./TableHead";
import { useSortTable } from "./useSortTable";

const Table = ({ caption, data, columns }) => {
  const [tableData, handleSorting] = useSortTable(data, columns);

  // console.log("data table",data,tableData)
  return (
      <table >
        <TableHead {...{ columns, handleSorting }} />
        <TableBody {...{ columns, tableData }} />
      </table>
   
  );
};

export default Table;