/* eslint-disable jam3/no-sanitizer-with-danger */

import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import {
  TextField,
  withStyles,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  RadioGroup,
} from '@material-ui/core'
import NxtRadio from 'components/nxt_radio/View'
import i18n from 'support/i18n'
import styles from './View.Style'

class ApproveView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      approveReason: '',
      checkedValue: '',
      returnInvestment: false,
    }
    this.handleTextFieldChange = this.handleTextFieldChange.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
    this.handleOnCLickOnCheck = this.handleOnCLickOnCheck.bind(this)
  }

  handleTextFieldChange(event) {
    const { value } = event.target
    this.setState({ approveReason: value })
  }

  handleOnClick(approveReason, returnInvestment) {
    const { id, onClick } = this.props
    onClick(id, approveReason, returnInvestment)
  }

  handleOnCLickOnCheck(event) {
    const { value } = event.target
    const { checkedValue } = this.state

    if (checkedValue === value) {
      this.setState({ approveReason: '', checkedValue: '' })
    } else {
      this.setState({ approveReason: value, checkedValue: value })
    }
  }

  render() {
    const { classes, cancelButtonAction, approvedReasons } = this.props
    const { approveReason, checkedValue, returnInvestment } = this.state
    return (
      <div className={classes.dialogBox}>
        <DialogTitle id="alert-dialog-title">{i18n.t('CONFIRMATION_MESSAGE')}</DialogTitle>
        <DialogContent>
          <span dangerouslySetInnerHTML={{ __html: i18n.t('EXPLORE_IDEA.APPROVE_MSG') }} />
          <div>
            <RadioGroup value={checkedValue}>
              {approvedReasons.data.length > 0 &&
                approvedReasons.data.map((reason) => {
                  return (
                    <FormControlLabel
                      key={reason.title}
                      control={<NxtRadio />}
                      onClick={this.handleOnCLickOnCheck}
                      label={
                        <div>
                          <p>{reason.title}</p>
                          <small>{reason.text}</small>
                        </div>
                      }
                      value={`${reason.title}\r${reason.text}`}
                    />
                  )
                })}
            </RadioGroup>
          </div>
          <TextField
            color="primary"
            id="filled-multiline-static"
            fullWidth
            label={i18n.t('IDEA.MESSAGE')}
            multiline
            onChange={this.handleTextFieldChange}
            rows="4"
            margin="normal"
            value={approveReason}
            variant="filled"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.handleOnClick(approveReason, returnInvestment)}
          >
            {i18n.t('ACCEPT')}
          </Button>
          <Button variant="contained" color="secondary" onClick={cancelButtonAction}>
            {i18n.t('CANCEL')}
          </Button>
        </DialogActions>
      </div>
    )
  }
}

ApproveView.defaultProps = {
  approvedReasons: {},
}

ApproveView.propTypes = {
  id: PropTypes.string.isRequired,
  approvedReasons: PropTypes.shape({}),
  onClick: PropTypes.func.isRequired,
  cancelButtonAction: PropTypes.func.isRequired,
}

export default withStyles(styles)(ApproveView)
