const styles = theme => ({
    background:{
        backgroundColor: "#fbfaff", //body should have this background color in the main .css for the new site design 
        // padding: "32px 85px 32px 85px"
        paddingTop:'32px',
        paddingBottom:'32px'
    },
    top_section_text: {
        display: "flex",
        alignItems: "center", 
        justifyContent: "space-between", 
        marginBottom:theme.spacing(2)
    },
    title: {
        fontFamily: 'VodafoneRg-Regular',
        fontSize: "32px",
        color: "#333333",
    },
    investedIdeasNumber: {
        fontFamily:  theme.fontFamily.fontFamilyRegular,
        fontSize:  theme.fontSize.fontsize16,
        color: theme.palette.BlueText.TextLightBlue,
        cursor: 'pointer',
        paddingTop: "16px",
        background:'transparent',
        border:'0',
        '@media (min-width:600px)': { 
            fontSize: theme.fontSize.fontsize20,
        },
    },
    no_idea_img: {
        height: '210px',
        width: '288px',
        marginBottom: '20px',
        marginTop:'24px'
    },
    no_idea_title: {
        textAlign: 'center',
        fontSize: '32px',
        color: '#333333',
        fontFamily: 'VodafoneRg-Regular'
    },
    IdeaGrid: {
        display: "grid",
        marginTop: "32px",
        marginBottom: "60px",
        gridTemplateColumns: "repeat(2,minmax(128px,1fr))",
        gridGap: '8px',
        alignItems: "center",
        justifyItems: "center",
        '@media (min-width:480px)': { 
            gridTemplateColumns: "repeat(3,minmax(144px,1fr))",
        },
        '@media (min-width:1024px)': { 
            gridTemplateColumns: "repeat(4,minmax(238px,1fr))",
        },
    }
  })
  

export default styles
