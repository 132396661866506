import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  withStyles,
  InputLabel,
} from '@material-ui/core'
import i18n from 'support/i18n'
import NxtStockChart from 'components/nxt_stock_chart/View'
import styles from './View.Style'
import ComparisonTable from '../comparison_table/View'
import CustomTable from '../../../../components/custom_table/View'
import TableHeader from './components/table_header/View'
import TableBody from './components/table_body/View'
import { headerCells } from './utils/header/headerCells'

const ActivityIdeas = props => {
  const {
    classes,
    tableHeaders,
    onChangeDates,
    fromDate,
    toDate,
    isChartLoading,
    isTableLoading,
    series,
    tableData,
    tableDataBottom,
    tableDataBottomTotal,
    tableDataBottomIsLoading,
    navigateToIdea,
    organizations,
    groups,
    strategics,
    challenges,
    types,
    statusFilterOptions,
    rowsPerPage,
    page,
  } = props

  const [showFilters, setShowFilters] = useState(true)
  const [selectedStatus, setStatus] = useState('')
  const [selectedGroup, setGroup] = useState('')
  const [selectedStrategic, setStrategic] = useState('')
  const [selectedOrganisations, setOrganisations] = useState('')
  const [selectedChallenges, setChallenges] = useState('')
  const [selectedTypes, setTypes] = useState('')
  const [hasMount, setHasMount] = useState(false)

  useEffect(() => {
    const { onPageChange } = props
    if (onPageChange && hasMount) {
      onPageChange(0, rowsPerPage, {
        status: selectedStatus,
        group: selectedGroup,
        goal: selectedStrategic,
        organizations: selectedOrganisations,
        challenge: selectedChallenges,
        types: selectedTypes,
      })
    }
  }, [
    selectedStatus,
    selectedGroup,
    selectedStrategic,
    selectedOrganisations,
    selectedChallenges,
    selectedTypes,
  ])

  useEffect(() => {
    if (!hasMount) setHasMount(true)
  }, [])

  const handlePageChange = (eventPage, eventRowsPerPage) => {
    const { onPageChange } = props
    const filter = {
      status: selectedStatus,
      group: selectedGroup,
      goal: selectedStrategic,
      organizations: selectedOrganisations,
      challenges: selectedChallenges,
      types: selectedTypes,
    }
    onPageChange(eventPage, eventRowsPerPage, filter)
  }

  const handleOpenFilter = () => setShowFilters(!showFilters)

  return (
    <div className={classes.root}>
      <NxtStockChart
        title={i18n.t('DASHBOARD.IDEAS_SERIES')}
        onChangeDates={onChangeDates}
        fromDate={fromDate}
        toDate={toDate}
        isLoading={isChartLoading}
        series={series}
      />
      <ComparisonTable
        header={tableHeaders}
        dataRows={tableData.dataRows}
        comparisonRow={tableData.comparisonRow}
        isLoading={isTableLoading}
      />
      <div className={classes.bottomTableTitle}>
        <h3>{i18n.t('IDEAS')}</h3>
        <div className={classes.viewAsContainer}>
          <span>
            {i18n.t('IDEAS_WALL.SHOWING')} {tableDataBottomTotal} {i18n.t('IDEAS_WALL.IDEAS')}
          </span>
        </div>
      </div>
      <div className={classes.userTableContainer}>
        {showFilters && (
          <div className={classes.filterPanel}>
            <h3>{i18n.t('IDEAS_WALL.FILTER_BY')}:</h3>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel shrink>{i18n.t('IDEAS_WALL.STATUS')}</InputLabel>
              <Select
                value={selectedStatus}
                onChange={event => setStatus(event.target.value)}
                displayEmpty
                className={classes.selectEmpty}
              >
                <MenuItem value="">{i18n.t('ALL')}</MenuItem>
                {statusFilterOptions &&
                  statusFilterOptions.map(option => {
                    return (
                      <MenuItem key={option.value} value={option.value}>
                        {i18n.t(option.text)}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel shrink id="group-label">
                {i18n.t('IDEAS_WALL.GROUP')}
              </InputLabel>
              <Select
                className={classes.selectEmpty}
                value={selectedGroup}
                onChange={event => setGroup(event.target.value)}
                displayEmpty
              >
                <MenuItem value="">{i18n.t('ALL')}</MenuItem>
                {groups &&
                  groups.map(group => {
                    return (
                      <MenuItem key={group.id} value={group.id}>
                        {group.name}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>

            {challenges && (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel shrink id="challenge-label">
                  {i18n.t('IDEAS_WALL.CHALLENGE')}
                </InputLabel>
                <Select
                  className={classes.selectEmpty}
                  value={selectedChallenges}
                  onChange={event => setChallenges(event.target.value)}
                  displayEmpty
                >
                  <MenuItem value="">{i18n.t('ALL')}</MenuItem>
                  {challenges &&
                    challenges.map(challenge => {
                      return (
                        <MenuItem key={challenge.id} value={challenge.id}>
                          {challenge.name}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            )}

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel shrink id="strategic-label">
                {i18n.t('IDEAS_WALL.STRATEGIC_PRIORITY')}
              </InputLabel>
              <Select
                className={classes.selectEmpty}
                value={selectedStrategic}
                onChange={event => setStrategic(event.target.value)}
                displayEmpty
              >
                <MenuItem value="">{i18n.t('ALL')}</MenuItem>
                {strategics &&
                  strategics.map(strategic => {
                    return (
                      <MenuItem key={strategic.id} value={strategic.id}>
                        {strategic.name}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel shrink id="organizations-label">
                {i18n.t('IDEAS_WALL.ORGANIZATIONS')}
              </InputLabel>
              <Select
                className={classes.selectEmpty}
                value={selectedOrganisations}
                onChange={event => setOrganisations(event.target.value)}
                displayEmpty
              >
                <MenuItem value="">{i18n.t('ALL')}</MenuItem>
                {organizations &&
                  organizations.map(organization => {
                    return (
                      <MenuItem key={organization.id} value={organization.id}>
                        {organization.name}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>

            {types && (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel shrink id="types-label">
                  {i18n.t('IDEAS_WALL.TYPES')}
                </InputLabel>
                <Select
                  className={classes.selectEmpty}
                  value={selectedTypes}
                  onChange={event => setTypes(event.target.value)}
                  displayEmpty
                >
                  <MenuItem value="">{i18n.t('ALL')}</MenuItem>
                  {types &&
                    types.map(type => {
                      return (
                        <MenuItem key={type.id} value={type.id}>
                          {type.name}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            )}
          </div>
        )}
        <div className={classes.userTable}>
          <IconButton onClick={handleOpenFilter} aria-label="open filter">
            <i className={`icon-${showFilters ? 'left' : 'right'}`} />
          </IconButton>
          <CustomTable
            isLoading={tableDataBottomIsLoading}
            renderHeaderComponent={<TableHeader showFilters={showFilters} headRows={headerCells} />}
            renderBodyComponent={
              <TableBody
                showFilters={showFilters}
                bodyRows={tableDataBottom}
                onClick={navigateToIdea}
              />
            }
            paginated
            fullCount={tableDataBottomTotal}
            rowsPerPage={rowsPerPage}
            page={page}
            onPaginationChanged={handlePageChange}
          />
        </div>
      </div>
    </div>
  )
}

// ActivityIdeas.defaultProps = {
//   tableDataBottom: [],
// }

// ActivityIdeas.propTypes = {
//   tableData: PropTypes.shape({
//     dataRows: PropTypes.arrayOf(
//       PropTypes.shape({
//         firstColumn: PropTypes.arrayOf(
//           PropTypes.shape({
//             mainText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//             subText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//           }),
//         ),
//         data: PropTypes.arrayOf(PropTypes.number),
//       }),
//     ),
//     comparisonRow: PropTypes.shape({
//       firstColumn: PropTypes.arrayOf(
//         PropTypes.shape({
//           mainText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//           subText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//         }),
//       ),
//       data: PropTypes.arrayOf(
//         PropTypes.shape({
//           value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//           percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//           type: PropTypes.string,
//         }),
//       ),
//     }),
//   }).isRequired,
//   isChartLoading: PropTypes.bool.isRequired,
//   isTableLoading: PropTypes.bool.isRequired,
//   series: PropTypes.arrayOf(
//     PropTypes.shape({
//       name: PropTypes.string,
//       data: PropTypes.array,
//     }),
//   ).isRequired,
//   tableHeaders: PropTypes.arrayOf(
//     PropTypes.shape({
//       text: PropTypes.string,
//       icon: PropTypes.string,
//     }),
//   ).isRequired,
//   tableDataBottom: PropTypes.arrayOf(PropTypes.shape({})),
// }

export default withStyles(styles)(ActivityIdeas)
