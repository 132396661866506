import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { FormControlLabel } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { ButtonLink, NxtRadio } from 'components'
import styles from './View.Style'

import ChallengeCard from 'components_newui/challenge_card/View'

const RadioItem = props => {
  const { classes, linkName, item, navigateTo, onRadioClick, globalIdeaCheck,checkedValue,taggedIcon } = props
 return (
    <FormControlLabel
      disabled={globalIdeaCheck ? !item.isGlobal : false}
      // classes={classes.marginLeft}
      key={item.id}
      control={<NxtRadio value={`${item.id}`}   />}
      onClick={onRadioClick}
      label={ <div className={classes.radioinput1}><ChallengeCard challenge={item} RadioCheckedValue={checkedValue === item.id} taggedIcon={taggedIcon} /></div>
      }
      labelPlacement="end"

    
    />
  )
}

RadioItem.defaultProps = {
  navigateTo: null,
  linkName: '',
}

RadioItem.propTypes = {
  classes: PropTypes.shape({
    // marginLeft: PropTypes.string,
    radioLabelWithLink: PropTypes.string,
    radioLabel: PropTypes.string,
  }).isRequired,
  navigateTo: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  linkName: PropTypes.string,
  onRadioClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(RadioItem)
