const styles = theme => ({
  root: {},
  mainTitle: {
    margin: `${theme.spacing(5 / 4)}px 0`,
  },
  tabsSection: {
    marginTop: theme.spacing(4),
  },
  secondary_header: {
    fontFamily:theme.fontFamily.fontFamilyRegular,
    color: theme.palette.Text.TextBlack333,
    fontSize: "32px",
    lineHeight: "40px",
    marginTop: "32px",
    '@media(max-width:767px)':{
      fontSize:'16px'
    }
},
  smaller_subtitle:{
    // fontFamily: "VodafoneRg-Regular",
    fontFamily:theme.fontFamily.fontFamilyRegular,
    color: theme.palette.Text.TextBlack333,
    fontSize: "18px",
    lineHeight: "24px",
    margin: "15px 0 15px 0",
    '@media(max-width:767px)':{
      fontSize:'14px',
      lineHeight:'18px'
    }
  },
})

export default styles
