
import React from 'react'
import { connect } from 'react-redux'
import { debounce } from 'debounce'
import { onUploadImageFailure, resetForm, setImages } from 'actions/sync/create_idea_form'
import { startConfirmationService, uploadFileThunk } from 'actions/async/uploadFileActions'
import { getActiveGoals } from 'services/goals'
import { getActiveTypes } from 'services/types'
import { getActiveOrganizations } from 'services/organizations'
import { getActiveChallenges } from 'services/challenges'
import { postIdeaVideo, deleteIdeaVideo } from 'services/videos'
import {
  editIdea,
  resubmitIdea,
  getIdea,
  postIdeaImage,
  saveIdeaDraft,
  titleAvailable,
  uploadFile,
  deleteIdeaImage,
  postIdeaDocument,
  deleteIdeaDocument,
  getServiceLineStatus,
} from 'services/create_idea'
import { getBase64Split } from 'support/utils/file'
import {
  showPopupDialog,
  showPopupErrorSnackbar,
  showPopupSuccessSnackbar,
} from 'support/popup_dialogs'
import i18n from 'support/i18n'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import { MAX_SIZE_DOCUMENT } from 'common_constants/Ideas'
import ImageCropper from 'components/image_uploader/components/image_cropper/View'
import Navigation from 'support/navigation'
import { ifExistsReturn } from 'support/utils/object'
import { VIDEO_STATE_TYPE } from 'common_constants/VzaarVideo'
import ConfirmSubmitModal from './components/dialogs/ConfirmSubmitContainer'
import ErrorSubmitModal from './components/dialogs/ErrorSubmitContainer'
import VideoUploadDialog from './components/dialogs/UploadVideoContainer'
import View from './View'
// import SucessSubmitContainer from './components/dialogs/SucessSubmitContainer'

const mapStateToProps = (state, ownProps) => ({
  // main data
  isLoading:state.data.createIdea.isLoading,
  imagesLength:state.data.createIdea.imagesLength,
  createDataIdeaAll: state.data.createIdea,
  isPrivate: ifExistsReturn(ownProps.isPrivate) || ifExistsReturn(state.data.createIdea.isPrivate),
  populateCreateData : state.data.createIdea,
  title: state.data.createIdea.title,
  isTitleLoading: state.data.createIdea.isTitleLoading,
  isTitleAvailable: state.data.createIdea.isTitleAvailable,
  initialImages: state.data.createIdea.images,
  imageUploadError: state.data.createIdea.errors.imageUploadError,
  description: state.data.createIdea.description,
  isGlobal: state.data.createIdea.isGlobal,
  externalVideoSrc: state.data.createIdea.videos.externalVideo,
  fileVideo: state.data.createIdea.videos.fileVideo,
  fileVideoId: state.data.createIdea.videos.fileVideoId,
  videoIdeaId: state.data.createIdea.videos.videoIdeaId,
  isFileVideoLoading: state.data.createIdea.videos.isVideoLoading,
  isFileVideoReady: state.data.createIdea.videos.isVideoReady,
  isVideoRemoving: state.data.createIdea.videos.isVideoRemoving,
  hasVideoError: state.data.createIdea.videos.hasVideoError,
  endDate: state.data.createIdea.endDate,
  ideaChallengeID: state.data.createIdea.challenge.id,
  challengesAll: state.data.challenge.challenges,
  organization: state.data.createIdea.organization.id,
  organizations: state.data.domain.folderOrganizations,
  organizationsName: state.data.domain.domainConfiguration.organizationsName,
  organizationHelpText: state.data.domain.domainConfiguration.organizationHelp,
  isOrganizationsActive: state.data.domain.domainConfiguration.isOrganizationsActive,
  typeTitle: state.data.domain.domainConfiguration.organizationTypeName,
  typeHelpText: state.data.domain.domainConfiguration.organizationTypeHelp,
  type: state.data.createIdea.type.id,
  status: state.data.createIdea.status,
  types: state.data.domain.types,
  isTypesActive: state.data.domain.domainConfiguration.isTypesActive,
  goalValues: state.data.createIdea.goals,
  goals: state.data.domain.goals,
  documents: state.data.createIdea.documents,
  isDocumentLoading: state.data.createIdea.isDocumentLoading,
  errors: state.data.createIdea.errors,
  isPreviousValidationEnabled: state.data.domain.domainConfiguration.isPreviousValidationEnabled,
  currencyIcon: state.data.domain.domainConfiguration.currencyIcon,
  // logistic data
  implementEstimatedHours: state.data.createIdea.implementEstimatedHours,
  implementEstimatedQuantity: state.data.createIdea.implementEstimatedQuantity,
  implementEstimatedTime: state.data.createIdea.implementEstimatedTime,
  implementEstimatedTimeType: state.data.createIdea.implementEstimatedTimeType,
  pilotEstimatedHours: state.data.createIdea.pilotEstimatedHours,
  pilotEstimatedQuantity: state.data.createIdea.pilotEstimatedQuantity,
  pilotEstimatedTime: state.data.createIdea.pilotEstimatedTime,
  pilotEstimatedTimeType: state.data.createIdea.pilotEstimatedTimeType,
  isDraft: state.data.createIdea.isDraft,
  economicBenefits: state.data.createIdea.economicBenefits,
  otherBenefits: state.data.createIdea.otherBenefits,
  showLogisticData: state.data.domain.domainConfiguration.logistic,
  isLogisticActiveOnCreate: state.data.domain.domainConfiguration.isLogisticActiveOnCreate,
  isChallengeMandatoryForIdeaCreation:
    state.data.domain.domainConfiguration.isChallengeMandatoryForIdeaCreation,
  logisticCurrency: state.data.domain.domainConfiguration.logisticCurrencyConfiguration.unit,
  isAnonymous: state.data.createIdea.isAnonymous,
  isAnonymousIdeas: state.data.domain.domainConfiguration.isAnonymousIdeas,
  isAnonymousIdeasMandatory: state.data.domain.domainConfiguration.isAnonymousIdeasMandatory,
  isAdmin: state.data.user.admin,
  isInnovator: state.data.user.isInnovator,
  isPendingValidation: state.data.createIdea.isPendingValidation,
  creatingIdea: state.data.createIdea.creatingIdea,
  hasLoaded: state.data.createIdea.hasLoaded,
  localAdmin: state.data.user.localAdmin,
  localInnovator: state.data.user.localInnovation,
  isOwner: state.data.createIdea.owner === state.data.user.userEmail,
  isServiceLineActivated: state.data.domain.domainConfiguration.isServiceLineActivated,
  csrf:state.auth.csrf,
  domainName: state.data.domain.domain.domainName,
  documentIdeaKey:state.data.createIdea.documentIdeaKey,
  usingTypes: state.data.domain.domainConfiguration.usingTypes,
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    navigateToAllChallenges: () => Navigation.navigateToChallenges(),
    navigateToChallenge: challengeId => Navigation.navigateToChallengeDetail(challengeId),
    checkTitleChange: debounce(value => {
      dispatch(titleAvailable(value))
    }, 1000),
    getGoals: () => dispatch(getActiveGoals()),
    getChallenges: () => dispatch(getActiveChallenges()),
    getOrganizations: () => dispatch(getActiveOrganizations()),
    getTypes: () => dispatch(getActiveTypes()),
    resetForm: () => dispatch(resetForm()),
    onUploadVideo: video => {
      const onFailure = errorResponse => {
        let message = i18n.t('VIDEO.VIDEO_ERROR')
        if (errorResponse.status === 413) {
          message = i18n.t('VIDEO.VIDEO_TOO_LARGE_UPLOAD_ERROR')
        }
        showPopupErrorSnackbar(message, dispatch)
      }
      dispatch(postIdeaVideo(ownProps.parentId, video, onFailure))
    },
    onDeleteVideo: fileVideoId => dispatch(deleteIdeaVideo(fileVideoId)),
    onCancel: () => dispatch(hideDialog()),
    submitDraft: formData => {
      const onSuccess = data => {
        const message=i18n.t('DRAFTIDEA')
        showPopupSuccessSnackbar(message, dispatch)
        dispatch(Navigation.navigateToIdea(data.idea.id))
      }
      const onFailure = ({ data }) => {
        const messageTag =
          data && data.error && data.error.error
            ? `BACKEND_ERRORS.${data.error.error}`
            : 'BACKEND_ERRORS.UNEXPECTED'
        showPopupDialog(<ErrorSubmitModal description={i18n.t(messageTag)} />, dispatch)
      }
      dispatch(saveIdeaDraft(formData, onSuccess, onFailure))
    },
    submitIdea: formData => showPopupDialog(<ConfirmSubmitModal formData={formData} />, dispatch),

    editIdea: (id, formData) => {
      
      // console.log("idea--",id,formData)
      const onSuccess = () => {
        const message=i18n.t('EXPLORE_IDEA.SEND_EDITSUCCESS')
        showPopupSuccessSnackbar(message, dispatch)
        // dispatch(Navigation.navigateToIdea(data.idea.id))
        Navigation.navigateBack()
      }
      const onFailure = ({ data }) => {
        const messageTag =
          // commented for not showing relevent error messages
          // data && data.error && data.error.error
          //   ? `BACKEND_ERRORS.${data.error.error}`
          //   : 'BACKEND_ERRORS.UNEXPECTED'
          data && data.error && data.error.error
            ? `${data.error.desc}`
            : 'BACKEND_ERRORS.UNEXPECTED'
        showPopupDialog(<ErrorSubmitModal description={i18n.t(messageTag)} />, dispatch)
      }
      dispatch(editIdea(id, formData, onSuccess, onFailure))
    },
    resubmitIdea: (id, resubmit, formData) => {
      const onSuccess = ({data}) => {
        const message=i18n.t('EXPLORE_IDEA.SEND_EDITSUCCESS')
        showPopupSuccessSnackbar(message, dispatch)
        // dispatch(Navigation.navigateToIdea(data.idea.id))    
        Navigation.navigateBack()
      }
      
      // const onSuccess = () => {
      //   // Navigation.navigateBack()
      // }
      const onFailure = ({ data }) => {
        const messageTag =
          // commented for not showing relevent error messages
          // data && data.error && data.error.error
          //   ? `BACKEND_ERRORS.${data.error.error}`
          //   : 'BACKEND_ERRORS.UNEXPECTED'
          data && data.error && data.error.error
            ? `${data.error.desc}`
            : 'BACKEND_ERRORS.UNEXPECTED'
        showPopupDialog(<ErrorSubmitModal description={i18n.t(messageTag)} />, dispatch)
      }
      dispatch(resubmitIdea(id, resubmit, formData, onSuccess, onFailure))
    },
    // onCancel: () => {
    //   Navigation.navigateBack()
    // },
    onFileSelect: file => {
      const onSuccess = url => {
        dispatch(uploadFileThunk(url, file))
      }
      dispatch(uploadFile(onSuccess))
    },
    getIdea: id => {
      dispatch(getIdea(id))
    },
    onSelectImage: (id, image) => {
      const onSuccess = () => dispatch(hideDialog())
      const onFailure = error => {
        if (error.status === 413) {
          dispatch(onUploadImageFailure(i18n.t('IDEA.CHOOSE_VALID_IMAGE')))
        }
        dispatch(hideDialog())
      }
      const component = (
        <ImageCropper
          imageFile={image}
          onSelectCrop={(_, croppedImage) => {
            dispatch(postIdeaImage(id, croppedImage, onSuccess, onFailure))
          }}
          onClose={() => dispatch(hideDialog())}
        />
      )
      showPopupDialog(component, dispatch)
    },
    onDeleteImage: (ideaId, newImages, imageToDelete) => {
      dispatch(setImages(newImages))
      if (ideaId) {
        const onFailure = () => {
          showPopupErrorSnackbar(i18n.t('IDEA.DELETE_IMAGE_ERROR'), dispatch)
          dispatch(setImages([...newImages, imageToDelete]))
        }
        dispatch(deleteIdeaImage(ideaId, imageToDelete.key, onFailure))
      }
    },
    onSelectFileVideo: ideaId =>
      showPopupDialog(
        <VideoUploadDialog
          parentId={ideaId}
          onOuterUploadVideo={postIdeaVideo}
          onOuterDeleteVideo={deleteIdeaVideo}
          stateType={VIDEO_STATE_TYPE.IDEA}
        />,
        dispatch,
      ),
    onVideoEncodingError: () =>
      showPopupErrorSnackbar(i18n.t('VIDEO.VIDEO_ENCODING_ERROR'), dispatch),
    onRemoveFileVideo: videoId => {
      const onSuccess = () => {
        showPopupSuccessSnackbar(i18n.t('VIDEO.VIDEO_DELETE_SUCCESS'), dispatch)
      }
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('VIDEO.VIDEO_DELETE_ERROR'), dispatch)
      }
      dispatch(deleteIdeaVideo(videoId, onSuccess, onFailure))
    },

    onDocumentUpload: (id, file) => {
      const onSuccess = () => {
        showPopupSuccessSnackbar(i18n.t('EXPLORE_IDEA.UPLOAD_DOC_COMPLETE'), dispatch)
        dispatch(hideDialog())
      }
      const onFailure = error => {
        if (error.status === 413) {
          showPopupErrorSnackbar(
            i18n.t('EXPLORE_IDEA.UPLOAD_MAX_SIZE_DOC_ERROR', { max_size: MAX_SIZE_DOCUMENT }),
            dispatch,
          )
        } else {
          showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.UPLOAD_DOC_ERROR'), dispatch)
        }
        dispatch(hideDialog())
      }
      getBase64Split(
        file,
        (fileName, base64File) => {
          dispatch(postIdeaDocument(id, fileName, base64File, onSuccess, onFailure))
        },
        true,
      )
    },
    onDocumentDelete: (id, documentId) => {
      const onFailure = () => {
        showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.DELETE_KO'), dispatch)
      }
      dispatch(deleteIdeaDocument(id, documentId, onFailure))
    },

    checkVideoState: videoId =>
      dispatch(
        startConfirmationService({
          isIdea: true,
          videoId,
        }),
      ),

    getServiceLineStatus: () => {
      dispatch(getServiceLineStatus())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
