import React, {useEffect} from "react";
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

ReactGA.initialize('G-4VVSCRJ7Z5');

const ReactGATracker = () => {
    const location = useLocation();
    useEffect(() => {
      ReactGA.send("pageview");
    }, [location]);

    return(
        <div className="ReactGATrackerDiv"></div>
    )
}

export default ReactGATracker;