import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import { Paper, TableCell, TableRow, TableSortLabel } from '@material-ui/core'
import NxtCustomTable from 'components/nxt_custom_table/View'
import Image from 'components/image/View'
import imageDefault from 'img/nxt-inverted-image.png'
import ButtonLink from 'components/button_link/View'
import NxtContributionCurrency from 'components/nxt_contribution_currency'
import { getSortedItems } from 'pagesNewUi/investments/View.Utils'
import styles from './View.Style'

const headRows = [
  {
    id: 'title',
    numeric: false,
    label: i18n.t('IDEA_TITLE'),
    canSort: true,
  },
  {
    id: 'time',
    numeric: true,
    label: i18n.t('EXPLORE_IDEA.CONTRIBUTE.TIME'),
    canSort: true,
  },
  {
    id: 'budget',
    numeric: true,
    label: i18n.t('EXPLORE_IDEA.CONTRIBUTE.CASH'),
    canSort: true,
  },
  {
    id: 'other',
    numeric: false,
    label: i18n.t('EXPLORE_IDEA.CONTRIBUTE.OTHER'),
    canSort: false,
  },
]

class ContributionsTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      order: true,
      orderBy: 'title',
      showedGroups: getSortedItems(props.contributions, 'asc', 'created'),
    }
    this.renderItemRow = this.renderItemRow.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { order, orderBy } = this.state
    const { contributions } = this.props
    if (contributions !== prevProps.contributions && contributions) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showedGroups: getSortedItems(contributions, order, orderBy) })
    }
  }

  handleRequestSort(property) {
    const { order, orderBy, showedGroups } = this.state
    const newOrder = orderBy === property ? !order : true

    const sortGroups = getSortedItems(showedGroups, newOrder, property)
    this.setState({
      order: newOrder,
      orderBy: property,
      showedGroups: sortGroups,
    })
  }

  renderItemRow() {
    const { showedGroups } = this.state
    const { classes, onTitleClick } = this.props

    return showedGroups.map(item => {
      const { id, image, title, budget, time, other } = item
      return (
        <TableRow hover className={classes.itemRow} key={id}>
          <TableCell>
            <Image defaultSrc={imageDefault} src={image} />
          </TableCell>
          <TableCell align="left">
            <ButtonLink classes={{ linkButton: classes.link }} onLinkClick={() => onTitleClick(id)}>
              {title}
            </ButtonLink>
          </TableCell>
          <TableCell align="right" className={classes.label}>
            {time} <span className={classes.hours}>h</span>
          </TableCell>
          <TableCell align="right" className={classes.label}>
            <NxtContributionCurrency>{budget}</NxtContributionCurrency>
          </TableCell>
          <TableCell align="left">{other}</TableCell>
        </TableRow>
      )
    })
  }

  renderHeader = () => {
    const { orderBy, order } = this.state
    const { classes } = this.props
    return (
      <TableRow>
        <TableCell />
        {headRows.map(row => (
          <TableCell className={classes.cell} align={row.numeric ? 'right' : 'left'} key={row.id}>
            {row.canSort && (
              <TableSortLabel
                id={row.id}
                active={orderBy === row.id}
                direction={order ? 'asc' : 'desc'}
                onClick={() => this.handleRequestSort(row.id)}
              >
                {row.label}
              </TableSortLabel>
            )}
            {!row.canSort && row.label}
          </TableCell>
        ))}
      </TableRow>
    )
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.middle}>
        <Paper>
          <NxtCustomTable
            renderHeaderComponent={this.renderHeader}
            renderRowComponent={this.renderItemRow}
          />
        </Paper>
      </div>
    )
  }
}

ContributionsTable.propTypes = {
  contributions: PropTypes.arrayOf(PropTypes.shape).isRequired,
  onTitleClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(ContributionsTable)
