import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, TextField, Button, InputAdornment, CircularProgress } from '@material-ui/core'
import i18n from 'support/i18n'
import NxtVirtualCurrency from 'components/nxt_virtual_currency'
import style from './View.Style'

class InvestSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorLabel: '',
      investmentAmount: '',
      showErrorRange: false,
    }
    this.handleInvestChange = this.handleInvestChange.bind(this)
    this.renderInputLabel = this.renderInputLabel.bind(this)
    this.handleInvestButtonClick = this.handleInvestButtonClick.bind(this)
  }

  handleInvestChange(event) {
    const { maxInvestment, minInvestment, userBalance } = this.props
    const { value } = event.target
    let showErrorRange = false
    let errorLabel = ''

    if (!value) {
      this.setState({
        investmentAmount: value,
        showErrorRange,
        errorLabel,
      })
      return
    }
    const amount = parseInt(value, 10)

    if (amount < minInvestment || Number.isNaN(amount)) {
      showErrorRange = true
      errorLabel = i18n.t('EXPLORE_IDEA.INVALID_AMOUNT')
    } else if (amount > maxInvestment && amount <= userBalance) {
      showErrorRange = true
      errorLabel = i18n
        .t('EXPLORE_IDEA.ERROR_INVESTING_MAX_ALLOWED')
        .replace('##AMOUNT##', maxInvestment)
    } else if (amount > userBalance) {
      showErrorRange = true
      errorLabel = i18n.t('EXPLORE_IDEA.NOT_ENOUGH_FOUND_INVEST')
    }

    this.setState({
      investmentAmount: value,
      showErrorRange,
      errorLabel,
    })
  }

  handleInvestButtonClick() {
    const { onInvestButtonClick } = this.props
    const { investmentAmount } = this.state
    onInvestButtonClick(investmentAmount)
    this.setState({
      investmentAmount: '',
    })
  }

  renderInputLabel() {
    const { currencyIcon, minInvestment, maxInvestment } = this.props
    const { errorLabel } = this.state
    if (errorLabel.length) {
      return <span>{errorLabel}</span>
    }
    return (
      <span>
        MIN:
        <span className={`icon-${currencyIcon}`} />
        &nbsp;
        {minInvestment} - MAX: <span className={`icon-${currencyIcon}`} /> &nbsp;
        <NxtVirtualCurrency>{maxInvestment}</NxtVirtualCurrency>
      </span>
    )
  }

  render() {
    const { investmentAmount, showErrorRange } = this.state
    const {
      classes,
      currencyIcon,
      isInvestmentInProcess,
      maxInvestment,
    } = this.props
    return (
      <div className={classes.root}>
        
        {maxInvestment > 0 && (
          <>
          <TextField
            error={showErrorRange}
            label={this.renderInputLabel()}
            type="number"
            value={investmentAmount}
            InputLabelProps={{ shrink: true }}
            onChange={this.handleInvestChange}
            margin="normal"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span className={`icon-${currencyIcon}`} />
                </InputAdornment>
              ),
            }}
          />
        
        <button type='button' className={classes.investNow} 
          onClick={this.handleInvestButtonClick}>
          {isInvestmentInProcess ? 'Investing' : 'Invest'}
          {isInvestmentInProcess && <CircularProgress size={24} color="secondary" />}
        </button>
        </>)}
      </div>
    )
  }
}

InvestSection.propTypes = {
  userBalance: PropTypes.number.isRequired,
  maxInvestment: PropTypes.number.isRequired,
  minInvestment: PropTypes.number.isRequired,
  currencyIcon: PropTypes.string.isRequired,
  onInvestButtonClick: PropTypes.func.isRequired,
  isInvestmentInProcess: PropTypes.bool.isRequired,
}

export default withStyles(style)(InvestSection)
