import React from 'react'
import * as PropTypes from 'prop-types'
import { FormControl, RadioGroup, Typography, IconButton } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'
import RadioItem from './radio_item/View'

const RadioSelector = props => {
  const {
    classes,
    items,
    title,
    helpText,
    subItemsKey,
    linkName,
    subLinkName,
    value,
    onValueChange,
    navigateTo,
    error,
    reference,
    globalIdeaCheck,
    checkedValue,
    taggedIcon
  } = props
  const [isSubItemsExpanded, setSubItemsExpanded] = React.useState({})
// console.log('checkedValue==============>',checkedValue)
  return (
    <>
      {helpText && (
          <div>
            <p>{helpText}</p>
            {error && <p className={classes.errorMessage}>{error}</p>}
          </div>
        )}
      <div className={classes.root} ref={reference}>
        
        <div className={classes.inputContainer1}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label={title}
              name={title}
              className={classes.group}
              value={`${value}`}
            >
              {items.map(item => {
                if (subItemsKey && item[subItemsKey]) {
                  return (
                    <>
                      <IconButton
                      key={item.name}
                        className={isSubItemsExpanded[item.name] ? 'icon-less' : 'icon-more'}
                        classes={{ root: classes.expandButton }}
                        onClick={() => {
                          setSubItemsExpanded({ [item.name]: !isSubItemsExpanded[item.name] })
                        }}
                        disableRipple
                      >
                        <Typography classes={{ root: classes.itemName }}>{item.name}</Typography>
                      </IconButton>
                      {isSubItemsExpanded[item.name] &&
                        item[subItemsKey].map(subItem => (
                          <RadioItem
                            classes={{ marginLeft: classes.marginLeft }}
                            key={subItem.id.toString}
                            item={subItem}
                            linkName={subLinkName}
                            navigateTo={navigateTo}
                            onRadioClick={event => onValueChange(event.target.value)}
                          />
                        ))}
                    </>
                  )
                }
                return (
                  <RadioItem
                    key={item.id}
                    item={item}
                    linkName={linkName}
                    navigateTo={navigateTo}
                    onRadioClick={event => onValueChange(event.target.value)}
                    globalIdeaCheck={globalIdeaCheck}
                    checkedValue={checkedValue}
                    taggedIcon={taggedIcon}
                  />
                )
              })}
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </>
  )
}

RadioSelector.defaultProps = {
  title: '',
  helpText: '',
  subItemsKey: '',
  linkName: '',
  subLinkName: '',
  value: '',
  navigateTo: null,
  error: '',
  reference: null,
}

RadioSelector.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    group: PropTypes.string,
    radioNotChecked: PropTypes.string,
    radioLabelWithLink: PropTypes.string,
    radioLabel: PropTypes.string,
    marginLeft: PropTypes.string,
    itemName: PropTypes.string,
    expandButton: PropTypes.string,
    inputContainer: PropTypes.string,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  title: PropTypes.string,
  helpText: PropTypes.string,
  subItemsKey: PropTypes.string,
  linkName: PropTypes.string,
  subLinkName: PropTypes.string,
  value: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  navigateTo: PropTypes.func,
  error: PropTypes.string,
  reference: PropTypes.shape({}),
}

export default withStyles(styles)(RadioSelector)
