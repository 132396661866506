import { withStyles, styled } from '@material-ui/core/styles'
import styles from './View.Style'
import React, { Fragment, useEffect, useState } from 'react'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const SingleCarousel = props => {

    const sliderSettings = {
        dots: true,
        autoplay:true,
        autoplaySpeed:1000,
        infinite: true,
        speed:1000,
        slidesToShow: 1,
        slidesToScroll: 1,
      };

    const {classes, children} = props
   

    return(
        <div className={classes.container} >
            <Slider {...sliderSettings}>
                {children}
            </Slider>
        </div>
    )
}

  
export default withStyles(styles)(SingleCarousel)