/* eslint-disable no-var, eqeqeq,no-lonely-if, array-callback-return,  react/no-unused-state */ 
/* eslint-disable no-useless-escape */ 
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-const */
/* eslint-disable  object-shorthand */
/* eslint-disable react/sort-comp */


import React, { Component } from 'react'
import { Button, TextField } from '@material-ui/core'
import { Multiselect } from 'multiselect-react-dropdown'

import FormControl from '@material-ui/core/FormControl'

import Grid from '@material-ui/core/Grid'

import Checkbox from '@material-ui/core/Checkbox'

import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'

import Dialog from '@material-ui/core/Dialog'

// import Button from '@material-ui/core/Button';
// import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'
// import id from 'date-fns/locale/id/index'

class ShareIdea extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dialogOpen: true,
      message: 'The NOVA team would value your input in reviewing this idea. ',
      userData: '',

      checkedGroupValues: [],
      checkedUserValues: [],
      // groupRadio: '',
      currentGroupId: '',
      currentGroupName: '',

      groupValues: props.expertGroups,

      usersValues: [],

      userSelected: [],
      manualEmail: '',
      manualEmailGroup: '',
    }

    this.handleGroupChange = this.handleGroupChange.bind(this)
    this.handleUserChange = this.handleUserChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleMessage = this.handleMessage.bind(this)
    this.handleShare = this.handleShare.bind(this)
    this.clearLocalStorage = this.clearLocalStorage.bind(this)
    this.onRemoveGroup = this.onRemoveGroup.bind(this)
    this.handleManualEmail = this.handleManualEmail.bind(this)
    this.handleManualEmailEnter = this.handleManualEmailEnter.bind(this)
  }

  componentDidMount() {
    const { getExpertPool } = this.props
    getExpertPool()
  }

  componentDidUpdate(prevState) {
    const { expertGroups, usersOnExpertGroup } = this.props
    const { currentGroupId } = this.state
    if (prevState.expertGroups !== expertGroups) {
      this.setState({ groupValues: expertGroups })
    }
    if (prevState.usersOnExpertGroup !== usersOnExpertGroup) {
      localStorage.setItem(currentGroupId, JSON.stringify(usersOnExpertGroup))
      this.setState({ usersValues: usersOnExpertGroup })
    }
  }

  handleGroupChange = e => {
    const { userData } = this.state
    const value = e[e.length - 1].groupId
    const { getUsersOnExpertService, ideaId } = this.props

    if (!localStorage.getItem(value)) {
      getUsersOnExpertService(value, ideaId, 0, 10)
    } else {
      this.setState({ usersValues: JSON.parse(localStorage.getItem(value) || '[]') })
    }

    if (!userData.trim() === '') {
      this.setState({
        userData: `##${value}##`,
        currentGroupId: value,
        currentGroupName: e[e.length - 1].name,
      })
    } else if (!userData.includes(value)) {
      this.setState({
        userData: `${userData}##${value}##`,
        currentGroupId: value,
        currentGroupName: e[e.length - 1].name,
      })
    } else {
      this.setState({
        userData: `${userData}##${value}##`,
        currentGroupId: value,
        currentGroupName: e[e.length - 1].name,
      })
    }
    // this.setState({ groupRadio: value })
  }

  onRemoveGroup = (selectedList, removedItem) => {
    if (selectedList.length === 0) {
      this.setState({ usersValues: [] })
      // return
    } else {
      this.setState({
        usersValues: JSON.parse(localStorage.getItem(selectedList[0].groupId) || '[]'),
      })
    }
    // /console.log(JSON.stringify(selectedList))
    // console.log(JSON.stringify(removedItem.groupId))
    localStorage.removeItem(removedItem.groupId)

    let localSelectedUser = JSON.parse(localStorage.getItem('selectedUserGroup') || '[]')
    var dups = []
    localSelectedUser.map(values => {
      if (values.groupId !== removedItem.groupId) {
        dups.push(values)
      }
    })

    // console.log(`dups:: ${JSON.stringify(dups)}`)
    // console.log(`arr:: $${JSON.stringify(arr)}`)

    localStorage.setItem('selectedUserGroup', JSON.stringify(dups))
    this.setState({ userSelected: JSON.parse(localStorage.getItem('selectedUserGroup') || '[]') })
  }

  handleUserChange = (e, x, name, email) => {
    // alert(JSON.stringify(e))
    // console.log(e.target.checked)

    const { currentGroupId, currentGroupName } = this.state

    const localuserData = JSON.parse(localStorage.getItem(currentGroupId) || '[]')

    const index = localuserData.findIndex(obj => obj.email === email)

    localuserData[index].defaultChecked = !localuserData[index].defaultChecked
    localStorage.setItem(currentGroupId, JSON.stringify(localuserData))

    if (e.target.checked) {
      if (localStorage.getItem('selectedUserGroup')) {
        const localSelectedUserGroup = JSON.parse(localStorage.getItem('selectedUserGroup') || '[]')
        const selectedGroupUser = {
          name: name,
          email: email,
          groupId: currentGroupId,
          groupName: currentGroupName,
        }

        localSelectedUserGroup.push(selectedGroupUser)
        // localSelectedUserGroup = [...localSelectedUserGroup, selectedGroupUser]
        localStorage.setItem('selectedUserGroup', JSON.stringify(localSelectedUserGroup))
      } else {
        const selectedGroupUser = [
          {
            name: name,
            email: email,
            groupId: currentGroupId,
            groupName: currentGroupName,
          },
        ]
        localStorage.setItem('selectedUserGroup', JSON.stringify(selectedGroupUser))
      }
    } else {
      if (localStorage.getItem('selectedUserGroup')) {
        let localSelectedUserGroup = JSON.parse(localStorage.getItem('selectedUserGroup') || '[]')
        const indexV = localSelectedUserGroup.findIndex(obj => obj.email == email)
        localSelectedUserGroup.splice(indexV, 1)
        localStorage.setItem('selectedUserGroup', JSON.stringify(localSelectedUserGroup))
      }
    }

    this.setState({
      usersValues: JSON.parse(localStorage.getItem(currentGroupId) || '[]'),
      userSelected: JSON.parse(localStorage.getItem('selectedUserGroup') || '[]'),
    })
  }

  handleMessage = event => {
    this.setState({ message: event.target.value })
  }

  handleManualEmail = event => {
    this.setState({ manualEmail: event.target.value })
  }

  handleManualEmailEnter = manualEmail => {
    const { manualEmailGroup } = this.state
    const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!regEmail.test(manualEmail)) {
      return
    }
    const localSelectedUserGroup = JSON.parse(localStorage.getItem('selectedUserGroup') || '[]')
    const selectedGroupUser = {
      name: manualEmail,
      email: manualEmail,
      groupId: manualEmail,
      groupName: manualEmail,
    }

    // console.log(`before::; ${this.state.manualEmail}`)

    localSelectedUserGroup.push(selectedGroupUser)
    // localSelectedUserGroup = [...localSelectedUserGroup, selectedGroupUser]
    localStorage.setItem('selectedUserGroup', JSON.stringify(localSelectedUserGroup))
    if (manualEmailGroup.length > 0) {
      this.setState({ manualEmailGroup: `${manualEmailGroup},${manualEmail}`, manualEmail: '' })
    } else {
      this.setState({ manualEmailGroup: manualEmail, manualEmail: '' })
    }
    this.setState({
      // usersValues: JSON.parse(localStorage.getItem(currentGroupId) || '[]'),
      userSelected: JSON.parse(localStorage.getItem('selectedUserGroup') || '[]'),
      manualEmail: '',
    })
    // console.log(`after::; ${this.state.manualEmail}`)
  }

  handleClose() {
    const { handleShareIdea } = this.props
    this.setState({ dialogOpen: false })
    handleShareIdea()
    this.clearLocalStorage()
  }

  clearLocalStorage() {
    const { expertGroups } = this.props
    expertGroups.map(value => {
      return localStorage.removeItem(value.groupId)
    })
    localStorage.removeItem('selectedUserGroup')
  }

  handleShare() {
    const { postShareIdeaExpertService, ideaId, handleShareIdea, showError } = this.props
    const { message, groupValues, manualEmailGroup } = this.state
    handleShareIdea()
    let stringData = ''

    groupValues.map(value => {
      const finalData = JSON.parse(localStorage.getItem(value.groupId) || '[]')

      let eString = ''

      if (finalData.length > 0) {
        finalData.map(uVal => {
          if (uVal.defaultChecked && !uVal.isExpertInivited) {
            eString = eString ? `${eString},${uVal.email}` : `${uVal.email}`
          }
        })
        if (eString.length > 0) {
          stringData = `${stringData}##${value.groupId}##${eString}`
        }
      }
    })

    if (stringData.length > 0 || manualEmailGroup.length > 0) {
      postShareIdeaExpertService(ideaId, stringData, message, manualEmailGroup)
    } else {
      showError('Please select any user!!!')
    }
    this.setState({ dialogOpen: false })
    this.clearLocalStorage()
  }

  render() {
    const { classes } = this.props
    const { dialogOpen, message, manualEmail, groupValues, usersValues, userSelected } = this.state

    return (
      //   <div className={classes.container}>
      <Dialog
        open={dialogOpen}
        maxWidth="md"
        onClose={this.handleClose}
        fullWidth={true}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle>Share this idea to experts groups</DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={2}>
            <Grid item xs>
              <div className={classes.columnContainer}>
                Select Groups
                <br></br>
                <Multiselect
                  options={groupValues} // Options to display in the dropdown
                  style={{ maxWidth: '35%', margin: 0 }}
                  // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                  onSelect={this.handleGroupChange} // Function will trigger on select event
                  onRemove={this.onRemoveGroup} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                />
                <div style={{ marginTop: '50px' }}>
                  Manual Invite
                  <br></br>
                  <TextField
                    id="outlined-size-normal"
                    size="small"
                    label="Enter email and press enter"
                    variant="outlined"
                    onKeyPress={ev => {
                      if (ev.key === 'Enter') {
                        this.handleManualEmailEnter(manualEmail)
                      }
                    }}
                    value={manualEmail}
                    style={{ width: '100%' }}
                    onChange={this.handleManualEmail}
                  />
                </div>
              </div>
            </Grid>

            <Grid item xs>
              <div className={classes.columnContainer}>
                Select Users
                <FormControl component="fieldset" className={classes.formControl}>
                  {/* <FormLabel component="legend">Select Users</FormLabel> */}
                  <FormGroup>
                    {usersValues.map(value => {
                      return (
                        <FormControlLabel
                          key={value.name}
                          control={
                            <Checkbox
                              disabled={value.isExpertInivited}
                              checked={value.isExpertInivited || value.defaultChecked}
                              // !this.state.checkedUserValues.includes(value)
                              onChange={e =>
                                this.handleUserChange(e, value, value.name, value.email)
                              }
                              name={value.name}
                            />
                          }
                          label={value.name}
                        />
                      )
                    })}
                  </FormGroup>
                  {/* <FormHelperText>Be careful</FormHelperText> */}
                </FormControl>
              </div>
            </Grid>
            <Grid item xs>
              <div className={classes.columnContainer}>
                Selected Users
                <br></br>
                {userSelected.map(values => {
                  return (
                    <div key={values.name} style={{ margin: '1px' }}>
                      <Tooltip title={values.groupName}>
                        <Chip label={values.name} color="primary" />
                      </Tooltip>
                    </div>
                  )
                })}
              </div>
            </Grid>
          </Grid>

          <div style={{ width: '100%', marginTop: '10px' }}>
            <TextField
              id="outlined-multiline-static"
              style={{ width: '100%' }}
              label="Message"
              multiline
              rows={4}
              defaultValue={message}
              variant="outlined"
              onChange={this.handleMessage}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Close
          </Button>
          <Button onClick={this.handleShare} color="primary" autoFocus>
            Share
          </Button>
        </DialogActions>
      </Dialog>
      //   </div>
    )
  }
}

export default withStyles(styles)(ShareIdea)
