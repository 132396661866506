const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '25px',
    right:'-6px',
    // left: '-341px',
    // width: '500px',
    width: '340px',
    zIndex: 25,
    lineHeight: 'normal',
    '@media (max-width:767px)':{
      width:'320px',
      // left:'-200px'
    },

    '&:before': {
      position: 'absolute',
      top: '-7px',
      // right: '100px',
      right: '4px',
      display: 'inline-block',
      borderRight: '7px solid transparent',
      borderBottom: '7px solid #FFF',
      borderLeft: '7px solid transparent',
      borderBottomColor: 'rgba(0, 0, 0, 0.2)',
      content: '""',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '-10px',
      // right: '95px',
      right: '4px',
      margin: 'auto',
      height: 0,
      width: 0,
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderBottom: '10px solid #fff',
    },
    '& > button': {
      margin: theme.spacing(2, 2),
    },
  },
  tab: {
    width: '50%',
  },
  tabsLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'VodafoneRg-bold',
    fontSize: '1rem',
    textTransform: 'capitalize'
  },
  inActiveTab: {
    opacity: 0.4,
  },
  noItems: {
    padding: theme.spacing(2),
    fontSize: '14px',
  },
  unread: {
    backgroundColor: '#e7e7ff',
    marginLeft: theme.spacing(1),
    // color: theme.palette.common.white,
    padding: theme.spacing(0, 1),
    borderRadius: '5px',
    color:'#0096e2',
    fontFamily: 'VodafoneRg-Bold',
    fontSize: '0.825rem',
  },
  content: {
    borderTop: '2px solid #dedede',
    borderBottom: '1px solid #dedede',
  },
  notificationList: {
    maxHeight: '320px',
    overflowY: 'auto',
    padding: 0,
    margin: 0,
    listStyle: 'none',
    '& > li:first-child': {
      borderTop: 'none',
    },
    '& > li': {
      borderTop: '1px solid #dedede',
    },
  },
})
export default styles
