import React from 'react'
import withStyles from '@material-ui/styles/withStyles'
import i18n from 'support/i18n'
import styles from './View.Style'

const ChallengeTag = (props) => {
    const {classes,challenged} = props
    return (
    
        <div className={classes.badgeBox}>
            {(challenged) && (
                <div className={classes.badgeBoxCorner}>
                {challenged  && (
                    <p>
                    <i className="icon-target" />
                    <span>{i18n.t('CHALLENGED')}</span>
                    </p>
                )}
                </div>
            )}
        </div>
    
  )
}


export default withStyles(styles)(ChallengeTag)