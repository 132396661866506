/* eslint-disable react/jsx-no-bind, no-shadow,react/function-component-definition */

import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
// import DialogTitle from '@material-ui/core/DialogTitle'
// import DialogContentText from '@material-ui/core/DialogContentText'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
//import './pdf.css'

export default function FileViewer(props) {
  const { name, file, closeDialog } = props
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [open, setOpen] = React.useState(true)

  const handleClose = () => {
    setOpen(false)
    closeDialog()
  }

  /*To Prevent right click on screen*/
  document.addEventListener('contextmenu', event => {
    event.preventDefault()
  })

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  return (
      <Dialog open={open} maxWidth="md" onClose={handleClose}>
        {/* <DialogTitle>NOVA 7 minute Design Guide</DialogTitle> */}
        <DialogContent>
          <div className="main">
            <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
            <div>
              <div className="pagec">
                Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
              </div>
              <div className="buttonc">
                <button
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                  className="Pre"
                >
                  Previous
                </button>
                <button type="button" disabled={pageNumber >= numPages} onClick={nextPage}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            <a href={file} style={{ textDecoration: 'none', textColor: 'red' }} download={name}>
              {' '}
              Download{' '}
            </a>
          </Button>
        </DialogActions>
      </Dialog>
   
  )
}
