import React from "react";
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'
import investorImg from 'img/Investor@2x.png'
import ideatorImg from 'img/Ideator@2x.png'
import contributorTypes from "../../contributor_type";
import defaultPhoto from 'img/default-photo.png'

const Contributor = props => {
    const {classes, contributor, type} = props
    const {
        fullname,
        ideas,
        investments,
        amountInvested,
        photo
    } = contributor

    const value = type === contributorTypes.IDEATOR ? 
        `${ideas} ideas`
        :
        `${amountInvested} invested`
    const surroundImg = type === contributorTypes.IDEATOR ? ideatorImg : investorImg

    return(
        <div className={classes.container}>
            <div className={classes.circle_cropper}>
                <img className={classes.profile_photo} src={photo || defaultPhoto} onError={({currentTarget}) => {
                    currentTarget.onerror = null
                    currentTarget.src = defaultPhoto
                }} alt="profile" />
            </div>
            <img src={surroundImg} className={classes.medal_img} alt="medal" />
            <div className={classes.name}>{fullname}</div>
            <div className={classes.value}>{value}</div>
        </div>
    )
}

export default withStyles(styles)(Contributor)