import React from 'react'
import { connect } from 'react-redux'
// import { getActiveChallenges } from 'services/challenges'
// import { putDraftChallenges } from 'services/challenge_steps'
import Navigation from 'support/navigation/index'
import AnalyticsService from 'support/analytics'
import View from './View'
import { getChallenge, getChallengeDraft } from '../../services/challenge_steps'
import { showPopupDialog } from 'support/popup_dialogs'
import ConfirmDeleteIdeaModal from './dialogs/ConfirmDeleteContainer'
const mapStateToProps = state => {
  return {
    challengesDraft: state.data.ChallengeDraft,
    isAdmin: state.data.user.admin,
    isInnovator: state.data.user.isInnovator,
    localAdmin: state.data.user.localAdmin,
    canProposeChallenge:
      !state.data.user.admin &&
      !state.data.user.isInnovator &&
      !state.data.user.localAdmin &&
      state.data.domain.domainConfiguration.challengeProposal,
  }
}

const mapDispatchToProps = dispatch => {
  return {

    onDeleteClick: id => {
      showPopupDialog(<ConfirmDeleteIdeaModal id={id} />, dispatch)
    },
    // navigateToChallengeDetail: id => {
    //   dispatch(Navigation.navigateToChallengeDetail(id))
    // },
    getChallengeDraftData: () => dispatch(getChallengeDraft()),


    // getActiveChallenges: () => dispatch(getActiveChallenges()),
    // getFilteredChallenges: status => {
    //   dispatch(getActiveChallenges(status))
    //   AnalyticsService.trackEvent({
    //     category: 'swiper',
    //     action: 'click',
    //     label: 'apply new filter',
    //   })
    // },
    navigateToChallengeDetail: id => dispatch(Navigation.navigateToChallengeDetail(id)),
    navigateToNewChallenge: () => dispatch(Navigation.navigateToChallengeNew()),
    navigateToExplore: () => dispatch(Navigation.navigateToExplore()),
    navigateToProposeChallenge: () => dispatch(Navigation.navigateToProposeChallenge()),
    navigateToNewIdea: challengeId => dispatch(Navigation.navigateToNewIdea(challengeId)),
    navigateToUserProfile: email => {
      dispatch(Navigation.navigateToUserProfile(email))
    },
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
