import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import i18n from 'support/i18n'
import NxtStockChart from 'components/nxt_stock_chart/View'
import styles from './View.Style'
import ComparisonTable from '../comparison_table/View'
import DevicesGraph from './components/devices_graph/View'

const comparisonTableHeaders = [
  { text: i18n.t('DASHBOARD.IDEAS_CREATED'), borderColor: '#ffcd40' },
  { text: i18n.t('DASHBOARD.INVESTMENTS'), borderColor: '#d19600' },
  { text: i18n.t('DASHBOARD.COMMENTS'), borderColor: '#0070a7' },
  { text: i18n.t('DASHBOARD.IDEAS_VISITS'), borderColor: '#0097e2' },
  { text: i18n.t('DASHBOARD.SYSTEM_ACCESS'), borderColor: '#63ffe4' },
]
const Activity = props => {
  const {
    classes,
    tableData,
    fromDate,
    toDate,
    onChangeDates,
    isChartLoading,
    isTableLoading,
    series,
    devicesInfo,
  } = props
  return (
    <div className={classes.root}>
      <div className={classes.graphContainer}>
        <NxtStockChart
          onChangeDates={onChangeDates}
          fromDate={fromDate}
          toDate={toDate}
          isLoading={isChartLoading}
          series={series}
        />
        <DevicesGraph
          desktopAccesses={devicesInfo.desktopAccesses}
          mobileAccesses={devicesInfo.mobileAccesses}
          iosAccesses={devicesInfo.iosAccesses}
          androidAccesses={devicesInfo.androidAccesses}
          createdIdeas={devicesInfo.newIdeas}
          implementedIdeas={devicesInfo.implementedIdeas}
          ideasVisits={devicesInfo.ideasVisits}
          activeUsers={devicesInfo.activeUsers}
          investmentsMade={devicesInfo.totalInvestments}
          publishedComments={devicesInfo.publishedComments}
        />
      </div>
      <ComparisonTable
        header={comparisonTableHeaders}
        dataRows={tableData.dataRows}
        comparisonRow={tableData.comparisonRow}
        isLoading={isTableLoading}
      />
    </div>
  )
}

// Activity.propTypes = {
//   devicesInfo: PropTypes.shape({}).isRequired,
//   onChangeDates: PropTypes.func.isRequired,
//   fromDate: PropTypes.instanceOf(Date).isRequired,
//   toDate: PropTypes.instanceOf(Date).isRequired,
//   tableData: PropTypes.shape({
//     dataRows: PropTypes.arrayOf(
//       PropTypes.shape({
//         firstColumn: PropTypes.arrayOf(
//           PropTypes.shape({
//             mainText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//             subText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//           }),
//         ),
//         data: PropTypes.arrayOf(PropTypes.number),
//       }),
//     ),
//     comparisonRow: PropTypes.shape({
//       firstColumn: PropTypes.arrayOf(
//         PropTypes.shape({
//           mainText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//           subText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//         }),
//       ),
//       data: PropTypes.arrayOf(
//         PropTypes.shape({
//           value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//           percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//           type: PropTypes.string,
//         }),
//       ),
//     }),
//   }).isRequired,
//   isChartLoading: PropTypes.bool.isRequired,
//   isTableLoading: PropTypes.bool.isRequired,
//   series: PropTypes.arrayOf(
//     PropTypes.shape({
//       name: PropTypes.string,
//       data: PropTypes.array,
//     }),
//   ).isRequired,
// }

export default withStyles(styles)(Activity)
