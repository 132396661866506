import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Image from 'components/image/View'
import logo from 'img/logo.png'
import logoContent from 'img/bulb_rocket.png'
import I18n from 'support/i18n'
import styles from './View.Style'
import { Sanitizer } from 'support/utils/sanitizer'

const WelcomeScreenModalPreview = props => {
  const [isChecked, setCheck] = useState(false)

  const { classes, textTitle, textContent, splashLogo, splashImage, onSubmit } = props
  return (
    <div className={classes.modalPreview}>
      <div className={classes.modalHeader}>
        <Image className={classes.modalLogoHeader} defaultSrc={logo} src={splashLogo} />
        <span className={classes.modalTitleHeader}>{textTitle}</span>
      </div>
      <div className={classes.modalBody}>
        <span
          className={classes.modalContentBody}
          dangerouslySetInnerHTML={{ __html: Sanitizer(textContent) }}
        />
        <Image className={classes.modalLogoBody} defaultSrc={logoContent} src={splashImage} />
      </div>
      <div className={classes.footer}>
        <div>
          <Checkbox onChange={() => setCheck(!isChecked)} color="primary" checked={isChecked} />
          <span>{I18n.t('SPLASH.CHECK_LABEL')}</span>
        </div>
        <Button color="primary" onClick={() => onSubmit(!isChecked)} variant="contained">
          {I18n.t('ACCEPT')}
        </Button>
      </div>
    </div>
  )
}

WelcomeScreenModalPreview.propTypes = {
  textTitle: PropTypes.string.isRequired,
  textContent: PropTypes.string.isRequired,
  splashLogo: PropTypes.string.isRequired,
  splashImage: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default withStyles(styles)(WelcomeScreenModalPreview)
