import { ServiceCall } from 'support/network/ServiceCallAction'
import {
  APPLY_FILTER_IDEAS_STARTED,
  APPLY_FILTER_IDEAS_SUCCESS,
  APPLY_FILTER_IDEAS_FAILURE,
  APPLY_SEARCH_IDEAS_FAILURE,
  APPLY_SEARCH_IDEAS_STARTED,
  APPLY_SEARCH_IDEAS_SUCCESS,
  IDEA_ME_STARTED,
  IDEA_ME_SUCCESS,
  IDEA_ME_FAILURE,
  DELETE_IDEA_BY_ID_STARTED,
  DELETE_IDEA_BY_ID_SUCCESS,
  DELETE_IDEA_BY_ID_FAILURE,
  DISCARD_IDEA_BY_ID_STARTED,
  DISCARD_IDEA_BY_ID_SUCCESS,
  DISCARD_IDEA_BY_ID_FAILURE,
  FEEDBACK_IDEA_BY_ID_STARTED,
  FEEDBACK_IDEA_BY_ID_SUCCESS,
  FEEDBACK_IDEA_BY_ID_FAILURE,
  REQUEST_JOIN_IDEA_STARTED,
  REQUEST_JOIN_IDEA_SUCCESS,
  REQUEST_JOIN_IDEA_FAILURE,
  APPROVE_IDEA_BY_ID_STARTED,
  APPROVE_IDEA_BY_ID_SUCCESS,
  APPROVE_IDEA_BY_ID_FAILURE,
  GET_PENDING_VALIDATION_IDEAS_STARTED,
  GET_PENDING_VALIDATION_IDEAS_SUCCESS,
  GET_PENDING_VALIDATION_IDEAS_FAILURE,
  FIRST_SEARCH_APPLY_FILTER_STARTED,
  FIRST_SEARCH_APPLY_FILTER_SUCCESS,
  FIRST_SEARCH_APPLY_FILTER_FAILURE,
  GET_PRIVATE_IDEAS_STARTED,
  GET_PRIVATE_IDEAS_SUCCESS,
  GET_PRIVATE_IDEAS_FAILURE,
  GET_MY_IDEAS_TO_INVEST_IN_STARTED,
  GET_MY_IDEAS_TO_INVEST_IN_SUCCESS,
  GET_MY_IDEAS_TO_INVEST_IN_FAILURE,
  GET_EXPIRING_IDEAS_STARTED,
  GET_EXPIRING_IDEAS_FAILURE,
  GET_EXPIRING_IDEAS_SUCCESS,
} from 'actions/sync/ideas/ideasActionConstans'
import { prepareHeadersFromState } from '../HeadersUtils'
import {
  transformFilterIdeasServiceResponseDataWithState,
  transformIdeaMeServiceResponseDataWithState,
  transformTypesFilterServiceResponseDataWithState,
  transformMyIdeasToInvestInResponseDataWithState,
  // transformServiceLineStatusResponse,
  transformExpiringIdeasDataWithState,
} from './ResponseUtils'
import {
  setEndpoint,
  setDiscardIdeaBodyFromState,
  setFeedbackIdeaBodyFromState,
  setRequestBody,
  setAdditionalFilters,
} from './RequestUtils'
import { FIRST_LIMIT_CONSTANT } from '../../common_constants/Ideas'

export const FILTER_IDEAS_URL = '/api/v1/idea/filter'
export const FILTER_SEARCH_URL = '/api/v1/idea/search/##search##?limit=20'
export const IDEA_ME_URL = '/s/idea/me'
export const ORGANIZATION_FILTER_URL = '/api/v1/user/organizationsfilter'
export const TYPES_FILTER_URL = '/api/v1/user/typesfilter?limit=50&offset=0'
export const DELETE_IDEA_BY_ID_URL = '/s/idea/##id##'
export const DISCARD_IDEA_BY_ID_URL = '/s/idea/##id##/discard'
export const FEEDBACK_IDEA_BY_ID_URL = '/s/idea/##id##/feedback'
export const REQUEST_JOIN_IDEA_TEAM_URL = '/s/idea/##id##/request'
export const APPROVE_IDEA_BY_ID_URL = '/s/idea/##id##/approve'
export const GET_PENDING_VALIDATION_IDEAS_BY_ID_URL = '/s/idea/pendingvalidation'
export const GET_MY_IDEAS_TO_INVEST_IN_URL = '/s/idea/share/me/titles'
export const GET_PRIVATE_IDEAS_URL = '/api/v1/idea/filter-private'

export function applyFilterService({
  challenge,
  organizations,
  status,
  types,
  ideasLocation,
  group,
  limit = FIRST_LIMIT_CONSTANT,
  offset = 0,
  shouldLoadPrivateIdeas = false,
}) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = offset ? APPLY_FILTER_IDEAS_STARTED : FIRST_SEARCH_APPLY_FILTER_STARTED
  apiCall.successReqType = offset ? APPLY_FILTER_IDEAS_SUCCESS : FIRST_SEARCH_APPLY_FILTER_SUCCESS
  apiCall.failureReqType = offset ? APPLY_FILTER_IDEAS_FAILURE : FIRST_SEARCH_APPLY_FILTER_FAILURE

  apiCall.setEndpointFromState = () =>
    setEndpoint({
      baseUrl: shouldLoadPrivateIdeas ? GET_PRIVATE_IDEAS_URL : FILTER_IDEAS_URL,
      challenge,
      organizations,
      status,
      types,
      ideasLocation,
      group,
      limit,
      offset,
    })
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformFilterIdeasServiceResponseDataWithState
  // console.log('api call here:')
  // console.log(apiCall)
  return apiCall.getAction()
}

export function getIdeas(
  {
    challenge,
    organizations,
    status,
    types,
    group,
    goal,
    limit = FIRST_LIMIT_CONSTANT,
    offset = 0,
  },
  onFailure,
) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = APPLY_FILTER_IDEAS_STARTED
  apiCall.successReqType = APPLY_FILTER_IDEAS_SUCCESS
  apiCall.failureReqType = APPLY_FILTER_IDEAS_FAILURE
  apiCall.setEndpointFromState = () =>
    setEndpoint({
      baseUrl: FILTER_IDEAS_URL,
      challenge,
      organizations,
      status,
      types,
      group,
      goal,
      limit,
      offset,
    })
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformFilterIdeasServiceResponseDataWithState
  return apiCall.getAction()
}

export function applySearchService({
  search = '',
  challengeId = '',
  shouldLoadPrivateIdeas = false,
}) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = APPLY_SEARCH_IDEAS_STARTED
  apiCall.successReqType = APPLY_SEARCH_IDEAS_SUCCESS
  apiCall.failureReqType = APPLY_SEARCH_IDEAS_FAILURE
  apiCall.setEndpointFromState = () =>
    `${FILTER_SEARCH_URL.replace('##search##', search)}${setAdditionalFilters({
      challengeId,
      shouldLoadPrivateIdeas,
    })}`
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformFilterIdeasServiceResponseDataWithState
  return apiCall.getAction()
}

export function applyIdeaMeService() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = IDEA_ME_STARTED
  apiCall.successReqType = IDEA_ME_SUCCESS
  apiCall.failureReqType = IDEA_ME_FAILURE
  apiCall.setEndpointFromState = () => IDEA_ME_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformIdeaMeServiceResponseDataWithState
  return apiCall.getAction()
}

export function deleteIdeaById(id) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = DELETE_IDEA_BY_ID_STARTED
  apiCall.successReqType = DELETE_IDEA_BY_ID_SUCCESS
  apiCall.failureReqType = DELETE_IDEA_BY_ID_FAILURE
  apiCall.setEndpointFromState = () => DELETE_IDEA_BY_ID_URL.replace('##id##', id)
  apiCall.method = 'DELETE'
  apiCall.auth = true
  return apiCall.getAction()
}

export function discardIdeaById(id, reason, returnInvestment) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = DISCARD_IDEA_BY_ID_STARTED
  apiCall.successReqType = DISCARD_IDEA_BY_ID_SUCCESS
  apiCall.failureReqType = DISCARD_IDEA_BY_ID_FAILURE
  apiCall.setEndpointFromState = () => DISCARD_IDEA_BY_ID_URL.replace('##id##', id)
  apiCall.method = 'POST'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.setBodyFromState = () => setDiscardIdeaBodyFromState(reason, returnInvestment)
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformTypesFilterServiceResponseDataWithState
  return apiCall.getAction()
}
export function feedbackIdeaById(id, reason, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = FEEDBACK_IDEA_BY_ID_STARTED
  apiCall.successReqType = FEEDBACK_IDEA_BY_ID_SUCCESS
  apiCall.failureReqType = FEEDBACK_IDEA_BY_ID_FAILURE
  apiCall.setEndpointFromState = () => FEEDBACK_IDEA_BY_ID_URL.replace('##id##', id)
  apiCall.method = 'POST'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.setBodyFromState = () => setFeedbackIdeaBodyFromState(reason)
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformTypesFilterServiceResponseDataWithState
  return apiCall.getAction()
}

export function approveIdeaById(id, reason, returnInvestment) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = APPROVE_IDEA_BY_ID_STARTED
  apiCall.successReqType = APPROVE_IDEA_BY_ID_SUCCESS
  apiCall.failureReqType = APPROVE_IDEA_BY_ID_FAILURE
  apiCall.setEndpointFromState = () => APPROVE_IDEA_BY_ID_URL.replace('##id##', id)
  apiCall.method = 'POST'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.setBodyFromState = () => setDiscardIdeaBodyFromState(reason, returnInvestment)
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformTypesFilterServiceResponseDataWithState
  return apiCall.getAction()
}

export function requestJoinIdeaTeam(id, request) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = REQUEST_JOIN_IDEA_STARTED
  apiCall.successReqType = REQUEST_JOIN_IDEA_SUCCESS
  apiCall.failureReqType = REQUEST_JOIN_IDEA_FAILURE
  apiCall.setEndpointFromState = () => REQUEST_JOIN_IDEA_TEAM_URL.replace('##id##', id)
  apiCall.method = 'POST'
  apiCall.setBodyFromState = () => setRequestBody(request)
  apiCall.auth = true
  return apiCall.getAction()
}

export function getPendingIdeas() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_PENDING_VALIDATION_IDEAS_STARTED
  apiCall.successReqType = GET_PENDING_VALIDATION_IDEAS_SUCCESS
  apiCall.failureReqType = GET_PENDING_VALIDATION_IDEAS_FAILURE
  apiCall.setEndpointFromState = () => GET_PENDING_VALIDATION_IDEAS_BY_ID_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformFilterIdeasServiceResponseDataWithState
  return apiCall.getAction()
}

export function getMyIdeasToInvestIn() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_MY_IDEAS_TO_INVEST_IN_STARTED
  apiCall.successReqType = GET_MY_IDEAS_TO_INVEST_IN_SUCCESS
  apiCall.failureReqType = GET_MY_IDEAS_TO_INVEST_IN_FAILURE
  apiCall.setEndpointFromState = () => GET_MY_IDEAS_TO_INVEST_IN_URL
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformMyIdeasToInvestInResponseDataWithState
  return apiCall.getAction()
}

export function getPrivateIdeas(
  {
    group,
    challenge,
    organizations,
    status,
    types,
    goal,
    limit = FIRST_LIMIT_CONSTANT,
    offset = 0,
  } = {},
  onFailure,
) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_PRIVATE_IDEAS_STARTED
  apiCall.successReqType = GET_PRIVATE_IDEAS_SUCCESS
  apiCall.failureReqType = GET_PRIVATE_IDEAS_FAILURE
  apiCall.setEndpointFromState = () =>
    setEndpoint({
      baseUrl: GET_PRIVATE_IDEAS_URL,
      group,
      challenge,
      organizations,
      status,
      types,
      goal,
      limit,
      offset,
    })
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformFilterIdeasServiceResponseDataWithState
  return apiCall.getAction()
}


export const GET_EXPIRING_IDEAS_URL = '/s/idea/aboutexpire/eigthyperfunded/ideas'

export function getGetExpiringNearFunded() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_EXPIRING_IDEAS_STARTED
  apiCall.successReqType = GET_EXPIRING_IDEAS_SUCCESS
  apiCall.failureReqType = GET_EXPIRING_IDEAS_FAILURE
  apiCall.setEndpointFromState = () => GET_EXPIRING_IDEAS_URL
  apiCall.method = 'GET'
  apiCall.getHeadersFromState = prepareHeadersFromState
  apiCall.transformResponseDataWithState = transformExpiringIdeasDataWithState
  apiCall.auth = true
  return apiCall.getAction()
}