/* eslint-disable eqeqeq */ 
/* eslint-disable react/no-unescaped-entities  */

import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Card from '@material-ui/core/Card'
import { CardMedia, Grid, Button, styled } from '@material-ui/core'
// import ButtonLink from 'components/button_link/View'
import PDFViewer from 'components/pdf_viewer/View'
import NovaResearchOne from './doc/Nova Research- Work From Home June 21.pdf'
import AutomatedDelivery from './doc/5G and Automated Delivery- July 21.pdf'
import GreenTech from './doc/Green Tech- August 21.pdf'
import NovaResearchPhoto from './img/nova_research_poster.png'
import SmartCitiesPDF from './doc/Smart Cities NOVA report.pdf'
import TechAndWellbeingPDF from './doc/Tech and Wellbeing- September.pdf'
import MecPDF from './doc/MEC-November.pdf'
import styles from './View.Style'

const ResearchRequestButton = styled(Button)({
  backgroundColor: '#e60000',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#ffffff',
    color: '#e60000',
  },
})

const documentDownloadUrl =`${window.location.origin.toString()}/s/idea/admin/novaresarch/downloadpdf`

class NovaResearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openCloseFileWfh: false,
      openCloseFile5g: false,
      openCloseFileGT: false,
      openCloseFileTechWellbeing: false,
      openCloseFileSmartCities: false,
      openCloseFileMec: false,
    }
    this.openfile = this.openfile.bind(this)
    this.closefile = this.closefile.bind(this)
  }

  componentDidMount() {
    const { onGetDocument, messageType } = this.props
    // console.log(`messageType:::${messageType}`)
    onGetDocument()
  }

  openfile = value => {
    if (value === 'wfh') {
      this.setState({ openCloseFileWfh: true })
    }
    if (value === '5g') {
      this.setState({ openCloseFile5g: true })
    }
    if (value === 'GT') {
      this.setState({ openCloseFileGT: true })
    }
    if (value === 'TechWellbeing') {
      this.setState({ openCloseFileTechWellbeing: true })
    }
    if (value == 'SmartCities') {
      this.setState({ openCloseFileSmartCities: true })
    }
    if (value == 'mec') {
      this.setState({ openCloseFileMec: true })
    }
  }

  closefile = () => {
    this.setState({ openCloseFileWfh: false })
    this.setState({ openCloseFile5g: false })
    this.setState({ openCloseFileGT: false })
    this.setState({ openCloseFileTechWellbeing: false })
    this.setState({ openCloseFileSmartCities: false })
    this.setState({ openCloseFileMec: false })
  }

  render() {
    const research_insight_request_url =
      'https://forms.office.com/pages/responsepage.aspx?id=Oz8oaIeEhkyts6Uijxi4k0UVLJ3_9dpNoWUSDxlFxn5UOTkxTzhaODhOTVI2Rk0yU041S0dYRFRYQy4u'
    const { classes, documents, isDocumentLoading, csrf } = this.props
    const {
      openCloseFileWfh,
      openCloseFile5g,
      openCloseFileGT,
      openCloseFileTechWellbeing,
      openCloseFileSmartCities,
      openCloseFileMec,
    } = this.state

    // console.log(`isDocumentLoading:::: ${JSON.stringify(isDocumentLoading)}`)
    // console.log(`documents:::: ${JSON.stringify(documents)}`)

    return (
      <div className={classes.top}>
        <div className={classes.topCenter}>
          <Card className={classes.cardContainer}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs>
                <div className={classes.gridContainer}>
                  <p>
                    The NOVA Research team was formed to inspire a culture of company-wide
                    innovation across Vodafone. Through our partnership with Mintel, we look to
                    deliver trends, analysis, and insights across the biggest growth areas in
                    business today.
                  </p>
                  <p>
                    From Remote Working, to 5G, IoT and Green Technology, we'll cover topics rife
                    for innovation. As a result, we aim to provide the use cases, statistics and
                    thought leadership to support your ideation and business case development within
                    the wider NOVA ecosystem.
                  </p>
                  <p>
                    {' '}
                    Look out for our research reports that can be found below and that are sent out
                    by NOVA every month.
                  </p>
                  <p>
                    If you'd like to request a custom research report for any area of interest (e.g.
                    IoT, 5G, MEC) click the{' '}
                    <a
                      className={classes.customResearchReportSpan}
                      href={research_insight_request_url}
                    >
                      Custom Research Report
                    </a>{' '}
                    button below!
                  </p>
                  {/* <ResearchRequestButton
                    target="_blank"
                    variant="outlined"
                    href={research_insight_request_url}
                  >
                    Custom Research Report
                  </ResearchRequestButton>
                  <p></p>
                  <Button variant="outlined" onClick={() => this.openfile('mec')}>
                    Multi-Access Edge Computing
                  </Button>
                  {openCloseFileMec && (
                    <PDFViewer
                      name="MEC - November.pdf"
                      file={MecPDF}
                      closeDialog={this.closefile}
                    />
                  )}
                  <p></p>
                  <Button variant="outlined" onClick={() => this.openfile('SmartCities')}>
                    Smart Cities
                  </Button>
                  {openCloseFileSmartCities && (
                    <PDFViewer
                      name="Smart Cities NOVA report.pdf"
                      file={SmartCitiesPDF}
                      closeDialog={this.closefile}
                    />
                  )}

                  <p></p>
                  <Button variant="outlined" onClick={() => this.openfile('TechWellbeing')}>
                    Tech and Wellbeing
                  </Button>
                  {openCloseFileTechWellbeing && (
                    <PDFViewer
                      name="Tech and Wellbeing NOVA report.pdf"
                      file={TechAndWellbeingPDF}
                      closeDialog={this.closefile}
                    />
                  )}

                  <p></p>
                  <Button variant="outlined" onClick={() => this.openfile('wfh')}>
                    2021 - Work From Home Era
                  </Button>
                  {openCloseFileWfh && (
                    <PDFViewer
                      name="2021 - Work From Home Era.pdf"
                      file={NovaResearchOne}
                      closeDialog={this.closefile}
                    />
                  )}

                  <p></p>
                  <Button variant="outlined" onClick={() => this.openfile('5g')}>
                    5G & Automated Delivery
                  </Button>
                  {openCloseFile5g && (
                    <PDFViewer
                      name="5G & Automated Delivery"
                      file={AutomatedDelivery}
                      closeDialog={this.closefile}
                    />
                  )}
                  <p></p>
                  <Button variant="outlined" onClick={() => this.openfile('GT')}>
                    Green Tech
                  </Button>
                  {openCloseFileGT && (
                    <PDFViewer name="Green Tech" file={GreenTech} closeDialog={this.closefile} />
                  )} */}
                  <p></p>
                  {documents.map(doc => {
                    return (
                      <div key={doc.name}>
                        <a href={`${documentDownloadUrl}/${doc.name}?_nxt_csrf=${csrf}`}>{doc.name}</a>
                        <br></br>
                        <br></br>
                      </div>
                    )
                  })}
                  <p>
                    NOVA Research Team members:
                    <ul className="roman">
                      <li>Charlie Green</li>
                      <li>Billy Chivers </li>
                      <li>Daniel Howorth </li>
                      <li>Ben Hancock </li>
                      <li>Kris Kowalksi</li>
                      <li>Lauren Shannon</li>
                    </ul>
                  </p>
                </div>
              </Grid>

              <Grid item xs>
                <div className={classes.gridContainer}>
                  <CardMedia
                    className={classes.media}
                    image={NovaResearchPhoto}
                    title="nova reasearch poster"
                  />
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(NovaResearch)
