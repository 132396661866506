/* eslint-disable no-shadow */ 
import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Challenge from 'components_newui/challenge_card/View'
import FilterComponent from '../../components/filter_component/View'
import styles from './View.Style'
import Breadcrumbs from 'components_newui/pageBreadcrumbsMenu/View'
import { Container } from '@material-ui/core'
import i18n from 'support/i18n' 
import NxtCircularLoader from 'components/nxt_circular_loader/View'
import noDraftChallangee from 'img/no_Draft_challangee.png'
import { FIRST_LIMIT_CONSTANT, LIMIT_CONSTANT } from '../../common_constants/Ideas'

const ACTIVE_CHALLENGES = 'ACTIVE'
const EXPIRED_CHALLENGES = 'FINISHED'


const Challenges = props => {

  const { 
    classes, 
    challengesAll, challengesFilter,
    isAdmin, hasMoreChallengeToLoad,
    challengeIdeasLoading,
    canProposeChallenge, 
    isInnovator, 
    localAdmin,
    navigateToChallengeDetail,
    getFilteredChallenges,
    getActiveChallenges,
    navigateToExplore
  } = props

  const [offset,setOffset] = useState(0);
  const [showExpiredChallenges, setShowExpiredChallenges] = useState(false)
  const [activeFilters, setActiveFilters] = useState({
    status: [],
  })
  const [challengeStatus, setChallengeStatus] = useState(ACTIVE_CHALLENGES)
  const [challengeAllData, setChallengeAllData] = useState([])
  const [nameQuery, setNameQuery] = useState('')

  const pageHierarchy = [
    {string: "Home", navigate: navigateToExplore}, 
    {string: "All challenges", navigate: ()=>{}}
  ]

  // useEffect(() => {
  //   const {getActiveChallenges, getFilteredChallenges} = props
  //   showExpiredChallenges ? getFilteredChallenges(EXPIRED_CHALLENGES) : getActiveChallenges()
  // }, [showExpiredChallenges])

 let  challengeList
  const unselected_style = {
    border: '1px solid #6551FF', 
    backgroundColor: '#FFFFFF',
    color: '#333333',
    cursor: 'pointer'
  }

const challengeFilterFn = (offset=0,challengeStatus)=>{
  
  getFilteredChallenges(
    challengeStatus,
    offset ? LIMIT_CONSTANT : FIRST_LIMIT_CONSTANT,
    offset 
  )
  // console.log('newfun',challengeStatus,offsetValue,offset ? LIMIT_CONSTANT : FIRST_LIMIT_CONSTANT)
}

//   useEffect(() => {
//     challengeList = offset > 0 ?   challengesFilter : challengesAll
//     setChallengeAllData(challengeList)
//     console.log('ch set repeate', offset > 0,offset)
// }, [challengesAll,challengesFilter])

useEffect(() => {
  setChallengeAllData(challengesAll)
}, [challengesAll])

useEffect(() => {
  // setChallengeAllData(challengesFilter)
  challengeFilterFn(offset,challengeStatus)
}, [offset])


useEffect(() => {
  setOffset(0)
  challengeFilterFn(offset,challengeStatus)
  // setChallengeAllData(challengesAll)
}, [challengeStatus])


const handleFilterChange = event => {
    const status = event.target.value
    setChallengeStatus(status)
}


const handleOnLoadMore = ()=>{
  const newLimit = offset ? LIMIT_CONSTANT : FIRST_LIMIT_CONSTANT
  const newOffset = newLimit + offset
  // console.log("nw chall",newLimit,newOffset)
  // challengeFilterFn(newOffset)
  setOffset(newOffset)
}

// console.log('ch',challengeAllData)
  return(
    <>
<Breadcrumbs crumbs={pageHierarchy} />
<Container>
      <div className={classes.background}>

        <div className={classes.content_container}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div className={classes.title}>
              <span> All Challenge ({challengeAllData.length})</span>
              {/* {showExpiredChallenges? 'Expired' : 'Active'} challenges ({challenges.length}) */}
            </div>
            
          </div>

                    <div className={classes.mychallengeFilter}>
                        <FilterComponent
                            activeFilters={activeFilters}
                            serviceLineOrgs={[]}
                            types={[]}
                            ideaStatusFilter={false}
                            handleActiveFilters={setActiveFilters}
                            search={nameQuery}
                            setSearch={setNameQuery}
                            applySearch={() => setChallengeAllData(challengesAll.filter(item => (
                                item.name.toLowerCase().includes(nameQuery.toLowerCase())
                            )))}
                            resetSearch={() => { setNameQuery(''); setChallengeAllData(challengesAll) }}
                        />

                        <div className={classes.filterContainer}>
                            <span>{i18n.t('CHALLENGES.FILTER.SHOW')}: </span>
                            <form>
                                <select value={challengeStatus} onChange={handleFilterChange} className={classes.filterSelect}>
                                    <option value="ACTIVE">{i18n.t('CHALLENGES.FILTER.ACTIVE')}</option>
                                    {(isAdmin || isInnovator || localAdmin) && [
                                        <option key="PROPOSED" value="PROPOSED">
                                            {i18n.t('CHALLENGES.FILTER.PROPOSED')}</option>,
                                        <option key="PLANNED" value="PLANNED">
                                            {i18n.t('CHALLENGES.FILTER.COMING_SOON')}</option>,
                                        <option key="DELETED" value="DELETED">
                                            {i18n.t('CHALLENGES.FILTER.DELETED')}</option>
                                    ]}
                                    <option value="FINISHED">{i18n.t('CHALLENGES.FILTER.FINISHED')}</option>
                                </select>
                            </form>

                        </div>
                    </div>
                    <NxtCircularLoader isLoading={challengeIdeasLoading}>
                                    {
                                      (challengeAllData && challengeAllData.length === 0) ?

                                      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                          <img className={classes.noIdeaImg} src={noDraftChallangee} alt='no challenge' />
                                          <p className={classes.noIdeas}>No existing <span className={classes.purpleNo}>Challenges</span></p>
                                      </div>
                                      :

                                      <Grid container spacing={3} className={classes.vf_container_flexbox}>
                                        {    challengeAllData.map(challenge => (
                                          <Grid item className={classes.challItem} key={challenge.id}>
                                            <Challenge challenge={challenge} urlID={challenge.id} onClick={()=>navigateToChallengeDetail(challenge.id)} />
                                          </Grid>
                                        ))}
                                      </Grid>
                                    }

          

                 
                 {hasMoreChallengeToLoad && (
                     <div className={classes.loadMoreWrapper}>
                         <button type='button' className={classes.loadmorebtn} onClick={handleOnLoadMore}>
                         {i18n.t('HOME.FILTER.LOAD_MORE')}
                         </button>
                     </div>
                  )} 
             </NxtCircularLoader>

        </div>
      </div>
    </Container>
    </>
    )
}

Challenges.propTypes = {
  classes: PropTypes.shape({
    cardGroup: PropTypes.string,
    userCard: PropTypes.string,
    top: PropTypes.string,
    middle: PropTypes.string,
  }).isRequired,
  challenges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getActiveChallenges: PropTypes.func.isRequired,
  navigateToChallengeDetail: PropTypes.func.isRequired,
  navigateToProposeChallenge: PropTypes.func.isRequired,
  navigateToNewChallenge: PropTypes.func.isRequired,
  navigateToUserProfile: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isInnovator: PropTypes.bool.isRequired,
  localAdmin: PropTypes.bool.isRequired,
  canProposeChallenge: PropTypes.bool.isRequired,
}

export default withStyles(styles)(Challenges)
