/* eslint-disable react/no-unescaped-entities, prefer-template  */

import React, { Component, useEffect, useRef, useState } from 'react'
import * as PropTypes from 'prop-types'
import { Button, RadioGroup } from '@material-ui/core'
import Slider from 'components/slider/View'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
import ImageItem from './components/image_item/View'
import styles from './View.Style'
import uploadIcon from 'img/Upload_file.png'

const  ImageUploader = (props) => {
  const [activeIndex,setActiveStep] = React.useState(0)
  const [imagesUploadDisabled,setImagesUploadDisabled] = React.useState(false)
  const  fileInput = useRef([]);

  const {
    classes,
    images,
    UploadLimit,
    imageUploadError,
    onSelectMainImage,
    mainImageValue,
    usingDelete,
    canChooseMainImage,
    isOneImageOnly,
    subtitle,
    imagesLength,
    deleteImage,
    onSelectImage,
  } = props
  const [refresh,setRefresh] = React.useState(false)
  const showButton = true
   
useEffect(()=>{

  if( imagesLength !== UploadLimit ){
    setImagesUploadDisabled(false)
  }
  setRefresh(true)
  // console.log("imagesLength",imagesLength)
},[images,refresh,imagesUploadDisabled])



  const handleUploadImage = (file) =>{
    // if (!target.files.length) return
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = event => {
      if (event.target.readyState === FileReader.DONE) {
          // console.log("event==>",event,file && file.name)
        const src = event.target.result
        onSelectImage({ name:file && file.name, src })
        // if (fileInput) {
        //   fileInput.current.value = ''
        // }
      }
    }
  }


  const handleSelectedUploadImage =(e) =>{
    const {target} = e
      if(imagesLength !== UploadLimit ){
        const file = target && target.files[0]
        // console.log("jjjj",target && target.files[0],file)
        handleUploadImage(file)
      }else{
        setImagesUploadDisabled(true)
      }
  }
  

  const handleDeleteButton =(e,imgId)=> {
    setRefresh(true)
    // console.log("log delete click ===>", activeIndex,imgId)
    const activeImageName = images[imgId] && images[imgId].name
    if (activeImageName) {
      const imageToDelete = images[imgId]
      const newImages = images.filter(image => image.name !== activeImageName)
      deleteImage(newImages, activeImageName, imageToDelete)
      setActiveStep(0)
    }
  }


  const shortenFileName = (filename)=>{
    // console.log("filename",filename)
    const [name, extension] = filename.split('.')
    // console.log("extension",extension)
    let shortName = name
    if (shortName.length > 9){
      shortName = `${shortName.substring(0, 4)}..${shortName.substring(shortName.length-4)}`
    }
    // return `${shortName}.${extension}`
    return `${shortName}`
  }

    return (
      <div className={classes.root}>
        <div className={classes.sliderContainer}>
          
          {isOneImageOnly && images.length === 1 && (
            <ImageItem
              image={images[0]}
              mainImageValue={mainImageValue}
              canChooseMainImage={canChooseMainImage}
              onSelectMainImage={onSelectMainImage}
              onDeleteButton={()=>handleDeleteButton()}
              usingDelete={usingDelete}
            />
          )}

          {showButton && (
            <label htmlFor="contained-button-file">
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
          
                multiple
                type="file"
                // ref={ref => {
                //   fileInput = ref
                // }}
                ref={fileInput}
                onChange={handleSelectedUploadImage}
                disabled={  imagesUploadDisabled  }
              />
                
              <div className={ imagesUploadDisabled ? classes.imageUploadDisabled + ' ' + classes.add_image : classes.add_image} onDragOver={e => e.preventDefault()} onDrop={e => {e.preventDefault();handleUploadImage(e.dataTransfer.files[0])}}>
                <img className={classes.upload_icon} src={uploadIcon} alt="upload"/>
                <div className={classes.upload_media_help_text_1}>{subtitle}</div>
              </div>
              <div className={classes.dragndrop_text}>Drag and drop here</div>
              <div className={classes.format_support_text}>Supports: JPG, PNG. | Limit 10 MB per image</div>
            </label>
          )}
          {imageUploadError && <p className={classes.error}>*{imageUploadError}</p>}
         {/* { console.log('canChooseMainImage',canChooseMainImage,mainImageValue) } */}
          {imagesUploadDisabled && <p className={classes.error}>*'you can upload max 5 images only'</p>}
          {!isOneImageOnly &&
            (images && images.length > 0 && (
                 <RadioGroup defaultValue={mainImageValue} name="mainImage" className={classes.image_grid}>
               
              {
                images.map((image,index) => (
                  <div key={image.key}>
                    <ImageItem
                   
                      image={image}
                      mainImageValue={mainImageValue}
                      canChooseMainImage={canChooseMainImage}
                      onSelectMainImage={onSelectMainImage}
                      onDeleteButton={(e)=>handleDeleteButton(e,index)}
                    />
                   { image.name ?  <div className={classes.image_name}>{shortenFileName(image.name)}</div> : null }
                  </div >
                ))
              }  
                </RadioGroup>
  
            ))}
        </div>
      </div>
    )
  }


ImageUploader.defaultProps = {
  imageUploadError: '',
  onSelectMainImage: () => {},
  mainImageValue: '',
  usingDelete: true,
  subtitle: '',
  canChooseMainImage: false,
  isOneImageOnly: false,
  deleteImage: () => {},
}

// ImageUploader.propTypes = {
//   classes: PropTypes.shape({
//     root: PropTypes.string,
//     sliderContainer: PropTypes.string,
//     imageContainer: PropTypes.string,
//     img: PropTypes.string,
//     input: PropTypes.string,
//     deleteButton: PropTypes.string,
//     button: PropTypes.string,
//     error: PropTypes.string,
//     stepperRoot: PropTypes.string,
//     radioButtonMain: PropTypes.string,
//   }).isRequired,
//   images: PropTypes.arrayOf(
//     PropTypes.shape({
//       key: PropTypes.string,
//       src: PropTypes.string,
//       name: PropTypes.string,
//     }).isRequired,
//   ).isRequired,
//   onSelectImage: PropTypes.func.isRequired,
//   subtitle: PropTypes.string.isRequired,
//   deleteImage: PropTypes.func,
//   imageUploadError: PropTypes.string,
//   onSelectMainImage: PropTypes.func,
//   mainImageValue: PropTypes.string,
//   usingDelete: PropTypes.bool,
//   canChooseMainImage: PropTypes.bool,
//   isOneImageOnly: PropTypes.bool,
// }

export default withStyles(styles)(ImageUploader)
