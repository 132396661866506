import React from "react"
import { debounce } from "debounce"
import { connect } from "react-redux"
import { setChallengeDescription, setExternalVideo, setTitleDescription, setWhyImportantDescription, uploadImageFailure } from "../../actions/sync/challenge_steps"
import { deleteInfoImageSaveDraft, getChallengeDraft, postChallengeMainImageSaveDraft, postInfoChallengeImageSaveDraft, saveDraftChallenges, saveDraftProposeChallenges } from "../../services/challenge_steps"
import View from "./View"
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import {
    showPopupDialog,
    showPopupErrorSnackbar,
    showPopupSuccessSnackbar,
} from 'support/popup_dialogs'
import ErrorSubmitModal from './components/dialogs/ErrorSubmitContainer'
import i18n from 'support/i18n'
import ImageCropper from 'components/image_uploader/components/image_cropper/View'
import ConfirmSubmitModal from './components/dialogs/ConfirmSubmitContainer'
import Navigation from 'support/navigation'
const mapStateToProps = state => ({
  externalVideoURL:state.data.ChallengeDraft.challengeDescription.externalVideo,
  challengeDescription : state.data.ChallengeDraft.challengeDescription,
  imagesLength:state.data.ChallengeDraft.challengeDescription.imagesLength,
})

const mapDispatchToProps = (dispatch) => {
    return{
        onTitleChange :  title => dispatch(setTitleDescription(title)),
        onWhyImportantChange : debounce(whyImportant => dispatch(setWhyImportantDescription(whyImportant)) , 300),
        onDescriptionChange: debounce( description => dispatch(setChallengeDescription(description)), 300) ,
        onSelectImageMain: image => {
            const onSuccess = () => dispatch(hideDialog())
            const onFailure = error => {
            if (error.status === 413) {
                dispatch(uploadImageFailure(i18n.t('IDEA.CHOOSE_VALID_IMAGE')))
            } else {
                showPopupErrorSnackbar(i18n.t('BACKEND_ERRORS.UNEXPECTED'), dispatch)
            }
            dispatch(hideDialog())
            }
            const component = (
            <ImageCropper
                imageFile={image}
                onSelectCrop={(_, croppedImage) => {
                dispatch(postInfoChallengeImageSaveDraft(croppedImage, onSuccess, onFailure))
                }}
                onClose={() => dispatch(hideDialog())}
            />
            )
            showPopupDialog(component, dispatch)
        },

    onUploadExternalVideo: src => dispatch(setExternalVideo(src)),
    onRemoveExternalVideo: () => dispatch(setExternalVideo('')),
        initializeChallenge: isProposing => {
            dispatch(getChallengeDraft())
        },
        onSelectMainImage: imageName => {
            const onFailure = () => {
              showPopupErrorSnackbar(i18n.t('BACKEND_ERRORS.UNEXPECTED'), dispatch)
            }
            dispatch(postChallengeMainImageSaveDraft(imageName, onFailure))
          },
          deleteImage: (_, imageName) => {
            // console.log("dele service propose ==>",imageName)
            const onFailure = errorResponse => {
              const { data } = errorResponse
              if (data && data.error && data.error.status === 400 && data.error.code === 2003) {
                showPopupErrorSnackbar(i18n.t('CHALLENGES.DELETE_IMAGE_ERROR'), dispatch)
              } else {
                showPopupErrorSnackbar(i18n.t('BACKEND_ERRORS.UNEXPECTED'), dispatch)
              }
            }
            dispatch(deleteInfoImageSaveDraft(imageName, onFailure))
          },
          navigateToChallenges: () => {
            dispatch(Navigation.navigateToChallenges())
          },
          submitDraft: () => {
            // console.log('formdata==>', formData)
            const onSuccess = data => {
              // console.log("success submit draft =>",data)
              const message = i18n.t('DRAFTCHALLENGE')
              showPopupSuccessSnackbar(message, dispatch)
              dispatch(Navigation.navigateToChallengeDetail(data.id))
            }
            const onFailure = ({ data }) => {
              const messageTag =
                data && data.error && data.error.error
                  ? `BACKEND_ERRORS.${data.error.error}`
                  : 'BACKEND_ERRORS.UNEXPECTED'
              showPopupDialog(<ErrorSubmitModal description={i18n.t(messageTag)} />, dispatch)
            }
            dispatch(saveDraftProposeChallenges(onSuccess, onFailure))
          },


        postProposeChallengeMethod: () => showPopupDialog(<ConfirmSubmitModal proposeData />, dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(View)