/* eslint-disable  object-shorthand, react/no-unused-class-component-methods */ 
/* eslint-disable jam3/no-sanitizer-with-danger */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */


import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import Container from '@material-ui/core/Container'
import ResumeSlider from 'components/nxt_resume_card/components/resume_slider/View'
import { Typography, TextField, Button } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Chip from '@material-ui/core/Chip'
import RadioGroup from '@material-ui/core/RadioGroup'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Share'
// import CommentsPieChart from '../pie_chart/View'
import NxtDatePicker from 'components/nxt_date_picker/View'
import CommentsPieChart from '../pie_chart/View'
import ShareIdea from '../share_idea'
// import { showPopupErrorSnackbar, showPopupDialog } from 'support/popup_dialogs'
import { showPopupErrorSnackbar, showPopupDialog } from 'support/popup_dialogs'
import SpeedDials from '../fab_action/View'

import Ecomments from '../fab_action/components/comments/View'

import EvaluatorCommentsList from '../evaluator/components/evaluator_comments/View'
// react-google-charts also needs react-load-script

import styles from './View.Style'

class AdminEvaluationDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // imageUrl: [],
      // videoUrl: [],
      boardRadioVal: '',
      pitchRadioVal: '',
      feedbackTextVal: '',
      boardCommentsTextVal: '',
      showChart: true,
      loadComments: false,
      stage: 0,
      boardReviewDate: props.boardReviewDate,
      ideaPitchDate: props.ideaPitchDate,
      openShareIdeaDialog: false,
      // ideaPitchDate,
      //ideaPitchDate,
      openViewExpertComments: false,
    }

    this.handlePitchChange = this.handlePitchChange.bind(this)
    this.handleBoardChange = this.handleBoardChange.bind(this)
    this.handleTextChange = this.handleTextChange.bind(this)
    this.handleBoardComments = this.handleBoardComments.bind(this)
    this.handleSaveAdminFeedback = this.handleSaveAdminFeedback.bind(this)
    this.handleShareAdminFeedback = this.handleShareAdminFeedback.bind(this)
    // this.handleBoardStatusRadio = this.handleBoardStatusRadio.bind(this)
    this.handleMenuClose = this.handleMenuClose.bind(this)
    this.loadPrevComments = this.loadPrevComments.bind(this)
    this.handleBoardDateChange = this.handleBoardDateChange.bind(this)
    this.handlePitchDateChange = this.handlePitchDateChange.bind(this)
    this.cancelCommentListUi = this.cancelCommentListUi.bind(this)
    this.handleShareIdea = this.handleShareIdea.bind(this)
    this.handleViewExpertComments = this.handleViewExpertComments.bind(this)
  }

  componentDidUpdate(prevProps) {
    const {
      boardStatus,
      pitchStatus,
      feedbackForIdeaOwner,
      boardComment,
      boardReviewDate,
      ideaPitchDate,
    } = this.props

    if (
      prevProps.boardStatus !== boardStatus ||
      prevProps.pitchStatus !== pitchStatus ||
      prevProps.feedbackForIdeaOwner !== feedbackForIdeaOwner ||
      prevProps.boardComment !== boardComment
    ) {
      this.setState({
        boardRadioVal: boardStatus,
        pitchRadioVal: pitchStatus,
        feedbackTextVal: feedbackForIdeaOwner,
        boardCommentsTextVal: boardComment,
      })
    }

    if (prevProps.boardReviewDate !== boardReviewDate) {
      this.setState({ boardReviewDate })
    }
    if (prevProps.ideaPitchDate !== ideaPitchDate) {
      this.setState({ ideaPitchDate })
    }
  }

  handleMenuClose = () => {}

  loadPrevComments = stage => {
    this.setState({ loadComments: true, stage })
  }

  cancelCommentListUi = () => {
    this.setState({ loadComments: false })
  }

  handleBoardDateChange = date => {
    this.setState({ boardReviewDate: date })
  }

  handlePitchDateChange = date => {
    this.setState({ ideaPitchDate: date })
  }

  

  handleBoardChange(event) {
    const { value } = event.target
    let pitchVal = ''

    if (value === 'APPROVED') {
      // pitchVal = event.target.value
      pitchVal = 'PENDING'
    }
    if (value === 'DROP') {
      pitchVal = 'DROP'
    }
    if (value === 'BACKLOG') {
      pitchVal = 'PENDING'
    }
    if (value === 'DIVERTED') {
      pitchVal = 'PENDING'
    }
    if (value === 'PENDING') {
      pitchVal = 'PENDING'
    }

    this.setState({ boardRadioVal: event.target.value, pitchRadioVal: pitchVal })
  }

  handlePitchChange(event) {
    this.setState({ pitchRadioVal: event.target.value })
  }

  handleTextChange(event) {
    const { value } = event.target
    this.setState({ feedbackTextVal: value })
  }

  handleBoardComments(event) {
    const { value } = event.target
    this.setState({ boardCommentsTextVal: value })
  }

  handleSaveAdminFeedback() {
    const { saveEvaluationIdeaById, id, errorMessage } = this.props
    const {
      feedbackTextVal,
      boardCommentsTextVal,
      boardRadioVal,
      pitchRadioVal,
      boardReviewDate,
      ideaPitchDate,
    } = this.state

    if (boardRadioVal === 'PENDING' && pitchRadioVal === 'PENDING') {
      errorMessage(`Both decision can't be pending! Please change!!!`)
      return
    }

    if (boardReviewDate === 'null' || boardReviewDate === null) {
      errorMessage(`Board review date can't be blank! Please select a date!!!`)
      return
    }

    saveEvaluationIdeaById(
      false,
      id,
      boardReviewDate,
      ideaPitchDate,
      boardRadioVal,
      pitchRadioVal,
      feedbackTextVal,
      boardCommentsTextVal,
    )
  }

  handleShareAdminFeedback() {
    const { saveEvaluationIdeaById, id, errorMessage } = this.props
    const {
      feedbackTextVal,
      boardRadioVal,
      pitchRadioVal,
      boardReviewDate,
      ideaPitchDate,
      boardCommentsTextVal,
    } = this.state

    if (boardRadioVal === 'PENDING' && pitchRadioVal === 'PENDING') {
      errorMessage(`Both decision can't be pending! Please change!!!`)
      return
    }

    if (boardReviewDate === 'null' || boardReviewDate === null) {
      errorMessage(`Board review date can't be blank! Please select a date!!!`)
      return
    }

    saveEvaluationIdeaById(
      true,
      id,
      boardReviewDate,
      ideaPitchDate,
      boardRadioVal,
      pitchRadioVal,
      feedbackTextVal,
      boardCommentsTextVal,
    )
  }

  // handleBoardStatusRadio(radioVal) {}
  handleCommentClick(event) {}
  // handleMenuClose = () => {}

  // loadPrevComments = stage => {
  //   this.setState({ loadComments: true, stage: stage })
  // }

  // cancelCommentListUi = () => {
  //   this.setState({ loadComments: false })
  // }

  // handleBoardDateChange = date => {
   
  //   this.setState({ boardReviewDate: date })
  // }

  // handlePitchDateChange = date => {
  //   this.setState({ ideaPitchDate: date })
  // }

  handleShareIdea() {
    const { openShareIdeaDialog } = this.state
    this.setState({ openShareIdeaDialog: !openShareIdeaDialog })
  }

  handleViewExpertComments() {
    const { openViewExpertComments } = this.state
    this.setState({ openViewExpertComments: !openViewExpertComments })
  }

  render() {
    const {
      adminEvaluationLoading,
      commentListGraph,
      ideaTitle,
      ideaText,
      roundClosed,
      images,
      isAdmin,
      localAdmin,
      // onShareIdea,
      id,
      getExpertCommentsMail,
      expertCommentMail,
    } = this.props

    const {
      boardReviewDate,
      ideaPitchDate,
      boardRadioVal,
      pitchRadioVal,
      feedbackTextVal,
      boardCommentsTextVal,
      showChart,
      loadComments,
      stage,
      openShareIdeaDialog,
      openViewExpertComments,
    } = this.state

    return (
      <div style={{ flexGrow: 1 }}>
        {/* <Grid container direction="column" justify="center" alignItems="center"> */}
        {loadComments && (
          <EvaluatorCommentsList
            stage={stage}
            commentsList={commentListGraph.Qcomments}
            open={loadComments}
            isAdmin={isAdmin}
            localAdmin={localAdmin}
            handleDialog={this.cancelCommentListUi}
          />
        )}

        <Grid container direction="row" justify="center">
          {/* Left grid */}
          <Grid item xs={12} sm={6}>
            <Box item xs={6} height="50%" width="80%">
              {images.length > 0 && <ResumeSlider images={images} />}
            </Box>
          </Grid>
          {/* Mid grid */}
          <Grid item xs={12} sm={6} justify="space-around">
            <Container height="100%" width="100%">
              {/* <Fab
                color="primary"
                aria-label="add"
                style={{ position: 'absolute', top: 10, right: 10 }}
              >
                <AddIcon onClick={this.handleShareIdea} />
              </Fab> */}
              <SpeedDials
                id={id}
                handleShareIdea={this.handleShareIdea}
                handleViewExpertComments={this.handleViewExpertComments}
                getExpertCommentsMail={getExpertCommentsMail}
              />
              <Typography style={{ overflowWrap: 'break-word' }} variant="h5" component="h1">
                {ideaTitle}
              </Typography>
              <div dangerouslySetInnerHTML={{ __html: ideaText }} />
            </Container>
          </Grid>
          {showChart && (
            <Grid item xs={12} sm={6} style={{ backgroundColor: 'white' }}>
              <Grid
                container
                style={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'white',
                }}
                alignContent="flex-start"
              >
                <Grid item xs={6} alignItems="center">
                  {commentListGraph.Q1measurement && !loadComments && (
                    <CommentsPieChart
                      title="How innovative is this idea?"
                      list={commentListGraph.Q1measurement}
                      loadPrevComments={this.loadPrevComments}
                      loadPrevCommentsNo={1}
                    />
                  )}
                </Grid>
                <Grid item xs={6} lignItems="center">
                  {commentListGraph.Q2measurement && !loadComments && (
                    <CommentsPieChart
                      title="Is the value of this idea clear?"
                      list={commentListGraph.Q2measurement}
                      loadPrevComments={this.loadPrevComments}
                      loadPrevCommentsNo={2}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  {commentListGraph.Q3measurement && !loadComments && (
                    <CommentsPieChart
                      title="Is this idea mature/ detailed enough to go to the next phase?"
                      list={commentListGraph.Q3measurement}
                      loadPrevComments={this.loadPrevComments}
                      loadPrevCommentsNo={3}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  {commentListGraph.type && (
                    <CommentsPieChart
                      title="How do you feel about this idea?"
                      list={commentListGraph.type}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* Left grid */}

          {/* Right grid */}

          <Grid item xs={12} md={6}>
            <Container>
              <Typography variant="h5" component="h1">
                Idea Progress
              </Typography>
              <Typography variant="p" component="p">
                {/* Date Presented: */}
                {/* <br></br>Board Review Date: {boardReviewDate} */}
                <Chip label="Board Review Date" color="primary" />
                <NxtDatePicker
                  // className={classes.dateInputItem}
                  id="date-picker-from"
                  onDateChange={this.handleBoardDateChange}
                  value={boardReviewDate}
                  endIconName="icon-th"
                  // minDate={minDate}
                />
                <br></br>
                <Chip label="Board Status" color="primary" />
                {/* <br></br>Board Status: */}
                <Box py={1} display="flex" justifyContent="center">
                  <div className="radio_buttons">
                    <FormControl component="fieldset">
                      {/* <FormLabel component="legend">Gender</FormLabel> */}
                      <RadioGroup
                        row
                        aria-label="option"
                        name="option"
                        value={boardRadioVal}
                        onChange={this.handleBoardChange}
                      >
                        <FormControlLabel value="APPROVED" control={<Radio />} label="Approve" />
                        <FormControlLabel value="DROP" control={<Radio />} label="Drop" />
                        <FormControlLabel value="BACKLOG" control={<Radio />} label="Backlog" />
                        <FormControlLabel value="DIVERTED" control={<Radio />} label="Divert" />
                        <FormControlLabel value="PENDING" control={<Radio />} label="Pending" />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Box>
                {/* <br></br>Idea Pitch Date: {ideaPitchDate} */}
                <Chip label="Idea Pitch Date" color="primary" />
                <NxtDatePicker
                  // className={classes.dateInputItem}
                  id="date-picker-from"
                  onDateChange={this.handlePitchDateChange}
                  value={ideaPitchDate}
                  endIconName="icon-th"
                  // minDate={minDate}
                />
                <br></br>
                {/* <br></br>Pitch Status: */}
                <Chip label="Pitch Status" color="primary" />
                <Box py={1} display="flex" justifyContent="center">
                  <div className="radio_buttons">
                    <FormControl component="fieldset">
                      {/* <FormLabel component="legend">Gender</FormLabel> */}
                      <RadioGroup
                        row
                        aria-label="option"
                        name="option"
                        value={pitchRadioVal}
                        onChange={this.handlePitchChange}
                      >
                        <FormControlLabel
                          disabled={boardRadioVal !== 'APPROVED'}
                          value="APPROVED"
                          control={<Radio />}
                          label="Approve"
                        />
                        <FormControlLabel
                          disabled={boardRadioVal !== 'APPROVED'}
                          value="DROP"
                          control={<Radio />}
                          label="Drop"
                        />
                        <FormControlLabel
                          disabled={boardRadioVal !== 'APPROVED'}
                          value="BACKLOG"
                          control={<Radio />}
                          label="Backlog"
                        />
                        <FormControlLabel
                          disabled={boardRadioVal !== 'APPROVED'}
                          value="DIVERTED"
                          control={<Radio />}
                          label="Divert"
                        />
                        <FormControlLabel
                          disabled={boardRadioVal !== 'APPROVED'}
                          value="PENDING"
                          control={<Radio />}
                          label="Pending"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Box>
                <br></br>Fully Funded Date: {roundClosed}
              </Typography>
              <Box pt={3} display="flex" justifyContent="center">
                <TextField
                  id="outlined-multiline-static"
                  label="Internal Board Comments"
                  value={boardCommentsTextVal}
                  onChange={this.handleBoardComments}
                  multiline
                  rowsMax={3}
                  variant="outlined"
                  style={{ width: '50vw' }}
                />
              </Box>
              <Box pt={3} display="flex" justifyContent="center">
                <TextField
                  id="outlined-multiline-static"
                  label="Feedback for Idea Owner"
                  value={feedbackTextVal}
                  onChange={this.handleTextChange}
                  multiline
                  rowsMax={3}
                  variant="outlined"
                  style={{ width: '50vw' }}
                />
              </Box>
              <Box pt={1} display="flex" justifyContent="center">
                <Box px={1}>
                  <Button
                    disabled={adminEvaluationLoading}
                    variant="contained"
                    px={3}
                    id="redBackground"
                    height="100%"
                    color="primary"
                    onClick={this.handleSaveAdminFeedback}
                  >
                    Save
                  </Button>
                </Box>
                <Box px={1}>
                  <Button
                    disabled={adminEvaluationLoading}
                    variant="contained"
                    px={3}
                    id="redBackground"
                    height="100%"
                    color="primary"
                    onClick={this.handleShareAdminFeedback}
                  >
                    Share
                  </Button>
                </Box>
                {/* <Box px={1}>
                  <Button
                    disabled={adminEvaluationLoading}
                    variant="contained"
                    px={3}
                    id="redBackground"
                    height="100%"
                    color="primary"
                    onClick={this.handleShareIdea}
                  >
                    Share Idea
                  </Button>
                </Box> */}
              </Box>
            </Container>
          </Grid>
        </Grid>
        {openShareIdeaDialog && <ShareIdea ideaId={id} handleShareIdea={this.handleShareIdea} />}
        {openViewExpertComments && <Ecomments expertCommentMail={expertCommentMail} handleDialog={this.handleViewExpertComments} />}
      </div>
    )
  }
}

export default withStyles(styles)(AdminEvaluationDashboard)
