import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import ChooseList from './components/choose_list/View'
import SideLogin from '../../components/side_login/View'
import styles from './View.Style'

const IdentityChoose = props => {
  const { classes, info, onClick } = props
  return (
    <div className={classes.base}>
      <SideLogin />
      <ChooseList info={info} onClick={onClick} />
    </div>
  )
}



export default withStyles(styles)(IdentityChoose)
