import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './View.Style';
import defaultPhoto from 'img/default-photo.png'

const TeamMember = props => {
    const {classes, member} = props

    return (
        <div className={classes.container}>
            <img src={member.photoSrc} alt='member img' className={classes.member_img} onError={({currentTarget}) => {
                    currentTarget.onerror = null
                    currentTarget.src = defaultPhoto
                }}/>
            <div className={classes.member_name} >{member.fullName}</div>
        </div>
    )
}

export default withStyles(styles)(TeamMember)