const styles = theme => ({
  root: {},
  titleSection: {
    // display: 'flex',
    // alignItems: 'center',
    // fontSize: '20px',
        fontWeight: 400,
        fontFamily: theme.fontFamily.fontFamilyRegular, 
        fontSize: '32px',
        marginTop: '24px',
        // marginBottom: '16px',
        marginBottom: 0,
    '& > span': {
      fontSize: '20px',
      marginRight: theme.spacing(1),
    },
  },
  pendingUsersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop:'24px',
    alignItems:'start',
    '& img': {
      '-webkit-filter': 'grayscale(1)',
      filter: 'grayscale(1)',
    },
  },
})

export default styles
