import { connect } from 'react-redux'
import { getNovaResearchDocument } from 'services/nova_research'

import i18n from 'support/i18n'
import {
  onLogin,
  resetAuthMessageBox,
  setAuthMessageBox,
  identityNotVerifiedYet,
} from 'actions/sync/auth'
import { MESSAGE_TYPES } from 'components/auth_message_box/View.Utils'
import { POSSIBLES_AUTH_ERRORS, POSSIBLES_AUTH_STATUSES } from 'support/utils/auth'
import View from './View'

const mapStateToProps = state => ({
  messageType: state.auth.messageBox.messageType,
  messageText: state.auth.messageBox.messageText,
  isLogging: state.auth.isLogging,
  isEmailNotVerifiedYet: state.auth.isEmailNotVerifiedYet,
  documents: state.data.novaResearch.documents,
  isDocumentLoading: state.data.novaResearch.isDocumentLoading,
  csrf: state.auth.csrf,
})

const mapDispatchToProps = dispatch => {
  return {
    onGetDocument: () => {
      const onSuccess = () => {
        // showPopupErrorSnackbar(I18n.t('NOVA_RESEARCH.GET_DOCUMENT'), dispatch)
      }
      const onFailure = () => {
        //  showPopupErrorSnackbar(I18n.t('NOVA_RESEARCH.GET_DOCUMENT'), dispatch)
      }
      dispatch(getNovaResearchDocument(onFailure))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
