const styles = theme => ({
    
    container: {
      fontFamily: 'VodafoneRg-Regular',
      width: '100%',
      height: '292px',
      backgroundColor: '#FFFFFF',
      borderRadius: '10px',
      border: '1px solid #CCCCCC',
      display: 'flex',
      flexDirection: 'column',
    },
    idea_img:{
      maxWidth: "100%",
      width: "100%",
      maxHeight: "120px",
      height: "120px",
      objectFit: "cover",
      objectPosition: "center top",
      borderRadius: "9px 9px 0 0", 
    },
    bottom_part: {
      padding: '8px',
      display: 'flex',
      flexDirection: 'column',
      flexGrow:1,
      justifyContent: 'space-between',
      '@media (min-width:480px)': { 
        padding: '16px',
      }
    },
    idea_title: {
      fontFamily: 'VodafoneRg-Bold',
      fontSize: '12px',
      color: '#333333',
      '@media (min-width:480px)': { 
        fontSize: '18px',
      }
    },
    creation_date: {
      fontSize: '10px',
      color: '#666666',
      opacity: '0.66',
      marginTop: '6px',
      '@media (min-width:480px)': { 
        fontSize: '12px',
      }
    },
    buttons:{
      display:'block',
      '@media (min-width:480px)': { 
        display: 'flex',
        justifyContent: 'center',
      }
    },
    delete_btn: {
      width: '114px',
      height: '34px',
      fontSize:'14px',
      borderRadius: '22.5px',
      border: '1px solid #6551FF',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#6551FF',
      marginRight: '20px',
      cursor: 'pointer',
      transition: '0.5s',
      margin:'4px 0px',
      '&:hover':{
        backgroundColor: '#6551FF',
        color: '#FFFFFF'
      },

      '@media (min-width:480px)': { 
        height: '44px',
        fontSize:'16px'
      },

    },
    continue_btn:{
      width: '114px',
      height: '34px',
      fontSize:'14px',
      borderRadius: '22.5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid #333333',
      backgroundColor: '#333333',
      color: 'white',
      cursor: 'pointer',
      transition: '0.5s',
      '&:hover':{
        backgroundColor: 'white',
        color: '#333333'
      },
      '@media (min-width:480px)': { 
        height: '44px',
        fontSize:'16px'
      },
    },
  })
  

export default styles
