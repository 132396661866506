/* eslint-disable jam3/no-sanitizer-with-danger */

import React from 'react'
import { Container, withStyles } from '@material-ui/core'
import clsx from 'clsx'
import {useLocation } from 'react-router-dom';
import Breadcrumbs from 'components_newui/pageBreadcrumbsMenu/View'
import Slider from "react-slick";
import defaultImgNova from 'img/nova_not_support.jpg'
import Navigation from 'support/navigation'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './View.Style'
import { tutorialSteps } from '../caseStudydb';


const IdeaTeams = [
  {imgsrc:defaultImgNova,name:'Bill Murphy',role:'Product Manager, Vodafone Group'},
  {imgsrc:defaultImgNova,name:'Jay Mooree',role:'Product Manager, Vodafone Group'},
  {imgsrc:defaultImgNova,name:'Arjun Shah ',role:'Product Manager, Vodafone Group'},
  {imgsrc:defaultImgNova,name:'Rick Malik ',role:'Product Manager, Vodafone Group'},
  {imgsrc:defaultImgNova,name:'Lynda James ',role:'Product Manager, Vodafone Group'},
  
]


const CaseStudyDetailCarousel = (props)=>{
const {classes,caseStudyDetailData} =props
  const sliderSettings = {
    dots: true,
    autoplay:true,
    autoplaySpeed:1000,
    infinite: false,
    speed:1000,
    slidesToShow: 1,
    slidesToScroll: 1,

  };
  return(
        <div className={classes.carouselContainer}>
            <h1 className={classes.contentTitle}>{caseStudyDetailData[0].header}</h1>
            <div className={classes.carouselContainerBlock}>
              <Slider {...sliderSettings}>
                  <div className={classes.carouselContainer__image}>
                    <img  src={  caseStudyDetailData[0].imgPath !== '' ? caseStudyDetailData[0].imgPath : defaultImgNova} alt='carousel img' />
                  </div>
              </Slider>
            </div>
        </div>
  )
}


const CaseContent = ({caseStudyDetailData,classes}) => {
  return (
    <div className={classes.blockContent}>
        <h4 className={classes.caseStudyContentSubTitle}>Prototype Description</h4>
        <p className={classes.caseStudyContent}>  <div dangerouslySetInnerHTML={{ __html: caseStudyDetailData[0].label }} /> </p>
      </div>
  )
}

const NovaTeamHelpContent = ({classes}) => {
  return (
    <div className={clsx(classes.caseStudyContent, classes.blockContent) }>
        <h4 className={classes.caseStudyContentSubTitle}>How NOVA team helped?</h4>
        <div className={ classes.blockquote }>
        <p>   
          <span className={classes.blockquoteTop}>&#8220;</span> 
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus massa est, placerat sed pellentesque quis, varius sit amet nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus massa est, placerat sed pellentesque quis, varius sit amet nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
          <span className={classes.blockquoteBottom} >&#8221;</span> 
        </p>
        </div>
        <p> - Bill Murphy </p>
        <p> Product Manager </p>
    </div>
  )
}

const CaseStrategic = ({classes}) => {
  return (
    <div className={classes.blockContent}>
        <h4 className={classes.caseStudyContentSubTitle}>Strategic priorities</h4>
          <ul className={clsx(classes.caseStudyContent,classes.caselist)}>
            <li>Continue to increase customer satisfaction</li>
            <li>Grow revenue in new market segments and with new offerings</li>
          </ul>
    </div>
  )
}


const CaseIdeaTeams = ({classes})=>{
  return(
    <div className={classes.blockContent}>
       <h4 className={classes.caseStudyContentSubTitle}>Idea team</h4>
        <div className={classes.collist}>
        {
          IdeaTeams.map(item=>(
            <div key={item.name}  className={classes.teamblock}>
              <img src={item.imgsrc}
                alt="idea teams"
                className={classes.memberimg}
              />
              <p className={classes.teamName}>{item.name}</p>
              <p className={classes.teamrole}>{item.role}</p>
            </div>
          ))
        }</div>
       
    </div>
  )
}






const CaseStudyDetails = (props) => {
  const location = useLocation();
  const lastLocationPath = location.pathname.split('/').pop()
  const caseStudyDetailData =  tutorialSteps.filter(item=>item.slug === lastLocationPath)

  const { classes } = props

  const pageHierarchy = [
    { string: "Home", navigate: ()=>Navigation.navigateToExplore() },
    { string: "Case Studies ", navigate: () => Navigation.navigateToCaseStudy() },
    { string: caseStudyDetailData[0].header, navigate: () => {} }
  ]

  return (
    <>
      <Breadcrumbs crumbs={pageHierarchy} />
      <Container>
        <CaseStudyDetailCarousel 
          caseStudyDetailData={caseStudyDetailData}
        {...props} />
        <CaseContent 
        caseStudyDetailData={caseStudyDetailData}
        {...props} />
        <NovaTeamHelpContent {...props} />
        <CaseStrategic {...props} />
        <CaseIdeaTeams {...props} />
      </Container>
    </>
  )
}

export default withStyles(styles)(CaseStudyDetails)

