/* eslint-disable prefer-template */
/* eslint-disable no-var */
/* eslint-disable func-names */

import { extractPropertiesFromJsonObject } from '../RequestUtils'

export const setEndpointEvaluationIdea = ({ baseUrl, limit = 11, offset = 0 }) => {
  let url = baseUrl
  url += `?${encodeURIComponent('limit')}=${limit}`
  url += `&${encodeURIComponent('offset')}=${offset}`
  return url
}

export function setEvaluationBody(title, q1c, q1m, q2c, q2m, q3c, q3m) {
  return `${encodeURIComponent('evatitle')}=${encodeURIComponent(title)}&${encodeURIComponent(
    'q1Comment',
  )}=${encodeURIComponent(q1c)}&${encodeURIComponent('q1measurement')}=${encodeURIComponent(
    q1m,
  )}&${encodeURIComponent('q2Comment')}=${encodeURIComponent(q2c)}&${encodeURIComponent(
    'q2measurement',
  )}=${encodeURIComponent(q2m)}&${encodeURIComponent('q3Comment')}=${encodeURIComponent(
    q3c,
  )}&${encodeURIComponent('q3measurement')}=${encodeURIComponent(q3m)}`
}

export function setEvaluationStatusBody(title, status, email) {
  return `${encodeURIComponent('title')}=${encodeURIComponent(title)}&${encodeURIComponent(
    'action',
  )}=${encodeURIComponent(status)}`
}
// export function setExpertCommentStatusBody(id, rating, comment) {
//   return `${encodeURIComponent('ideaId')}=${encodeURIComponent(id)}&${encodeURIComponent(
//     'ratings',
//   )}=${encodeURIComponent(rating)}&${encodeURIComponent('expertComment')}=${encodeURIComponent(
//     comment,
//   )}`
// }

export function setExpertCommentStatusBody(id, rating, comment) {
  return extractPropertiesFromJsonObject({ ideaId: id, ratings: rating, expertComment: comment })
}

const convertDateDDMMYY = date => {
  if (!date) return '' + date
  var todayTime = new Date(date)
  var month = todayTime.getMonth() + 1
  var day = todayTime.getDate()
  var year = todayTime.getFullYear()
  return month + '/' + day + '/' + year
}

export function setSaveEvaluationBody(
  boardReviewDate,
  ideaPitchDate,
  boardStatus,
  pitchStatus,
  feedbackForIdeaOwner,
  boardComments,
) {
  return `${encodeURIComponent('boardReviewDate')}=${encodeURIComponent(
    boardReviewDate,
  )}&${encodeURIComponent('ideaPitchDate')}=${encodeURIComponent(
    convertDateDDMMYY(ideaPitchDate),
  )}&${encodeURIComponent('boardStatus')}=${encodeURIComponent(boardStatus)}&${encodeURIComponent(
    'pitchStatus',
  )}=${encodeURIComponent(pitchStatus)}&${encodeURIComponent(
    'feedbackForIdeaOwner',
  )}=${encodeURIComponent(feedbackForIdeaOwner)}&${encodeURIComponent(
    'boardComment',
  )}=${encodeURIComponent(boardComments)}`
}


// export const getIdGroupUrl = (url, id, invited, offset, limit) => () => {
//   let invitedContent = ''
//   if (invited) invitedContent = '/invited'
//   return `${url}/${id}/users${invitedContent}?offset=${offset}&limit=${limit}`
// }

export function getIdGroupUrl(groupId, ideaId) {
  return `${encodeURIComponent('ideaId')}=${encodeURIComponent(ideaId)}&${encodeURIComponent(
    'groupId',
  )}=${encodeURIComponent(groupId)}`
}

export function getShareIdeaUrl(ideaId, groupUserData, emailMessage, manualEmail) {
  return `${encodeURIComponent('ideaId')}=${encodeURIComponent(ideaId)}&${encodeURIComponent(
    'groupUserData',
  )}=${encodeURIComponent(groupUserData)}&${encodeURIComponent(
    'emailMessage',
  )}=${encodeURIComponent(emailMessage)}&${encodeURIComponent('manualEmail')}=${encodeURIComponent(
    manualEmail,
  )}`
}
