import React, { useState } from 'react'
// import styles from './View.Style'
// import withStyles from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'
import { ClickAwayListener } from '@material-ui/core'
import SimpleMenu from 'components/simple_menu'
import { UserAvtar } from './UserAvtar'

const UserProfile = ({classes,userName,userBalance,userPhoto,showAdmin}) => {
    const [showNavList, setShowNavList] = useState(false)

  const toggleNavList = () => {
    setShowNavList(!showNavList);
  };

  const closeMenuItem = () => {
    if(showNavList){  setShowNavList(false);}
  };
  
  return (
    <>
         
            <button 
              type='button' 
              className={clsx('btns',classes.btnRelative)}   
              tabIndex="0"  
              onClick={toggleNavList}
            >
                <span className={classes.avtarOnline}>
                  <UserAvtar userPhoto={userPhoto} classes={classes} />
                  <span className={classes.dotIcon}>...</span>
                </span>
            </button>

                {/* <div
                  role="button" tabIndex="0"
                  className={clsx(classes.badge, classes.burger, 'icon-menu')}
                  onClick={() => setShowNavList(prev => !prev)}
                > */}
                  {showNavList && (
                    <ClickAwayListener onClickAway={() => setShowNavList(false)}>
                      <div className={classes.navList}  >
                        <SimpleMenu 
                          // id="user-menu" 
                          closeMenuItem={closeMenuItem}
                          showAdmin={showAdmin}
                          userName={userName}
                          userBalance={userBalance} 
                          userPhoto={userPhoto} 
                          classes={classes} 
                        />
                      </div>
                    </ClickAwayListener>
                  )}
                
    </>
  )
}


export default UserProfile
// export default withStyles(styles)(UserProfile)
