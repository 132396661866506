const styles = (theme) => ({
  vf_container_flexbox:{
    width: 'calc(100% + 20px)',
    margin: '24px -10px 10px',
    "& .MuiGrid-item": {
        padding: '0px 10px',
        marginBottom: '24px',
        flex: '0 0 33.33%'
    },
    '@media (max-width:767px)':{
      maxWidth:'420px',
      marginLeft:'auto',
      marginRight:'auto',
      width:'100%',
    }
  },
  background:{
    backgroundColor: "#fbfaff",
    paddingBottom: theme.spacing(8),
    fontFamily: 'VodafoneRg-Regular',
    color: '#333333'
  },
  
  title: {
    fontSize: '32px',
    paddingTop:theme.spacing(4),
    paddingBottom:theme.spacing(4),
      '@media (max-width:767px)':{
          fontSize:'16px',
          paddingTop:theme.spacing(2),
          paddingBottom:theme.spacing(2),
      }
  
  },
  active_expire_switch: {
    fontFamily: 'VodafoneRg-Regular',
    fontSize: '18px',
    display: 'flex',
    '& div': {
      width: '149px',
      height: '48px',
      backgroundColor: '#333333',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#FFFFFF',
      boxSizing: 'border-box',
      '-moz-box-sizing': 'border-box',
      '-webkit-box-sizing': 'border-box',
    }
  },
  activeButton: {
    borderRadius: '24px 0 0 24px'
  },
  expiredButton: {
    borderRadius: '0 24px 24px 0'
  },

  mychallengeFilter:{
    display:'flex',
    justifyContent:'space-between',
    marginBottom:theme.spacing(4),
    marginTop:theme.spacing(2),
    
      '@media (max-width:480px)':{
        display:'block'
      }
  
  },
  filterContainer:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
    
  },
  filterSelect:{
    border:'2px solid #6551FF',
    padding: '0.6rem 1rem',
    background:'#fff',
    fontSize:theme.fontSize.fontsize20,
    fontFamily:theme.fontFamily.fontFamilyRegular,
    marginLeft:'0.8rem',
    borderRadius:'26px',
    '-webkit-border-radius':'26px',
    '-moz-border-radius':'26px',
    '-ms-border-radius':'26px',
    '-o-border-radius':'26px',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    'appearance': 'none',
    outline:'none',
    cursor:'pointer',
    minWidth:'112px',
    minHeight:'48px',
    '@media(max-width:767px)':{
      minHeight: "38px",
      border: "1px solid #6551FF",
      fontSize:'14px'
    },
  },

  noIdeaImg: {
    marginTop:theme.spacing(2),
    height: '288px',
  },
  noIdeas: {
    fontFamily:theme.fontFamily.fontFamilyRegular,
    fontSize:theme.fontSize.fontsize32,
      color: '#333333',
  },
  loadMoreWrapper:{
    textAlign:'center',
    marginTop:'2rem'
},
loadmorebtn: {
    width: '145px',
    height: '45px',
    borderRadius: '22.5px',
    border: '1px solid #6551FF',
    color: '#6551FF',
    fontFamily: 'VodafoneRg-Regular',
    fontSize: '20px',
    cursor: 'pointer',
    backgroundColor: '#FFFFFF',
    transition: '0.5s',
    '&:hover':{
        backgroundColor: '#6551FF',
        color: '#FFFFFF'
    }
},
challItem:{
  '@media (max-width:767px)':{
      flex:'1 1 auto !important',
  },
  '@media (min-width:768px) and (max-width:992px)':{
      flex:'1 1 50% !important',
  }
},


})

export default styles
