/* eslint-disable   no-unneeded-ternary, no-shadow */ 
import { withStyles } from '@material-ui/core/styles'
import styles,{ myTheme }  from './View.Style'
import React, { Fragment, useEffect, useState } from 'react'
import { FormControl, ListItemText, MenuItem, Select, ThemeProvider } from '@material-ui/core'




const Select_Filter = props => {
    
    const {classes, label, items, onChange, value} = props
    

    return(
        <ThemeProvider theme={myTheme}>
        <div className={classes.container}>
            <p className={classes.label}>{label}</p>
            <div className={classes.select_container}>
                <FormControl style={{
                    width: "90%",
                    alignSelf: "center",
                    border: "none",
                    paddingLeft: "10px"
                    }}>
                    <Select
                        disableUnderline
                        displayEmpty
                        value={value ? value : ""}
                        onChange={onChange}
                        onClick={event=>{}}
                        MenuProps={{ disableScrollLock: true }}
                        placeholder='Select'
                        renderValue={value => value === "" ? <p style={{color: "#999999", margin: "0", padding: "0"}}>Select</p> : value}
                    >
                        {items.map(item => (
                            <MenuItem key={item} value={item}>
                                <ListItemText primary={item} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
        </ThemeProvider>
    )
}

export default withStyles(styles)(Select_Filter)