import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { IconButton, TableCell, TableRow } from '@material-ui/core'
import FilterByText from '../filter_by_text/View'

const TableHeader = props => {
  const { headRows, onFilterByText } = props
  const [showTextFilter, setShowTextFilter] = useState(false)
  return (
    <>
      <TableRow>
        <TableCell align="center">
          <IconButton
            aria-label="search"
            color="primary"
            size="small"
            onClick={() => setShowTextFilter(!showTextFilter)}
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            <span className="icon-search" />
          </IconButton>
        </TableCell>
        {headRows.map(row => (
          <TableCell key={row.id} align={row.numeric ? 'right' : 'left'}>
            {row.label}
          </TableCell>
        ))}
      </TableRow>
      {showTextFilter && (
        <TableRow>
          <TableCell align="left" colSpan={4}>
            <FilterByText onFilterByText={onFilterByText} />
          </TableCell>
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />
        </TableRow>
      )}
    </>
  )
}

TableHeader.propTypes = {
  headRows: PropTypes.arrayOf(
    PropTypes.shape({
      numeric: PropTypes.bool,
      label: PropTypes.string,
    }),
  ).isRequired,
}

export default TableHeader
