import React from 'react'
import { Button, Table, TableBody, TableCell, TableRow, withStyles } from '@material-ui/core'
import i18n from 'support/i18n'
import Navigate from 'support/navigation'
import styles from './View.Style'

const DataProtection = props => {
  const { classes } = props
  const tableContent = [
    {
      firstCellText: i18n.t('TERMS_AND_CONDITIONS_TABLE.RESPONSABLE'),
      secondCellText: i18n.t('TERMS_AND_CONDITIONS_TABLE.RESPONSABLE_TEXT'),
    },
    {
      firstCellText: i18n.t('TERMS_AND_CONDITIONS_TABLE.PURPOSE'),
      secondCellText: i18n.t('TERMS_AND_CONDITIONS_TABLE.PURPOSE_TEXT'),
    },
    {
      firstCellText: i18n.t('TERMS_AND_CONDITIONS_TABLE.LEGITIMATION'),
      secondCellText: i18n.t('TERMS_AND_CONDITIONS_TABLE.LEGITIMATION_TEXT'),
    },
    {
      firstCellText: i18n.t('TERMS_AND_CONDITIONS_TABLE.RECIPIENTS'),
      secondCellText: i18n.t('TERMS_AND_CONDITIONS_TABLE.RECIPIENTS_TEXT'),
    },
    {
      firstCellText: i18n.t('TERMS_AND_CONDITIONS_TABLE.RIGHTS'),
      secondCellText: i18n.t('TERMS_AND_CONDITIONS_TABLE.RIGHTS_TEXT'),
    },
  ]
  return (
    <>
      <h3>{i18n.t('TERMS_AND_CONDITIONS_TABLE.INFORMATION')}</h3>
      <Table aria-label="simple table">
        <TableBody>
          {tableContent.map(row => {
            return (
              <TableRow key={row.firstCellText}>
                <TableCell component="th" scope="row" className={classes.boldText}>
                  {row.firstCellText}
                </TableCell>
                <TableCell align="left">{row.secondCellText}</TableCell>
              </TableRow>
            )
          })}
          <TableRow>
            <TableCell className={classes.boldText} component="th" scope="row">
              {i18n.t('TERMS_AND_CONDITIONS_TABLE.ADDITIONAL_INFORMATION')}
            </TableCell>
            <TableCell align="left">
              {i18n.t('TERMS_AND_CONDITIONS_TABLE.ADDITIONAL_INFORMATION_TEXT')}
              <Button
                className={classes.linkToInfo}
                id="button-link"
                onClick={() =>
                  Navigate.navigateToExternal('https://www.nextinit.com/data-protection')
                }
              >
                {i18n.t('TERMS_AND_CONDITIONS_TABLE.MORE_INFO')}
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  )
}

export default withStyles(styles)(DataProtection)
