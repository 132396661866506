/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import styles from './View.Style'
import default_img from 'img/hero_image.png'
import { withStyles } from '@material-ui/core/styles'
import  format  from 'date-fns/format';

 const ChallengeDraftCard = (props) => {
   const {classes,challenge,handleDelete,handleContinue} = props

    const {challengeDescription:{images,title},createDate}
         = challenge

    const getDayStr = day => {
        const chars = ['th', 'st', 'nd', 'rd']
        // console.log(day%10)
        // console.log(chars[day%10])
        if (( day > 3 && day < 21) || (day % 10 > 3)){ 
            // console.log('why here')
            return `${day}${chars[0]} `
        }
        // else 
        return `${day}${chars[day%10]} `
    }

    const formatDate = epoch_ms => {
        const date = new Date(epoch_ms)
        return(`${getDayStr(date.toLocaleDateString("en-US", {day: "numeric"}))} ${date.toLocaleDateString("en-US", {month: 'long'})} ${date.toLocaleDateString("en-US", {year: 'numeric'})}`)
    }

    let formdataFormate
    // console.log("createDate",createDate)
    if(createDate !== undefined && createDate !== null){
        const prevForDate = new Date(createDate)
        formdataFormate = prevForDate ? format(prevForDate,'PPP') : null; 
    }

    return (
    <div className={classes.container}>
        <div className={classes.containerwrp}>
            <div className={classes.left_sidebar}>
                <img className={classes.challenge_img} src={ images[0] && images[0].src} onError={({currentTarget}) => {
                    currentTarget.onerror = null
                    currentTarget.src = default_img
                }} />
            </div>
            <div className={classes.right_sidebar}>
                <div>
                    <div className={classes.challenge_title}>{title}</div>
                    <div className={classes.creation_date}>Date created: <span className={classes.datebold}>{ formdataFormate }</span></div>
                </div>
                <div className={classes.buttons}>
                    <button type='button' className={classes.delete_btn} onClick={handleDelete}>Delete</button>
                    <button type='button' className={classes.continue_btn} onClick={handleContinue}>Continue</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default withStyles(styles)(ChallengeDraftCard)