
/* eslint-disable prefer-const */

import {
  ROI_SETTINGS,
  fromPercentageToValue,
  challengeStatuses,
} from 'pagesNewUi/post_challenge/View.Utils'
import { transformToEmbedUrl } from 'support/utils/file'
import { VZAAR_STATES } from 'common_constants/VzaarVideo'

export const transformSuggestions = jsonResponse => {
  return jsonResponse.map(user => ({
    email: user.email,
    fullName: user.fullname || '',
    photoSrc: user.photo || '',
    balance: `${user.balance}`,
    investedFunds: `${user.currentAmountInvested}`,
  }))
}
export const transformAdvancedRoi = (jsonResponse, state) => {
  const advancedGaming = state.data.challengeSteps.gaming.advancedMode
  return {
    defaultValues: {
      approveMult: jsonResponse.approveMult * 100,
      implementMult: jsonResponse.implementMult * 100,
      supportMult: jsonResponse.supportMult * 100,
      currentGoal: jsonResponse.currentGoal,
    },
    approveMult: fromPercentageToValue(jsonResponse.approveMult * 100, advancedGaming.approveMult),
    implementMult: fromPercentageToValue(
      jsonResponse.implementMult * 100,
      advancedGaming.implementMult,
    ),
    supportMult: fromPercentageToValue(jsonResponse.supportMult * 100, advancedGaming.supportMult),
    currentGoal: fromPercentageToValue(jsonResponse.currentGoal, advancedGaming.goalMult),
  }
}

export const transformImageServiceResponse = (jsonResponse, state) => {
  const { items } = jsonResponse
  const { images, mainImageValue } = state.data.challengeSteps.challengeDescription
  if (!items || (items && !items[0])) return {}
  return {
    image: {
      src: items[0].url,
      key: items[0].key,
      name: items[0].name,
    },
    mainImageValue: (images.find(image => image.src === mainImageValue) || {}).src || items[0].url,
  }
}

export const transformImageServiceResponseSaveDraft = (jsonResponse, state) => {
  const { items } = jsonResponse
  const { images, mainImageValue } = state.data.ChallengeDraft.challengeDescription
  if (!items || (items && !items[0])) return {}
  return {
    image: {
      src: items[0].url,
      key: items[0].key,
      name: items[0].name,
    },
    mainImageValue: (images.find(image => image.src === mainImageValue) || {}).src || items[0].url,
  }
}

export const mapImageItem = images => {
  if (!images) return []
  return images.map(image => ({
    src: image.url,
    key: image.key,
    name: image.name,
  }))
}

export const buildPreview = challenge => ({
  fromDate: (challenge.starts && new Date(challenge.starts)) || new Date(),
  toDate: (challenge.ends && new Date(challenge.ends)) || null,
  groupId: challenge.groupId || '',
  isGlobal: challenge.isGlobal,


    // fromDate: (challengeDraft.starts && new Date(challengeDraft.starts)) || new Date(),
    // toDate: (challengeDraft.ends && new Date(challengeDraft.ends)) || null,
    //working below
    // fromDate: challenge.starts || new Date(),
    // toDate: challenge.ends,
    // groupId: challenge.groupId || '',
    // isGlobal: challenge.isGlobal,
})

export const transformChallengeDraft = jsonResponse => {
  // console.log("in challenge.........===>  ", jsonResponse)
  const { items } = jsonResponse
  if (!items || (items && !items[0])) return {}
  const challengeDraft = items[0]
  let activeTab = 1
  let selectedSponsor = {}
  let selectedProposer = {}
  let easyModeSetting = ROI_SETTINGS.DEFAULT
  if (challengeDraft.roiDefault) {
    activeTab = 0
  } else if (challengeDraft.roiLessIdeas) {
    activeTab = 0
    easyModeSetting = ROI_SETTINGS.FEWER_IDEAS
  } else if (challengeDraft.roiMoreIdeas) {
    activeTab = 0
    easyModeSetting = ROI_SETTINGS.MORE_IDEAS
  } else if (
    !Object.hasOwnProperty.call(challengeDraft, 'roiDefault') &&
    !Object.hasOwnProperty.call(challengeDraft, 'roiLessIdeas') &&
    !Object.hasOwnProperty.call(challengeDraft, 'roiMoreIdeas')
  ) {
    activeTab = 0
  }
  const gaming = {
    activeTab,
    easyMode: {
      settings: easyModeSetting,
    },
    advancedMode: {
      approveMult: 100,
      goalMult: 100,
      implementMult: 100,
      supportMult: 100,
      currentGoal: 20000,
      defaultValues: {
        approveMult: 100,
        implementMult: 100,
        supportMult: 100,
        currentGoal: 20000,
      },
    },
  }
  if (challengeDraft.approveMult) {
    gaming.advancedMode = {
      ...gaming.advancedMode,
      approveMult: challengeDraft.approveMult * 100,
      goalMult: challengeDraft.goalMult * 100,
      implementMult: challengeDraft.implementMult * 100,
      supportMult: challengeDraft.supportMult * 100,
    }
  }
  if (challengeDraft.sponsorEmail) {
    selectedSponsor = {
      email: challengeDraft.sponsorEmail,
      fullName: challengeDraft.sponsorName,
      photoSrc: challengeDraft.sponsorPhoto || '',
    }
  }
  if (challengeDraft.proposerEmail) {
    selectedProposer = {
      email: challengeDraft.proposerEmail,
      fullName: challengeDraft.proposerName,
      photoSrc: challengeDraft.proposerPhoto || '',
    }
  }
  const preview = buildPreview(challengeDraft)
  // console.log("previe res",preview)
  return {
    id: challengeDraft.id,
    createDate:challengeDraft.created || '',
    challengeDescription: {
      title: challengeDraft.title || '',
      whyImportant: challengeDraft.importance || '',
      description: challengeDraft.desc || '',
      images: mapImageItem(challengeDraft.images),
      mainImageValue: challengeDraft.mainPhoto || '',
      externalVideo: transformToEmbedUrl(challengeDraft.videoURL) || '',
      fileVideoId: (challengeDraft.video && challengeDraft.video.id) || '',
      fileVideo: (challengeDraft.video && challengeDraft.video.playerUrl) || '',
      isVideoReady: challengeDraft.video && challengeDraft.video.state === VZAAR_STATES.READY,
      isVideoLoading: false,
      isVideoRemoving: false,
      hasVideoError: challengeDraft.video && challengeDraft.video.state === VZAAR_STATES.FAILED,
    },
    reward: {
      image: challengeDraft.rewardImage
        ? {
            src: challengeDraft.rewardImage.url || '',
            key: challengeDraft.rewardImage.key || '',
            name: challengeDraft.rewardImage.name || '',
          }
        : {},
      rewarded: challengeDraft.rewardFor || '',
      description: challengeDraft.rewardDesc || '',
      rewardedOther: challengeDraft.rewardOther || '',
    },
    selectedSponsor,
    selectedProposer,
    gaming,
    preview,
    // fromDate: challengeDraft.starts ||  new Date(),
    // toDate: challengeDraft.ends || null,
    status: challengeDraft.status || '',
    isActive: challengeDraft.status === challengeStatuses.ACTIVE,
    isProposed: challengeDraft.status === challengeStatuses.PROPOSED,
  }
}


export const transformDeleteImageResponse = jsonResponse => {
  const { items } = jsonResponse
  if (!items || (items && !items[0])) return {}
  return {
    images: mapImageItem(items[0].images),
    mainImageValue: items[0].mainPhoto,
  }
}

export const transformMainImageServiceResponse = jsonResponse => {
  const { items } = jsonResponse
  if (!items || (items && !items[0])) return ''
  return items[0].url
}

export const transformGroupsResponse = jsonResponse => {
  const { data } = jsonResponse
  if (!data) {
    return {
      defaultGroups: [],
      rightGroups: [],
    }
  }
  return data.map(group => ({
    name: group.name,
    id: group.id,
    category: group.category,
    checked: false,
  }))
}

export const transformUploadVideoResponse = jsonResponse => {
  return {
    fileVideoSrc: jsonResponse.playerUrl,
    fileVideoId: jsonResponse.id,
    fileVideoStatus: jsonResponse.status,
  }
}

export const transformChallengeSelectedGroupsResponse = jsonResponse => {
  const { data } = jsonResponse
  if (Array.isArray(data)) {
    return data.map(group => ({
      name: group.name,
      id: group.id,
      category: group.category,
      checked: false,
    }))
  } 
  // else {

    let convertObjectToArray = [jsonResponse]
    return convertObjectToArray.map(group => ({
      name: group.name,
      id: group.id,
      category: group.category,
      checked: false,
    }))
  // }
}
