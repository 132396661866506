import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import * as PropTypes from 'prop-types'
import HeaderButtons from './components/header_buttons/View'
import HeaderLogo from './components/header_logo/View'
import HeaderUserBalance from './components/header_user_balance/View'
import HeaderUserLogo from './components/header_user_logo/View'
import HeaderDomains from './components/header_domains/View'
import styles from './View.Style'
import Button from '@material-ui/core/Button'

const Header = props => {
  const {
    classes,
    logoUrl,
    navigateToExplore,
    navigateToMyIdeas,
    navigateToMyChallenge,
    navigateToInvestments,
    navigateToChallenges,
    notifications,
    userBalance,
    userName,
    userPhoto,
    showAdmin,
    markNotificationsRead,
    domains,
    navigateToDomain,
    onLogoClick,
    navigateToAboutus,
    navigateToNovaResearch,
    submitYourIdea,
    isPrivate

  } = props



  return (
    <div className={classes.root}>
      <div className={classes.nav}>
        <HeaderLogo logoUrl={logoUrl} onLogoClick={onLogoClick} />
        <HeaderDomains navigateToDomain={navigateToDomain} domains={domains} />
        <HeaderButtons
          navigateToExplore={navigateToExplore}
          navigateToMyIdeas={navigateToMyIdeas}
          // // navigateToInvestments={navigateToInvestments}
          navigateToAboutus= {navigateToAboutus}
          navigateToNovaResearch={navigateToNovaResearch}
          navigateToChallenges={navigateToChallenges} 
        />
        <div className={classes.submit}>
          <HeaderUserBalance userBalance={userBalance} userName={userName} />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => submitYourIdea(isPrivate)}
            >
              Submit Idea
            </Button>
        </div>
        <HeaderUserLogo
          navigateToExplore={navigateToExplore}
          navigateToMyIdeas={navigateToMyIdeas}
          navigateToInvestments={navigateToInvestments}
          navigateToChallenges={navigateToChallenges}
          notifications={notifications}
          showAdmin={showAdmin}
          userPhoto={userPhoto}
          markNotificationsRead={markNotificationsRead}
        />
      </div>
    </div>
  )
}

Header.defaultProps = {
  logoUrl: '',
  userPhoto: '',
}

Header.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
  logoUrl: PropTypes.string,
  notifications: PropTypes.shape({
    notifications: PropTypes.arrayOf(PropTypes.shape({})),
    activities: PropTypes.arrayOf(PropTypes.shape({})),
    totalUnread: PropTypes.number,
    unreadActivities: PropTypes.number,
    unreadNotifications: PropTypes.number,
  }).isRequired,
  userBalance: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  userPhoto: PropTypes.string,
  showAdmin: PropTypes.bool.isRequired,
  domains: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  navigateToDomain: PropTypes.func.isRequired,
  markNotificationsRead: PropTypes.func.isRequired,
  submitYourIdea: PropTypes.func.isRequired,
}

export default withStyles(styles)(Header)
