
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Challenge from 'components_newui/challenge_card/View'
import styles from './View.Style'
import Breadcrumbs from 'components_newui/pageBreadcrumbsMenu/View'
import noDraftChallangee from 'img/no_Draft_challangee.png'
import { Link } from 'react-router-dom'
import FilterComponent from '../../components/filter_component/View'
import i18n from 'support/i18n'
import { Container, FormControl, MenuItem, Select } from '@material-ui/core'
import navigation from '../../support/navigation'

const ACTIVE_CHALLENGES = 'ACTIVE'
const EXPIRED_CHALLENGES = 'FINISHED'

const MyChallenes = props => {

    const {
        draftChallengesProps,
        classes,
        challenges,
        isAdmin,
        canProposeChallenge,
        isInnovator,
        localAdmin,
        navigateToChallengeDetail,
        getFilteredChallenges,
        getActiveChallenges,
        getChallengesAll,
        navigateToExplore
    } = props
    const [challengesAll, setChallenges] = useState([])
    const [showExpiredChallenges, setShowExpiredChallenges] = useState(false)
    const [activeFilters, setActiveFilters] = useState({
        status: [],
    })
    const [nameQuery, setNameQuery] = useState('')
    const [challengeStatus, setChallengeStatus] = useState(ACTIVE_CHALLENGES)
    const [challengeData, setChallengeData] = useState(challenges)
    const [draftChallenges,setDraftChallenges] = useState([draftChallengesProps])
    
    const pageHierarchy = [
        { string: "Home", navigate: navigateToExplore },
        { string: "My challenges", navigate: () => {} }
    ]

    useEffect(() => {
        setChallenges(getChallengesAll)
    }, [])

    useEffect(() => {
        // const { getActiveChallenges, getFilteredChallenges } = props
        // showExpiredChallenges ? getFilteredChallenges(EXPIRED_CHALLENGES) : getActiveChallenges()
        
        if(showExpiredChallenges){
            getFilteredChallenges(EXPIRED_CHALLENGES)
        }else{
            getActiveChallenges()
        }
    }, [showExpiredChallenges])


    // const unselected_style = {
    //     border: '1px solid #6551FF',
    //     backgroundColor: '#FFFFFF',
    //     color: '#333333',
    //     cursor: 'pointer'
    // }

    useEffect(() => {
        setChallengeData(challenges)
    }, [challenges])

    const handleFilterChange = event => {
        const status = event.target.value
        setChallengeStatus(status)
    }

    useEffect(() => {
        getFilteredChallenges(challengeStatus)
    }, [challengeStatus])


    const reDirectToDraftChallenge = () =>{
        navigation.navigateToChallengeDraft()
    }


    return (
        <>
         <Breadcrumbs crumbs={pageHierarchy} />
            <Container>

                <main className={classes.background}>

                    <div className={classes.mywrp}>
                        <h3 className={classes.myText}>My Challenges ({challengeData.length})</h3>
                        <Link to='#' onClick={reDirectToDraftChallenge} className={classes.draftText}>Draft Challenge ({
                        draftChallenges[0].challengeDescription && draftChallenges[0].challengeDescription.title !== '' ? 
                        draftChallenges.length : '0'
                        
                        })</Link>
                    </div>
                    
                    <div className={classes.mychallengeFilter}>
                        <FilterComponent
                            activeFilters={activeFilters}
                            serviceLineOrgs={[]}
                            types={[]}
                            ideaStatusFilter={false}
                            handleActiveFilters={setActiveFilters}
                            search={nameQuery}
                            setSearch={setNameQuery}
                            applySearch={() => setChallengeData(challenges.filter(item => (
                                item.name.toLowerCase().includes(nameQuery)
                            )))}
                            resetSearch={() => { setNameQuery(''); setChallengeData(challenges) }}
                        />

                        <div className={classes.filterContainer}>
                            <span>{i18n.t('CHALLENGES.FILTER.SHOW')}: </span>

                            <form>
                                <select value={challengeStatus} onChange={handleFilterChange} className={classes.filterSelect}>
                                    <option value="ACTIVE">{i18n.t('CHALLENGES.FILTER.ACTIVE')}</option>
                                    {(isAdmin || isInnovator || localAdmin) && [
                                        <option key="PROPOSED" value="PROPOSED">
                                            {i18n.t('CHALLENGES.FILTER.PROPOSED')}</option>,
                                        <option key="PLANNED" value="PLANNED">
                                            {i18n.t('CHALLENGES.FILTER.COMING_SOON')}</option>,
                                        <option key="DELETED" value="DELETED">
                                            {i18n.t('CHALLENGES.FILTER.DELETED')}</option>
                                    ]}
                                    <option value="FINISHED">{i18n.t('CHALLENGES.FILTER.FINISHED')}</option>
                                </select>
                            </form>

                        </div>

                    </div>
                    {
                        (challengeData && challengeData.length === 0) ?

                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <img className={classes.noIdeaImg} alt='no draft challenge' src={noDraftChallangee} />
                                <p className={classes.noIdeas}>No existing <span className={classes.purpleNo}>Challenges</span></p>
                            </div>
                            :
                            <Grid container spacing={2} className={classes.vf_container_flexbox}>
                                {challengeData.map((challenge,i) => (
                                    <Grid key={challenge.id} item className={classes.challItem}>
                                        <Challenge  myChallengeHide={true} myChallenge={true} challenge={challenge} urlID={challenge.id} onClick={() => navigateToChallengeDetail(challenge.id)} />
                                    </Grid>
                                ))}
                            </Grid>

                    }


                </main>
            </Container>
        </>
    )
}

export default withStyles(styles)(MyChallenes)