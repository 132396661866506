import React, {  Component } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Button } from '@material-ui/core'
import i18n from 'support/i18n'
import AutoSuggestion from 'components/autosuggestion/View'
import SuggestionSelected from 'components/suggestion_selected/View'
import styles from './View.Style'

class InviteeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showAddMemberButton: true,
      selectedSuggestion: {},
    }
    this.handleSendInvitation = this.handleSendInvitation.bind(this)
  }

  handleSendInvitation(email) {
    const { pendingInvitationsUsers, onSendInvitationClick } = this.props
    const alreadyPending = pendingInvitationsUsers.find(user => user.email === email)
    if (!alreadyPending) {
      this.setState({
        selectedSuggestion: {},
      })
      onSendInvitationClick(email)
    }
  }

  render() {
    const {
      classes,
      onSearchSuggestions,
      suggestions,
      inputLabel,
      inputHelpText,
      isSearchingForUser,
    } = this.props
    const { showAddMemberButton, selectedSuggestion } = this.state
    return (
    
        <div className={classes.root}>
          {showAddMemberButton && (
            
              <Button
                id="add-member-button"
                variant="contained"
                color="primary"
                onClick={() => this.setState({ showAddMemberButton: false })}
              >
                {i18n.t('IDEA.ADD_MEMBER')}
              </Button>
            
          )}
          {!showAddMemberButton && (
            <>
              {selectedSuggestion.fullName && (
                <SuggestionSelected
                  suggestion={selectedSuggestion}
                  onRemoveSuggestion={() =>
                    this.setState({
                      selectedSuggestion: {},
                    })
                  }
                />
              )}
              {!selectedSuggestion.fullName && (
                <>
                  {inputHelpText && (
                    <div id="input-help-text" className={classes.helpText}>
                      {inputHelpText}
                    </div>
                  )}
                  <AutoSuggestion
                    classes={{ root: classes.autosuggestionContainer }}
                    isLoading={isSearchingForUser}
                    inputLabel={inputLabel}
                    onSuggestionSelected={suggestion =>
                      this.setState({
                        selectedSuggestion: suggestion,
                      })
                    }
                    getSuggestions={onSearchSuggestions}
                    suggestions={suggestions}
                  />
                </>
              )}
              <div className={classes.actionButtonsContainer}>
                <Button
                  id="send-invitation-button"
                  variant="contained"
                  color="primary"
                  disabled={!selectedSuggestion.fullName}
                  onClick={() => this.handleSendInvitation(selectedSuggestion.email)}
                >
                  {i18n.t('IDEA.SEND_INVITATION')}
                </Button>
                <Button
                  id="cancel-button"
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    this.setState({
                      showAddMemberButton: true,
                      selectedSuggestion: {},
                    })
                  }
                >
                  {i18n.t('IDEA.CANCEL')}
                </Button>
              </div>
            </>
          )}
        </div>
      
    )
  }
}

InviteeForm.defaultProps = {
  inputHelpText: '',
}

InviteeForm.propTypes = {
  isSearchingForUser: PropTypes.bool.isRequired,
  inputLabel: PropTypes.string.isRequired,
  inputHelpText: PropTypes.string,
  onSearchSuggestions: PropTypes.func.isRequired,
  onSendInvitationClick: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      photoSrc: PropTypes.string,
      email: PropTypes.string.isRequired,
    }),
  ).isRequired,
  pendingInvitationsUsers: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      photoSrc: PropTypes.string,
      email: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default withStyles(styles)(InviteeForm)
