/* eslint-disable jam3/no-sanitizer-with-danger */

import React from 'react'
//import * as PropTypes from 'prop-types'
import clsx from 'clsx'
import withStyles from '@material-ui/core/styles/withStyles'
import { Paper } from '@material-ui/core'
import styles from '../status_section/View.Style'

const AdminFeedback = props => {
  const { classes, messageText, boardDecision, pitchDecision } = props
  return (
    <Paper
      className={clsx(classes.messagePaper, classes.messagePaperDiscarded)}
      //id={isApprovedStatus ? 'approved-paper' : 'discarded-paper'}
    >
      {/* {messageTitle && <span className={classes.messageTitle}>{messageTitle}</span>} */}
      <span className={classes.messageTitle}>Board Decision: {boardDecision}</span>
      <span className={classes.messageTitle}>Pitch Decision: {pitchDecision}</span>
      <span className={classes.messageTitle}>Feedback from the NOVA team:</span>
      {/* <span className={classes.messageText}>{messageText}</span> */}
      <span dangerouslySetInnerHTML={{ __html: messageText }} className={classes.messageText} />
    </Paper>
  )
}

// AdminFeedback.propTypes = {
//   messageText: PropTypes.string.isRequired,
// }

export default withStyles(styles)(AdminFeedback)
