import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import Slider from 'components/slider/View'
import { Image } from 'components/index'
import i18n from 'support/i18n'
import NxtUserCard from 'components/nxt_user_card/View'
import imgDefault from 'img/nxt-inverted-image.png'
import styles from './View.Style'

class ResumeSlider extends Component {
  constructor(props) {
    super(props)
    const slides = [...props.images]
    if (props.videoSrc) {
      slides.push({
        isVideo: true,
        src: props.videoSrc,
      })
    }
    if (slides.length === 0) {
      slides.push({
        src: '',
      })
    }
    this.state = {
      activeStep: 0,
      slides,
    }
    this.renderSlide = this.renderSlide.bind(this)
  }
  
  componentDidUpdate(prevProps) {
    const { videoSrc, images } = this.props
    if (prevProps.videoSrc !== videoSrc) {
      const slides = [...images]
      if (videoSrc) {
        slides.push({
          isVideo: true,
          src: videoSrc,
        })
      }
      if (slides.length === 0) {
        slides.push({
          src: '',
        })
      }
      this.setState({ slides })
    }
  }

  renderSlide(itemSlide) {
    const { classes } = this.props
    if (itemSlide.isVideo) {
      return (
        <div className={classes.iframeVideo} key={itemSlide.src}>
          <iframe
            title="ytVideo"
            width="500"
            height="310"
            src={itemSlide.src}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      )
    }
    return (
      <Image defaultSrc={imgDefault} src={itemSlide.src} alt={itemSlide.name} key={itemSlide.src} />
    )
  }

  render() {
    const { classes, selectedProposer } = this.props
    const { activeStep, slides } = this.state
    const maxSteps = slides.length
    return (
      <div className={classes.root}>
        {selectedProposer.fullName && (
          <div className={classes.overlay}>
            <NxtUserCard
              label={i18n.t('CHALLENGES.DETAIL.PROPOSER')}
              imageSrc={selectedProposer.photo}
              usernameElement={<span>{selectedProposer.fullName}</span>}
              isTextInverted
              imageSize="2.2rem"
            />
          </div>
        )}
        <Slider
          activeStep={activeStep}
          maxSteps={maxSteps}
          setActiveStep={newStep => this.setState({ activeStep: newStep })}
        >
          {slides.map(slide => {
            return this.renderSlide(slide)
          })}
        </Slider>
      </div>
    )
  }
}

ResumeSlider.defaultProps = {
  classes: {
    root: '',
    iframeVideo: '',
    overlay: '',
  },
  images: [],
  videoSrc: '',
  selectedProposer: {},
}
ResumeSlider.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
    }),
  ),
  videoSrc: PropTypes.string,
  selectedProposer: PropTypes.shape({}),
}

export default withStyles(styles)(ResumeSlider)
