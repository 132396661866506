// import prototyping_img from 'img/prototyping.png'
// import implemented_img from 'img/implemented.png'
// import seeking_funding_img from 'img/seeking_funding.png'
// import pending_review_img from 'img/pending_review.png'
// import prototype_completed_img from 'img/prototype_completed.png'

/* eslint-disable  object-shorthand,  no-fallthrough */

import DiscardIcon from 'img/delete-hi-dark.png'
import ApproveIcon from  'img/icons/completed.png'
import implementIcon from  'img/icons/implement.png'
import pendingReviewIcon from  'img/icons/pending-review.png'
import prototypeIcon from  'img/icons/prototype.png'
import prototypecompleteIcon from  'img/icons/prototype-complete.png'
import seekingFundIcon from  'img/icons/seeking-fund.png'

import statusConstants from 'common_constants/idea_states_constants'
import ChallengeStatus from 'common_constants/ChallengeStatus'
const { PENDING_SUPPORT, PENDING_VALIDATION , APPROVED_BY_IT, SEEKING_FUNDING, IMPLEMENTED, PILOT_ACTIVE, SUPPORTED, DRAFT,DISCARDED,ROUND_CLOSED} = statusConstants

const {
    PROPOSED,
    PLANNED,
    ACTIVE,
    FINISHED,
    DELETED,
    ALL
} = ChallengeStatus


export const stateDictionary = {
    [PENDING_SUPPORT]: 'Seeking Funding',
    [PENDING_VALIDATION]: 'Pending Validation',
    [SEEKING_FUNDING]: 'Funded',
    [IMPLEMENTED]: 'Implemented',
    [PILOT_ACTIVE]: 'Pilot Project',
    [DRAFT]: 'Draft',
    [APPROVED_BY_IT]: 'Approved',
    [DISCARDED]: 'Discarded',
    [ROUND_CLOSED]:'Round closed'
}


export const getStyledStatus = status => {
    let statusImg = pendingReviewIcon
    let color = "#00B0CA"
    switch(status){
        case APPROVED_BY_IT :
            statusImg = ApproveIcon
            color = "#E60000"
            break
        case PENDING_SUPPORT:
            statusImg = pendingReviewIcon
            color = "#9C2AA0"
            break
        case SEEKING_FUNDING:
            statusImg = seekingFundIcon
            color = "#EB9700"
            break
        case DISCARDED:
            statusImg =  DiscardIcon
            color = '#F63F40'
            break
        case IMPLEMENTED:
            statusImg = implementIcon
            color = "#428600"
            break
        case PILOT_ACTIVE:
            statusImg = prototypeIcon
            color = "#00B0CA"
            break
        case SUPPORTED:
            statusImg = prototypecompleteIcon
            color = "#FF5400"
         default:
            statusImg = pendingReviewIcon
            color = "#9C2AA0"
            break
    }

    const name = Object.keys(stateDictionary).includes(status) ? stateDictionary[status] : status
    return({name: name, image:statusImg, color: color})
}



export const getChallengeStatus = status => {
    let statusImg = pendingReviewIcon
    let color = "#00B0CA"
    switch(status){
        case ACTIVE :
            color = "#ABB400"
            statusImg = seekingFundIcon
            break
        case PROPOSED:
            color = "#EB9700"
            statusImg = pendingReviewIcon
            break
        case PLANNED:
            color = "#EB9700"
            statusImg = implementIcon
            break
        case DELETED:
            color = "#E60000"
            statusImg = prototypeIcon
            break
        case FINISHED:
            color = "#9C2AA0"
            statusImg = prototypecompleteIcon
            break;
        default:
            color = "#EB9700"
            statusImg = implementIcon
            break
    }

    const name = Object.keys(ChallengeStatus).includes(status) ? status : status
    // console.log('obj serv',name)
    return({name: name, image: statusImg, color: color})
}
