import React from 'react'
import defaultPhoto from 'img/default-photo.png'

export const UserAvtar = ({ userPhoto, classes }) => {
    return (
        <img
            src={userPhoto}
            className={classes.profilePic}
            onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = defaultPhoto
            }}
            alt="User Profile"
        />
    )
}
