 /* eslint-disable   no-unneeded-ternary, no-shadow */
 import { withStyles, styled } from '@material-ui/core/styles'
import styles,{ myTheme }  from './View.Style'
import closeIcon from '../../../../img/ic_close.png'
import React, { Fragment, useEffect, useState } from 'react'
import { Checkbox, FormControl, ListItemText, MenuItem,Input, Select, ThemeProvider } from '@material-ui/core'



const MultiSelectFilter = props => {
    
    const {classes, label, items, onChange, value, checkFunction,renderValue, clearAll} = props
    
    return(
        <div className={classes.container}>
            <p className={classes.label}>{label}</p>
            <div className={classes.select_container}>
                
                <FormControl style={{
                    width: "90%",
                    alignSelf: "center",
                    border: "none",
                    paddingLeft: "10px"
                    }}>
                    <ThemeProvider theme={myTheme}>
                    <Select
                        disableUnderline
                        
                                           
                        input={<Input />}
                        displayEmpty
                        multiple
                        MenuProps={{
                            getContentAnchorEl: null,
                            disableScrollLock: false,
                            variant: "menu",
                        }}
                        value={value ? value : []}
                        onChange={onChange}
                        placeholder='Select'
                        renderValue={value => value.length < 1 ? <p style={{color: "#999999", margin: "0", padding: "0"}}>Select</p> : ""}
                    >   
                    {/* {console.log('items --',items)} */}
                     
                        {items.map(item => {
                            const checked = checkFunction(value, item)
                                return (
                                    <MenuItem key={item.name} value={ item.id || item.name }>
                                        <Checkbox checked={checked} />
                                        <ListItemText primary={item.name} />
                                    </MenuItem>
                                )
                            }   
                        )}
                    </Select>
                    </ThemeProvider>
                </FormControl>

                {value.length > 0 && <div role="button" tabIndex="0" className={classes.selected_service_lines} onClick={clearAll} >
                    <p>{value.length} Selected</p>
                    <img src={closeIcon} className={classes.close_icon} alt='cloise icon' />
                </div>}
            </div>
        </div>
        
    )
}

export default withStyles(styles)(MultiSelectFilter)