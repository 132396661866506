/* eslint-disable import/no-cycle,react/state-in-constructor */

import React, { Component } from 'react'
import { Image } from 'components'
import withStyles from '@material-ui/core/styles/withStyles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Card from '@material-ui/core/Card'
import IdeaLifeCycle1 from './img/Idea_Submission.png'
import IdeaLifeCycle2 from './img/Idea_Approval.png'
import IdeaLifeCycle3 from './img/The_Pitch.png'
import IdeaLifeCycle4 from './img/Prototyping.png'
import IdeaLifeCycle5 from './img/Review.png'
import IdeaLifeCycle6 from './img/Demand_and_Delivery.png'
import styles from './View.Style'

class VerticalTab extends Component {
  state = {
    value: 0,
  }

  handleChange = (event, value) => {
    this.setState({ value })
  }

  render() {
    const { classes } = this.props
    const { value } = this.state
    return (
      <Card className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={this.handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab label="Idea Submission" />
          <Tab label="Idea Approval" />
          <Tab label="The Pitch" />
          <Tab label="Prototyping" />
          <Tab label="Review" />
          <Tab label="Demand & Delivery" />
        </Tabs>
        {value === 0 && <Image src={IdeaLifeCycle1} defaultSrc={IdeaLifeCycle1} alt="idea submission" />}
        {value === 1 && <Image src={IdeaLifeCycle2} defaultSrc={IdeaLifeCycle2} alt="idea approval" />}
        {value === 2 && <Image src={IdeaLifeCycle3} defaultSrc={IdeaLifeCycle3} alt="the pitch" />}
        {value === 3 && <Image src={IdeaLifeCycle4} defaultSrc={IdeaLifeCycle4} alt="prototyping" />}
        {value === 4 && <Image src={IdeaLifeCycle5} defaultSrc={IdeaLifeCycle5} alt="review" />}
        {value === 5 && <Image src={IdeaLifeCycle6} defaultSrc={IdeaLifeCycle6} alt="demand" />}
      </Card>
    )
  }
}

export default withStyles(styles)(VerticalTab)
