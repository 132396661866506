const side_margin = '85px'

const styles = theme => ({
    background:{
        // backgroundColor: "#fbfaff", //body should have this background color in the main .css for the new site design 
        // padding: "16px 85px 32px 85px",
        fontFamily: 'VodafoneRg-Regular',
    },
    status:{
        color: '#FFFFFF',
        marginLeft: '0px',
        minWidth: 'auto',
        height: '18px',
        fontFamily: 'VodafoneRg-Bold',
        fontSize: '14px',
        borderRadius: '35px',
        // marginBottom: '0px',
        display: 'inline-flex',
        alignItems: 'center',
        padding:'8px 16px',
        marginBottom:'1.125rem',
        '& img':{
            margin: '2px 8px',
            width: '9px',
            // float: 'left',
        },
        '@media (max-width:480px)':{
            fontSize:'12px',
            padding:'4px 14px'
        },
        '@media (min-width:481px) and (max-Width:767px)':{
            fontSize:'12px',
            padding:'4px 14px'
        },
        

    
    },
    statusButton: {
        height: '24px',
        borderRadius: '10px',
        fontSize: '14px',
        color: '#FFFFFF',
        fontFamily: 'VodafoneRg-Bold',
        padding: '3px 8px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '16px',
        cursor: 'pointer',
    },
    title:{
        fontSize: '32px',
        color: '#333333',
        width: '650px',
        '@media (max-width:480px)':{
            fontSize:'16px',
            width:'100%'
        },
        '@media (min-width:481px) and (max-Width:767px)':{
            fontSize:'18px',
            width:'100%'
        },
        '@media (min-width:768px) and (max-Width:1024px)':{
            fontSize:'26px',
            width:'100%'
        },
        
    },
    respondBtn: {
        width: '192px',
        height: '44px',
        backgroundColor: '#333333',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#FFFFFF',
        fontSize: '18px',
        borderRadius: '22px',
        position: 'absolute',
        bottom: 0,
        right: 0,
        cursor: 'pointer',
        border:'0',
        
        '@media (max-width:480px)':{
            fontSize:'12px',
            width:'140px',
            position:'inherit',
            height:'28px'
        },
        '@media (min-width:481px) and (max-Width:767px)':{
            fontSize:'14px',
            width:'140px',
            position:'inherit',
            height:'28px'
        }, 
        '@media (min-width:768px) and (max-Width:1024px)':{
            fontSize:'14px',
            width:'140px',
            position:'inherit',
            height:'28px'
        }
    },
    btnDisabled:{
        cursor:'default',
        'pointer-events':'none',
        color:'rgba(0, 0, 0, 0.26)',
        boxShadow: 'none',
        backgroundColor:' rgba(0, 0, 0, 0.12)',
        border:'0px'
    },
    imageInfoPanel:{
        paddingBottom: '42px',
        marginTop: '16px',
        display: 'flex',
        '@media (max-width:1024px)':{
            display:'block'
        },
    },
    imagePanel:{
        width: '680px',
        height: '290px',
        '@media (max-width:480px)':{
           width:'100%',
           height:'170px'
        },
        '@media (min-width:481px) and (max-Width:767px)':{
            width:'100%',
            height:'230px',
            marginBottom:'8px'
        },
        '@media (min-width:768px) and (max-Width:1024px)':{
            width:'100%'
        },
        '@media (min-width:1025px) and (max-Width:1199px)':{
            width:'50%'
        }
        
    },
    images: {
        // objectFit: "cover",
        objectPosition: "center top",
        width: '604px',
        height: '290px',
        borderRadius: '10px',
        '@media (max-width:480px)':{
            width:'100%',
            height:'170px'
        },
        '@media (min-width:481px) and (max-Width:767px)':{
            width:'100%',
            height:'230px'
        },
        '@media (min-width:768px) and (max-Width:1024px)':{
            width:'100%'
        },
        '@media (min-width:1025px) and (max-Width:1199px)':{
            width:'100%'
        }
    },
    infoPanel: {
        height: '290px',
        // width: '480px',
        position: 'absolute',
        right: 0,
        width: '95%',
        border: '1px solid #CCCCCC',
        fontFamily: 'VodafoneRg-Regular',
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
        '@media (max-width:480px)':{
            width:'100%',
            position:'inherit',
            height:'auto',
            marginTop:'8px',
        },
        '@media (min-width:481px) and (max-Width:767px)':{
            width:'100%',
            height:'auto',
            position:'inherit'
        },
        '@media (min-width:768px) and (max-Width:1024px)':{
            width:'100%',
            height:'auto',
            position:'inherit',
            marginTop:'16px'
        }
    },
    infoGrid:{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridRowGap: '16px',
        margin: '22px',
        marginBottom: '33px',
        '@media (max-width:480px)':{
            margin:'8px'
        },
        '& div': {
            display: 'flex',
            justifyContent: 'center',
            
        }
    },
    infoGrid2:{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr ',
        gridRowGap: '16px',
        margin: '22px',
        marginBottom: '33px',
        '& div': {
            display: 'flex',
            justifyContent: 'center'
        }
    },
    infoPanelHeaders: {
        color: '#646464',
        fontSize: '18px',
        '@media (max-width:480px)':{
            fontSize:'14px'
        }
    },
    infoPanelIcons: {
        flexDirection: 'column',
        alignItems: 'center',
        
    },
    icon_img: {
        height: '44px',
        '@media (max-width:480px)':{
            height: '38px',
        }
    },
    infoPanelNumber: {
        color: '#6D45FF',
        fontSize: '32px',
        fontFamily: 'VodafoneRg-Bold',
        paddingTop: '8px',
        paddingBottom: '25px',
        '@media (max-width:480px)':{
            fontSize:'16px'
        }
    },
    sponsoredSubmitted: {
        margin: '24px 16px 24px 16px',
        color: '#666666',
        fontSize: '16px',
        '@media (max-width:480px)':{
            margin:'16px',

        }
    },
    nameDateSpan: {
        fontFamily: 'VodafoneRg-Bold',
        color: '#333333',
        fontSize: '18px',
        '@media (max-width:480px)':{
            fontSize:'14px'
        }
    },
    no_details_header: {
        fontFamily: 'VodafoneRg-Bold',
        color: '#6D45FF',
        fontSize: '32px',
        paddingTop: '8px',
        '@media (max-width:480px)':{
            fontSize:'16px'
        }
    },
    reward_panel: {
        width: '100%',
        // height: '84px',
        background: '#FFFFFF',
        boxSizing: 'border-box',
        padding:'10px',
        borderRadius: '10px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        marginBottom: '32px',
        '@media (max-width:1024px)':{
            display:'block',
            height:'auto'
        },
        // ['@media (min-width:768px) and (max-width:992px) ']:{
        //     display:'block',
        //     height:'auto'
        // }
    },
    inner_reward_panel: {
        padding: '0px 16px',
        display: 'flex',
        '@media (max-width:1024px)':{
            marginBottom:'8px',
            borderRight:'0px !important'
        }
    },
    reward_info: {
        fontFamily: 'VodafoneRg-Regular',
        fontSize: '16px',
        color: '#646464',
        '@media (max-width:480px)':{
            fontSize:'12px'
        }
    },
    reward_header: {
        fontFamily: 'VodafoneRg-Bold',
        color: '#333333',
        fontSize: '16px',
        paddingBottom:"4px",
        '@media (max-width:480px)':{
            fontSize:'14px'
        }
    },
    wrp:{
        marginBottom:'2rem'
    },
    header: {
        fontFamily:theme.fontFamily.fontFamilyRegular,
        fontSize: theme.fontSize.fontsize32,
        color: '#333333',
        paddingTop:theme.spacing(2),
        paddingBottom:theme.spacing(4),
        '@media (max-width:480px)':{
            fontSize:'16px',
            paddingBottom:theme.spacing(2),
        },
        '@media (min-width:481px) and (max-width:767px)':{
            fontSize:'20px',
            paddingBottom:theme.spacing(2),
        }
    },

    sub_header: {
        fontFamily:theme.fontFamily.fontFamilyRegular,
        fontSize: theme.fontSize.fontsize24,
        color: '#333333',
        margin: '16px 0px',
        '@media (max-width:480px)':{
            fontSize:'14px'
        },
        '@media (min-width:481px) and (max-width:767px)':{
            fontSize:'18px'
        }
    },
    challengeDesc_question:{
        fontFamily:theme.fontFamily.fontFamilyRegular,
        fontSize: theme.fontSize.fontsize24,
        color:theme.palette.Text.TextBlack333,
        lineHeight:theme.lineHeight.lineHeight32,
        fontWeight:'normal',
        margin:theme.spacing(0),
        '@media (max-width:480px)':{
            fontSize:'14px'
        },
        '@media (min-width:481px) and (max-width:767px)':{
            fontSize:'18px'
        },
    },
    challengeDesc_ans:{
        fontFamily:theme.fontFamily.fontFamilyRegular,
        color:theme.palette.Text.TextBlack333,
        fontSize: theme.fontSize.fontsize16,
        lineHeight:theme.lineHeight.lineHeight24,
        marginBottom:'0px',
        '@media (max-width:480px)':{
            fontSize:'12px'
        },
        '@media (min-width:481px) and (max-width:767px)':{fontSize:'16px'}
    },
    paragraph: {
        marginTop: '16px',
        fontSize: '16px',
        '@media (max-width:480px)':{
            fontSize:'14px'
        },
    },
    showMore: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '16px',
        color: '#646464',
        margin: '16px 0px',
        '& img' : {
            height: '10px',
            marginLeft: '4px',
        },
        '@media (max-width:480px)':{
            fontSize:'12px'
        }
    },
    chevron_down: {
        transform: 'rotate(180deg)',
        '-webkit-transform': 'rotate(180deg)',
        '-moz-transform': 'rotate(180deg)',
    },
    horizontal_line:{
        width: '100%',
        height: '1px',
        backgroundColor: '#CCCCCC',
        margin: '32px 0px'
    },
    idea_grid: {
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        alignItems: "center",
        justifyItems: "center",
        '@media (max-width:1250px)': { 
            gridTemplateColumns: "repeat(3, 1fr)",
        },
        '@media (max-width:1050px)': { 
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        '@media (max-width:720px)': { 
            gridTemplateColumns: "1fr",
        },
    },
    no_idea_img: {
        height: '210px',
        width: '288px',
        marginBottom: '20px',
        '@media (max-width:720px)': { 
           
            height: '110px',
            width: '140px',
        },
    },
    no_idea_title: {
        textAlign: 'center',
        fontSize: '32px',
        color: '#333333',
        fontFamily: 'VodafoneRg-Regular',
        '@media (max-width:720px)': { 
            fontSize:'16px'
        }
    },
    extracontent:{width:'100%',paddingLeft:'1rem'},
    extraBenefitWrp:{
        display:'flex',
        alignItems:'flex-start',
        padding:'0px 16px',
        '@media (max-width:1024px)':{
            borderRight:'0px !important',
            marginBottom:'8px'
        }
    },
    extraList:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    icons_eb:{
        width:'48px'
    },
    extraItem:{
        textAlign:'center',
    },
    extraBenefitValue:{
        fontSize:theme.fontSize.fontsize20,
        fontFamily:theme.fontFamily.fontFamilyRegular,
        color:theme.palette.BlueText.TextBlue,
        margin:theme.spacing(1),
        '@media (max-width:480px)':{
            fontSize:'14px'
        }
    },
    extraBenefitName:{
        fontSize:theme.fontSize.fontsize16,
        fontFamily:theme.fontFamily.fontFamilyRegular,
        color:theme.palette.Text.TextBlack333,
        margin:'0',
        '@media (max-width:480px)':{
            fontSize:'12px'
        }
    },
    tagwrp:{
        paddingBottom:theme.spacing(4),
        paddingTop:theme.spacing(2)
    },
    tagsfilter:{
        paddingBottom:theme.spacing(4),
        // paddingTop:theme.spacing(4)
    },
    nofoundwrp:{
        paddingBottom:theme.spacing(4),
        paddingTop:theme.spacing(4),
        display: 'flex', 
        alignItems: 'center', 
        flexDirection: 'column'
    },
    btnBlank:{
        backgroundColor:'transparent',
        border:'0',
        padding:'0',
        boxShadow:'none',
        color:'#e60001',
        borderBottom:'1px solid #e60001',
        fontSize: '18px',
        fontFamily: 'VodafoneRg-Bold',
        cursor: 'pointer',
    }

  })
  

export default styles
