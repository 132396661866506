const styles = theme => ({
    background:{
        backgroundColor: "#fbfaff",
        paddingBottom: '200px',
    },
    content_container:{
        // paddingLeft: "6%",
        // paddingRight: "6%"
    },
    top_section_text: {
        display: "flex",
        alignItems: "center", 
        justifyContent: "space-between", 
    },
    no_idea_img: {
        height: '210px',
        width: '288px',
        marginBottom: '20px',
    },
    no_idea_title: {
        textAlign: 'center',
        fontSize: '32px',
        color: '#333333',
        fontFamily: 'VodafoneRg-Regular'
    },
    title: {
        fontFamily: 'VodafoneRg-Regular',
        fontSize: "32px",
        color: "#333333",
    },
    invested_ideas_number: {
        fontFamily: 'VodafoneRg-Regular',
        fontSize: "20px",
        paddingTop: "16px",
        cursor: 'pointer',
        color: "#7836FF",
        background:'transparent',
        border:'0',
        
    },
    lower_title: {
        fontFamily: "VodafoneRg-Regular",
        color: "#333333",
        fontSize: "40px",
        textAlign: "center",
        marginBottom: "16px",
    },
    lower_description: {
        fontFamily: "VodafoneLt-Regular",
        color: "#333333",
        fontSize: "24px",
        textAlign: "center",
        marginTop: "16px",
    },
    IdeaGrid: {
        display: "grid",
        marginTop: "32px",
        marginBottom: "60px",
        gridTemplateColumns: "repeat(2,minmax(128px,1fr))",
        gridGap: '8px',
        alignItems: "center",
        justifyItems: "center",
        '@media (min-width:480px)': { 
            gridTemplateColumns: "repeat(3,minmax(144px,1fr))",
        },
        '@media (min-width:1024px)': { 
            gridTemplateColumns: "repeat(4,minmax(238px,1fr))",
        },
    },
    loadMoreWrapper:{
        textAlign:'center',
        marginTop:'2rem'
    },
    loadmorebtn: {
        width: '145px',
        height: '45px',
        borderRadius: '22.5px',
        border: '1px solid #6551FF',
        color: '#6551FF',
        fontFamily: 'VodafoneRg-Regular',
        fontSize: '20px',
        cursor: 'pointer',
        backgroundColor: '#FFFFFF',
        transition: '0.5s',
        '&:hover':{
            backgroundColor: '#6551FF',
            color: '#FFFFFF'
        }
    },
  })
  

export default styles
