/* eslint-disable no-empty, object-shorthand, react/no-unused-state,react/no-unused-class-component-methods */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jam3/no-sanitizer-with-danger */
/* eslint-disable react/sort-comp */


import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { ShowFilter } from 'components'
import {
  Button,
  Container,
  Box,
  Typography,
  Grid,
  Chip,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from '@material-ui/core'
import ResumeSlider from 'components/nxt_resume_card/components/resume_slider/View'
//import EvaluatorIdeaDescription from '../idea_description/View'
import { FIRST_LIMIT_CONSTANT, LIMIT_CONSTANT } from 'common_constants/Evaluation'
import CommentsPieChart from '../pie_chart/View'
import EvaluatorCommentsList from '../evaluator/components/evaluator_comments/View'
import NxtCircularLoader from 'components/nxt_circular_loader/View'
import styles from './View.Style'
import withStyles from '@material-ui/core/styles/withStyles'

class ArchivedEvaliuationBoard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // imageUrl: [],
      // videoUrl: [],
      showSlider: false,
      offset: 0,
      loadArchivedIdeas: true,
      id: '',
      loadComments: false,
      stage: 0,
    }
    //this.id = props.match.params.id
    this.handleFilter = this.handleFilter.bind(this)
    this.handleOnLoadMore = this.handleOnLoadMore.bind(this)
    this.ideaClickHandle = this.ideaClickHandle.bind(this)
    this.handleRestoer = this.handleRestoer.bind(this)
    this.loadPrevComments = this.loadPrevComments.bind(this)
    this.cancelCommentListUi = this.cancelCommentListUi.bind(this)
    this.getEvaluationArchivedIdeaWithFilter = this.getEvaluationArchivedIdeaWithFilter.bind(this)
    // this.moveToBoardCheck = this.moveToBoardCheck.bind(this)
    // this.handleArchived = this.handleArchived.bind(this)
  }

  loadPrevComments = stage => {
    this.setState({ loadComments: true, stage: stage })
  }

  cancelCommentListUi = () => {
    this.setState({ loadComments: false })
  }

  getEvaluationArchivedIdeaWithFilter(offset = 0) {
    const { getEvaluationArchivedIdea } = this.props
    getEvaluationArchivedIdea(offset ? LIMIT_CONSTANT : FIRST_LIMIT_CONSTANT, offset)
  }

  componentDidMount() {
    // const { getEvaluationArchivedIdea } = this.props
    // getEvaluationArchivedIdea(offset ? LIMIT_CONSTANT : FIRST_LIMIT_CONSTANT, offset)
    this.getEvaluationArchivedIdeaWithFilter()
    // getEvaluationIdeaById(id)
  }

  componentDidUpdate(prevProps) {
    const { ideas, archivedClicked } = this.props
    if (prevProps.ideas !== ideas) {
      
    }
    if (prevProps.archivedClicked === archivedClicked) {
      // getEvaluationArchivedIdea()
    }
  }

  handleFilter(value) {
    this.setState({ showSlider: value })
  }

  handleOnLoadMore() {
    const { getEvaluationArchivedIdea } = this.props
    const { offset } = this.state
    const newLimit = offset ? LIMIT_CONSTANT : FIRST_LIMIT_CONSTANT
    const newOffset = newLimit + offset
    // getEvaluationArchivedIdea(newOffset)
    getEvaluationArchivedIdea(LIMIT_CONSTANT, newOffset)
    this.setState({ offset: newOffset })
  }

  ideaClickHandle(id) {
    const { getEvaluationArchivedIdeaById } = this.props
    getEvaluationArchivedIdeaById(id)
    this.setState({ loadArchivedIdeas: false, id: id })
  }

  handleRestoer = () => {
    const {
      restoreEvaluationArchivedIdeaById,
      restoreEvaluationArchivedIdeaByIdUpdated,
    } = this.props
    // restoreEvaluationArchivedIdeaById(this.state.id)
    restoreEvaluationArchivedIdeaByIdUpdated(this.state.id)
  }

  moveToBoardCheck = () => {
    const { isAdmin, localAdmin, archivedBoardStatus, archivedPitchStatus } = this.props
    // (archivedBoardStatus === 'APPROVED' && archivedPitchStatus === 'APPROVED') ||
    //     (archivedBoardStatus === 'DROP' && archivedPitchStatus === 'DROP') ||
    //     (archivedBoardStatus === 'APPROVED' && archivedPitchStatus === 'DROP')
    // if (
    //   (isAdmin || localAdmin) &&
    //   ((archivedBoardStatus === 'APPROVED' && archivedPitchStatus === 'APPROVED') ||
    //     (archivedBoardStatus === 'DROP' && archivedPitchStatus === 'DROP') ||
    //     (archivedBoardStatus === 'APPROVED' && archivedPitchStatus === 'DROP'))
    // ) {
    //   return true
    // }

    // if (!localAdmin || !isAdmin) {
    //   return true
    // }

    return false
  }

  render() {
    const { showSlider } = this.state
    const {
      ideas,
      images,
      totalEvaluationIdea,
      hasMoreIdeasToLoad,
      ideaStatusStepper,
      navigateToUserProfile,
      isAdmin,
      localAdmin,
      commentListGraph,
      archivedIdeaTitle,
      archivedIdeaText,
      archivedBoardReviewDate,
      archivedBoardStatus,
      archivedFeedbackForIdeaOwner,
      archivedBoardComments,
      archivedIdeaPitchDate,
      archivedPitchStatus,
      archivedRoundClosed,
      archivedAdminImages,
      adminEvaluationLoading,
    } = this.props
    const { loadComments, stage } = this.state
    return (
      <div style={{ flexGrow: 1 }}>
        {loadComments && (
          <EvaluatorCommentsList
            stage={stage}
            commentsList={commentListGraph.Qcomments}
            open={loadComments}
            isAdmin={isAdmin}
            localAdmin={localAdmin}
            handleDialog={this.cancelCommentListUi}
          />
        )}
        {this.state.loadArchivedIdeas && (

          <NxtCircularLoader isLoading={adminEvaluationLoading} >
            <ShowFilter
              cards={ideas}
              showSlider={showSlider}
              // onClick={navigateToIdeaDetail}
              onClick={this.ideaClickHandle}
              //onClick={getEvaluationIdea}
              onCardTypeChange={this.handleFilter}
              onLoadMore={this.handleOnLoadMore}
              totalIdeas={totalEvaluationIdea}
              showLoadMoreButton={hasMoreIdeasToLoad}
              ideaStatusStepper={ideaStatusStepper}
              onSubmitterClick={navigateToUserProfile}
              showSmallCardCircle
            />
          </NxtCircularLoader>

        )
        }
        {
          !this.state.loadArchivedIdeas && images && (
            <div style={{ flexGrow: 1, overflowWrap: 'break-word' }}>
              {/* <EvaluatorIdeaDescription
              images={archivedAdminImages}
              videoUrl={this.state.videoUrl}
              title={archivedIdeaTitle}
              description={archivedIdeaText}
            /> */}
              <Grid container direction="row" justify="center">
                {/*Left grid*/}
                <Grid item xs={12} sm={6}>
                  <Box item xs={6} height="50%" width="80%">
                    {archivedAdminImages.length > 0 && <ResumeSlider images={archivedAdminImages} />}
                  </Box>
                </Grid>
                {/*Mid grid*/}
                <Grid item xs={12} sm={6} justify="space-around">
                  <Container height="100%" width="100%">
                    <div style={{ overflowWrap: 'break-word' }}>
                      <Typography variant="h6" gutterBottom>
                        {archivedIdeaTitle}
                      </Typography>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: archivedIdeaText }} />
                  </Container>
                </Grid>
              </Grid>

              {/* <Grid container direction="row" justify="center" justify="space-between"> */}
              <Grid container direction="row" justify="center">
                <Grid item xs={12} sm={6} style={{ backgroundColor: 'white' }}>
                  <Grid
                    container
                    style={{
                      height: '100%',
                      width: '100%',
                      backgroundColor: 'white',
                    }}
                    alignContent="flex-start"
                  >
                    <Grid item xs={6} alignItems="center">
                      {commentListGraph.Q1measurement && !loadComments && (
                        <CommentsPieChart
                          title="How innovative is this idea?"
                          list={commentListGraph.Q1measurement}
                          loadPrevComments={this.loadPrevComments}
                          loadPrevCommentsNo={1}
                        />
                      )}
                    </Grid>
                    <Grid item xs={6} lignItems="center">
                      {commentListGraph.Q2measurement && !loadComments && (
                        <CommentsPieChart
                          title="Is the value of this idea clear?"
                          list={commentListGraph.Q2measurement}
                          loadPrevComments={this.loadPrevComments}
                          loadPrevCommentsNo={2}
                        />
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {commentListGraph.Q3measurement && !loadComments && (
                        <CommentsPieChart
                          title="Is this idea mature/ detailed enough to go to the next phase?"
                          list={commentListGraph.Q3measurement}
                          loadPrevComments={this.loadPrevComments}
                          loadPrevCommentsNo={3}
                        />
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {commentListGraph.type && (
                        <CommentsPieChart
                          title="How do you feel about this idea?"
                          list={commentListGraph.type}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Container>
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableBody>
                          <TableRow key="Board Review Date">
                            <TableCell style={{ borderBottom: 'none' }} component="th" scope="row">
                              <Chip label="Board Review Date" color="primary" />
                            </TableCell>
                            <TableCell style={{ borderBottom: 'none' }} align="right">
                              {archivedBoardReviewDate}
                            </TableCell>
                          </TableRow>
                          <TableRow key="Board Status">
                            <TableCell style={{ borderBottom: 'none' }} component="th" scope="row">
                              <Chip label="Board Status" color="primary" />
                            </TableCell>
                            <TableCell style={{ borderBottom: 'none' }} align="right">
                              {archivedBoardStatus}
                            </TableCell>
                          </TableRow>
                          <TableRow key="Pitch Date">
                            <TableCell style={{ borderBottom: 'none' }} component="th" scope="row">
                              <Chip label="Pitch Date" color="primary" />
                            </TableCell>
                            <TableCell style={{ borderBottom: 'none' }} align="right">
                              {archivedIdeaPitchDate}
                            </TableCell>
                          </TableRow>
                          <TableRow key="Pitch Status">
                            <TableCell style={{ borderBottom: 'none' }} component="th" scope="row">
                              <Chip label="Pitch Status" color="primary" />
                            </TableCell>
                            <TableCell style={{ borderBottom: 'none' }} align="right">
                              {archivedPitchStatus}
                            </TableCell>
                          </TableRow>
                          <TableRow key="Round Closed">
                            <TableCell style={{ borderBottom: 'none' }} component="th" scope="row">
                              <Chip label="Round Closed" color="primary" />
                            </TableCell>
                            <TableCell style={{ borderBottom: 'none' }} align="right">
                              {archivedRoundClosed}
                            </TableCell>
                          </TableRow>
                          <TableRow key="Feedback From Admin">
                            <TableCell style={{ borderBottom: 'none' }} component="th" scope="row">
                              <Chip label="Feedback From Admin" color="primary" />
                            </TableCell>
                            <TableCell style={{ borderBottom: 'none' }} align="right">
                              {archivedFeedbackForIdeaOwner || 'No Feedback'}
                            </TableCell>
                          </TableRow>

                          <TableRow key="Board Comments">
                            <TableCell style={{ borderBottom: 'none' }} component="th" scope="row">
                              <Chip label="Internal Board Comments" color="primary" />
                            </TableCell>
                            <TableCell style={{ borderBottom: 'none' }} align="right">
                              {archivedBoardComments || 'No Feedback'}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {(isAdmin || localAdmin) && (
                      <Button
                        disabled={
                          (archivedBoardStatus === 'APPROVED' &&
                            archivedPitchStatus === 'APPROVED') ||
                          (archivedBoardStatus === 'DROP' && archivedPitchStatus === 'DROP') ||
                          (archivedBoardStatus === 'APPROVED' && archivedPitchStatus === 'DROP')
                        }
                        variant="contained"
                        px={3}
                        id="redBackground"
                        height="100%"
                        color="primary"
                        onClick={this.handleRestoer}
                      >
                        Move to Board
                      </Button>
                    )}
                  </Container>
                </Grid>
              </Grid>
            </div>
          )
        }
      </div >
    )
  }
}

ArchivedEvaliuationBoard.propTypes = {
  getEvaluationArchivedIdea: PropTypes.func.isRequired,
}

export default withStyles(styles)(ArchivedEvaliuationBoard)
