/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import styles from './View.Style'
import Breadcrumbs from 'components_newui/pageBreadcrumbsMenu/View'
import no_Draft_challangee from 'img/no_Draft_challangee.png'
import { Link } from 'react-router-dom'
import i18n from 'support/i18n'
import { Container, FormControl, MenuItem, Select } from '@material-ui/core'
import navigation from '../../support/navigation'
import ChallengeDraftCard from '../../components_newui/challengeDraftCard/View'


const DraftChallenges = props => {
    const {
        classes,
        challengesDraft,
        getChallengeDraftData,
        isAdmin,
        canProposeChallenge,
        isInnovator,
        localAdmin,
        navigateToChallengeDetail,
        getFilteredChallenges,
        getActiveChallenges,
        getChallengesAll,
        onDeleteClick,
        navigateToExplore
    } = props


    const [challengeData, setChallengeData] = useState([challengesDraft])
    const [refresh,setrefresh] = useState(false)
    const pageHierarchy = [
        { string: "Home", navigate: navigateToExplore },
        { string: "Draft challenge", navigate: () => {} }
    ]


    useEffect(()=>{
        setrefresh(true)
        getChallengeDraftData()
        setrefresh(false)
    },[refresh])

    const redirectNewChallenge = () =>{
        navigation.navigateToChallengeNew()
    }

// console.log("challenge data",challengeData[0].challengeDescription && challengeData[0].challengeDescription.title !=='' )

    return (
        <>
        <Breadcrumbs crumbs={pageHierarchy} />
            <Container>
                
                <main className={classes.background}>

                    <div className={classes.mywrp}>
                        <h3 className={classes.myText}>Draft Challenges ({
                            (challengeData[0].challengeDescription && challengeData[0].challengeDescription.title !== '') ? 
                            challengeData.length : '0'
                        })</h3>
                        <Link to='#' onClick={redirectNewChallenge} className={classes.draftText}>Create Challenge </Link>
                    </div>
                    
                    {
                        (  (challengeData && challengeData.length === 0) ) ?

                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <img className={classes.noIdeaImg} src={no_Draft_challangee} alt='img' />
                                <p className={classes.noIdeas}>No existing <span className={classes.purpleNo}>Challenges</span></p>
                            </div>
                            :
                                challengeData && challengeData.map((challenge,i) => (
                                     (challenge.challengeDescription && challenge.challengeDescription.title !== '') ?
                                    <Grid key={challenge.id}  container spacing={2} className={classes.vf_container_flexbox}>
                                        <Grid item>
                                            <ChallengeDraftCard key={challenge.id}  challenge={challenge} handleDelete={()=>onDeleteClick(challenge.id)} urlID={challenge.id} handleContinue={()=>navigateToChallengeDetail(challenge.id)} />
                                        </Grid> 
                                    </Grid> : 
                                        <div key={challenge.id} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                            <img className={classes.noIdeaImg} src={no_Draft_challangee} alt='img' />
                                            <p className={classes.noIdeas}>No existing <span className={classes.purpleNo}>Challenges</span></p>
                                        </div>
                                 )
                        )
                            

                    }


                </main>
            </Container>
        </>
    )
}

export default withStyles(styles)(DraftChallenges)