const styles = theme => ({
  root: {
    //display: 'flex',
    // width: '100%',
    // justifyContent: 'center',
  },
  sliderContainer: {
    alignSelf: 'center',
  },
  radioImages: {
    '& > div:first-child': {
      width: '500px',
    },
  },
  imageContainer: {
    float: 'right',
    flexGrow: 1,
    position: 'relative',
    width: '100%',
  },
  img: {
    display: 'block',
    overflow: 'hidden',
    width: '100%',
  },
  input: {
    display: 'none',
  },
  deleteButton: {
    position: 'absolute',
    right: 0,
  },
  button: {
    width: '100%',
  },
  error: {
    color: theme.palette.error.main,
  },
  stepperRoot: {
    float: 'right',
    width: '484px',
  },
  radioButtonMain: {
    paddingLeft: theme.spacing(1.5),
  },
  add_image: {
    width: '280px',
    height: '120px',
    border: '1px dashed #666666',
    borderRadius: '10px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '@media(max-width:767px)':{
      width:'100%'
    }
  },
  upload_icon: {
    width: '62px',
    height: '62px',
    padding: '2px',
  },
  upload_media_help_text_1: {
    fontFamily: 'VodafoneRg-Bold',
    color: '#6551FF',
    fontSize: '14px',
    padding: '2px',
  },
  dragndrop_text:{
    fontFamily: 'VodafoneRg-Regular',
    color: '#333333',
    fontSize: '16px',
    paddingTop: '6px',
  },
  format_support_text: {
    fontFamily: 'VodafoneRg-Regular',
    color: '#999999',
    fontSize: '14px',
    paddingTop: '6px',
    paddingBottom: '15px',
  },
  image_grid: {
    display: 'grid', 
    gridTemplateColumns: '1fr 1fr 1fr', 
    columnGap: '25px',
    rowGap: '25px',
  },
  image_name: {
    fontSize: '16px',
    fontFamily: 'VodafoneRg-Regular',
    color: '#333333',
    paddingTop: '7px',
  },
  imageUploadDisabled:{
    cursor:'default',
    'pointer-events':'none',
    color:'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    backgroundColor:' rgba(0, 0, 0, 0.12)',
    border:'0px'
  }
})

export default styles
