/* eslint-disable react/sort-comp */

import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {Container} from '@material-ui/core'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import styles from './View.Style'
import UserDetailSection from './components/user_detail_section/View'
import BadgesSection from './components/badges_section/View'
import EditMyProfile from './components/user_detail_section/components/edit_my_profile/View'
import RequestInvestment from './components/user_detail_section/components/request_investment/View'
import MyIdeasSection from './components/my_ideas_section/View'
import TeamsSection from './components/teams_section/View'
import OrganisationsSection from './components/organisations_section/View'
import InvestmentsSection from './components/investments_section/View'
import ContributionsSection from './components/contributions_section/View'
import TimelineSection from './components/timeline_section/View'

class Profile extends Component {
  constructor(props) {
    super(props)
    const { match, myEmail } = props
    const isMe = match.params.email.toLowerCase() === 'me' || match.params.email === myEmail
    const userEmail = isMe ? myEmail : match.params.email
    this.state = {
      isMe,
      userEmail,
      showAll: false,
      badges: [],
      organisations: [],
    }
    this.renderRightDetailElement = this.renderRightDetailElement.bind(this)
    this.handleChangeEmailUrlParam = this.handleChangeEmailUrlParam.bind(this)
    this.handleOrganisationClick = this.handleOrganisationClick.bind(this)
    this.getUserProfile = this.getUserProfile.bind(this)
    this.getBadges = this.getBadges.bind(this)
  }

  componentDidMount() {
    const { hasDomainLoaded } = this.props
    const { userEmail } = this.state
    this.getUserProfile()
    if (hasDomainLoaded) {
      this.getProfileData(userEmail)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      myEmail,
      match,
      hasDomainLoaded,
      hasUserProfileLoaded,
      organisationsToBeShown,
      domainOrganisations,
      isOrganizationsActive,
      getOrganisations,
    } = this.props
    const newEmail = match.params.email
    const isMeNow = newEmail.toLowerCase() === 'me' || newEmail === myEmail
    if (prevProps.match.params.email !== newEmail) {
      this.handleChangeEmailUrlParam(myEmail, newEmail)
    }
    if (prevProps.myEmail !== myEmail) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        userEmail: isMeNow ? myEmail : newEmail,
        isMe: isMeNow,
      })
    }
    if (!prevProps.hasDomainLoaded && hasDomainLoaded) {
      this.getProfileData(isMeNow ? myEmail : newEmail)
    }
    if (
      (!prevProps.hasUserProfileLoaded && hasUserProfileLoaded && hasDomainLoaded) ||
      (!prevProps.hasDomainLoaded && hasDomainLoaded && hasUserProfileLoaded)
    ) {
      this.getBadges()
    }
    if (
      prevProps.organisationsToBeShown !== organisationsToBeShown ||
      prevProps.domainOrganisations !== domainOrganisations
    ) {
      this.getOrganisations()
    }
    if (!prevProps.isOrganizationsActive && isOrganizationsActive) {
      getOrganisations()
    }
  }

  getUserProfile() {
    const { getMyProfile, getUserProfile } = this.props
    const { isMe, userEmail } = this.state
    if (isMe) {
      getMyProfile()
    } else {
      getUserProfile(userEmail)
    }
  }

  getProfileData(userEmail) {
    const {
      getUserIdeas,
      getUserIdeasTeam,
      getUserIdeasPilotTeam,
      getUserIdeasInvestedIn,
      getUserOrganisations,
      isOrganizationsActive,
      getUserContributions,
      getMyIdeasToInvestIn,
      getTimeline,
      resetTimeline,
    } = this.props
    resetTimeline()
    getUserIdeas(userEmail)
    getUserIdeasTeam(userEmail)
    getUserIdeasInvestedIn(userEmail)
    getUserIdeasPilotTeam(userEmail)
    getUserContributions(userEmail)
    getMyIdeasToInvestIn()
    if (isOrganizationsActive) {
      getUserOrganisations()
    }
    getTimeline(userEmail)
  }

  getBadges() {
    const { userBadges, allBadges, ideaStatuses } = this.props
    const badges = allBadges.reduce((acc, badge) => {
      const isActive = !!userBadges.find(userBadge => userBadge.name === badge.name)
      acc.push({
        ...badge,
        isActive,
        tooltip: i18n
          .t(`BADGES.${badge.name.toUpperCase()}`)
          .replace('@@IDEA_STATUS.APPROVED_BY_IT@@', ideaStatuses.approvedLabel)
          .replace('@@IDEA_STATUS.SUPPORTED@@', ideaStatuses.supportedLabel)
          .replace('@@IDEA_STATUS.PILOT_ACTIVE@@', ideaStatuses.pilotActiveLabel),
      })
      return acc
    }, [])
    this.setState({
      badges,
    })
  }

  getOrganisations() {
    const { organisationsToBeShown, domainOrganisations } = this.props
    const organisations = domainOrganisations.reduce((acc, organisation) => {
      const isActive = !!organisationsToBeShown.find(
        innerOrganisation => innerOrganisation.id === organisation.id,
      )
      acc.push({
        ...organisation,
        isActive,
      })
      return acc
    }, [])
    this.setState({
      organisations,
    })
  }

  handleChangeEmailUrlParam(myEmail, urlEmail) {
    const isMeNow = urlEmail.toLowerCase() === 'me' || urlEmail === myEmail
    const userEmail = isMeNow ? myEmail : urlEmail
    this.setState(
      () => ({
        isMe: isMeNow,
        userEmail,
      }),
      () => {
        this.getUserProfile()
        this.getProfileData(userEmail)
      },
    )
  }

  handleOrganisationClick(organisationClicked) {
    const { organisationsToBeShown, onOrganisationClicked } = this.props
    let organisations = []
    if (organisationClicked.isActive) {
      const { name, id } = organisationClicked
      organisations = [...organisationsToBeShown, { id, name }]
    } else {
      organisations = organisationsToBeShown.filter(
        organisation => organisation.id !== organisationClicked.id,
      )
    }
    onOrganisationClicked(
      organisations,
      organisations.reduce((acc, organisation, index) => {
        return `${acc}${organisation.id}${index === organisations.length - 1 ? '' : ','}`
      }, ''),
    )
  }

  renderRightDetailElement() {
    const { isMe, userEmail } = this.state
    const { onEditProfile, onRequestInvestmentClick } = this.props
    if (isMe) {
      return <EditMyProfile onEditProfileClick={onEditProfile} />
    }
    return (
      <RequestInvestment onRequestInvestmentClick={() => onRequestInvestmentClick(userEmail)} />
    )
  }

  render() {
    const {
      classes,
      fullName,
      photoSrc,
      balance,
      invested,
      portfolio,
      ideas,
      navigateToIdea,
      userBadges,
      teamIdeas,
      teamPilotIdeas,
      ideasInvestedIn,
      contributions,
      isOrganizationsActive,
      organizationsName,
      timeline,
      getTimeline,
      isTimelineComplete,
      isTimelineLoading,
    } = this.props
    const { userEmail, showAll, badges, organisations, isMe } = this.state
    return (
      <Container>
      <div className={classes.root}>
        <UserDetailSection
          fullName={fullName}
          email={userEmail}
          photoSrc={photoSrc}
          balance={balance}
          invested={invested}
          portfolio={portfolio}
        >
          {this.renderRightDetailElement()}
        </UserDetailSection>
        <BadgesSection
          hasBadges={userBadges.length > 0}
          showAll={showAll}
          buttonLabel={showAll ? i18n.t('BADGES.HIDE_BUTTON') : i18n.t('BADGES.SHOW_BUTTON')}
          badges={badges}
          onShowAllClick={() => this.setState(prevState => ({ showAll: !prevState.showAll }))}
        />
        <MyIdeasSection ideas={ideas} onIdeaClick={navigateToIdea} username={fullName} />
        <TeamsSection
          ideas={teamIdeas}
          username={fullName}
          onIdeaClick={navigateToIdea}
          sectionText={i18n.t('PROFILE.PARTICIPATING_ON')}
        />
        <TeamsSection
          ideas={teamPilotIdeas}
          username={fullName}
          onIdeaClick={navigateToIdea}
          sectionText={i18n.t('PROFILE.PARTICIPATING_INITIATIVE_ON')}
        />
        <InvestmentsSection
          ideas={ideasInvestedIn}
          onIdeaClick={navigateToIdea}
          username={fullName}
        />
        {isOrganizationsActive && isMe && (
          <OrganisationsSection
            organisationLabel={organizationsName}
            organisations={organisations}
            onOrganisationClick={this.handleOrganisationClick}
          />
        )}
        <ContributionsSection
          contributions={contributions}
          onIdeaClick={navigateToIdea}
          userName={fullName}
        />
        {timeline.length > 0 && (
          <TimelineSection
            events={[]}
            timeline={timeline}
            navigateToIdea={navigateToIdea}
            onLoadClick={() => getTimeline(userEmail)}
            badges={badges}
            isTimelineComplete={isTimelineComplete}
            isTimelineLoading={isTimelineLoading}
          />
        )}
      </div>
      </Container>
    )
  }
}

Profile.propTypes = {
  hasDomainLoaded: PropTypes.bool.isRequired,
  hasUserProfileLoaded: PropTypes.bool.isRequired,
  navigateToIdea: PropTypes.func.isRequired,
  getOrganisations: PropTypes.func.isRequired,
  getMyProfile: PropTypes.func.isRequired,
  onEditProfile: PropTypes.func.isRequired,
  onRequestInvestmentClick: PropTypes.func.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  getUserIdeas: PropTypes.func.isRequired,
  getUserIdeasTeam: PropTypes.func.isRequired,
  getUserOrganisations: PropTypes.func.isRequired,
  getUserIdeasPilotTeam: PropTypes.func.isRequired,
  getUserIdeasInvestedIn: PropTypes.func.isRequired,
  getUserContributions: PropTypes.func.isRequired,
  getMyIdeasToInvestIn: PropTypes.func.isRequired,
  onOrganisationClicked: PropTypes.func.isRequired,
  myEmail: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  photoSrc: PropTypes.string.isRequired,
  balance: PropTypes.string.isRequired,
  invested: PropTypes.string.isRequired,
  portfolio: PropTypes.string.isRequired,
  organizationsName: PropTypes.string.isRequired,
  userBadges: PropTypes.arrayOf(
    PropTypes.shape({
      imgSrc: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  allBadges: PropTypes.arrayOf(
    PropTypes.shape({
      imgSrc: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  ideaStatuses: PropTypes.shape({}).isRequired,
  ideas: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  contributions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  teamIdeas: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  teamPilotIdeas: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  ideasInvestedIn: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  organisationsToBeShown: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  domainOrganisations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isOrganizationsActive: PropTypes.bool.isRequired,
  getTimeline: PropTypes.func.isRequired,
  timeline: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  isTimelineLoading: PropTypes.bool.isRequired,
  isTimelineComplete: PropTypes.bool.isRequired,
  resetTimeline: PropTypes.func.isRequired,
}

export default withStyles(styles)(Profile)
