/* eslint-disable react/no-unescaped-entities  */

import React, { Component, Fragment, useEffect } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import InvestmentsTable from 'components/investments_table/View'
import TotalInvestments from './components/total_investments/View'
import styles from './View.Style'
import Breadcrumbs from 'components_newui/pageBreadcrumbsMenu/View'
import topImage from 'img/investments_graphic.png'
import defaultPhoto from 'img/default-photo.png'
import { Container } from '@material-ui/core'


const Investments = props => {
  const {
    classes,
    navigateHome,
    navigateToProfile,
    fullInfoLoaded, 
    getInvestments, 
    userEmail,
    totalInvested, 
    totalProfit, 
    ideas
  } = props

  const pageHierarchy = [
    {string: "Home", navigate: navigateHome}, 
    {string: "Profile", navigate: navigateToProfile},
    {string: "Current balance details", navigate: ()=>{}}
  ] 

  
  useEffect(() => {
    getInvestments(userEmail)
  },[fullInfoLoaded])

  return(
    <>
    <Breadcrumbs crumbs={pageHierarchy} />
    <Container>
      <div className={classes.background}>
        <div style={{
          display: 'flex', 
          justifyContent: 'space-between',
          marginBottom: '34px'
        }}>
          <div>
            <h1 style={{
              fontSize: '40px'
            }}>{i18n.t('INVESTMENTS.INVEST_TITLE')}</h1>
            <p style={{
              fontSize: '18px',
              marginTop: '16px'
            }}> Here you can see a list of all the ideas you've invested in, check their status, and see how much you've invested in each one. </p>
          </div>
          <img src={topImage} style={{width:'300px'}} alt='investments icon'/>
        </div>
        
        <div className={classes.investmentsGrid}>
          <div className={classes.gridHeader}>Idea</div>
          <div className={classes.gridHeader}>Status</div>
          <div className={classes.gridHeader}>Invested</div>
          <div className={classes.gridHeader}>Profit</div>
          {ideas.map(idea => (
            <>
              <div className={classes.gridItem}>
                <img src={idea.image} style={{
                  width: '57px',
                  height: '57px',
                  objectFit:'cover',
                  objectPosition:'center',
                  borderRadius:'15px',
                  marginRight: '16px'
                  }} 
                  onError={({currentTarget}) => {
                    currentTarget.onerror = null
                    currentTarget.src = defaultPhoto
                  }}
                  alt='idea'
                />
                <div>{idea.title}</div>
              </div>
              <div className={classes.gridItem}>{idea.status}</div>
              <div className={classes.gridItem}>{idea.invested}</div>
              <div className={classes.gridItem}>{idea.profit}</div>
            </>
          ))}
        </div>
      </div>
    </Container>
    </>
  )
}

export default withStyles(styles)(Investments)
