import { ServiceCall } from 'support/network/ServiceCallAction'
import {
  SEARCH_USER_SPONSOR_FAILURE,
  SEARCH_USER_SPONSOR_STARTED,
  SEARCH_USER_SPONSOR_SUCCESS,
  SEARCH_USER_PROPOSER_FAILURE,
  SEARCH_USER_PROPOSER_STARTED,
  SEARCH_USER_PROPOSER_SUCCESS,
  GET_ROI_INFO_STARTED,
  GET_ROI_INFO_SUCCESS,
  GET_ROI_INFO_FAILURE,
  ADD_CHALLENGE_STEPS_IMAGE_STARTED,
  ADD_CHALLENGE_STEPS_IMAGE_SUCCESS,
  ADD_CHALLENGE_STEPS_IMAGE_FAILURE,
  GET_CHALLENGE_DRAFT_STARTED,
  GET_CHALLENGE_DRAFT_SUCCESS,
  GET_CHALLENGE_DRAFT_FAILURE,
  SET_CHALLENGE_MAIN_IMAGE_STARTED,
  SET_CHALLENGE_MAIN_IMAGE_SUCCESS,
  SET_CHALLENGE_MAIN_IMAGE_FAILURE,
  DELETE_CHALLENGE_INFO_IMAGE_STARTED,
  DELETE_CHALLENGE_INFO_IMAGE_SUCCESS,
  DELETE_CHALLENGE_INFO_IMAGE_FAILURE,
  GET_GROUPS_STARTED,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAILURE,
  ADD_CHALLENGE_STEPS_REWARD_IMAGE_STARTED,
  ADD_CHALLENGE_STEPS_REWARD_IMAGE_SUCCESS,
  ADD_CHALLENGE_STEPS_REWARD_IMAGE_FAILURE,
  DELETE_CHALLENGE_REWARD_IMAGE_STARTED,
  DELETE_CHALLENGE_REWARD_IMAGE_SUCCESS,
  DELETE_CHALLENGE_REWARD_IMAGE_FAILURE,
  UPLOAD_CHALLENGE_VIDEO_STARTED,
  UPLOAD_CHALLENGE_VIDEO_SUCCESS,
  UPLOAD_CHALLENGE_VIDEO_FAILURE,
  PUT_CHALLENGE_INFO_FAILURE,
  PUT_CHALLENGE_INFO_STARTED,
  PUT_CHALLENGE_INFO_SUCCESS,
  
  PUT_DRAFT_CHALLENGE_STARTED,
  PUT_DRAFT_CHALLENGE_SUCCESS,
  PUT_DRAFT_CHALLENGE_FAILURE,

  PUT_PEOPLE_INFO_STARTED,
  PUT_PEOPLE_INFO_SUCCESS,
  PUT_PEOPLE_INFO_FAILURE,
  PUT_ROI_INFO_STARTED,
  PUT_ROI_INFO_FAILURE,
  PUT_ROI_INFO_SUCCESS,
  DELETE_CHALLENGE_DRAFT_STARTED,
  DELETE_CHALLENGE_DRAFT_SUCCESS,
  DELETE_CHALLENGE_DRAFT_FAILURE,
  PUT_REWARD_INFO_STARTED,
  PUT_REWARD_INFO_SUCCESS,
  PUT_REWARD_INFO_FAILURE,
  POST_LAUNCH_CHALLENGE_STARTED,
  POST_LAUNCH_CHALLENGE_SUCCESS,
  POST_LAUNCH_CHALLENGE_FAILURE,
  PUT_PROPOSE_CHALLENGE_STARTED,
  PUT_PROPOSE_CHALLENGE_SUCCESS,
  PUT_PROPOSE_CHALLENGE_FAILURE,
  GET_CHALLENGE_STARTED,
  GET_CHALLENGE_SUCCESS,
  GET_CHALLENGE_FAILURE,
  GET_CHALLENGE_SELECTED_GROUPS_STARTED,
  GET_CHALLENGE_SELECTED_GROUPS_SUCCESS,
  GET_CHALLENGE_SELECTED_GROUPS_FAILURE,
  PUT_CHALLENGE_DATES_STARTED,
  PUT_CHALLENGE_DATES_SUCCESS,
  PUT_CHALLENGE_DATES_FAILURE,
  // GET_CHALLENGE_SAVE_DRAFT_FAILURE, 
  // GET_CHALLENGE_SAVE_DRAFT_STARTED, 
  // GET_CHALLENGE_SAVE_DRAFT_SUCCESS ,
} from 'actions/sync/challenge_steps/Constants'
import {
  transformSuggestions,
  transformAdvancedRoi,
  transformImageServiceResponse,
  transformMainImageServiceResponse,
  transformChallengeDraft,
  transformDeleteImageResponse,
  transformUploadVideoResponse,
  transformGroupsResponse,
  transformChallengeSelectedGroupsResponse,
  // transformSaveDraftResponse,
  // transformImageServiceResponseSaceDraft,
  transformImageServiceResponseSaveDraft,
} from './ResponseUtils'
import {
  setImageContent,
  setDeleteImageContent,
  setMainImageContent,
  setVideoContent,
  setChallengeInfo,
  setChallengeDraft,
  setPeopleInfo,
  setRoiInfo,
  setRewardInfo,
  setLaunchBody,
  setProposeBody,
  setChangeDatesBody,
  setImageContentSaveDraft,
  setDeleteImageContentSaveDraft,
  setMainImageContentSaveDraft,
  setLaunchBodyChallenges,
  setChallengeLaunch,
  setProposeBodyDraft,
  setChangeDatesBodyNew,
} from './RequestUtils'

const SEARCH_BY_EMAIL = '/s/user/search-by-email'
const ROI_INFO_URL = '/s/domain/roi-info'
const ADD_IMAGE_URL = '/s/v1.0/challenge/image'
const DELETE_IMAGE_URL = '/s/v1.0/challenge'
const ADD_VIDEO_URL = '/s/v1.0/challenge/##challengeId##/videos'
const DELETE_DRAFT_CHALLENGE_URL = '/s/v1.0/challenge/draft'
const POST_MAIN_IMAGE_URL = '/s/v1.0/challenge/main-image'
const GET_DRAFT_CHALLENGE_URL = '/s/v1.0/challenge/draft'
const GET_GROUPS_URL = '/api/v1/group'
const GET_CHALLENGE = '/s/v1.0/challenge/##challengeId##'
const GET_CHALLENGE_SELECTED_GROUPS_URL = '/api/v1/group/groupSelectedForChallenge/##groupId##'
const PUT_CHALLENGE_INFO_URL = '/s/v1.0/challenge/info'
const PUT_PEOPLE_INFO_URL = '/s/v1.0/challenge/people'
const PUT_ROI_INFO_URL = '/s/v1.0/challenge/roi'
const PUT_REWARD_INFO_URL = '/s/v1.0/challenge/reward'
const POST_LAUNCH_CHALLENGE_URL = '/s/v1.0/challenge/launch'
const PUT_PROPOSE_CHALLENGE_URL = '/s/v1.0/challenge/propose'
const PUT_CHALLENGE_DATES_URL = '/s/v1.0/challenge/dates'
const PUT_DRAFTCHALLENGE_URL = '/s/v1.0/challenge/draftChallenge'
const DELETE_CHALLENGE_DRAFT_URL = '/s/v1.0/challenge/draftChallenge'

export function getSponsorSuggestions(username) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = SEARCH_USER_SPONSOR_STARTED
  apiCall.successReqType = SEARCH_USER_SPONSOR_SUCCESS
  apiCall.failureReqType = SEARCH_USER_SPONSOR_FAILURE
  apiCall.setEndpointFromState = () =>
    `${SEARCH_BY_EMAIL}?${encodeURIComponent('e')}=${encodeURIComponent(username)}&f=ALL`
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformSuggestions
  return apiCall.getAction()
}

export function getProposerSuggestions(username) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = SEARCH_USER_PROPOSER_STARTED
  apiCall.successReqType = SEARCH_USER_PROPOSER_SUCCESS
  apiCall.failureReqType = SEARCH_USER_PROPOSER_FAILURE
  apiCall.setEndpointFromState = () =>
    `${SEARCH_BY_EMAIL}?${encodeURIComponent('e')}=${encodeURIComponent(username)}&f=ALL`
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformSuggestions
  return apiCall.getAction()
}

export function getRoiInfo() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_ROI_INFO_STARTED
  apiCall.successReqType = GET_ROI_INFO_SUCCESS
  apiCall.failureReqType = GET_ROI_INFO_FAILURE
  apiCall.setEndpointFromState = () => ROI_INFO_URL
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformAdvancedRoi
  return apiCall.getAction()
}

export function postChallengeImage({
  image,
  onSuccess,
  onFailure,
  type,
  startedAction,
  successAction,
  failureAction,
}) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = startedAction
  apiCall.successReqType = successAction
  apiCall.failureReqType = failureAction
  apiCall.setEndpointFromState = () => ADD_IMAGE_URL
  apiCall.method = 'POST'
  apiCall.setBodyFromState = state => setImageContent(image, state, type)
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformImageServiceResponse 
  apiCall.auth = true
  return apiCall.getAction()
}


export function postInfoChallengeImage(image, onSuccess, onFailure) {
  return postChallengeImage({
    image,
    onSuccess,
    onFailure,
    type: 'info',
    startedAction: ADD_CHALLENGE_STEPS_IMAGE_STARTED,
    successAction: ADD_CHALLENGE_STEPS_IMAGE_SUCCESS,
    failureAction: ADD_CHALLENGE_STEPS_IMAGE_FAILURE,
  })
}


export function postRewardChallengeImage(image, onSuccess, onFailure) {
  return postChallengeImage({
    image,
    onSuccess,
    onFailure,
    type: 'reward',
    startedAction: ADD_CHALLENGE_STEPS_REWARD_IMAGE_STARTED,
    successAction: ADD_CHALLENGE_STEPS_REWARD_IMAGE_SUCCESS,
    failureAction: ADD_CHALLENGE_STEPS_REWARD_IMAGE_FAILURE,
  })
}

export function deleteChallengeImage({
  imageName,
  onFailure,
  type,
  startedAction,
  successAction,
  failureAction,
}) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = startedAction
  apiCall.successReqType = successAction
  apiCall.failureReqType = failureAction
  apiCall.setEndpointFromState = state =>
    `${DELETE_IMAGE_URL}/${setDeleteImageContent(imageName, state, type)}` 
  apiCall.method = 'DELETE'
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformDeleteImageResponse
  apiCall.auth = true
  return apiCall.getAction()
}



export function postChallengeVideo(id, video, onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = UPLOAD_CHALLENGE_VIDEO_STARTED
  apiCall.successReqType = UPLOAD_CHALLENGE_VIDEO_SUCCESS
  apiCall.failureReqType = UPLOAD_CHALLENGE_VIDEO_FAILURE
  apiCall.setEndpointFromState = () => ADD_VIDEO_URL.replace('##challengeId##', id)
  apiCall.method = 'POST'
  apiCall.getHeadersFromState = () => ({
    'content-type': 'multipart/form-data',
  })
  apiCall.setBodyFromState = () => setVideoContent(video)
  apiCall.transformResponseDataWithState = transformUploadVideoResponse
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.auth = true
  return apiCall.getAction()
}

export function deleteChallenge(onSuccess) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = DELETE_CHALLENGE_DRAFT_STARTED
  apiCall.successReqType = DELETE_CHALLENGE_DRAFT_SUCCESS
  apiCall.failureReqType = DELETE_CHALLENGE_DRAFT_FAILURE
  apiCall.setEndpointFromState = () => DELETE_DRAFT_CHALLENGE_URL
  apiCall.method = 'DELETE'
  apiCall.transformResponseDataWithState = transformChallengeDraft
  apiCall.onSuccess = onSuccess
  apiCall.auth = true
  return apiCall.getAction()
}

export function deleteRewardImage(imageName, onFailure) {
  return deleteChallengeImage({
    imageName,
    onFailure,
    type: 'reward',
    startedAction: DELETE_CHALLENGE_REWARD_IMAGE_STARTED,
    successAction: DELETE_CHALLENGE_REWARD_IMAGE_SUCCESS,
    failureAction: DELETE_CHALLENGE_REWARD_IMAGE_FAILURE,
  })
}

export function deleteInfoImage(imageName, onFailure) {
  return deleteChallengeImage({ 
    imageName,
    onFailure,
    type: 'info',
    startedAction: DELETE_CHALLENGE_INFO_IMAGE_STARTED,
    successAction: DELETE_CHALLENGE_INFO_IMAGE_SUCCESS,
    failureAction: DELETE_CHALLENGE_INFO_IMAGE_FAILURE,
  })
}


export function postChallengeMainImage(imageName, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = SET_CHALLENGE_MAIN_IMAGE_STARTED
  apiCall.successReqType = SET_CHALLENGE_MAIN_IMAGE_SUCCESS
  apiCall.failureReqType = SET_CHALLENGE_MAIN_IMAGE_FAILURE
  apiCall.setEndpointFromState = () => POST_MAIN_IMAGE_URL
  apiCall.method = 'POST'
  apiCall.setBodyFromState = state => setMainImageContent(imageName, state)
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformMainImageServiceResponse
  apiCall.auth = true
  return apiCall.getAction()
}



export function getChallengeDraft(onSuccess) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_CHALLENGE_DRAFT_STARTED
  apiCall.successReqType = GET_CHALLENGE_DRAFT_SUCCESS
  apiCall.failureReqType = GET_CHALLENGE_DRAFT_FAILURE
  apiCall.setEndpointFromState = () => GET_DRAFT_CHALLENGE_URL
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformChallengeDraft
  apiCall.onSuccess = onSuccess
  return apiCall.getAction()
}

export function putChallengeInfoNew(onSuccess) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = PUT_CHALLENGE_INFO_STARTED
  apiCall.successReqType = PUT_CHALLENGE_INFO_SUCCESS
  apiCall.failureReqType = PUT_CHALLENGE_INFO_FAILURE
  apiCall.setEndpointFromState = () => PUT_CHALLENGE_INFO_URL
  apiCall.method = 'PUT'
  apiCall.setBodyFromState = state =>
    setChallengeInfo(state.data.ChallengeDraft.id, state.data.ChallengeDraft.challengeDescription)
  apiCall.onSuccess = onSuccess
  apiCall.auth = true
  return apiCall.getAction()
}


export function putChallengeInfo(onSuccess) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = PUT_CHALLENGE_INFO_STARTED
  apiCall.successReqType = PUT_CHALLENGE_INFO_SUCCESS
  apiCall.failureReqType = PUT_CHALLENGE_INFO_FAILURE
  apiCall.setEndpointFromState = () => PUT_CHALLENGE_INFO_URL
  apiCall.method = 'PUT'
  apiCall.setBodyFromState = state =>
    setChallengeInfo(state.data.challengeSteps.id, state.data.challengeSteps.challengeDescription)
  apiCall.onSuccess = onSuccess
  apiCall.auth = true
  return apiCall.getAction()
}

export function putPeopleInfoNew(onSuccess) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = PUT_PEOPLE_INFO_STARTED
  apiCall.successReqType = PUT_PEOPLE_INFO_SUCCESS
  apiCall.failureReqType = PUT_PEOPLE_INFO_FAILURE
  apiCall.setEndpointFromState = () => PUT_PEOPLE_INFO_URL
  apiCall.method = 'PUT'
  apiCall.setBodyFromState = state =>
    setPeopleInfo(
      state.data.ChallengeDraft.id,
      state.data.ChallengeDraft.selectedSponsor,
      state.data.ChallengeDraft.selectedProposer,
    )
  apiCall.onSuccess = onSuccess
  apiCall.auth = true
  return apiCall.getAction()
}


export function putPeopleInfo(onSuccess) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = PUT_PEOPLE_INFO_STARTED
  apiCall.successReqType = PUT_PEOPLE_INFO_SUCCESS
  apiCall.failureReqType = PUT_PEOPLE_INFO_FAILURE
  apiCall.setEndpointFromState = () => PUT_PEOPLE_INFO_URL
  apiCall.method = 'PUT'
  apiCall.setBodyFromState = state =>
    setPeopleInfo(
      state.data.challengeSteps.id,
      state.data.challengeSteps.selectedSponsor,
      state.data.challengeSteps.selectedProposer,
    )
  apiCall.onSuccess = onSuccess
  apiCall.auth = true
  return apiCall.getAction()
}

export function putRoiInfoNew(onSuccess) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = PUT_ROI_INFO_STARTED
  apiCall.successReqType = PUT_ROI_INFO_SUCCESS
  apiCall.failureReqType = PUT_ROI_INFO_FAILURE
  apiCall.setEndpointFromState = () => PUT_ROI_INFO_URL
  apiCall.method = 'PUT'
  apiCall.setBodyFromState = state =>
    setRoiInfo(
      state.data.ChallengeDraft.id,
      state.data.ChallengeDraft.gaming,
      state.data.ChallengeDraft.gaming.activeTab,
    )
  apiCall.onSuccess = onSuccess
  apiCall.auth = true
  return apiCall.getAction()
}

export function putRoiInfo(onSuccess) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = PUT_ROI_INFO_STARTED
  apiCall.successReqType = PUT_ROI_INFO_SUCCESS
  apiCall.failureReqType = PUT_ROI_INFO_FAILURE
  apiCall.setEndpointFromState = () => PUT_ROI_INFO_URL
  apiCall.method = 'PUT'
  apiCall.setBodyFromState = state =>
    setRoiInfo(
      state.data.challengeSteps.id,
      state.data.challengeSteps.gaming,
      state.data.challengeSteps.gaming.activeTab,
    )
  apiCall.onSuccess = onSuccess
  apiCall.auth = true
  return apiCall.getAction()
}

export function putRewardInfoNew(onSuccess) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = PUT_REWARD_INFO_STARTED
  apiCall.successReqType = PUT_REWARD_INFO_SUCCESS
  apiCall.failureReqType = PUT_REWARD_INFO_FAILURE
  apiCall.setEndpointFromState = () => PUT_REWARD_INFO_URL
  apiCall.method = 'PUT'
  apiCall.setBodyFromState = state =>
    setRewardInfo(state.data.ChallengeDraft.id, state.data.ChallengeDraft.reward)
  apiCall.onSuccess = onSuccess
  apiCall.auth = true
  return apiCall.getAction()
}


export function putRewardInfo(onSuccess) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = PUT_REWARD_INFO_STARTED
  apiCall.successReqType = PUT_REWARD_INFO_SUCCESS
  apiCall.failureReqType = PUT_REWARD_INFO_FAILURE
  apiCall.setEndpointFromState = () => PUT_REWARD_INFO_URL
  apiCall.method = 'PUT'
  apiCall.setBodyFromState = state =>
    setRewardInfo(state.data.challengeSteps.id, state.data.challengeSteps.reward)
  apiCall.onSuccess = onSuccess
  apiCall.auth = true
  return apiCall.getAction()
}

export function getGroups() {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_GROUPS_STARTED
  apiCall.successReqType = GET_GROUPS_SUCCESS
  apiCall.failureReqType = GET_GROUPS_FAILURE
  apiCall.setEndpointFromState = () => GET_GROUPS_URL
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformGroupsResponse
  return apiCall.getAction()
}

export function postLaunchChallenge(onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = POST_LAUNCH_CHALLENGE_STARTED
  apiCall.successReqType = POST_LAUNCH_CHALLENGE_SUCCESS
  apiCall.failureReqType = POST_LAUNCH_CHALLENGE_FAILURE
  apiCall.setEndpointFromState = () => POST_LAUNCH_CHALLENGE_URL
  apiCall.setBodyFromState = setLaunchBody
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export function putProposeChallenge(onSuccess, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = PUT_PROPOSE_CHALLENGE_STARTED
  apiCall.successReqType = PUT_PROPOSE_CHALLENGE_SUCCESS
  apiCall.failureReqType = PUT_PROPOSE_CHALLENGE_FAILURE
  apiCall.setEndpointFromState = () => PUT_PROPOSE_CHALLENGE_URL
  apiCall.setBodyFromState = setProposeBody
  apiCall.method = 'PUT'
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformChallengeDraft
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export function getChallenge(id, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_CHALLENGE_STARTED
  apiCall.successReqType = GET_CHALLENGE_SUCCESS
  apiCall.failureReqType = GET_CHALLENGE_FAILURE
  apiCall.setEndpointFromState = () => GET_CHALLENGE.replace('##challengeId##', id)
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformChallengeDraft
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export function getChallengeSelectedGroups(groupId, onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = GET_CHALLENGE_SELECTED_GROUPS_STARTED
  apiCall.successReqType = GET_CHALLENGE_SELECTED_GROUPS_SUCCESS
  apiCall.failureReqType = GET_CHALLENGE_SELECTED_GROUPS_FAILURE
  apiCall.setEndpointFromState = () =>
    GET_CHALLENGE_SELECTED_GROUPS_URL.replace('##groupId##', groupId)
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.transformResponseDataWithState = transformChallengeSelectedGroupsResponse
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}

export function putChallengeDates(onSuccess) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = PUT_CHALLENGE_DATES_STARTED
  apiCall.successReqType = PUT_CHALLENGE_DATES_SUCCESS
  apiCall.failureReqType = PUT_CHALLENGE_DATES_FAILURE
  apiCall.setEndpointFromState = () => PUT_CHALLENGE_DATES_URL
  apiCall.setBodyFromState = state => setChangeDatesBody(state)
  apiCall.method = 'PUT'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  return apiCall.getAction() 
}

// ================== new ui-ux services challenge save as draft  ================================


export function putChallengeDatesNew(onSuccess) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = PUT_CHALLENGE_DATES_STARTED
  apiCall.successReqType = PUT_CHALLENGE_DATES_SUCCESS
  apiCall.failureReqType = PUT_CHALLENGE_DATES_FAILURE
  apiCall.setEndpointFromState = () => PUT_CHALLENGE_DATES_URL
  apiCall.setBodyFromState = state => setChangeDatesBodyNew(state)
  apiCall.method = 'PUT'
  apiCall.auth = true
  apiCall.onSuccess = onSuccess
  return apiCall.getAction() 
}


export function saveDraftChallenges(onSuccess,onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = PUT_DRAFT_CHALLENGE_STARTED
  apiCall.successReqType = PUT_DRAFT_CHALLENGE_SUCCESS
  apiCall.failureReqType = PUT_DRAFT_CHALLENGE_FAILURE
  apiCall.setEndpointFromState = () => PUT_DRAFTCHALLENGE_URL
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.setBodyFromState = setChallengeDraft 
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformChallengeDraft
  return apiCall.getAction()
}


export function saveDraftProposeChallenges(onSuccess,onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = PUT_DRAFT_CHALLENGE_STARTED
  apiCall.successReqType = PUT_DRAFT_CHALLENGE_SUCCESS
  apiCall.failureReqType = PUT_DRAFT_CHALLENGE_FAILURE
  apiCall.setEndpointFromState = () => PUT_DRAFTCHALLENGE_URL
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.setBodyFromState = setProposeBodyDraft 
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformChallengeDraft
  return apiCall.getAction()
}


export function saveLaunchChallenges(onSuccess,onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = POST_LAUNCH_CHALLENGE_STARTED
  apiCall.successReqType = POST_LAUNCH_CHALLENGE_SUCCESS
  apiCall.failureReqType = POST_LAUNCH_CHALLENGE_FAILURE
  apiCall.setEndpointFromState = () => POST_LAUNCH_CHALLENGE_URL
  apiCall.method = 'POST'
  apiCall.auth = true
  apiCall.setBodyFromState = setLaunchBodyChallenges 
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  return apiCall.getAction()
}


export function postChallengeDraftImage({
  image,
  onSuccess,
  onFailure,
  type,
  startedAction,
  successAction,
  failureAction,
}) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = startedAction
  apiCall.successReqType = successAction
  apiCall.failureReqType = failureAction
  apiCall.setEndpointFromState = () => ADD_IMAGE_URL
  apiCall.method = 'POST'
  apiCall.setBodyFromState = state => setImageContentSaveDraft(image, state, type)
  apiCall.onSuccess = onSuccess
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformImageServiceResponseSaveDraft
  apiCall.auth = true
  return apiCall.getAction()
}


export function deleteChallengeImageSaveDraft({
  imageName,
  onFailure,
  type,
  startedAction,
  successAction,
  failureAction,
}) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = startedAction
  apiCall.successReqType = successAction
  apiCall.failureReqType = failureAction
  apiCall.setEndpointFromState = state =>
    `${DELETE_IMAGE_URL}/${setDeleteImageContentSaveDraft(imageName, state, type)}` 
  apiCall.method = 'DELETE'
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformDeleteImageResponse
  apiCall.auth = true
  return apiCall.getAction()
}


export function postInfoChallengeImageSaveDraft(image, onSuccess, onFailure) {
  return postChallengeDraftImage({
    image,
    onSuccess,
    onFailure,
    type: 'info',
    startedAction: ADD_CHALLENGE_STEPS_IMAGE_STARTED,
    successAction: ADD_CHALLENGE_STEPS_IMAGE_SUCCESS,
    failureAction: ADD_CHALLENGE_STEPS_IMAGE_FAILURE,
  })
}

export function postChallengeMainImageSaveDraft(imageName, onFailure){
  const apiCall = new ServiceCall()
  apiCall.startedReqType = SET_CHALLENGE_MAIN_IMAGE_STARTED
  apiCall.successReqType = SET_CHALLENGE_MAIN_IMAGE_SUCCESS
  apiCall.failureReqType = SET_CHALLENGE_MAIN_IMAGE_FAILURE
  apiCall.setEndpointFromState = () => POST_MAIN_IMAGE_URL
  apiCall.method = 'POST'
  apiCall.setBodyFromState = state => setMainImageContentSaveDraft(imageName, state)
  apiCall.onFailure = onFailure
  apiCall.transformResponseDataWithState = transformMainImageServiceResponse
  apiCall.auth = true
  return apiCall.getAction()
}

export function deleteInfoImageSaveDraft(imageName, onFailure) {
  // console.log("enter intdex dlete=>",imageName)
  return deleteChallengeImageSaveDraft({ 
    imageName,
    onFailure,
    type: 'info',
    startedAction: DELETE_CHALLENGE_INFO_IMAGE_STARTED,
    successAction: DELETE_CHALLENGE_INFO_IMAGE_SUCCESS,
    failureAction: DELETE_CHALLENGE_INFO_IMAGE_FAILURE,
  })
}
