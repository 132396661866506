/* eslint-disable no-console */
/* eslint-disable no-var */
/* eslint-disable func-names */

import i18n from 'support/i18n'

const getDateFromObject = date => {
  const result = date === null ? undefined : date

  if (
    typeof result !== 'undefined' &&
    typeof result !== 'string' &&
    Object.keys(result).length > 0
  ) {
    return result.timestamp === null ? undefined : result.timestamp
  }

  if (
    (typeof result !== 'undefined' && Object.keys(result).length === 0) ||
    typeof result === 'string'
  ) {
    return new Date(result).getTime()
  }
  return result
}

export const parseEvoluationIdeasData = data => {
  return data.map(object => {
    return {
      anonymous: object.anonymous,
      challengeRef: object.challengeRef,
      challengeTitle: object.challengeTitle,
      challenged: object.challenged,
      countdownEnds: getDateFromObject(object.countdownEnds),
      created: getDateFromObject(object.created),
      documentRL: object.documentRL,
      externalVideo: object.externalVideo,
      goal: `${object.goal || '0'}`,
      id: object.id,
      images: object.images,
      innovationTeamUsers: object.innovationTeamUsers,
      investors: `${object.investors || '0'}`,
      isPrivate: object.isPrivate,
      kpis: object.kpis,
      logistic: object.logistic,
      logisticRef: object.logisticRef,
      numDocuments: object.numDocuments,
      numPrivateComments: object.numPrivateComments,
      numPublicComments: object.numPublicComments,
      organizations: object.organizations,
      owner: object.owner,
      ownerName: object.anonymous ? i18n.t('ANONYMOUS') : object.ownerName,
      pledged: `${object.pledged || '0'}`,
      published: object.published,
      singleId: object.singleId,
      sponsored: object.sponsored,
      status: object.status,
      teamUsers: object.teamUsers,
      text: object.text,
      textWithoutTags: object.textWithoutTags,
      title: object.title,
      url: object.url,
      visits: object.visits,
    }
  })
}

export const transformEvaluationIdea = jsonResponse => {
  return jsonResponse.map(user => ({
    email: user.email,
    fullName: user.fullname || '',
    photoSrc: user.photo || '',
    balance: `${user.balance}`,
    investedFunds: `${user.currentAmountInvested}`,
  }))
}

export const transformFilterIdeasServiceResponseDataWithState = jsonResponse => {
  return {
    data: parseEvoluationIdeasData(jsonResponse.data),
    items: jsonResponse.items,
    total: jsonResponse.total,
  }
}
export const transformFilterIdeasArchivedServiceResponseDataWithState = jsonResponse => {
  return {
    dataArchived: parseEvoluationIdeasData(jsonResponse.data),
    itemsArchived: jsonResponse.items,
    totalArchived: jsonResponse.total,
  }
}
export const transformFilterIdeasPendingServiceResponseDataWithState = jsonResponse => {
  return {
    dataPending: parseEvoluationIdeasData(jsonResponse.data),
    itemsPending: jsonResponse.items,
    totalPending: jsonResponse.total,
  }
}


export const transformIdeaevaluation = data => {
  return {
    id: data.id,
    statusType: data.statusType,
    roundClosed: data.roundClosed,
    ownerEmail: data.ownerEmail,
    ideaTitle: data.ideaTitle,
    ideaText: data.ideaText,
    Q1Comment: data.Q1Comment,
    Q1measurement: data.Q1measurement,
    Q2Comment: data.Q2Comment,
    Q2measurement: data.Q2measurement,
    Q3Comment: data.Q3Comment,
    Q3measurement: data.Q3measurement,
    type: data.type,
  }
}

export const transformEvaluatiorsComments = data => {
  return data.map(comments => ({
    Q1Comment: comments.Q1Comment,
    Q1measurement: comments.Q1measurement,
    Q2Comment: comments.Q2Comment,
    Q2measurement: comments.Q2measurement,
    Q3Comment: comments.Q3Comment,
    Q3measurement: comments.Q3measurement,
    loggedInUser: comments.loggedInUser,
    type: comments.type,
  }))
}

export const transformEvaluationIdeaPost = jsonResponse => {
  return jsonResponse.map(user => ({
    email: user.email,
    fullName: user.fullname || '',
    photoSrc: user.photo || '',
    balance: `${user.balance}`,
    investedFunds: `${user.currentAmountInvested}`,
  }))
}

export const parseEvaluationExpertCommentFromMail = data => {
  return data.map(comment => ({
    ratings: comment.ratings,
    expertComments: comment.expertComments,
    emailIdLoggedInUser: comment.emailIdLoggedInUser,
  }))
}
export const transformGetEvaluationExpertCommentFromMail = jsonResponse => {
  return {
    expertCommentMail: parseEvaluationExpertCommentFromMail(jsonResponse.list),
  }
}



export const transformIdeaevaluationById = data => {
  return {
    //id: data.id,
    statusType: data.statusType,
    roundClosed: data.roundClosed,
    ownerEmail: data.ownerEmail,
    ideaTitle: data.ideaTitle,
    ideaText: data.ideaText,
    boardReviewDate: data.boardReviewDate,
    boardStatus: data.boardStatus,
    feedbackForIdeaOwner: data.feedbackForIdeaOwner,
    boardComment: data.boardComment,
    ideaPitchDate: data.ideaPitchDate,
    pitchStatus: data.pitchStatus,
    images: data.images.map(image => ({
      key: image.key,
      name: image.name,
      src: image.url,
    })),
  }
}




/* eslint-disable no-param-reassign */
export const getGraphPercentageQ1 = data => {
  if (data.length === 0) {
    return [['Task', 'Hours per Day'], ['No data', '1']]
    //return []
  }
  const arr1 = []
  var count = {}
  data.forEach(obj => {
    arr1.push(obj.Q1measurement)
  })
  arr1.forEach(function(i) {
    count[i] = (count[i] || 0) + 1
  })
  var count1 = [count].map(function(obj) {
    obj['zero'] = obj['0'] // Assign new key
    obj['one'] = obj['1']
    obj['two'] = obj['2']
    obj['Three'] = obj['3']
    delete obj['0'] // Delete old key
    delete obj['1']
    delete obj['2']
    delete obj['3']
    return obj
  })
  var abc = Object.entries(count1[0])
  abc.splice(0, 0, ['Task', 'Hours per Day'])
  return abc
}

export const getGraphPercentageQ2 = data => {
  if (data.length === 0) {
    return [['Task', 'Hours per Day'], ['No data', '1']]
    //return []
  }
  const arr1 = []
  var count = {}
  data.forEach(obj => {
    arr1.push(obj.Q2measurement)
  })
  arr1.forEach(function(i) {
    count[i] = (count[i] || 0) + 1
  })
  var count1 = [count].map(function(obj) {
    obj['zero'] = obj['0'] // Assign new key
    obj['one'] = obj['1']
    obj['two'] = obj['2']
    obj['Three'] = obj['3']
    delete obj['0'] // Delete old key
    delete obj['1']
    delete obj['2']
    delete obj['3']
    return obj
  })
  var abc = Object.entries(count1[0])
  abc.splice(0, 0, ['Task', 'Hours per Day'])
  return abc
}

export const getGraphPercentageQ3 = data => {
  if (data.length === 0) {
    return [['Task', 'Hours per Day'], ['No data', 1]]
    //return []
  }
  const arr1 = []
  var count = {}
  data.forEach(obj => {
    arr1.push(obj.Q3measurement)
  })
  arr1.forEach(function(i) {
    count[i] = (count[i] || 0) + 1
  })
  /* eslint-disable no-param-reassign */
  var count1 = [count].map(function(obj) {
    obj['zero'] = obj['0'] // Assign new key
    obj['one'] = obj['1']
    obj['two'] = obj['2']
    obj['Three'] = obj['3']
    delete obj['0'] // Delete old key
    delete obj['1']
    delete obj['2']
    delete obj['3']
    return obj
  })
  var abc = Object.entries(count1[0])
  abc.splice(0, 0, ['Task', 'Hours per Day'])
  return abc
}
export const getGraphPercentageType = data => {
  if (data.length === 0) {
    return [['Task', 'Hours per Day'], ['No data', 1]]
    //return []
  }
  const arr1 = []
  var count = {}
  data.forEach(obj => {
    arr1.push(obj.type)
  })
  arr1.forEach(function(i) {
    count[i] = (count[i] || 0) + 1
  })
  var count1 = [count].map(function(obj) {
    obj['No Action'] = obj['null'] // Assign new key
    delete obj['null'] // Delete old key
    return obj
  })
  var abc = Object.entries(count1[0])
  abc.splice(0, 0, ['Task', 'Hours per Day'])
  return abc
}


export const transformEvaluatiorsGraph = data => {
  return {
    Q1measurement: getGraphPercentageQ1(data),
    Q2measurement: getGraphPercentageQ2(data),
    Q3measurement: getGraphPercentageQ3(data),
    Qcomments: transformEvaluatiorsComments(data),
    type: getGraphPercentageType(data),
  }
}

function parseExpertGroups(groupsList) {
  return groupsList.map((group, idx) => ({
    id: idx,
    name: group.name,
    category: group.category || '',
    users: group.au,
    numChallengesActives: group.numChallengesActives,
    groupId: group.id,
  }))
}


function parseUsersOnGroup(userOnGroup) {
  if (userOnGroup) {
    return userOnGroup.map(user => ({
      name: user.fullname,
      email: user.id,
      //photo: user.photo,
      isExpertInivited: user.isExpertInivited,
      defaultChecked: false,
    }))
  }
  return []
}

export const transformGetEvaluationArchivedIdeaByIdResponse = jsonResponse => {
  // console.log(`transformGetEvaluationArchivedIdeaByIdResponse...123 ..${JSON.stringify(jsonResponse.archiveidea)}`)
  return {
    archiveidea: transformIdeaevaluationById(jsonResponse.archiveidea),
    list: transformEvaluatiorsGraph(jsonResponse.list),
  }
}

export const transformEvaluationDraft = jsonResponse => {
  //console.log(`Data response: ${JSON.stringify(data)}`)
  return {
    ideaevaluation: transformIdeaevaluation(jsonResponse.ideaevaluation),
    list: transformEvaluatiorsComments(jsonResponse.list),
  }
}


export const transformGetExpertGroupsResponse = jsonResponse => {
  return {
    expertGroups: parseExpertGroups(jsonResponse.data),
  }
}
export const transformGetUsersOnExpertGroupResponse = jsonResponse => {
  return {
    usersOnExpertGroup: parseUsersOnGroup(jsonResponse.data),
    memberUsersTotal: jsonResponse.total,
  }
}
export const transformGetEvaluationIdeaByIdResponse = jsonResponse => {
  // console.log(`transformGetEvaluationIdeaByIdResponse...123 ..${JSON.stringify(jsonResponse.ideaevaluation)}`)
  return {
    ideaevaluation: transformIdeaevaluationById(jsonResponse.ideaevaluation),
    list: transformEvaluatiorsGraph(jsonResponse.list),
  }
}

