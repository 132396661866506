import React from 'react'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import { withStyles } from '@material-ui/core'
import { Image } from 'components'
import bulbImage from 'img/bulb_rocket.png'
import styles from './View.Style'

const BadgeCard = props => {
  const { classes, badges = [], badgeType } = props
  const actualBadge = badges.find(badge => badge.name === badgeType) || { imgSrc: '' }
  return (
    <>
      <div className={classes.title}>
        <span className="icon-best" />
        <span>{i18n.t('TIMELINE.BADGE_WIN')}</span>
      </div>
      <div className={classes.body}>
        <div className={classes.badgeDescription}>
          <Image defaultSrc={bulbImage} src={actualBadge.imgSrc} className={classes.badge} />
          <span>
            {i18n
              .t(`BADGES.${badgeType}`)
              .replace('@@IDEA_STATUS.APPROVED_BY_IT@@', i18n.t('IDEA_STATUS.APPROVED_BY_IT'))
              .replace('@@IDEA_STATUS.SUPPORTED@@', i18n.t('IDEA_STATUS.SUPPORTED'))
              .replace('@@IDEA_STATUS.PILOT_ACTIVE@@', i18n.t('IDEA_STATUS.PILOT_ACTIVE'))}
          </span>
        </div>
      </div>
    </>
  )
}

BadgeCard.propTypes = {
  badges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default withStyles(styles)(BadgeCard)
