/* eslint-disable react/prefer-stateless-function ,react/state-in-constructor */
/* eslint-disable no-console */
import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Card from '@material-ui/core/Card'
import { AboutUsDetails, HowItWorks, NovaDelivered, Faq } from 'components'
import styles from './View.Style'

class AboutUs extends Component {
  state = {
    value: 0,
  }

  componentDidMount() {
    const { getAboutUsDetails } = this.props
    getAboutUsDetails()
  }

  handleChange = (event, value) => {
    this.setState({ value })
  }

  render() {
    const { classes, aboutus } = this.props
    const { value } = this.state
    console.log(`aboutus: ${JSON.stringify(aboutus)}`)
    return (
      <div className={classes.top}>
        <div className={classes.topCenter}>
          <Paper className={classes.root}>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={value}
              onChange={this.handleChange}
              centered
            >
              <Tab label="About Us" />
              <Tab label="How NOVA Works" />
              <Tab label="NOVA Prototypes" />
              <Tab label="FAQ" />
            </Tabs>
          </Paper>
          {value === 0 && (
            <Card className={classes.card}>
              <AboutUsDetails aboutus={aboutus.aboutUsData} />
            </Card>
          )}
          {value === 1 && (
            <Card className={classes.card}>
              <HowItWorks />
            </Card>
          )}
          {value === 2 && (
            <Card>
              <NovaDelivered />
            </Card>
          )}
          {value === 3 && (
            <Card>
              <Faq />
            </Card>
          )}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(AboutUs)
