/* eslint-disable react/destructuring-assignment, react/no-unescaped-entities, react/prefer-stateless-function  */

import React, { Component } from 'react'
import './style.css';

import * as PropTypes from 'prop-types'
 

export default class AzureSsoError extends Component {
    render() {
        return (
            <div className="clouds">
                <div id="clouds">
                    <div className="cloud x1"></div>
                    <div className="cloud x1_5"></div>
                    <div className="cloud x2"></div>
                    <div className="cloud x3"></div>
                    <div className="cloud x4"></div>
                    <div className="cloud x5"></div>
                </div>
                <div className='c'>
                    <div className='_404'>User Not Found</div>
                    <hr/>
                    <div className='_1'>It seems that your account doesn't have access to NOVA platform</div>
                    <div className='_2'>please reach out to <a href="mailto:nova@vodafone.com">nova@vodafone.com</a> to request access to NOVA</div>
              {/* <a className='btn' onClick={this.props.goBack}>Back To eRIS</a>  */}
                    <button type='button' className='btn' onClick={this.props.goBack}>Back To NOVA</button>
                </div>
           </div>
        )
    }
}


