import React from "react";
import withStyles from '@material-ui/core/styles/withStyles'
import styles from "./View.Style";
import defaultImg from 'img/hero_image.png'

const DraftIdeaCard = props => {
    const {
        classes,
        idea,
        handleDelete,
        handleContinue,
    } = props
    const {
        title, 
        status, 
        images, 
        created,
        textWithoutTags, 
        // investment_total, 
        investors,
    } = idea
    
    const getDayStr = day => {
        const chars = ['th', 'st', 'nd', 'rd']
        // console.log(day%10)
        // console.log(chars[day%10])
        if ((day > 3 && day < 21) || (day % 10 > 3)){ 
            // console.log('why here')
            return `${day}${chars[0]} `
        }
        // else 
        return `${day}${chars[day%10]} `
    }

    const formatDate = epochMs => {
        const date = new Date(epochMs)
        return(`${getDayStr(date.toLocaleDateString("en-US", {day: "numeric"}))} ${date.toLocaleDateString("en-US", {month: 'long'})} ${date.toLocaleDateString("en-US", {year: 'numeric'})}`)
    }
    

    return(
        <div className={classes.container}>
            <img className={classes.idea_img} src={images[0].url} onError={({currentTarget}) => {
                    currentTarget.onerror = null
                    currentTarget.src = defaultImg
                }} alt="idea img" />
            <div className={classes.bottom_part}>
                <div>
                    <div className={classes.idea_title}>{title}</div>
                    <div className={classes.creation_date}>Date created: {formatDate(created)}</div>
                </div>
                <div className={classes.buttons}>
                    <button type="button" className={classes.delete_btn}  onClick={handleDelete}>Delete</button>
                    <button type="button" className={classes.continue_btn}  onClick={handleContinue}>Continue</button>
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(DraftIdeaCard)