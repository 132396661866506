import React from 'react'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import { withStyles, Button } from '@material-ui/core'
import AutoSuggestion from 'components/autosuggestion/View'
import SuggestionSelected from 'components/suggestion_selected/View'
import styles from './View.Style'

const TransferLeader = props => {
  const {
    classes,
    showTransferLeader,
    onTransferLeader,
    onSearchSuggestions,
    suggestions,
    onSelectSuggestion,
    selectedUser,
    onRemoveUser,
    isSearchingForUser,
  } = props
  return (
    <div className={classes.root}>
      {showTransferLeader && (
        <AutoSuggestion
          getSuggestions={onSearchSuggestions}
          suggestions={suggestions}
          minLengthToSearch={3}
          onSuggestionSelected={onSelectSuggestion}
          inputLabel={i18n.t('EXPLORE_IDEA.TRANSFER_DESC')}
          isLoading={isSearchingForUser}
        />
      )}
      {!showTransferLeader && Object.keys(selectedUser).length !== 0 && (
        <>
          <SuggestionSelected suggestion={selectedUser} onRemoveSuggestion={onRemoveUser} />
          <Button variant="contained" color="primary" onClick={onTransferLeader}>
            {i18n.t(`EXPLORE_IDEA.TRANSFER_NOW`)}
          </Button>
        </>
      )}
    </div>
  )
}

TransferLeader.defaultProps = {
  selectedUser: {},
}

TransferLeader.propTypes = {
  showTransferLeader: PropTypes.bool.isRequired,
  onTransferLeader: PropTypes.func.isRequired,
  onSearchSuggestions: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelectSuggestion: PropTypes.func.isRequired,
  selectedUser: PropTypes.shape({}),
  onRemoveUser: PropTypes.func.isRequired,
  isSearchingForUser: PropTypes.bool.isRequired,
}

export default withStyles(styles)(TransferLeader)
