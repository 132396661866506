/* eslint-disable react/jsx-boolean-value */

import React, { Component } from 'react'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Thankyou from '../../assets/thankyou.png'
import Box from '@material-ui/core/Box'
// import FormControl from '@material-ui/core/FormControl'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
//import FormLabel from '@material-ui/core/FormLabel'
// import Radio from '@material-ui/core/Radio'
// import RadioGroup from '@material-ui/core/RadioGroup'
// import { withStyles, makeStyles } from '@material-ui/core/styles'
// import style from './View.Style.js'

class EvaluationThankyou extends Component {
  constructor(props) {
    super(props)
    // this.state = {
    //   radioVal: '',
    // }
    this.handleHome = this.handleHome.bind(this)
  }

  handleHome() {
    const { goHome } = this.props
    goHome()
    window.location.reload()
  }

  render() {
    const { goBack } = this.props
    return (
        <Box item xs={12} width="100%" maxHeight="420px">
          <Container
            style={{ height: '100%', width: '100%', backgroundColor: 'white', padding: '30px' }}
          >
            <Box display="flex" justifyContent="center">
              {/*Disabled overrieds normal color="secondary" syntax, so will need ID for higher priority CSS*/}
              <Box px={1}>
                <Button variant="contained" px={3} disabled id="redBackground"></Button>
              </Box>
              <Box px={1}>
                <Button variant="contained" px={3} disabled id="greyBackground"></Button>
              </Box>
              <Box px={1}>
                <Button variant="contained" px={3} disabled id="greyBackground"></Button>
              </Box>
              <Box px={1}>
                <Button variant="contained" px={3} disabled id="greyBackground"></Button>
              </Box>
            </Box>
            <Box py={1} display="flex" justifyContent="center">
              {/*Variant affects actual size, component keeps HTML nerds happy by having a logical progression of H1 to H6 etc*/}
              <Typography variant="h5" component="h1">
                Thank you for your input!
              </Typography>
            </Box>
            <Box py={3} display="flex" justifyContent="center">
              <img src={Thankyou} alt="logo" height="20%" width="20%" />
            </Box>
            <Box py={1} display="flex" justifyContent="center">
              {/*Variant affects actual size, component keeps HTML nerds happy by having a logical progression of H1 to H6 etc*/}
              <Typography variant="subtitle1">
                We have loggged your feedback. Click below to see how this idea has performed with
                other board members.
              </Typography>
            </Box>

            <Box pt={1} display="flex" justifyContent="center">
              <Box px={1}>
                <Button
                  disabled={true}
                  variant="contained"
                  px={3}
                  id="redBackground"
                  color="primary"
                  height="100%"
                  onClick={goBack}
                >
                  Live Results
                </Button>
              </Box>
              <Box px={1}>
                <Button
                  variant="contained"
                  px={3}
                  id="redBackground"
                  color="primary"
                  height="100%"
                  onClick={this.handleHome}
                >
                  Home
                </Button>
              </Box>
              {/*<Box px={1}>
                <Button variant="contained" px={3} id="redBackground" height="100%">
                  Submit
                </Button>
              </Box> */}
            </Box>
          </Container>
        </Box>
    )
  }
}
export default EvaluationThankyou
