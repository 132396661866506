/* eslint-disable no-shadow */

import React, {  useEffect, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'
import { FIRST_LIMIT_CONSTANT, LIMIT_CONSTANT } from 'common_constants/Ideas'
import Breadcrumbs from 'components_newui/pageBreadcrumbsMenu/View'
import IdeaPanel from '../../components_newui/idea_panel/View'

// import Recommended_Ideas from '../../components_newui/recommended_ideas/View'
import FilterComponent from '../../components/filter_component/View'
import noDraftIdeasImg from '../../img/no_draft_idea.png'
import Navigation from 'support/navigation'

const AllIdeas = props => {
    const {
        classes, 
        domainLoaded,
        ideas,
        isIdeasLoading,
        challenges,
        totalIdeas,
        hasMoreIdeasToLoad,
        footerInvestors,
        footerEntrepreneurs,
        headerSection,
        helpList,
        organizationsName,
        organizations,
        folderOrganizations,
        types,
        typeTitle,
        useHelpSection,
        ideaStatusStepper,
        isOrganizationsActive,
        isTypesActive,
        isPrivateFlowActive,
        privateBannerSrc,
        organisationsSelected,
        hasUserOrganisationsLoaded,
        expiringIdeas,
        navigateToIdea,
        getOrganization,
        getTypes,
        applySearch,
        query,
        navigateToInvestedIdeas
    } = props

    const [firstQueryUsed, setFirstQueryUsed] = useState(false)
    //We need a useState for offset I think

    const [searchQuery, setSearchQuery] = useState(query)

    const description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce commodo feugiat dui nec commodo."

    //Maybe have page hierarchy come from state????? at least get navigate functions from state
    const pageHierarchy = [
        {string: "Home", navigate: ()=>Navigation.navigateToExplore()}, 
        {string: "Search", navigate: ()=>{}}
    ]

    const [activeFilters, setActiveFilters] = useState({
        status: [],
        challenge: {},
        organizations: [],
        folderOrganizations: [],
        types: [],
    })


    
    useEffect(()=> {
        const { getUserOrganisations, getActiveChallenges, getRankings, domainLoaded, getExpiringNearFunded, getTypes } = props
        if (domainLoaded) {
          getUserOrganisations()
          getRankings()
          getActiveChallenges()
          getTypes()
          getExpiringNearFunded()
        }
    },[])


    useEffect(()=> {
        const {
            getHelpSection,
            getHeaderSection,
        } = props
        if (useHelpSection){
            getHeaderSection()
            getHelpSection()
        }
    }, [useHelpSection])

    useEffect(() => {
        const {
            getActiveChallenges,
            getRankings,
            getUserOrganisations,
            getTypes,
            getExpiringNearFunded,
          } = props
        if (domainLoaded){
            getUserOrganisations()
            getActiveChallenges()
            getExpiringNearFunded()
            getRankings()
            getTypes()
        }
    }, [domainLoaded])


    const getIdeas = (offset = 0) => {
        const { applyFilter } = props
        const { status, challenge, folderOrganizations, types, ideasLocation } = activeFilters
        
        if (query.length > 0 && !firstQueryUsed) {
            applySearch(query)
            setFirstQueryUsed(true)
        } else{
            applyFilter(
            challenge.id || '',
            folderOrganizations.map(org => `${org.name}`),
            status,
            types.map(type => `${type.id}`),
            ideasLocation,
            offset ? LIMIT_CONSTANT : FIRST_LIMIT_CONSTANT,
            offset,
            )
        }
    }

    useEffect(()=> {
        const {organisationsSelected} = props
        if (domainLoaded && hasUserOrganisationsLoaded){
            setFirstQueryUsed(false)
            setActiveFilters(prevState => {
                return{
                    ...prevState,
                    folderOrganizations: organisationsSelected,
                }
            })
        }
        else{
            getIdeas()
        }
    }, [hasUserOrganisationsLoaded])

    const handleActiveFilters = async (newFilters) => {
        await setActiveFilters(newFilters)
    }

    useEffect(()=> {
        getIdeas()
    }, [activeFilters])

    return(
        
            <main className={classes.background}>
            <Breadcrumbs crumbs={pageHierarchy} />
                
                <FilterComponent
                    activeFilters={activeFilters}
                    serviceLineOrgs={organizations}
                    types={types}
                    handleActiveFilters={handleActiveFilters}
                    search={searchQuery}
                    preSearched={query.length > 0 && !firstQueryUsed}
                    setSearch={setSearchQuery}
                    applySearch={applySearch}
                    resetSearch={getIdeas}
                />
                
                <div className={classes.content_container}>
                    {ideas.length > 0 ?
                    <div className={classes.idea_grid}>
                        {ideas.map((idea,i) => <IdeaPanel key={idea.title} onMainButtonClick={navigateToIdea} idea={idea} />)}
                    </div>
                    :
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                        <img src={noDraftIdeasImg} alt='no draft img' className={classes.no_idea_img}/>
                        <div className={classes.no_idea_title}>
                            No ideas found
                        </div>
                    </div>
                    }
                </div>
                
            </main>
        
    )
}

export default withStyles(styles)(AllIdeas)