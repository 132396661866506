import React from 'react'

const Error = () => {
  return (
    <div>
      <h1>Error</h1>
      <p>UPS! You are in Error page. Sorry :(</p>
    </div>
  )
}

export default Error
