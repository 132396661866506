/* eslint-disable jam3/no-sanitizer-with-danger */

import React from 'react'
import { Modal } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import i18n from 'support/i18n'
// import thumbnail from 'img/nxt-logo-fondo-ideas.png'
import removeIcon from 'img/close-system.png'
// import Divider from '@material-ui/core/Divider'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Checkbox from '@material-ui/core/Checkbox'
// import documentUpload from 'img/pdf_ico.png'
import * as PropTypes from 'prop-types'
// import Challenge_card from '../../components_newui/challenge_card/View'
import format from 'date-fns/format'
import {fromValueToPercentage} from 'pagesNewUi/post_challenge/View.Utils'
import styles from './View.Style'


const ChallengePreview = props => {
  const {
    isLoading,
    ChallangesIsActive,
    showPreview,
    setShowPreview,
    classes,
    handleCancleClick,
    handleSubmitClick,
    title,
    images,
    video,
    description,
    whyImportant,
    fromDate,
    toDate,
    sponsor,
    proposer,
    optionReward,
    organization,
    approveMult,
    implementMult,
    supportMult,
    currentGoal,
    defaultValues,
    organizationSelectedName,
  } = props


  const fromDateNumber = new Date(fromDate).getTime()
  const toDateNumber = new Date(toDate).getTime()
//   console.table('toDateNumber', toDateNumber, fromDateNumber)
//   const mockUploadedVideos = [thumbnail, thumbnail, thumbnail]

  let formdataFormate

  if (fromDate !== undefined && fromDate !== null) {
    formdataFormate = format(fromDateNumber, 'PPP')
  }
  let toDateFormate
  if (toDate !== undefined && toDate !== null) {
    toDateFormate = format(toDateNumber, 'PPP')
  }
  let gamingMode
  if (gamingMode) {
    gamingMode = {
      approveMult: fromValueToPercentage(approveMult, defaultValues.approveMult),
      implementMult: fromValueToPercentage(implementMult, defaultValues.implementMult),
      supportMult: fromValueToPercentage(supportMult, defaultValues.supportMult),
      currentGoal,
    }
  }

  const shortenFileName = filename => {
    const [name] = filename.split('.')
    let shortName = name
    if (shortName.length > 9) {
      shortName = `${shortName.substring(0, 4)}..${shortName.substring(shortName.length - 4)}`
    }
    return `${shortName}`
  }



  return (
      <Modal
        open={showPreview}
        onClose={() => setShowPreview(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes.innerWidth}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <button type="button" className={classes.removeIconBtn} onClick={() => setShowPreview(false)}>
            <img
              src={removeIcon}
              style={{
                height: '30px',
                cursor: 'pointer',
              }}
             alt="cancel"
            />
            </button>

            
          </div>

          <h3 className={classes.heading}>Challenge title</h3>
          <p className={classes.titleDesc}>{title}</p>
          {/* <h3 className={classes.heading}>Uploaded Images/Videos</h3> */}
          <div className={classes.uploadedPicsAndVids}>
            <div className={classes.firstblock}>
              <p className={classes.boldFont}>Uploaded Images</p>
              <div className={classes.uploadedImages}>
                {images.map((picture) => (
                  <figure key={picture.name} className={classes.picIcon}>
                    <img src={picture.src} alt={picture.name} />
                    {picture.name ? <figcaption>{shortenFileName(picture.name)}</figcaption> : null}
                  </figure>
                ))}
              </div>
            </div>
            <div className={classes.secondblock}>
            {video && (
            <iframe
              className={classes.iframe}
              title="ytVideo"
              width="320"
              height="180"
              src={video}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
            </div>
          </div>

          <div className={classes.horizontal_line} />

          <h3 className={classes.heading}>Challenge description</h3>
          <div className={classes.ideaDescription}>
            <div className={classes.answers}>
              {/* <p className={classes.boldFont}>Tell us about the problem you are trying to solve.</p> */}
              <p>
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </p>
            </div>
          </div>

          <div className={classes.horizontal_line} />

          <h3 className={classes.heading}>Why is this important?</h3>
          <div className={classes.ideaDescription}>
            <div className={classes.answers}>
              {/* <p className={classes.boldFont}>Tell us about the problem you are trying to solve.</p> */}
              <p>
                <div dangerouslySetInnerHTML={{ __html: whyImportant }} />
              </p>
            </div>
          </div>

          {formdataFormate && (
            <>
              <div className={classes.horizontal_line} />

              <h3 className={classes.heading}>When is the challenge active?</h3>
              <div className={classes.twocols}>
                <div className={classes.answers}>
                  <p className={classes.boldFont}>From Date</p>
                  <p>{formdataFormate}</p>
                </div>
                <div className={classes.answers}>
                  <p className={classes.boldFont}>To Date</p>
                  <p>{toDateFormate}</p>
                </div>
              </div>
            </>
          )}

          {sponsor && (
            <>
              <div className={classes.horizontal_line} />
              <div className={classes.twocols}>
                <div className={classes.answers}>
                  <h3 className={classes.heading}>Sponsor*</h3>
                  <p>{sponsor}</p>
                </div>

                <div className={classes.answers}>
                  <h3 className={classes.heading}>Proposer</h3>
                  <p>{proposer}</p>
                </div>
              </div>
            </>
          )}

          {gamingMode && (
            <>
              <div className={classes.horizontal_line} />

              <h3 className={classes.heading}>Gaming Parameters(ROI)</h3>
              <div className={classes.fourcols}>
                <div className={classes.blocks}>
                  <p>{gamingMode && gamingMode.currentGoal}</p>
                  <p className={classes.boldFont}>Idea Goal</p>
                </div>
                <div className={classes.blocks}>
                  <p>{gamingMode && gamingMode.approveMult} %</p>
                  <p className={classes.boldFont}>Approved</p>
                </div>
                <div className={classes.blocks}>
                  <p>{gamingMode && gamingMode.implementMult} %</p>
                  <p className={classes.boldFont}>Implemented</p>
                </div>
                <div className={classes.blocks}>
                  <p>{gamingMode && gamingMode.supportMult} %</p>
                  <p className={classes.boldFont}>Supported</p>
                </div>
              </div>
            </>
          )}

          {optionReward &&
            (optionReward.description ||
              (optionReward.images && optionReward.images.src) ||
              optionReward.rewardedOther) && (
              <>
                <div className={classes.horizontal_line} />

                <h3 className={classes.heading}>Reward</h3>
                <div className={classes.twocols}>
                  <div className={classes.leftside}>
                    <div className={classes.answers}>
                      <p className={classes.boldFont}>Reward-title</p>
                      <p>{optionReward.description}</p>
                    </div>
                    <div className={classes.answers}>
                      <p className={classes.boldFont}>Who gets rewarded</p>
                      <p>{optionReward.rewarded}</p>
                      {optionReward.rewardedOther && <p> - {optionReward.rewardedOther}</p>}
                    </div>
                  </div>
                  <div className={classes.rightside}>
                    {optionReward.image.src && (
                      <figure className={classes.picIcon}>
                        <img src={optionReward.image.src} alt="rewardImg" />
                      </figure>
                    )}
                  </div>
                </div>
              </>
            )}

          {/* {console.log('organization',organization)} */}
          {organization && (
            <>
              <div className={classes.horizontal_line} />
              <h3 className={classes.heading}>Organization</h3>
              <p>{organizationSelectedName}</p>
            </>
          )}
          <div className={classes.horizontal_line} />
          {/* On clicks need to be implemented */}
          <div className={classes.end_buttons}>
            <button type="button" disabled={isLoading ? 'true' : false } className={classes.cancel} onClick={handleCancleClick}>
               Cancel
            </button>
            <button type="button"  disabled={isLoading ? 'true' : false } className={classes.submit} onClick={handleSubmitClick}>
              {ChallangesIsActive
                ? i18n.t('CHALLENGES.SAVE_CHALLENGE')
                : i18n.t('CHALLENGES.LAUNCH')}{' '}
            </button>
          </div>
        </div>
      </Modal>
   
  )
}

ChallengePreview.propTypes = {
  title: PropTypes.string.isRequired,
  // images: PropTypes.array.isRequired,
  // problemDescription: PropTypes.string.isRequired,
  // whyNeeded: PropTypes.string.isRequired,
  // whatUnique: PropTypes.string.isRequired,
  // similarExisting: PropTypes.string.isRequired,
  // planDescription: PropTypes.string.isRequired,
  // benefitsDescription: PropTypes.string.isRequired,
}

export default withStyles(styles)(ChallengePreview)
