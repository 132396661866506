/* eslint-disable jam3/no-sanitizer-with-danger */

import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'
import styles from './View.Style'
import i18n from '../../support/i18n'
import HowItWorksVideo from './components/how_it_works_video/View'

class HowItWorks extends Component {
  componentDidMount() {
    const { domainDataLoaded, getHowItWorksInfo } = this.props
    if (domainDataLoaded) {
      getHowItWorksInfo()
    }
  }

  componentDidUpdate() {
    const { domainDataLoaded, infoPointsLoaded, getHowItWorksInfo } = this.props
    if (domainDataLoaded && !infoPointsLoaded) {
      getHowItWorksInfo()
    }
  }

  render() {
    const { classes, infoPoints } = this.props

    return (
      <div className={classes.top}>
        <h1>
          <i className="icon-help" /> {i18n.t('SCREEN.HOWITWORKS')}
        </h1>
        <div className={classes.container}>
          <HowItWorksVideo />
          <div className={classes.listContainter}>
            <div className={classes.listIcon}>
              <i className={clsx(classes.listLabel, 'icon-share')}></i>
            </div>
            <div className={classes.dashedIcon}></div>
            <ul className={classes.items}>
              {infoPoints.map((item, index) => (
                <li key={item.id} className={classes.item}>
                  <h1 className={classes.number}> {index + 1}. </h1>
                  <div className={classes.dashed}>
                    <div className={classes.listTitle}>{item.name}</div>
                    <span
                      dangerouslySetInnerHTML={{ __html: item.desc }}
                      className={classes.listText}
                    />
                  </div>
                </li>
              ))}
            </ul>
            <div className={classes.listIcon}>
              <i className={clsx(classes.listLabel, 'icon-ideas-implemented')}></i>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

HowItWorks.propTypes = {
  classes: PropTypes.shape({
    top: PropTypes.string,
    container: PropTypes.string,
    listContainer: PropTypes.string,
    listIcon: PropTypes.string,
    dashed: PropTypes.string,
    items: PropTypes.string,
    item: PropTypes.string,
    number: PropTypes.string,
    listTitle: PropTypes.string,
    listText: PropTypes.string,
  }).isRequired,
  domainDataLoaded: PropTypes.bool.isRequired,
  infoPoints: PropTypes.shape([]).isRequired,
  getHowItWorksInfo: PropTypes.func.isRequired,
}

export default withStyles(styles)(HowItWorks)
