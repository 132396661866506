/* eslint-disable jam3/no-sanitizer-with-danger */
import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import CardMedia from '@material-ui/core/CardMedia'
// import { Image } from 'components'
import Team from './img/team.png'
import styles from './View.Style'

const AboutUsDetails = props => {
  const { classes, aboutus } = props

  if (window.location.href.indexOf('VOIS') > -1) {
    return (
      <div className={classes.container}>
        <h3>ABOUT Nova </h3>
        <p>
          Welcome to NOVA! The community led platform that supports _VOIS on its mission to scale
          @speed by capturing and helping us bring to life the innovative ideas that can change the
          way we work and add value to our business, customers and colleagues.
        </p>
        <p>
          NOVA started its life in Group Enterprise IT in 2016 as an idea itself and is successfully
          used across Group &amp; Business as its innovation ecosystem. Now we are scaling it to use
          within _VOIS to give everyone the opportunity to actively contribute in creating the
          future of our organization, by sharing their innovative ideas that keep us moving.
        </p>
        <p>
          It’s a community platform run by NOVA champions from across all the functions in _VOIS.
          They have volunteered their time and they are passionate about change, co-creation and
          moving the world further.{' '}
        </p>
        <p>Join our community and let’s move the world together! Submit your ideas now! </p>

        <h3>WHY ARE WE DOING IT?</h3>
        <p>
          Our _VOIS Strategy is our Big Shifts and our Big Shifts help us get Future Ready. What
          worked in the past won’t necessarily work in the future. To move forwards, we have to
          experiment, learn fast and continuously challenge how we work &amp; do things, to get it
          done together.
        </p>
        <p>
          Nova is the platform that helps us foster a culture of innovation @_VOIS, where people
          feel safe to experiment, learn fast and challenge the status quo. The platform supports us
          on our mission to scale @speed and bring to life your innovative ideas that can change the
          way we work, adding value to our business and delighting our customers.{' '}
        </p>
        <p>
          Nova is also an enabler for unlocking our Vodafone Spirit Behaviors. Embarking on the
          journey of innovation and transformation requires curiosity and drive. It takes courage to
          experiment and learn fast along the way. Take part in the journey and bring your active
          contribution in creating the future @_VOIS!{' '}
        </p>
        <h3>HOW CAN YOU GET INVOLVED WITH NOVA? </h3>
        <p>
          You can share your ideas with us here on the platform at any time during the year. In case
          of any queries please reach out to respective service line NOVA Champion in the below
          table. You can also email us at{' '}
          <a
            href="mailto:voisnovasupport@vodafone.com"
            target="_blank"
            rel="noopener noreferrer"
            data-auth="NotApplicable"
            data-linkindex="0"
          >
            voisnovasupport@vodafone.com
          </a>{' '}
          if you have any questions or if you want to get involved in organizing innovation events
          or become part of our super energetic NOVA Core team. We are looking forward to support
          you in your innovation journey!
        </p>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <h3>What is NOVA?</h3>
      {/* <p>
        is an innovation management ecosystem that enables and empowers you to find funding, talent
        and leadership support to turn your ideas into successful realities. Simply if you have an
        idea, we will help you make it happen! NOVA started in Group Enterprise IT as an idea itself
        and now we are a big team of volunteers across the Business supporting the programme
        collaborating with start-ups, partners and our customers who are passionate about change,
        co-creation and turning your ideas into reality. NOVA started in 2016 in Vodafone Group
        (Vodafone Business), and has been scaling up through partnerships with various innovation
        teams across Vodafone. In 2021, NOVA is extending its reach along with the global VOIS team
        to help drive and deliver innovation. So what are you waiting for, submit your ideas now!
      </p> */}

      <span dangerouslySetInnerHTML={{ __html: aboutus.aboutUsAns1 }} />

      <h3>Why are we doing it?</h3>
      {/* <p>
        The Vodafone Way is what makes us stand out from the rest. We want to embody being
        "Innovation Hungry" by creating and delivering new products, services and ways of working,
        while being externally focussed and providing fresh thinking. Whilst we work in a climate
        that promotes innovation and risk-taking in the pursuit of our commercial objectives, the
        need for a more collaborative, structured, agile approach to innovation management across
        our business is apparent. We need an environment that enables us to learn fast, make rapid
        decisions and tap into our vast network of people - and this is where NOVA comes in! We
        believe we can provide you this environment, always looking into the future of your ideas
        that can change the way we work adding value to our business and delighting our customers.
      </p> */}
      <span dangerouslySetInnerHTML={{ __html: aboutus.aboutUsAns2 }} />
      <h3>How can you get involved with NOVA?</h3>
      {/* <p>
        You can share your ideas with us here on the platform at any time during the year, or join
        any of our Ideathon or Hackathon events or even our workshops. Check out Latest Events Here
        You can also email us at nova@vodafone.com if you have any questions or if you want to get
        involved in organizing innovation events, or become part of our super energetic NOVA Core
        team. We are looking forward to support you in your innovation journey!
      </p> */}
      <span dangerouslySetInnerHTML={{ __html: aboutus.aboutUsAns3 }} />
      <CardMedia className={classes.media} image={Team} title="Contemplative Reptile" />
    </div>
  )
}

AboutUsDetails.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(AboutUsDetails)
