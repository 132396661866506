import { connect } from 'react-redux'
import {
  showPopupSuccessSnackbar,
  showPopupErrorSnackbar,
  showAnotherPopupDialog,
} from 'support/popup_dialogs'
import i18n from 'support/i18n'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import { editProfile } from 'services/user'
import View from 'pagesNewUi/profile/components/user_detail_section/components/edit_my_profile_dialog/View'

const mapStateToProps = state => ({
  myName: state.data.user.userName,
  myLastName: state.data.user.userLastName,
  myEmailId: state.data.user.userEmail,
  myPhotoSrc: state.data.user.userPhoto,
  isEditUserLoading: state.data.user.isEditUserLoading,
})

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (name, lastName, photo, oldPwd, newPwd) => {
      const onSuccess = () => {
        dispatch(hideDialog())
        showPopupSuccessSnackbar(i18n.t('PROFILE.EDIT_INFO_SUCCESS'), dispatch)
      }
      const onFailure = errorResponse => {
        const { data } = errorResponse
        let i18nErrorKey = ''
        if (data && data.error && data.error.error) {
          i18nErrorKey = `BACKEND_ERRORS.${data.error.error}`
        }
        showPopupErrorSnackbar(
          i18n.t(i18nErrorKey, {
            defaults: [{ scope: 'PROFILE.ERROR_EDITING_INFO' }],
          }),
          dispatch,
        )
      }
      dispatch(editProfile(onSuccess, onFailure, name, lastName, photo, oldPwd, newPwd))
    },
    onSelectImage: component => showAnotherPopupDialog(component, dispatch),
    showErrorOnSubmit: errorMessage => showPopupErrorSnackbar(errorMessage, dispatch),
    onClose: () => dispatch(hideDialog()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
