/* eslint-disable react/jsx-no-bind */
import React, { useCallback } from 'react'
import * as PropTypes from 'prop-types'
import TablePagination from '@material-ui/core/TablePagination'
import i18n from 'support/i18n'
import TablePaginationActions from './components/pagination_actions/View'

const CustomPagination = props => {
  const { page, fullCount, rowsPerPage, onPaginationChange } = props

  function handleChangePage(event, newPage) {
    onPaginationChange(newPage, rowsPerPage)
  }

  function handleChangeRowsPerPage(event) {
    onPaginationChange(0, parseInt(event.target.value, 10))
  }

  function renderPaginationActions() {
    return (
      <TablePaginationActions
        fullCount={fullCount}
        page={page}
        rowsPerPage={rowsPerPage}
        onPaginationChange={onPaginationChange}
      />
    )
  }

  // const handleChangePage = useCallback((newPage) => {
  //     onPaginationChange(newPage, rowsPerPage)
  // }, [newPage,rowsPerPage]);

  // const handleChangeRowsPerPage = useCallback((event) => {
  //     onPaginationChange(0, parseInt(event.target.value, 10))
  // }, []);

  // const renderPaginationActions = useCallback(() => {
  //   return (
  //     <TablePaginationActions
  //       fullCount={fullCount}
  //       page={page}
  //       rowsPerPage={rowsPerPage}
  //       onPaginationChange={onPaginationChange}
  //     />
  //   )
  // }, []);

  return (
    <TablePagination
      component="div"
      rowsPerPageOptions={[5, 10, 25]}
      colSpan={3}
      count={fullCount}
      rowsPerPage={rowsPerPage}
      page={page}
      labelRowsPerPage={i18n.t('TABLESORTER.ROWS_PAGE')}
      SelectProps={{
        inputProps: { 'aria-label': i18n.t('PAGINATION.ROWS_PER_PAGE') },
        native: true,
      }}
      backIconButtonProps={{
        'aria-label': i18n.t('PAGINATION.PREVIOUS_PAGE'),
      }}
      nextIconButtonProps={{
        'aria-label': i18n.t('PAGINATION.NEXT_PAGE'),
      }}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      ActionsComponent={renderPaginationActions}
    />
  )
}

CustomPagination.propTypes = {
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  fullCount: PropTypes.number.isRequired,
  onPaginationChange: PropTypes.func.isRequired,
}

export default CustomPagination
