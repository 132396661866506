/* eslint-disable  prefer-template */
import React from "react";
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'


const Button = props => {
    const {
        classes,
        text,
        action,
        bgColor,
        btnDisable
    } = props
    // console.log('prop',props)
    return(
        <button type="button" style={{backgroundColor: bgColor}} onClick={action} className={btnDisable ? [classes.btnDisable + ' ' + classes.container ] : classes.container} >
            {text}
        </button>
    )
}

Button.propTypes = {
    text: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    bgColor: PropTypes.string,
}

Button.defaultProps = {
    bgColor: '#6D45FF'
}

export default withStyles(styles)(Button)