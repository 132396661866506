/* eslint-disable  react/jsx-no-undef */ 

import React from 'react'
import * as PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router'
import pages from 'common_constants/Pages'
import Logout from 'components_newui/Logout/View'
// import {
//   CustomLogin,
//   Error,
//   IdentityChoose,
//   // Login,
//   ResetPassword,
//   SignUp,
//   Validate,
//   Maintenance,
//   AzureLogin,
//   AdfsLogin,
//   LandingPage,
//   AzureSsoError,
//   NovaHome,
// } from 'pages'



import {
  NewLandingPage,
  IdentityChoose,  

} from 'pagesNewUi'
import NavigationService from 'support/navigation/NavigationService'
import AzureSSO from 'pagesNewUi/azure_sso/View'

const LoggedOutNavigator = props => {
  const {
    loggedIn,
    logging,
    lastDomainVisited,
    isDomainInMaintenance,
    shouldGoToMaintenance,
  } = props
  const ROUTES = NavigationService.getRoutes()
  // let loggedOutRedirect = lastDomainVisited
  //   ? ROUTES[pages.CUSTOM_LOGIN_PAGE].path.replace(':domain', lastDomainVisited)
  //   : ROUTES[pages.LOGIN_PAGE].path
  let loggedOutRedirect = lastDomainVisited
    ? ROUTES[pages.CUSTOM_LOGIN_PAGE].path.replace(':domain', lastDomainVisited)
    : ROUTES[pages.LANDING_PAGE].path
  if (isDomainInMaintenance && shouldGoToMaintenance) {
    loggedOutRedirect = ROUTES[pages.MAINTENANCE].path.replace(':domain', lastDomainVisited)
  }
  // console.log("hello /there")
  return (
    
      <Switch>
        <Route
          exact
          path={ROUTES[pages.LANDING_PAGE].path}
          render={() => <NewLandingPage />  }
          />
         <Route
          exact
          path={ROUTES[pages.LOGOUT_PAGE].path}
          render={() => <Logout />  }
          />
          
        <Route exact path="/sso" render={() => <AzureSSO />} />
        <Route exact path={ROUTES[pages.SSO_AUTH_ERROR].path} render={() => <AzureSsoError />} />

        <Route
          exact
          path={ROUTES[pages.IDENTITY_CHOOSE_PAGE].path}
          render={componentProps => logging ? <IdentityChoose {...componentProps} /> : <Redirect to={ROUTES.login.path} />  }
        />
        {/* <Route
          exact
          path={ROUTES[pages.CUSTOM_LOGIN_PAGE].path}
          render={componentProps => <CustomLogin {...componentProps} />}
        /> */}
        {/* <Route
          exact
          path={ROUTES[pages.AZURE_LOGIN].path}
          render={componentProps => <AzureLogin {...componentProps} />}
        />
        <Route
          exact
          path={ROUTES[pages.ADFS_LOGIN].path}
          render={componentProps => <AdfsLogin {...componentProps} />}
        /> */}
        {/* <Route
          exact
          path={ROUTES[pages.LOGIN_PAGE].path}
          render={componentProps => <Login {...componentProps} />}
        /> */}
        <Route
          exact
          path={ROUTES[pages.RESET_PASSWORD].path}
          render={componentProps => <ResetPassword {...componentProps} />}
        />
        {/* <Route
          exact
          path={ROUTES[pages.SIGN_UP].path}
          render={componentProps => <SignUp {...componentProps} />}
        /> */}
        {/* <Route
          exact
          path={ROUTES[pages.VALIDATE].path}
          render={componentProps => <Validate {...componentProps} />}
        /> */}
        <Route
          // path={ROUTES[pages.MEDIA_FILE].path}
          path="/static/*"
          // render={() => <LandingPage />} />
          render={() => <Maintenance />}
        />
        {isDomainInMaintenance && (
          <Route
            exact
            path={ROUTES[pages.MAINTENANCE].path}
            render={componentProps => <Maintenance {...componentProps} />}
          />
        )}
        <Route render={() => (loggedIn ? <Error /> : <Redirect to={loggedOutRedirect} />)} />
      </Switch>
    
  )
}

LoggedOutNavigator.propTypes = {
  classes: PropTypes.shape({
    base: PropTypes.string.isRequired,
  }).isRequired,
  isDomainInMaintenance: PropTypes.bool.isRequired,
  shouldGoToMaintenance: PropTypes.bool.isRequired,
}

export default LoggedOutNavigator
