/* eslint-disable no-shadow */

import React, {  useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'
import { FIRST_LIMIT_CONSTANT, LIMIT_CONSTANT } from 'common_constants/Ideas'
import IdeaPanel from 'components_newui/idea_panel/View'
import Breadcrumbs from 'components_newui/pageBreadcrumbsMenu/View'
import RecommendedIdeas from 'components_newui/recommended_ideas/View'
import FilterComponent from 'components/filter_component/View'
import noDraftIdeasImg from '../../img/no_draft_idea.png'
import Navigation from 'support/navigation'
import NxtCircularLoader from 'components/nxt_circular_loader/View'
import { Button, Container } from '@material-ui/core'
import i18n from 'support/i18n'
import * as pages from 'common_constants/Pages'
import NavigationService from 'support/navigation/NavigationService'


const AllIdeas = props => {

const ROUTES = NavigationService.getRoutes()
const AllInvestedIdeas_path =  ROUTES[pages.MY_INVESTED_IDEAS].path


    const {
        classes, 
        domainLoaded,
        ideas,
        isIdeasLoading,
        itemsIdeas,
        ideasInvested,
        challenges,
        totalIdeas,
        hasMoreIdeasToLoad,
        footerInvestors,
        footerEntrepreneurs,
        headerSection,
        helpList,
        organizationsName,
        serviceLineOrgs,
        folderOrganizations,
        typesStatus,
        typeTitle,
        useHelpSection,
        ideaStatusStepper,
        isOrganizationsActive,
        isTypesActive,
        isPrivateFlowActive,
        privateBannerSrc,
        organisationsSelected,
        hasUserOrganisationsLoaded,
        expiringIdeas,
        navigateToIdea,
        getUserOrganisations,
        getActiveOrganization,
        getActiveTypes,
        applySearch,
        navigateToInvestedIdeas
    } = props

    // We need a useState for offset I think

    const [searchQuery, setSearchQuery] = useState("")
    const [offset,setOffset] = useState(0);    

    const description = "See what others have proposed!"

    // Maybe have page hierarchy come from state????? at least get navigate functions from state
    const pageHierarchy = [
        {string: "Home", navigate: ()=>Navigation.navigateToExplore()}, 
        {string: "All Ideas", navigate: ()=>{}}
    ]

    const [activeFilters, setActiveFilters] = useState({
        ideaStatusFilter: [],
        challenge: {},
        organizationsFilter: [],
        types: [],
    })


    useEffect(()=> {
        const {
            getHelpSection,
            getHeaderSection,
        } = props
        if (useHelpSection){
            getHeaderSection()
            getHelpSection()
        }
    }, [useHelpSection])

    useEffect(() => {
        if (domainLoaded){
            getUserOrganisations()
            getActiveOrganization()
            getActiveTypes()
            //   getRankings()
            //   getActiveChallenges()
            //   getTypes()
            //   getExpiringNearFunded()
        }
    }, [domainLoaded])

    const getIdeas = (offset = 0) => { 
        const { applyFilter } = props
        const { ideaStatusFilter, challenge, types, organizationsFilter,ideasLocation } = activeFilters
        // console.log(serviceLineOrgs.filter(g => organizationsFilter.includes(g.id)).map(g => g.name))
       const serviceLineOrgsNameList = serviceLineOrgs.filter(item => organizationsFilter.includes(item.id)).map(itemName => itemName.name)
        
        applyFilter(
          challenge.id || '',
          serviceLineOrgsNameList,
          ideaStatusFilter,
          types,
          ideasLocation,
          offset ? LIMIT_CONSTANT : FIRST_LIMIT_CONSTANT,
          offset,
        )
    }
    const handleActiveFilters = async (newFilters) => { 
        await setActiveFilters(newFilters)
        setOffset(0) 
    }

    useEffect(()=> {
        getIdeas()
    }, [activeFilters])



const handleOnLoadMore = ()=>{
  const newLimit = offset ? LIMIT_CONSTANT : FIRST_LIMIT_CONSTANT
  const newOffset = newLimit + offset
//   console.log("nw",newLimit,newOffset)
  getIdeas(newOffset)
  setOffset(() => newOffset)
}

// totatl count of My investment
const countTotalMyInvestedIdeas = ideasInvested.length


    return(
        <>
            <Breadcrumbs crumbs={pageHierarchy} />
            <Container>
            <main className={classes.background}>

                <div className={classes.content_container}>
                    <div className={classes.top_section_text}>
                        <p className={classes.title}>All ideas</p>
                        <Link to={`${AllInvestedIdeas_path}`} className={classes.invested_ideas_number}>My invested ideas  ({countTotalMyInvestedIdeas})</Link>
                        {/* <button type='button' className={classes.invested_ideas_number} onClick={navigateToInvestedIdeas}>My invested ideas  ({countTotalMyInvestedIdeas})</button> */}
                    </div>
                </div>
                {/* <RecommendedIdeas onMainButtonClick={navigateToIdea} recommendedIdeas={ideas.slice(0, 9)} /> */}
                
                <p className={classes.lower_title}>All Ideas ({totalIdeas})</p>
                <p className={classes.lower_description}>{description}</p>
               
                <FilterComponent
                    activeFilters={activeFilters}
                    serviceLineOrgs={serviceLineOrgs}
                    types={typesStatus}
                    ideaStatusFilter={ideaStatusStepper}
                    handleActiveFilters={handleActiveFilters}
                    search={searchQuery}
                    setSearch={setSearchQuery}
                    applySearch={applySearch}
                    resetSearch={getIdeas}
                />
                

                <NxtCircularLoader isLoading={isIdeasLoading}>
                <div className={classes.content_container}>
                    {ideas.length > 0 ?
                    <div className={classes.IdeaGrid}>
                        {ideas.map((idea) => <IdeaPanel key={idea.id} urlId={idea.id} idea={idea}/>)}
                    </div>
                    :
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                        <img src={noDraftIdeasImg} className={classes.no_idea_img} alt="no idea found" />
                        <div className={classes.no_idea_title}>
                            No ideas found
                        </div>
                    </div>
                    }
                </div>
                 
                    { itemsIdeas >= 11 && itemsIdeas !== 0 ? (
                        <div className={classes.loadMoreWrapper}>
                            <button type='button' className={classes.loadmorebtn} onClick={handleOnLoadMore}>
                            {i18n.t('HOME.FILTER.LOAD_MORE')}
                            </button>
                        </div>
                    ):null}
                </NxtCircularLoader>
                
            </main>
            </Container>
        </>
    )
}

export default withStyles(styles)(AllIdeas)