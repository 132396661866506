/* eslint-disable react/function-component-definition,react/jsx-no-bind */

import React, { useCallback } from 'react'
import * as PropTypes from 'prop-types'
import styles from './View.Style'
import withStyles from '@material-ui/core/styles/withStyles'
import Box from '@material-ui/core/Box';
// import { borders } from '@material-ui/system';


function landingPageBlock(props) {
  const {title, description, icon, classes,} = props

  function showBorder(e) {
    e.target.style.border = '1px solid #6551FF';
    e.target.style.borderRadius = '15px';
  }
  function hideBorder(e){
    e.target.style.border = '0px'
  }

  // const showBorder = useCallback((e) => {
  //   e.target.style.border = '1px solid #6551FF';
  //   e.target.style.borderRadius = '15px';
  // }, []);
  // const hideBorder = useCallback((e) => {
  //     e.target.style.border = '0px'
  // }, []);

  return (
    <Box onMouseEnter={showBorder} onMouseLeave={hideBorder}>
      <div className={classes.block}>
        <img className={classes.icon} src={icon} alt="img not found"/>
        <p className={classes.title}>{title}</p>
        <p className={classes.description}>{description}</p>
      </div>
    </Box>
  )
}

landingPageBlock.defaultProps = {
  title: '',
  src: '',
  description: '',
}



export default withStyles(styles)(landingPageBlock)
