/* eslint-disable react/destructuring-assignment,  no-unneeded-ternary */
/* eslint-disable react/sort-comp */


import React, { Component } from 'react'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Mature from '../../assets/mature.png'
import Box from '@material-ui/core/Box'
import PrettoSlider from '../pretto_slider/View'
import TextField from '@material-ui/core/TextField'
import EvaluatorCommentsList from '../evaluator_comments/View'
// import { withStyles, makeStyles } from '@material-ui/core/styles'
// import style from './View.Style.js'

const marks = [{ value: 1, label: 1 }, { value: 2, label: 2 }, { value: 3, label: 3 }]

class EvaluationMature extends Component {
  constructor(props) {
    super(props)
    this.state = {
      textValue: '',
      sliderVal: 0,
      loadComments: false,
    }
    this.sliderValue = this.sliderValue.bind(this)
    this.handleTextChange = this.handleTextChange.bind(this)
    this.sendEvaluationMature = this.sendEvaluationMature.bind(this)
    this.loadPrevComments = this.loadPrevComments.bind(this)
  }

  componentDidMount() {
    const { getEvaluationDraft, title } = this.props
    if (title) getEvaluationDraft(title)
  }

  componentDidUpdate(prevProps) {
    const { evaluationIdea } = this.props
    const { Q3Comment, Q3measurement } = evaluationIdea
    if (prevProps.evaluationIdea !== this.props.evaluationIdea) {
      this.setState({ textValue: Q3Comment, sliderVal: Q3measurement })
    }
  }

  sendEvaluationMature() {
    const { textValue, sliderVal } = this.state
    const { postEvaluationIdea, title, evaluationIdea } = this.props
    const { Q1Comment, Q1measurement, Q2Comment, Q2measurement } = evaluationIdea
    postEvaluationIdea(
      title,
      Q1Comment,
      Q1measurement || 0,
      Q2Comment,
      Q2measurement || 0,
      textValue,
      sliderVal || 0,
    )
  }
  
  handleTextChange(event) {
    const { value } = event.target
    this.setState({ textValue: value })
  }

  sliderValue = (event, newValue) => {
   
    this.setState({ sliderVal: newValue })
  }
  
  loadPrevComments() {
    this.setState( (prevState)=>(
      { loadComments: !prevState.loadComments }
    ))
  }

  render() {
    const { goBack, evaluationLoading, commentsList } = this.props
    const { sliderVal, textValue, loadComments } = this.state

    return (
      <>
        <Box item xs={12} width="100%" maxHeight="420px">
          {/*Right grid*/}

          <Container
            style={{ height: '100%', width: '100%', backgroundColor: 'white', padding: '30px' }}
          >
            <Box display="flex" justifyContent="center">
              {/*Disabled overrieds normal color="secondary" syntax, so will need ID for higher priority CSS*/}
              <Box px={1}>
                <Button variant="contained" px={3} color="primary"></Button>
              </Box>
              <Box px={1}>
                <Button variant="contained" px={3} color="primary"></Button>
              </Box>
              <Box px={1}>
                <Button variant="contained" px={3} color="primary"></Button>
              </Box>
              <Box px={1}>
                <Button variant="contained" px={3} disabled></Button>
              </Box>
            </Box>
            <Box py={1} display="flex" justifyContent="center">
              {/*Variant affects actual size, component keeps HTML nerds happy by having a logical progression of H1 to H6 etc*/}
              <Typography variant="h5" component="h1">
                3. Is this idea mature/ detailed enough to go to the next phase?
              </Typography>
            </Box>
            <Box py={1} display="flex" justifyContent="center">
              <img src={Mature} alt="logo" height="10%" width="10%" />
            </Box>
            <Box py={1} display="flex" justifyContent="center">
              <PrettoSlider
                value={sliderVal}
                onChange={this.sliderValue}
                step={1}
                marks={marks}
                min={0}
                max={3}
              />
            </Box>
            <Box pt={3} display="flex" justifyContent="center">
              <TextField
                id="outlined-multiline-static"
                value={textValue ? textValue : ''}
                onChange={this.handleTextChange}
                label="Comments"
                multiline
                rowsMax={3}
                variant="outlined"
                style={{ width: '50vw' }}
              />
            </Box>
            <Box pt={1} display="flex" justifyContent="center">
              <Box px={1}>
                <Button
                  disabled={evaluationLoading}
                  variant="contained"
                  px={3}
                  id="redBackground"
                  height="100%"
                  color="primary"
                  onClick={this.loadPrevComments}
                >
                  Prev Comments
                </Button>
              </Box>
              <Box px={1}>
                <Button
                  disabled={evaluationLoading}
                  variant="contained"
                  px={3}
                  id="redBackground"
                  color="primary"
                  height="100%"
                  onClick={goBack}
                >
                  Back
                </Button>
              </Box>
              <Box px={1}>
                <Button
                  disabled={evaluationLoading}
                  variant="contained"
                  px={3}
                  id="redBackground"
                  color="primary"
                  height="100%"
                  onClick={this.sendEvaluationMature}
                >
                  Save & Next
                </Button>
              </Box>
              {/* <Box px={1}>
                <Button
                  disabled={evaluationLoading}
                  variant="contained"
                  px={3}
                  id="redBackground"
                  color="primary"
                  height="100%"
                  onClick={goNext}
                >
                  Next
                </Button>
              </Box> */}
            </Box>
          </Container>
        </Box>
        {loadComments && (
          <EvaluatorCommentsList
            stage={3}
            commentsList={commentsList}
            open={loadComments}
            handleDialog={this.loadPrevComments}
          />
        )}
      </>
    )
  }
}
export default EvaluationMature
