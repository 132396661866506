import { connect } from 'react-redux'
import { getInvestments ,applyFilterService} from 'services/user'
import Navigation from 'support/navigation'
import AnalyticsService from 'support/analytics'
import { FIRST_LIMIT_CONSTANT } from 'common_constants/Ideas'
import { applySearchService } from 'services/ideas'
import { getActiveOrganizations } from 'services/organizations'
import View from './View'
import { getActiveTypes } from 'services/types'

const mapStateToProps = state => {
  return {
    ideaALL:state.data,
    ideas: state.data.idea.data,
    isIdeasLoading: state.data.idea.ideasLoading,
    serviceLineOrgs: state.data.domain.organizations,
    ideasInvested: state.data.user.investments.ideas,
    totalInvested: state.data.user.investments.totalInvested,
    totalProfit: state.data.user.investments.totalProfit,
    fullInfoLoaded: state.data.domain.dataLoaded,
    userEmail: state.data.user.userEmail,
    typesStaus: state.data.domain.types,
    ideaStatusStepper: state.data.domain.domainConfiguration.ideaStatusStepper,
    domainLoaded: state.data.domain.dataLoaded,
  }
}

const mapDispatchToProps = dispatch => {
  return {  
    applyFilter: (userEmail,
      organizations,
      status,
      types,
      limit = FIRST_LIMIT_CONSTANT,
      offset = 0,
     ) => { 
      dispatch(
        applyFilterService(
          userEmail,
          organizations,
          status,
          types,
          limit,
          offset,
        ),
      )
    },
    getInvestments: userEmail => {
      dispatch(getInvestments(userEmail))
    },
    getActiveOrganizations: () => dispatch(getActiveOrganizations()),
    getTypes: () => dispatch(getActiveTypes()),
    navigateToIdea: (id, index) => {
      dispatch(Navigation.navigateToIdea(id))
      AnalyticsService.trackEvent({
        category: 'swiper',
        action: 'click',
        label: 'idea panel',
        value: index,
      })
    },
    applySearch: search => dispatch(applySearchService({ search })),
    resetSearch: () => dispatch(applySearchService()),
    navigateToAllIdeas: () => {
      dispatch(Navigation.navigateToAllIdeas())
    },
    onTitleClick: id => {
      dispatch(Navigation.navigateToIdea(id))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
