import { createStyles } from '@material-ui/core'

const styles = () =>
  createStyles({
    container: {
      width: '230px',
      position: 'absolute',
      backgroundColor: '#FFF',
      top: '15px',
      zIndex: 1201,
      right: 0,
      borderRadius: '4px',
      boxShadow: '0 .25rem .75rem 0 rgba(34,48,62,.14)',
      // boxShadow: '0 1px 1px rgba(0, 0, 0, 0.35)',
      // padding: '10% 0',
      padding: '8px 0',
      '&:before': {
        position: 'absolute',
        top: '-7px',
        right: '7px',
        display: 'inline-block',
        borderRight: '7px solid transparent',
        borderBottom: '7px solid #FFF',
        borderLeft: '7px solid transparent',
        borderBottomColor: 'rgba(0, 0, 0, 0.2)',
        content: '""',
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        top: '-10px',
        right: '7px',
        margin: 'auto',
        height: 0,
        width: 0,
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderBottom: '10px solid #fff',
      },
      '& li': {
        // fontSize: '16px',
        cursor: 'pointer',
        listStyleType: 'none',
        clear: 'both',
        // color: 'black',
      },
      '& li:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
      '& li > a': {
        textDecoration: 'none',
        // color: 'inherit',
        // padding: '7% 8%',
        width: '100%',
        fontSize: '1rem',
        fontFamily: 'VodafoneRg-Regular',
        textTransform:'capitalize'
      },
      '& li > a > span': {
        color: 'rgba(0, 0, 0, 0.54)',
        padding: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
      },
      '& li > a > span > [class^="icon-"]': {
        color: 'rgba(0, 0, 0, 0.54)',
        fontWeight: 'bold',
        marginRight: '1em',
      },
    },
    userRole:{
      marginLeft:'8px'
    },
    userinfo:{
      display:'flex',
      alignItems: 'center',
      width: '100%',
      padding: '8px 0'
    }
  })

export default styles
